import React, { useRef, useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import PropTypes from "prop-types";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';




import { colors } from '../../constants/Colors';
import TALKING_HEAD from '../../assets/talking-head.png'
import AUDIO_WAVE from '../../assets/audio-wave.gif'
import STOP_ICON from '../../assets/stop-icon.png'
import CONFETTI from '../../assets/confetti.png'

import { getStreamingAxiosInstance } from '../../utils/ApiUtils';

import config from '../../env.json'
import { useSelector } from 'react-redux';

import styles from './CandidateQuestion.module.css'

export default function CandidateQuestion(props) {
  const { BASE_URI } = config

  const [isListening, setIsListening] = useState(false);
  const [videoURL, setVideoURL] = useState(null)
  const [hideStopButton, setHideStopButton] = useState(false)

  const firstName = useSelector((state) => state.auth.firstName)


  const {
    id,
    question,
    questionURI,
    submitResponseForFeedback,
    setCandidateResponseId,
    candidateResponseId,
    token,
    questionType,
    transcript,
    submittedResponse,
    setIsTranscribingResponse,
    submissionSuccessful,
    awaitingEvaluation
  } = props

  useEffect(() => {

    if (candidateResponseId) {
      getVideoForInterviewQuestion(candidateResponseId)
    }

  }, [candidateResponseId])

  const getVideoForInterviewQuestion = (candidateResponseId) => {
    getStreamingAxiosInstance(token).get(`${BASE_URI}/interviews/asset/mp4/${questionType}/${candidateResponseId}`)
      .then(response => {
        let newVideoBlob = new Blob([response.data], { type: 'video/webm;codecs="vp8,opus"' })
        setVideoURL(URL.createObjectURL(newVideoBlob))
      })
      .catch((error) => {

      })
  }

  const microphoneRef = useRef(null);
  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return (
      <div className="mircophone-container">
        Browser is not Support Speech Recognition.
      </div>
    );
  }
  const handleListing = () => {
    setCandidateResponseId(candidateResponseId)
    setIsListening(true);
    setIsTranscribingResponse(true)
    microphoneRef.current.classList.add("listening");
    SpeechRecognition.startListening({
      continuous: true,
    });
  };
  const stopHandle = () => {
    setIsTranscribingResponse(false)
    setIsListening(false);
    microphoneRef.current.classList.remove("listening")
    SpeechRecognition.stopListening()

    submitResponseForFeedback()

  }

  const renderTranscriptionButton = () => {
    if (!isListening && (!submittedResponse || submittedResponse == false)) {
      return (
        <Button
          variant='outlined'
          style={{ borderColor: 'black', width: 250, }}
        >
          <Stack direction='row'>
            <Typography
              style={{ color: colors.blazeRed, fontSize: 32, fontWeight: 'bold' }}
            >
              ANSWER
            </Typography>
          </Stack>
          <Box
            component="img"
            style={{ height: 35, width: 35, marginLeft: 10 }}
            alt="Image of a face talking."
            src={TALKING_HEAD}
          />
        </Button>
      )
    }
    else if (!isListening && submittedResponse == true) {
      return (
        <Stack
        direction='row'
        className={styles.completedResponseWrapper}
      >
        <Typography className={styles.completedResponseText}>COMPLETED</Typography>
        <img src={CONFETTI} className={styles.completedIcon} />
      </Stack>
      )
    }
  }

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <Stack>
        <Stack className={styles.videoPlayerWrapper}>
          <ReactPlayer
            url={videoURL ? videoURL : null}
            height={500}
            width={650}
            playing
          />
        </Stack>
        <div
          ref={microphoneRef}
          onClick={!submittedResponse ? handleListing : null}
          className={styles.answerWrapper}
        >

          <Stack style={{ justifyContent: 'center', alignItems: 'center' }}>
            {renderTranscriptionButton()}
          </Stack>
        </div>

        <div
          style={{ width: '100%', justifyContent: 'center', alignItems: 'center', }}
        >
          <Stack style={{ justifyContent: 'center', alignItems: 'center' }}>
            {isListening && !submittedResponse && (
              <Button
                variant='outlined'
                style={{ borderColor: 'black', width: 250 }}
                onClick={stopHandle}
              >
                <Stack direction='row'>
                  <Typography
                    style={{ color: colors.blazeRed, fontSize: 32, fontWeight: 'bold' }}
                  >
                    STOP
                  </Typography>
                </Stack>
                <Box
                  component="img"
                  style={{ height: 35, width: 35, marginLeft: 10 }}
                  alt="Animated wavelength"
                  src={AUDIO_WAVE}
                />
              </Button>
            )}
          </Stack>
        </div>
      </Stack>
      <Stack className={styles.transcriptWrapper}>
        <Stack direction='row'>
          <Typography>
            <strong>Hiring Manager: </strong>
            {question}
          </Typography>

        </Stack>
        <Stack direction='row'>
          <Typography>
            <strong>{firstName ? `${firstName.trim()}:` : 'You:'} </strong>
            {transcript}
          </Typography>
        </Stack>
      </Stack>
    </div>
  )
}

CandidateQuestion.propTypes = {
  id: PropTypes.string,
  question: PropTypes.string,
  questionURI: PropTypes.string,
  questionId: PropTypes.string,
  submitResponseForFeedback: PropTypes.func,
  setCandidateResponseId: PropTypes.func,
  candidateResponseId: PropTypes.string,
  questionType: PropTypes.string,
  setIsTranscribingResponse: PropTypes.func,
  token: PropTypes.string,
  submittedResponse: PropTypes.bool,
  submissionSuccessful: PropTypes.bool,
  awaitingEvaluation: PropTypes.bool
};

