const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';
const seafoam = '#a0d6b4'

export const colors = {
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
  },
  // seafoam: '#a0d6b4',
  seafoam: '#51c1a6',
  sunrise: '#f5c801',
  paleSunFlower: '#ffeea2',
  plum: '#4a0c3f',
  cloudy: '#8fbdff',
  offWhite: '#f7f7f7',
  coolBlue: '#72c6d8',
  slateBlue: '#4d6772',
  oceanBlue: '#199fbc',
  // passionFruit: '#ffadad',
  // passionFruitDark: '#ff5252'
  //-----------------------------
  blazeRed: '#F05454',
  navyBlue: '#30475E',
  lightGray: '#E8E8E8',
  coolBlack: '#222831'


};
