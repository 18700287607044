import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Cookies from 'js-cookie';

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField"
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "react-datepicker";

import PropTypes from 'prop-types'
import { colors } from '../../../constants/Colors';
import { addEducationHistory } from '../../../redux/actions/userActions';

export default function AddEducationModal(props) {

  const dispatch = useDispatch()

  const {
    isVisible,
    handleClose,
  } = props

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    padding: 40,
    height: '80%',
    overflowY: 'scroll',
    p: 4,
  };

  // const token = Cookies.get('token')
  const token = Cookies.get('token');


  const authedUserId = useSelector((state) => state.auth.id)

  const [school, setSchool] = useState('')
  const [degree, setDegree] = useState('')
  const [fieldOfStudy, setFieldOfStudy] = useState('')
  const [attendanceType, setAttendanceType] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [grade, setGrade] = useState(0)
  const [currentActivity, setCurrentActivity] = useState('')
  const [activities, setActivities] = useState([])
  

  const attendanceTypeOptions = [
    'Full-Time',
    'Part-Time',
  ]

  const handleAttendanceTypeChange = (event, type) => {
    const {
      target: { value },
    } = event;

    setAttendanceType(value)
  }

  const renderAttendanceTypes = () => {
    if (attendanceTypeOptions.length > 0) {
      return attendanceTypeOptions.map((option, index) => {
        return (
          <MenuItem key={index} value={index}>{option}</MenuItem>
        )
      })
    }
  }

  

  const addActivity = () => {
    const activity = {
      id: Math.floor(Math.random() * 100),
      value: currentActivity
    }

    setActivities(activities.concat(activity))
    setCurrentActivity('')
  }

  const removeActivitiy = (activityId) => {
    const updatedActivities = activities.filter((activity) => activity.id !== activityId)
    setActivities(updatedActivities)
  }

  const renderActivities = () => {
    if (activities.length > 0) {
      return activities.map((activity, index) => {
        return (
          <Stack key={index} direction='row' style={{justifyContent: 'space-between', width: '80%', margin: 5}}>
            <Typography>{activity.value}</Typography>
            <Stack>
              <Button
                onClick={() => removeActivitiy(activity.id)}
                style={{height: 30, width: 30, backgroundColor: colors.blazeRed}}
              >
                <Typography style={{color: 'white'}}>X</Typography>
              </Button>
            </Stack>
          </Stack>
        )
      })
    }
  }

  const addEducation = () => {

    const educationConfig = {
      userId: authedUserId,
      school: school,
      degree: degree,
      fieldOfStudy: fieldOfStudy,
      attendanceType: attendanceTypeOptions[attendanceType],
      startDate: startDate,
      endDate: endDate,
      grade: grade,
      activities: activities
    
    }

    dispatch(addEducationHistory(educationConfig, token, handleClose))
  }


  return (
    <div>
      <Modal
        open={isVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Typography 
            id="modal-modal-title" variant="h6" component="h2"
            style={{textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5, fontSize: 20}}>
          
            EDUCATION DETAILS
          </Typography>
          <Stack style={{marginTop: 20}}>
            <Typography style={{fontSize: 16, color: colors.plum, fontWeight: '700'}}>School<span style={{color: colors.blazeRed, fontWeight: 'bold', fontSize: 18}}>*</span></Typography>
            <TextField
              onChange={(event) => setSchool(event.target.value)}
              value={school ? school : null}
              style={{ borderWidth: 1, width: '80%' }}
            />
          </Stack>
          <Stack style={{marginTop: 20}}>
            <Typography style={{fontSize: 16, color: colors.plum, fontWeight: '700'}}>Degree</Typography>
            <TextField
              onChange={(event) => setDegree(event.target.value)}
              value={degree ? degree : null}
              style={{ borderWidth: 1, width: '80%' }}
            />
          </Stack>
          <Stack style={{marginTop: 20}}>
            <Typography style={{fontSize: 16, color: colors.plum, fontWeight: '700'}}>Field of Study</Typography>
            <TextField
              onChange={(event) => setFieldOfStudy(event.target.value)}
              value={fieldOfStudy ? fieldOfStudy : null}
              style={{ borderWidth: 1, width: '80%' }}
            />
          </Stack>
          <Stack style={{marginTop: 20}}>
            <Typography style={{fontSize: 16, color: colors.plum, fontWeight: '700'}}>Attendance Type</Typography>
            <Select
              labelId="attendance-type-label"
              id="attendance-type-id"
              value={attendanceType}
              // label="Interview"
              style={{ width: '80%' }}
              onChange={(event) => handleAttendanceTypeChange(event)}
            >
              {renderAttendanceTypes()}
            </Select>
          </Stack>
         
          <Stack direction='row' style={{marginTop: 20, marginBottom: 20, alignItems: 'center'}}>
            <Stack style={{ height: 60, zIndex: 2 }}>
              <Typography style={{fontSize: 16, color: colors.plum, fontWeight: '700'}}>Start Date<span style={{color: colors.blazeRed, fontWeight: 'bold', fontSize: 16}}>*</span></Typography>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </Stack>

            <Stack style={{ height: 60, zIndex: 3, marginLeft: 30 }}>
              <Typography style={{fontSize: 16, color: colors.plum, fontWeight: '700'}}>End date (or expected)</Typography>
              <DatePicker
                selected={endDate}
                minDate={startDate}
                onChange={(date) => setEndDate(date)}
              />
            </Stack>
          </Stack>

          <Stack style={{marginTop: 20}}>
            <Typography style={{fontSize: 16, color: colors.plum, fontWeight: '700'}}>Grade Point Average</Typography>
            <TextField
              onChange={(event) => setGrade(event.target.value)}
              value={grade ? grade : null}
              style={{ borderWidth: 1, width: '80%' }}
            />
          </Stack>

          <Typography style={{fontSize: 16, color: colors.plum, fontWeight: '700', paddingTop: 20}}>Add details about your experience including any activities or societies you participated in.</Typography>
          <Stack direction='row' style={{justifyContent: 'space-between', alignItems: 'center', width: '80%'}}>
            
            <TextField 
              onChange={(event) => setCurrentActivity(event.target.value)}
              value={currentActivity}
              multiline
              rows={3}
              style={{borderWidth: 1, width: '80%',}}
            />
            <Button
               style={{ backgroundColor: colors.lightGray, height: 45, width: 45 }}
               onClick={() => addActivity()}
            >
                <Typography style={{color: 'black'}}>ADD</Typography>
              </Button>
          </Stack>

          {renderActivities()}




          <Stack direction='row' style={{ justifyContent: 'space-around', alignItems: 'center', marginTop: 50 }}>
            <Stack>
              <Button variant='contained'
                style={{ backgroundColor: 'silver', height: 45, width: 150 }}
                onClick={() => handleClose()}
              >
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>CANCEL</Typography>
              </Button>
            </Stack>
            <Stack>
              <Button variant='contained'
                style={{ backgroundColor: 'purple', height: 45, width: 150 }}
                onClick={() => addEducation()}
              >
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>SAVE</Typography>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>

  );
}

AddEducationModal.propTypes = {
  isVisible: PropTypes.bool,
  handleClose: PropTypes.func,
  triggerAction: PropTypes.func,
  triggerText: PropTypes.string,
}