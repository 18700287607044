import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';

import Stack from '@mui/material/Stack'
import SidebarNav from '../../components/navigation/SidebarNav'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField';

import StandardButton from '../../components/buttons/StandardButton'
import { colors } from '../../constants/Colors'

import { changeUserPassword, logOutUser } from '../../redux/actions/authenticationActions'
import { showErrorToast } from '../../utils/ToastUtils'


export default function SettingsScreen() {

  const dispatch = useDispatch()

  const authedUserId = useSelector((state) => state.auth.id)
  const token = Cookies.get('token')
  const navigate = useNavigate()


  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')


  const loggingUserOut = () => {
    sessionStorage.clear();
    Cookies.remove('token', { path: '/', });
    dispatch(logOutUser())
    navigate('../login', {replace: true})
  }

  const submitPasswordChange = () => {

    if (currentPassword === '') {
      showErrorToast('Current password cannot be blank')
      return
    }
    else if (newPassword === '') {
      showErrorToast('New password cannot be blank.')
      return
    }
    else if (passwordConfirmation === '') {
      showErrorToast('Confirmed password cannot be blank.')
      return
    }
    else if (newPassword !== passwordConfirmation) {
      showErrorToast('New password and confirmed password do not match.')
      return
    }

    const passwordConfig = {
      userId: authedUserId,
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmedPassword: passwordConfirmation,
    }

    dispatch(changeUserPassword(passwordConfig, token, loggingUserOut))
  }


  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <SidebarNav />
      <Stack style={{ marginTop: 50, marginLeft: 50, paddingBottom: 50 }}>
        <Stack style={{ width: '100%' }}>
          <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4 }}>RESET PASSWORD</Typography>
          <Stack style={{ marginTop: 40 }}>
            <Typography style={{ fontSize: 18 }}>Current Password</Typography>
            <TextField
              type="password"
              value={currentPassword}
              onChange={(event) => setCurrentPassword(event.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />

            <Typography style={{ fontSize: 18 }}>New Password</Typography>

            <TextField
              type="password"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />

            <Typography style={{ fontSize: 18 }}>Confirm New Password</Typography>

            <TextField
              type="password"
              value={passwordConfirmation}
              onChange={(event) => setPasswordConfirmation(event.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Stack>
          <Stack style={{ marginTop: 30 }}>
            <StandardButton
              label='CHANGE PASSWORD'
              backgroundColor={colors.oceanBlue}
              buttonWidth={280}
              onPress={() => submitPasswordChange()}
            />
          </Stack>
        </Stack>
      </Stack>
    </div >
  )
}