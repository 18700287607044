import React, { useState, useEffect } from 'react'

import Cookies from 'js-cookie';

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from '@mui/material/CircularProgress';

import PropTypes from 'prop-types'
import { getStreamingAttachmentAxiosInstance } from '../../../utils/ApiUtils';

import config from '../../../env.json'
import styles from './ViewResumeModal.module.css'
import { showErrorToast } from '../../../utils/ToastUtils';

export default function ViewResumeModal(props) {

  const [isLoading, setIsLoading] = useState(false)
  const [resumeURI, setResumeURI] = useState(null)

  const { BASE_URI } = config
  const token = Cookies.get('token')
  const DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  const DOC = 'application/msword'
  const PDF = 'application/pdf'

  const { attachmentURI, isVisible, handleClose, fileExtension, setSelectedResume } = props

  
  useEffect(() => {
    if (attachmentURI && fileExtension) {
      let ext;
      switch(fileExtension) {
        case 'pdf':
          ext = PDF
          break;
        case 'doc':
          ext = DOC
          break;
        case 'docx':
          ext = DOCX
          break;
      }

      const assestConfig = {
        fileType: "work-history",
        path: attachmentURI
      }

      setIsLoading(true)
      getStreamingAttachmentAxiosInstance(token).post(`${BASE_URI}/assets/download`, assestConfig)
        .then(response => {
          let newImageBlob = new Blob([response.data], { type: ext })
          setIsLoading(false)
          setResumeURI(URL.createObjectURL(newImageBlob))
        })
        .catch((error) => {
          setIsLoading(false)
          showErrorToast('Error retrieving resume. Please try again later.')
        })
    }
  }, [attachmentURI])

  return (
    <div>
      <Modal
        open={isVisible}
        onClose={() => {
          setSelectedResume(null)
          handleClose()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Stack className={styles.resumeWrapper}>
            {isLoading ?  
              <Box>
                <CircularProgress />
              </Box> :
              <iframe src={resumeURI} className={styles.resumeIframe} title="description" />}
          </Stack>
        </Box>
      </Modal>
    </div>
  )

}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  padding: 40,
  height: '80%',
  overflowY: 'scroll',
  p: 4,
};


ViewResumeModal.propTypes = {
  isVisible: PropTypes.bool,
  attachmentURI: PropTypes.string,
  handleClose: PropTypes.func,
  fileExtension: PropTypes.string,
  setSelectedResume: PropTypes.func

}