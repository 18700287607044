import React, { useState, useEffect } from 'react'

import Cookies from 'js-cookie';

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import SidebarNav from '../../components/navigation/SidebarNav'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import { getAxiosInstance, getStreamingAttachmentAxiosInstance } from '../../utils/ApiUtils'
import { useNavigate, useParams } from 'react-router-dom'
import { colors } from '../../constants/Colors'
import { Typography } from '@mui/material'
import { getCalendarDate, timeSinceMessageSent } from '../../utils/DateUtils'
import CheckInItem from '../../components/checkIns/CheckInItem'
import StandardButton from '../../components/buttons/StandardButton';

import config from '../../env.json'
import { useDispatch, useSelector } from 'react-redux';

import PROFILE_ICON from '../../assets/profile-icon.png'
import NEUTRAL_FACE from '../../assets/neutral-face.png'
import styles from './ViewCompletedCheckIn.module.css'
import { addCommentToSentiment, addCommentToSuggestion } from '../../redux/actions/checkInActions';
import { showErrorToast, showSuccessToast } from '../../utils/ToastUtils';

export default function ViewCompleteCheckIn() {

  const { BASE_URI } = config

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = Cookies.get('token')
  const params = useParams()
  const role = useSelector((state) => (state.user.role))
  const authedUserId = useSelector((state) => state.auth.id)
  const s3AvatarURI = useSelector((state) => state.user.avatarURI)

  const { id } = params

  const [user, setUser] = useState(null)
  const [userId, setUserId] = useState(null)
  const [checkInSentiment, setCheckInSentiment] = useState(null)
  const [sentimentRating, setSentimentRating] = useState(0)
  const [sentimentDetail, setSentimentDetail] = useState(null)
  const [dueDate, setDueDate] = useState(null)
  const [checkInItems, setCheckInItems] = useState([])
  const [checkInSuggestion, setCheckInSuggestion] = useState(null)
  const [highFives, setHighFives] = useState([])
  const [suggestionCommentBoxVisible, setSuggestionCommentBoxVisible] = useState(false)
  const [suggestionComment, setSuggestionComment] = useState('')
  const [editIconVisible, setEditIconVisible] = useState(false)
  const [avatarURI, setAvatarURI] = useState(null)
  const [reviewStatus, setReviewStatus] = useState(null)
  const [sentimentComment, setSentimentComment] = useState(null)


  useEffect(() => {
    getAxiosInstance(token).get(`${BASE_URI}/checkIns/${id}`)
      .then((res) => {
        if (res.status == 200) {

          if (role === 'ADMIN' || role === 'FACULTY' || res.data.userId === authedUserId) {
            setEditIconVisible(true)
          }

          setUser(res.data.user)
          setUserId(res.data.userId)
          setCheckInSentiment(res.data.checkInSentiment)
          // setSentimentRating(res.data.sentiment)
          // setSentimentDetail(res.data.sentimentDetail)
          setDueDate(res.data.dueDate)
          setCheckInItems(res.data.inputs)
          setHighFives(res.data.highFives)
          setCheckInSuggestion(res.data.suggestion)
          setReviewStatus(res.data.reviewStatus)
        }
      })
      .catch((error) => {

      })

      if (s3AvatarURI) {
        const assestConfig = {
          fileType: "profile-attachment",
          path: s3AvatarURI
        }

        getStreamingAttachmentAxiosInstance(token).post(`${BASE_URI}/assets/download`, assestConfig)
          .then(response => {
            let newImageBlob = new Blob([response.data], { type: 'image/jpeg' })
            setAvatarURI(URL.createObjectURL(newImageBlob))
          })
          .catch((error) => {

          })
      }
  }, [id, authedUserId])

  const renderCheckInItems = () => {
    if (checkInItems != null) {
      if (checkInItems.length == 0) {
        return <Typography>No wins or challenges were captured.</Typography>
      }
      else {
        return checkInItems.map((checkInItem) => {
          return <CheckInItem
            id={checkInItem.id}
            checkInCategory={checkInItem.category}
            value={checkInItem.value}
            commentable={true}
            checkInItems={checkInItems}
            setCheckInItems={setCheckInItems}
            comments={checkInItem.checkInDetailComments}
            userId={checkInItem.userId}
          />
        })
      }
    }
  }

  const renderHighFives = () => {
    if (highFives.length > 0) {
      return highFives.map((highFive) => {
        return <Typography>{highFive.value}</Typography>
      })
    }
    else {
      return <Typography>No high fives given on this check-in.</Typography>
    }
  }


  const submitSuggestionComment = () => {
    if (suggestionComment !== '') {
      const commentConfig = {
        checkInSuggestionId: checkInSuggestion.id,
        commenter: {
          id: authedUserId
        },
        comment: suggestionComment
      }

      dispatch(addCommentToSuggestion(commentConfig, token, checkInSuggestion, setCheckInSuggestion, setSuggestionComment))
    }

  }

  const submitSentimentComment = () => {
    if (sentimentComment) {
      const commentConfig = {
        checkInSentimentId: checkInSentiment.id,
        sentimentComment: sentimentComment,
        commenter: {
          id: authedUserId
        },

      }
      dispatch(addCommentToSentiment(commentConfig, token, checkInSentiment, setCheckInSentiment, setSentimentComment))
    }
  }

  const renderSentimentComments = () => {
    if (checkInSentiment) {
      return (
        <Stack className={styles.commentSectionWrapper}>
          {checkInSentiment.checkInSentimentComments.length > 0 ? checkInSentiment.checkInSentimentComments.map((comment) => {
            return (
              <Stack className={styles.commentSectionRowItem}>
                <Stack direction='row' className={styles.commentRow}>
                  <Stack>
                    <img src={avatarURI ? avatarURI: PROFILE_ICON} style={{ height: 25, width: 25, borderRadius: '50%', border: '1px solid #4a0c3f', justifyContent: 'center', alignItems: 'center' }} />
                  </Stack>
                  <Typography className={styles.commentAuthorText}>
                    {comment.commenter.firstName !== '' ? comment.commenter.firstName : comment.commentor.email}: &nbsp;
                    <span className={styles.commentText}>{comment.sentimentComment}</span>
                  </Typography>
                </Stack>
                <Typography className={styles.commentDateSentText}>{timeSinceMessageSent(comment.dateCreated)} ago</Typography>
              </Stack>
            )
          }) : null}

          {editIconVisible ? (<Button
            className={styles.pencilIconButtonStyle}
            variant='text'
            onClick={() => {
              setEditIconVisible(false)
              setSuggestionCommentBoxVisible(true)
            }}
          >

            <Typography className={styles.commentButtonText}>+ Comment</Typography>
          </Button>) : null}

          {suggestionCommentBoxVisible ? (<Stack>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={2}
              defaultValue="Your comment...."
              className={styles.textFieldStyle}
              value={sentimentComment}
              onChange={(event) => setSentimentComment(event.target.value)}
            />
            <Stack direction='row' className={styles.commentSubmitButtonWrapper}>
              <Button variant='text' onClick={(event) => {
                setSuggestionCommentBoxVisible(false)
                setEditIconVisible(true)

              }}>
                <Typography>CANCEL</Typography>
              </Button>
              <StandardButton
                label='Submit Comment'
                inactiveCriteria={sentimentComment === '' || sentimentComment == null}
                onPress={() => submitSentimentComment()}
                backgroundColor={sentimentComment === '' || sentimentComment == null ? 'silver' : colors.oceanBlue}
                buttonWidth={275}
              />
            </Stack>
          </Stack>) : null}

        </Stack>
      )
    }
  }

  const renderCheckInSuggestionComments = () => {
    if (checkInSuggestion) {
      return (
        <Stack className={styles.commentSectionWrapper}>

          {checkInSuggestion.suggestionComments.length > 0 ? checkInSuggestion.suggestionComments.map((comment) => {
            return (
              <Stack className={styles.commentSectionRowItem}>
                <Stack direction='row' className={styles.commentRow}>
                  <Stack>
                    <img src={avatarURI ? avatarURI: PROFILE_ICON} style={{ height: 25, width: 25, borderRadius: '50%', border: '1px solid #4a0c3f', justifyContent: 'center', alignItems: 'center' }} />
                  </Stack>
                  <Typography className={styles.commentAuthorText}>
                    {comment.commenter.firstName !== '' ? comment.commenter.firstName : comment.commentor.email}: &nbsp;
                    <span className={styles.commentText}>{comment.comment}</span>
                  </Typography>
                </Stack>
                <Typography className={styles.commentDateSentText}>{timeSinceMessageSent(comment.dateCreated)} ago</Typography>
              </Stack>
            )
          }) : null}

          {editIconVisible ? (<Button
            className={styles.pencilIconButtonStyle}
            variant='text'
            onClick={() => {
              setEditIconVisible(false)
              setSuggestionCommentBoxVisible(true)
            }}
          >

            <Typography className={styles.commentButtonText}>+ Comment</Typography>
          </Button>) : null}

          {suggestionCommentBoxVisible ? (<Stack>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={2}
              defaultValue="Your comment...."
              className={styles.textFieldStyle}
              value={suggestionComment}
              onChange={(event) => setSuggestionComment(event.target.value)}
            />
            <Stack direction='row' className={styles.commentSubmitButtonWrapper}>
              <Button variant='text' onClick={(event) => {
                setSuggestionCommentBoxVisible(false)
                setEditIconVisible(true)

              }}>
                <Typography>CANCEL</Typography>
              </Button>
              <StandardButton
                label='Submit Comment'
                inactiveCriteria={suggestionComment === '' || suggestionComment == null}
                onPress={() => submitSuggestionComment()}
                backgroundColor={suggestionComment === '' || suggestionComment == null ? 'silver' : colors.oceanBlue}
                buttonWidth={275}
              />
            </Stack>
          </Stack>) : null}

        </Stack>
      )
    }
  }

  const markCheckInAsReviewed = () => {
    getAxiosInstance(token).put(`${BASE_URI}/checkIns/review/${id}/${authedUserId}/complete`)
      .then((res) => {
        if (res.status == 200) {
          setReviewStatus(res.data.reviewStatus)
          showSuccessToast('Check-In successfully reviewed.')
        }
      })
      .catch((error) => {
        showErrorToast('Error reviewing this check in. Please try again later.')
      })

  }

  const renderUserName = () => {
    let userName = ''
    if (user) {
     if (user.firstName) {
      return <span>{user.firstName} {user.lastName ? user.lastName : null}</span>
     }
     else {
      return <span>{user.email}</span>
     }
    }
  }

  return (
    <div
      style={{ display: 'flex', flex: 1, paddingBottom: 50 }}

    >
      
      <Stack style={{ paddingLeft: 100, marginTop: 50, }}>
     
        <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>{renderUserName()}'s Check-In For {getCalendarDate(dueDate)}</Typography>

        <Stack direction='row'
          style={{ width: '80%', }}
        >
          <Stack style={{ width: '15%' }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 'bold', color: colors.slateBlue, textAlign: 'center' }}
            >
              Sentiment Rating
            </Typography>
            <Typography
              style={{ fontSize: 48, fontWeight: 'bold', textAlign: 'center', color: colors.blazeRed }}
            >
              {checkInSentiment ? checkInSentiment.sentiment : 0}
            </Typography>

          </Stack>
          <Stack style={{ width: '60%', marginLeft: 40 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 'bold', color: colors.slateBlue, textAlign: 'left' }}
            >
              Details
            </Typography>
            <Typography
              style={{ fontSize: 18, fontWeight: '500', textAlign: 'left', color: colors.coolBlack }}
            >
              {checkInSentiment? checkInSentiment.details : "You didn't share any additional details on your feelings during this check-in."}
            </Typography>

          </Stack>
        </Stack>
        <Stack style={{marginTop: 10}}>
          {renderSentimentComments()}
        </Stack>
        <Stack style={{ width: '80%' }}>

          <Typography style={{ fontSize: 32, fontWeight: 'bold', paddingTop: 30 }}>Check-In Details</Typography>
          {renderCheckInItems()}
        </Stack>

        <Stack style={{ width: '80%' }}>
          <Typography style={{ fontSize: 32, fontWeight: 'bold', paddingTop: 30 }}>High-Fives</Typography>
          {renderHighFives()}
        </Stack>

        <Stack style={{ width: '80%' }}>
          <Typography style={{ fontSize: 32, fontWeight: 'bold', paddingTop: 30 }}>Suggestions for Improvement</Typography>
          <Typography>{checkInSuggestion ? checkInSuggestion.suggestionValue : 'No improvements suggested.'}</Typography>
          {renderCheckInSuggestionComments()}
        </Stack>

        <Stack>
          {editIconVisible ? (
            <Stack direction='row' className={styles.actionButtonWrapper}>
              <StandardButton 
                label="Go Back"
                onPress={() => navigate(-1)}
                backgroundColor='silver'
                labelColor='white'
              />

              {(reviewStatus && reviewStatus === 'UNREVIEWED' && (role === 'ADMIN' || role === 'FACULTY' || role === 'STAFF')) ?  <StandardButton 
                  label="Complete Review"
                  onPress={() => markCheckInAsReviewed()}
                  backgroundColor={colors.oceanBlue}
                  labelColor='white'
                  buttonWidth={275}
              /> : null}
             
            </Stack>
          ) : null}
          
        </Stack>
      </Stack>

    </div>
  )
}