import {
  CREATING_INTERVIEW,
  CREATED_INTERVIEW,
  ERROR_CREATING_INTERVIEW,
  SENDING_INTERVIEW_PROMPT,
  SENT_INTERVIEW_PROMPT,
  ERROR_SENDING_INTERVIEW_PROMPT,
  ERROR_CONTINUING_INTERVIEW,
  CONTINUED_INTERVIEW,
  CONTINUING_INTERVIEW,
  RETRIEVING_ACTIVE_INTERVIEW,
  RETRIEVED_ACTIVE_INTERVIEW,
  ERROR_RETRIEVING_ACTIVE_INTERVIEW,
  RETRIEVING_INTERVIEW,
  RETRIEVED_INTERVIEW,
  ERROR_RETRIEVING_INTERVIEW,
  SUBMITTING_INTERVIEW_RESPONSE_FOR_EVALUATION,
  SUBMITTED_INTERVIEW_RESPONSE_FOR_EVALUATION,
  ERROR_SUBMITTING_INTERVIEW_RESPONSE_FOR_EVALUATION,
  RETRIEVING_COMPLETED_INTERVIEWS,
  RETRIEVED_COMPLETED_INTERVIEWS,
  ERROR_RETRIEVING_COMPLETED_INTERVIEWS,
  COMPLETING_ACTIVE_INTERVIEW,
  COMPLETED_ACTIVE_INTERVIEW,
  ERROR_COMPLETING_ACTIVE_INTERVIEW,
  CREATING_INTERVIEW_QUESTION,
  CREATED_INTERVIEW_QUESTION,
  ERROR_CREATING_INTERVIEW_QUESTION,
  DELETING_INTERVIEW_QUESTION,
  DELETED_INTERVIEW_QUESTION,
  ERROR_DELETING_INTERVIEW_QUESTION,
  EDITING_INTERVIEW_QUESTION,
  EDITED_INTERVIEW_QUESTION,
  ERROR_EDITING_INTERVIEW_QUESTION
} from '../constants/interviewActionTypes'

import { getAxiosInstance } from '../../utils/ApiUtils'

// const BASE_URI = 'http://localhost:5000'
// const PROD_SERVER_URI = process.env.REACT_APP_PROD_SERVER_URI

import { showErrorToast, showSuccessToast } from '../../utils/ToastUtils'
import config from '../../env.json'
import { retrieveCreditsForAccount } from './accountActions'
const { BASE_URI } = config

// CREATE NEW INTERVIEW
export const creatingInterview = () => {
  return {
    type: CREATING_INTERVIEW
  }
}

export const createdInterview = (interviewData) => {
  return {
    type: CREATED_INTERVIEW,
    payload: interviewData
  }
}

export const errorCreatingInterview = (error) => {
  return {
    type: ERROR_CREATING_INTERVIEW,
    payload: error
  }
}

export const createInterview = (interviewConfig, token) => {
  return function (dispatch, getState) {
    dispatch(creatingInterview())

    return getAxiosInstance(token).post(`${BASE_URI}/interviews/new`, interviewConfig)
      .then(res => {
        if (res.status === 200) {
          dispatch(createdInterview(res.data))
        }
      })
      .catch(err => {
        dispatch(errorCreatingInterview())

      })
  }
}

// SENT INITIAL INTERVIEW PROMPT
export const sendingInterviewPrompt = () => {
  return {
    type: SENDING_INTERVIEW_PROMPT
  }
}

export const sentInterviewPrompt = (orgData) => {
  return {
    type: SENT_INTERVIEW_PROMPT,
    payload: orgData
  }
}

export const errorSendingInterviewPrompt = (error) => {
  return {
    type: ERROR_SENDING_INTERVIEW_PROMPT,
    payload: error
  }
}

export const sendPromptForInterview = (promptConfig, token) => {
  return function (dispatch, getState) {
    dispatch(sendingInterviewPrompt())

    return getAxiosInstance(token).post(`${BASE_URI}/interviews/initialPrompt`, promptConfig)
      .then(res => {
        if (res.status === 200) {
          dispatch(sentInterviewPrompt(res.data))
        }
      })
      .catch(err => {
        dispatch(errorSendingInterviewPrompt())

      })
  }
}

// CONTINUE INTERVIEW
export const continuingInterview = () => {
  return {
    type: CONTINUING_INTERVIEW
  }
}

export const continuedInterview = (orgData) => {
  return {
    type: CONTINUED_INTERVIEW,
    payload: orgData
  }
}

export const errorContinuingInterview = (error) => {
  return {
    type: ERROR_CONTINUING_INTERVIEW,
    payload: error
  }
}

export const continueInterview = (promptConfig, token, existingInterviews) => {
  return function (dispatch, getState) {
    dispatch(continuingInterview())

    return getAxiosInstance(token).post(`${BASE_URI}/interviews/continue`, promptConfig)
      .then(res => {
        if (res.status === 200) {
          let updatedInterview = [...existingInterviews]
          updatedInterview.map((interview) => {
            if (interview.id === res.data.id) {
              interview.messages = res.data.messages
            }
          })
          dispatch(continuedInterview(updatedInterview))
        }
      })
      .catch(err => {
        dispatch(errorContinuingInterview())

      })
  }
}



// RETRIEVE ACTIVE INTERVIEWS
export const retrievingActiveInterviews = () => {
  return {
    type: RETRIEVING_ACTIVE_INTERVIEW
  }
}

export const retrievedActiveInterviews = (interviews) => {
  return {
    type: RETRIEVED_ACTIVE_INTERVIEW,
    payload: interviews
  }
}

export const errorRetrievingActiveInterviews = (error) => {
  return {
    type: ERROR_RETRIEVING_ACTIVE_INTERVIEW,
    payload: error
  }
}

export const retrieveActiveInterviews = (userId, token) => {
  return function (dispatch, getState) {
    dispatch(retrievingActiveInterviews())

    return getAxiosInstance(token).get(`${BASE_URI}/interviews/active/${userId}`)
      .then((res) => {
        if (res.status == 200) {
          dispatch(retrievedActiveInterviews(res.data))
        }
      })
      .catch(err => {
        dispatch(errorRetrievingActiveInterviews())
      })
  }
}

function sortDescending(data) {

  const updatedData = data
  return updatedData.sort(function(a, b) {
    if (a.interviewDate > b.interviewDate) {
      return -1;
    }
    if (a.interviewDate < b.interviewDate) {
      return 1;
    }
    return 0;
  });
}

// RETRIEVE COMPLETED INTERVIEWS
export const retrievingCompletedInterviews = () => {
  return {
    type: RETRIEVING_COMPLETED_INTERVIEWS
  }
}

export const retrievedCompletedInterviews = (interviews) => {
  return {
    type: RETRIEVED_COMPLETED_INTERVIEWS,
    payload: interviews
  }
}

export const errorRetrievingCompletedInterviews = (error) => {
  return {
    type: ERROR_RETRIEVING_COMPLETED_INTERVIEWS,
    payload: error
  }
}

export const retrieveCompletedInterviews = (userId, token) => {
  return function (dispatch, getState) {
    dispatch(retrievingCompletedInterviews())

    return getAxiosInstance(token).get(`${BASE_URI}/interviews/completed/${userId}`)
      .then((res) => {
        if (res.status == 200) {
          dispatch(retrievedCompletedInterviews(sortDescending(res.data)))
        }
      })
      .catch(err => {
        dispatch(errorRetrievingCompletedInterviews())
      })
  }
}


// RETRIEVE SINGLE INTERVIEW
export const retrievingInterview = () => {
  return {
    type: RETRIEVING_INTERVIEW
  }
}

export const retrievedInterview = (interviews) => {
  return {
    type: RETRIEVED_INTERVIEW,
    payload: interviews
  }
}

export const errorRetrievingInterview = (error) => {
  return {
    type: ERROR_RETRIEVING_INTERVIEW,
    payload: error
  }
}

export const retrieveInterview = (interviewId, token, setCurrentInterview, preloadSteps ) => {
  return function (dispatch, getState) {
    dispatch(retrievingInterview())
    return getAxiosInstance(token).get(`${BASE_URI}/interviews/${interviewId}`)
      .then((res) => {
        if (res.status == 200) {
          dispatch(retrievedInterview(res.data))
          setCurrentInterview(res.data)
          preloadSteps(res.data)
        }
      })
      .catch(err => {
        dispatch(errorRetrievingInterview())
      })
  }
}

// Retrieve Interview Focus Areas
export const retrieveInterviewFocusAreas = (programmingLanguage, token, setFocusAreas) => {
  return function (dispatch, getState) {
    // dispatch(retrievingInterviewFocusArea())
    return getAxiosInstance(token).get(`${BASE_URI}/interviews/focusAreas/${programmingLanguage}`)
      .then((res) => {
        if (res.status == 200) {
          setFocusAreas(res.data);
          
        }
      })
      .catch(err => {
        // dispatch(errorRetrievingInterviewFocusAreas())
      })
  }
}



// Submit Candidate Response for Evaluation
export const submittingInterviewResponse = () => {
  return {
    type: SUBMITTING_INTERVIEW_RESPONSE_FOR_EVALUATION
  }
}

export const submittedInterviewResponse = (interviews) => {
  return {
    type: SUBMITTED_INTERVIEW_RESPONSE_FOR_EVALUATION,
    payload: interviews
  }
}

export const errorSubmittingInterviewResponse = (error) => {
  return {
    type: ERROR_SUBMITTING_INTERVIEW_RESPONSE_FOR_EVALUATION,
    payload: error
  }
}
 
export const submitIntervewResponseForEvaulation = (interviewConfig, token, handleComplete) => {
  return function (dispatch, getState) {
    dispatch(submittingInterviewResponse())

    return getAxiosInstance(token).post(`${BASE_URI}/interviews/assessCandidateResponseFromText`, interviewConfig)
      .then((res) => {
        if (res.status == 200) {
          handleComplete()
          dispatch(submittedInterviewResponse(res.data))
        }
        if (res.status == 500 || res.status == 503) {
          showErrorToast('Error evaluating your response. Please try again later.')
        }
      })
      .catch(err => {
        dispatch(errorSubmittingInterviewResponse())
        showErrorToast('Error evaluating your response. Please try again later.')
      })
  }
}

// Updating Interview Status
export const completingActiveInterview = () => {
  return {
    type: COMPLETING_ACTIVE_INTERVIEW
  }
}

export const completedActiveInterview = (interview) => {
  return {
    type: COMPLETED_ACTIVE_INTERVIEW,
    payload: interview
  }
}

export const errorCompletingActiveInterview = (error) => {
  return {
    type: ERROR_COMPLETING_ACTIVE_INTERVIEW,
    payload: error
  }
}

export const completeActiveInterview = (interviewId, token, navToSummary) => {
  return function (dispatch, getState) {
    dispatch(completingActiveInterview())
    
    return getAxiosInstance(token).put(`${BASE_URI}/interviews/complete/${interviewId}`)
      .then((res) => {
        if (res.status == 200) {
          dispatch(completedActiveInterview(res.data))
          navToSummary()
        }
      })
      .catch(err => {
        dispatch(errorCompletingActiveInterview())
      })
  }
}


// Get Interview Technical Focus Areas By Programming Lanaguage
// export const submittingInterviewResponse = () => {
//   return {
//     type: SUBMITTING_INTERVIEW_RESPONSE_FOR_EVALUATION
//   }
// }

// export const submittedInterviewResponse = (interviews) => {
//   return {
//     type: RETRIEVED_INTERVIEW,
//     payload: interviews
//   }
// }

// export const errorSubmittingInterviewResponse = (error) => {
//   return {
//     type: ERROR_RETRIEVING_INTERVIEW,
//     payload: error
//   }
// }

export const retrieveTechnicalFocusAreasByProgrammingLanguage = (programmingLanguage, token, setTechnicalFocusAreas) => {
  return function (dispatch, getState) {
    dispatch(submittingInterviewResponse())
    
    return getAxiosInstance(token).get(`${BASE_URI}/interviews/focusAreas/technical/software/${programmingLanguage}`)
      .then((res) => {
        if (res.status == 200) {
          setTechnicalFocusAreas(res.data)
        }
      })
      .catch(err => {
        dispatch(errorSubmittingInterviewResponse())
      })
  }
}


export const retrieveTechnicalFocusAreasByRole = (role, token, setTechnicalFocusAreas) => {
  return function (dispatch, getState) {
    dispatch(submittingInterviewResponse())

    return getAxiosInstance(token).get(`${BASE_URI}/interviews/focusAreas/technical/role/${role}`)
      .then((res) => {
        if (res.status == 200) {
          setTechnicalFocusAreas(res.data)
          return res.data
        }
      })
      .catch(err => {
        dispatch(errorSubmittingInterviewResponse())
      })
  }
}

// GET INTERVIEWABLE ROLES
export const getInterviewableRoles = (token) => {
  return function (dispatch, getState) {
    return getAxiosInstance(token).get(`${BASE_URI}/interviews/roles`)
      .then((res) => {
        if (res.status == 200) {
          return res.data
        }
      })
      .catch(err => {
        // dispatch(errorSubmittingInterviewResponse())
      })
  }
}


// CREATE INTERVIEW QUESTION 
export const creatingInterviewQuestion = () => {
  return {
    type: CREATING_INTERVIEW_QUESTION
  }
}

export const createdInterviewQuestion = (interviewQuestion) => {
  return {
    type: CREATED_INTERVIEW_QUESTION,
    payload: interviewQuestion
  }
}

export const errorCreatingInterviewQuestion = (error) => {
  return {
    type: ERROR_CREATING_INTERVIEW_QUESTION,
    payload: error
  }
}

export const createInterviewQuestion = (interviewQuestionConfig, token, clearForm) => {
  return function (dispatch, getState) {
    dispatch(creatingInterviewQuestion())
    
    return getAxiosInstance(token).post(`${BASE_URI}/interviews/create/question`, interviewQuestionConfig)
      .then((res) => {
        if (res.status == 200) {
          dispatch(createdInterviewQuestion(res.data))
          clearForm()
          showSuccessToast('Successfully created interview question!')
          
        }
      })
      .catch(err => {
        dispatch(errorCreatingInterviewQuestion())
        showErrorToast('Error creating interview question.')
      })
  }
}

// DELETE INTERVIEW QUESTION 
export const deletingInterviewQuestion = () => {
  return {
    type: DELETING_INTERVIEW_QUESTION
  }
}

export const deletedInterviewQuestion = (interviewQuestion) => {
  return {
    type: DELETED_INTERVIEW_QUESTION,
    payload: interviewQuestion
  }
}

export const errorDeletingInterviewQuestion = (error) => {
  return {
    type: ERROR_DELETING_INTERVIEW_QUESTION,
    payload: error
  }
}

export const deleteInterviewQuestion = (interviewQuestion, type, token, currentInterviewQuestions, closeModal) => {
  return function (dispatch, getState) {
    dispatch(deletingInterviewQuestion())
    
    return getAxiosInstance(token).delete(`${BASE_URI}/interviews/question/${type}/${interviewQuestion.id}`)
      .then((res) => {
        if (res.status == 200) {

          const updatedInterviewQuestion = currentInterviewQuestions.filter((question) => question.id !== interviewQuestion.id)
          dispatch(deletedInterviewQuestion(updatedInterviewQuestion))
          showSuccessToast('Successfully deleted interview question!')
          closeModal()
        }
      })
      .catch(err => {
        dispatch(errorCreatingInterviewQuestion())
        showErrorToast('Error deleting interview question.')
      })
  }
}


// EDITING INTERVIEW QUESTIONS 
export const editingInterviewQuestion = () => {
  return {
    type: EDITING_INTERVIEW_QUESTION
  }
}

export const editedInterviewQuestion = (interviewQuestion) => {
  return {
    type: EDITED_INTERVIEW_QUESTION,
    payload: interviewQuestion
  }
}

export const errorEditingInterviewQuestion = (error) => {
  return {
    type: ERROR_EDITING_INTERVIEW_QUESTION,
    payload: error
  }
}

export const editInterviewQuestion = (interviewQuestionConfig, token, currentInterviewQuestions, closeModal) => {
  return function (dispatch, getState) {
    dispatch(editingInterviewQuestion())
    
    return getAxiosInstance(token).put(`${BASE_URI}/interviews/update/question`, interviewQuestionConfig)
      .then((res) => {
        if (res.status == 200) {
          currentInterviewQuestions = currentInterviewQuestions
            .map((question) =>  question.id == res.data.id ? res.data : question);
       
          dispatch(editedInterviewQuestion(currentInterviewQuestions))
          showSuccessToast('Successfully edited interview question!')
          closeModal()
        }
      })
      .catch(err => {
        dispatch(errorEditingInterviewQuestion())
        showErrorToast('Error editing interview question.')
      })
  }
}









