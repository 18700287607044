import React, {useState } from 'react'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch';

import PropTypes from 'prop-types'

import DELETE_ICON from '../../assets/delete-icon.png'
import { colors } from '../../constants/Colors'

export default function ViewHighFivePreview(props) {

  const {
    id,
    onPress,
    value,
    isPublic,
    setIsPublic
  } = props


  return (
    <Box style={{ border: '1px solid silver', padding: 10, width: '75%', borderRadius: 10, margin: 5 }}>
      <Stack direction='row' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography>
          {value}
        </Typography>
        <Button
          variant='text'
          onClick={() => onPress(id)}
        >
          <img src={DELETE_ICON} style={{ height: 30, width: 30 }} />
        </Button>
      </Stack>
      {/* <Stack direction='label' style={{alignItems: 'center'}}>
        <Typography style={{fontSize: 14}}>PRIVATE</Typography>
        <Switch  
          checked={isPublic}
          onChange={(event) => setIsPublic(id, event.target.checked)}
        />
        <Typography style={{fontSize: 14}}>PUBLIC</Typography>
      </Stack> */}

    </Box>
  )
}


ViewHighFivePreview.propTypes = {
  id: PropTypes.number,
  value: PropTypes.string,
  onPress: PropTypes.func,
  isPublic: PropTypes.bool,
  setIsPublic: PropTypes.func
}