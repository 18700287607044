import React from 'react'

import PropTypes from 'prop-types'
import StandardButton from '../buttons/StandardButton';

import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography';
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import { colors } from '../../constants/Colors';


import TextField from '@mui/material/TextField'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import BUG_IMAGE from '../../assets/bug-image.png'
import CONSTRUCTION_ICON from '../../assets/construction-icon.png'
import LIGHT_BULB from '../../assets/light-bulb.png'
import FRUSTRATION_ICON from '../../assets/frustration-icon.png'
import WINS_ICON from '../../assets/wins-icon.png'
import COLLABORATION_ICON from '../../assets/paired-icon.png'
import CONFUSION_ICON from '../../assets/confusion-icon.png'
import PENCIL_ICON from '../../assets/pencil-icon.png'
import DELETE_ICON from '../../assets/delete-icon.png'
import INTERVIEW_ICON from '../../assets/interview-icon.png'

import HAPPY_FACE from '../../assets/happy-face.png'
import NEUTRAL_FACE from '../../assets/neutral-face.png'
import SAD_FACE from '../../assets/sad-face.png'



export default function EditableCheckInReportModal(props) {

  const {
    open,
    handleClose,
    checkInItems,
    deletCheckInItem,
    sentiment,
    sentimentDetail,
    updateCheckInDetail
  } = props

  const renderSentimentIcon = () => {
    switch (sentiment) {
      case 'HAPPY':
        return (
          <img src={HAPPY_FACE} style={{ height: 50, width: 50 }} />
        )
      case 'NEUTRAL':
        return (
          <img src={NEUTRAL_FACE} style={{ height: 50, width: 50 }} />
        )
      case 'SAD':
        return (
          <img src={SAD_FACE} style={{ height: 50, width: 50 }} />
        )
      default:
        break;
    }
  }

  const renderCheckInCategoryIcon = (category) => {
    switch (category) {
      case 'AHA_MOMENT':
        return (
          <img src={LIGHT_BULB} style={{ height: 50, width: 50 }} />
        )
      case 'BUG':
        return (
          <img src={BUG_IMAGE} style={{ height: 50, width: 50 }} />
        )
      case 'COLLABORATION':
        return (
          <img src={COLLABORATION_ICON} style={{ height: 50, width: 50 }} />
        )
      case 'CONFUSION':
        return (
          <img src={CONFUSION_ICON} style={{ height: 50, width: 50 }} />
        )
      case 'FRUSTRATION':
        return (
          <img src={FRUSTRATION_ICON} style={{ height: 50, width: 50 }} />
        )
        case 'INTERVIEW':
          return (
            <img src={INTERVIEW_ICON} style={{ height: 50, width: 50 }} />
          )
      case 'PROJECT':
        return (
          <img src={CONSTRUCTION_ICON} style={{ height: 50, width: 50 }} />
        )
      case 'WIN':
        return (
          <img src={WINS_ICON} style={{ height: 50, width: 50 }} />
        )
      default:
        break;
    }
  }
  
  const renderEditButton = () => {
    return (
      <Button variant='text'>
        <img src={PENCIL_ICON} style={{ height: 35, width: 35 }} />
      </Button>
      
    )
  }

  const renderDeleteButton = (tempId) => {
    return (
      <Button 
        onClick={() => deletCheckInItem(tempId)}
      variant='text'>
        <img src={DELETE_ICON} style={{ height: 35, width: 35 }} />
      </Button>
      
    )
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Stack>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sentiment Rating</TableCell>
                    <TableCell align="left">Sentiment Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    <TableRow
                      key={sentiment}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography
                          style={{fontSize: 16, fontWeight: 'bold', color: colors.blazeRed}}
                        >
                          {sentiment}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography>

                        </Typography>
                        {sentimentDetail ? sentimentDetail : ''}
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
          <Stack style={{ marginTop: 20 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650, }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Category</TableCell>
                    <TableCell align="left">Details</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ maxHeight: 240, overflowY: 'scroll'}}>
                  {checkInItems.length > 0 ? checkInItems.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {renderCheckInCategoryIcon(row.category)}
                      </TableCell>
                      {/* <TableCell align="left">{row.value}</TableCell> */}
                      <TableCell>
                      <TextField
                        onChange={(event) => updateCheckInDetail(row.tempId, event.target.value)}
                        id="outlined-multiline-static"
                        multiline
                        defaultValue={row.value ? row.value : null}
                        minRows={1}
                        row={4}
                        size='large'
                        fullWidth={true} />
                      </TableCell>
                      <TableCell align="center">{renderDeleteButton(row.tempId)} </TableCell>
                    </TableRow>
                  )) : null}
                </TableBody>
              </Table>
            </TableContainer>

          </Stack>

          <Stack style={{ justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
            <StandardButton
              label={'CLOSE'}
              buttonWidth={'100%'}
              onPress={() => handleClose()}
              backgroundColor={colors.slateBlue}
            />
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );

}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: '90vh',
  overflowY: 'scroll',
  width: '60%',
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

EditableCheckInReportModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  checkInItems: PropTypes.array,
  deletCheckInItem: PropTypes.func,
  sentiment: PropTypes.string,
  sentimentDetail: PropTypes.string,
  updateCheckInDetail: PropTypes.func
}