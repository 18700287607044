import validator from 'validator';


export function isIdentifcal(password, passwordConfirmation) {
  return validator.equals(password, passwordConfirmation)
}

export function isValidPassword(password) {
  return validator.isStrongPassword(password, 
    [{minLength: 8, minNumbers: 1, minSymbols: 1}])
}

export function isValidEmailFormat(email) {
  return validator.isEmail(email, 
    {
      allow_display_name: false, 
      require_display_name: false,
      require_tld: true, 
      allow_ip_domain: false,
      allow_underscores: false
    })
}