import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

import BUG_IMAGE from '../../assets/bug-image.png'
import CONSTRUCTION_ICON from '../../assets/construction-icon.png'
import LIGHT_BULB from '../../assets/light-bulb.png'
import FRUSTRATION_ICON from '../../assets/frustration-icon.png'
import WINS_ICON from '../../assets/wins-icon.png'
import COLLABORATION_ICON from '../../assets/paired-icon.png'
import CONFUSION_ICON from '../../assets/confusion-icon.png'
import INTERVIEW_ICON from '../../assets/interview-icon.png'

import StandardButton from '../buttons/StandardButton'

import PropTypes from 'prop-types'
import styles from './CheckInItem.module.css'
import { colors } from '../../constants/Colors'
import { useDispatch, useSelector } from 'react-redux'
import { submitCommentOnCheckInDetail } from '../../redux/actions/checkInActions'

import PROFILE_ICON from '../../assets/profile-icon.png'
import NEUTRAL_FACE from '../../assets/neutral-face.png'
import { timeSinceMessageSent } from '../../utils/DateUtils';

import config from '../../env.json'
import { getStreamingAttachmentAxiosInstance } from '../../utils/ApiUtils';

export default function CheckInItem(props) {
  const { BASE_URI } = config

  const token = Cookies.get('token');

  const dispatch = useDispatch()

  const {
    checkInCategory,
    value,
    commentable,
    id,
    checkInItems,
    setCheckInItems,
    comments,
    userId
  } = props

  const authedUserId = useSelector((state) => state.auth.id)
  const role = useSelector((state) => (state.user.role))
  const s3AvatarURI = useSelector((state) => state.user.avatarURI)

  const [commentBoxVisible, setCommentBoxVisible] = useState('')
  const [checkInDetailComment, setCheckInDetailComment] = useState('')
  const [editIconVisible, setEditIconVisible] = useState(false)
  const [avatarURI, setAvatarURI] = useState(null)


  useEffect(() => {
    if (role === 'ADMIN' || role === 'FACULTY' || userId === authedUserId) {
      setEditIconVisible(true)
    }

    if (s3AvatarURI) {
      const assestConfig = {
        fileType: "profile-attachment",
        path: s3AvatarURI
      }

      getStreamingAttachmentAxiosInstance(token).post(`${BASE_URI}/assets/download`, assestConfig)
        .then(response => {
          let newImageBlob = new Blob([response.data], { type: 'image/jpeg' })
          setAvatarURI(URL.createObjectURL(newImageBlob))
        })
        .catch((error) => {

        })
    }
  }, [id])

  const renderDetailTypeIcon = () => {
    switch (checkInCategory) {
      case 'PROJECT':
        return (
          <img
            src={CONSTRUCTION_ICON}
            className={styles.detailconStyle}
          />
        )
      case 'BUG':
        return (
          <img
            src={BUG_IMAGE}
            className={styles.detailconStyle}
          />
        )
      case 'FRUSTRATION':
        return (
          <img
            src={FRUSTRATION_ICON}
            className={styles.detailconStyle}
          />
        )
      case 'WIN':
        return (
          <img
            src={WINS_ICON}
            className={styles.detailconStyle}
          />
        )
      case 'COLLABORATION':
        return (
          <img
            src={COLLABORATION_ICON}
            className={styles.detailconStyle}
          />
        )
      case 'AHA_MOMENT':
        return (
          <img
            src={LIGHT_BULB}
            className={styles.detailconStyle}
          />
        )
      case 'CONFUSION':
        return (
          <img
            src={CONFUSION_ICON}
            className={styles.detailconStyle}
          />
        )
      case 'INTERVIEW':
        return (
          <img
            src={INTERVIEW_ICON}
            className={styles.detailconStyle}

          />
        )
      default:
        break;
    }
  }

  const renderCommentInput = () => {
    
        return (
          <Stack className={styles.commentSectionWrapper}>
            {comments.length > 0 ? comments.map((comment) => {
              return (
                <Stack className={styles.commentSectionRowItem}>
                  <Stack direction='row' className={styles.commentRow}>
                    <Stack>
                      <img src={avatarURI ? avatarURI : PROFILE_ICON} style={{ height: 25, width: 25, borderRadius: '50%', border: '1px solid #4a0c3f', justifyContent: 'center', alignItems: 'center' }} />
                    </Stack>
                    <Typography className={styles.commentAuthorText}>
                      {comment.commentor.firstName !== '' ? comment.commentor.firstName : comment.commentor.email}: &nbsp;
                      <span className={styles.commentText}>{comment.comment}</span>
                    </Typography>
                  </Stack>
                  <Typography className={styles.commentDateSentText}>{timeSinceMessageSent(comment.dateCreated)} ago</Typography>
                </Stack>
              )
            }) : null}

            {editIconVisible ? (  <Button
            className={styles.pencilIconButtonStyle}
            variant='text'
            onClick={() => {
              setEditIconVisible(false)
              setCommentBoxVisible(true)
            }}
          >

            <Typography className={styles.commentButtonText}>+ Comment</Typography>
          </Button>) : null}

          {commentBoxVisible ? ( <Stack>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={2}
            defaultValue="Your comment...."
            className={styles.textFieldStyle}
            value={checkInDetailComment}
            onChange={(event) => setCheckInDetailComment(event.target.value)}
          />
          <Stack direction='row' className={styles.commentSubmitButtonWrapper}>
            <Button variant='text' onClick={(event) => {
              setCommentBoxVisible(false)
              setEditIconVisible(true)

            }}>
              <Typography>CANCEL</Typography>
            </Button>
            <StandardButton
              label='Submit Comment'
              inactiveCriteria={checkInDetailComment === '' || checkInDetailComment == null}
              onPress={() => submitCheckInDetailComment()}
              backgroundColor={checkInDetailComment === '' || checkInDetailComment == null ? 'silver' : colors.oceanBlue}
              buttonWidth={275}
            />
          </Stack>
        </Stack>) : null}
           
          </Stack>
        )
      
  
   if (commentBoxVisible) {
      return (
        <Stack className={styles.commentInputWrapper}>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            defaultValue="Your comment...."
            className={styles.textFieldStyle}
            value={checkInDetailComment}
            onChange={(event) => setCheckInDetailComment(event.target.value)}
          />
          <Stack direction='row' className={styles.commentSubmitButtonWrapper}>
            <Button variant='text' onClick={(event) => {
              setCommentBoxVisible(false)
              setEditIconVisible(true)

            }}>
              <Typography>CANCEL</Typography>
            </Button>
            <StandardButton
              label='Submit Comment'
              inactiveCriteria={checkInDetailComment === '' || checkInDetailComment == null}
              onPress={() => submitCheckInDetailComment()}
              backgroundColor={checkInDetailComment === '' || checkInDetailComment == null ? 'silver' : colors.oceanBlue}
              buttonWidth={275}
            />
          </Stack>
        </Stack>
      )
    }

  }

  // on submit return return checkInDetail and render comments in new comments box. Allow reply from student

  const submitCheckInDetailComment = () => {
    if (checkInDetailComment !== '') {
      const commentConfig = {
        checkInDetailId: id,
        commentor: {
          id: authedUserId
        },
        comment: checkInDetailComment
      }
      dispatch(submitCommentOnCheckInDetail(commentConfig, token, checkInItems, setCheckInItems, setCheckInDetailComment))
    }
  }



  return (
    <Stack>
      <Stack
        direction='row'
        style={{ width: '80%', margin: 10, alignItems: 'center' }}
      >
        <Stack style={{ marginRight: 10 }}>
          {renderDetailTypeIcon()}
        </Stack>

        <Stack>
          <Typography
            style={{ fontSize: 18 }}
          >
            {value ? value : ''}
          </Typography>
        </Stack>
      </Stack>
      {commentable ? renderCommentInput() : null}
    </Stack>

  )
}

CheckInItem.propTypes = {
  id: PropTypes.string,
  checkInCategory: PropTypes.string,
  value: PropTypes.string,
  commentable: PropTypes.bool,
  checkInItems: PropTypes.string,
  setCheckInItems: PropTypes.func,
  comments: PropTypes.array,
  userId: PropTypes.string
}