import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Cookies from 'js-cookie';

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField"
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DatePicker from "react-datepicker";

import PropTypes from 'prop-types'
import { colors } from '../../../constants/Colors';
import { addJobToWorkHistory } from '../../../redux/actions/userActions';

export default function AddExperienceModal(props) {

  const dispatch = useDispatch()

  const {
    isVisible,
    handleClose,
    triggerAction,
    triggerText,
  } = props

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    padding: 40,
    height: '80%',
    overflowY: 'scroll',
    p: 4,
  };

  // const token = Cookies.get('token')
  const token = Cookies.get('token');

  const authedUserId = useSelector((state) => state.auth.id)

  const [jobTitle, setJobTitle] = useState('')
  const [employmentType, setEmploymentType] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [location, setLocation] = useState('')
  const [locationType, setLocationType] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [currentEmployer, setCurrentEmployer] = useState("false")
  const [endDate, setEndDate] = useState(null)
  const [currentJobDetail, setCurrentJobDetail] = useState('')
  const [jobDetails, setJobDetails] = useState([])


  const employmentTypeOptions = [
    'Contractor',
    'Full-Time',
    'Part-Time',
  ]

  const workplaceLocationOptions = [
    'Hybrid',
    'Onsite',
    'Remote',
  ]

  const handleEmploymentTypeChange = (event, type) => {
    const {
      target: { value },
    } = event;

    setEmploymentType(value)
  }

  const handleOfficeLocationTypeChange = (event, type) => {
    const {
      target: { value },
    } = event;

    setLocationType(value)
  }

  const renderEmploymentTypes = () => {
    if (employmentTypeOptions.length > 0) {
      return employmentTypeOptions.map((option, index) => {
        return (
          <MenuItem value={index}>{option}</MenuItem>
        )
      })
    }
  }

  const renderWorkplaceLocationOptions = () => {
    if (workplaceLocationOptions.length > 0) {
      return workplaceLocationOptions.map((option, index) => {
        return (
          <MenuItem value={index}>{option}</MenuItem>
        )
      })
    }
  }

  const addJobDetail = () => {
    const jobDetail = {
      id: Math.floor(Math.random() * 100),
      value: currentJobDetail
    }

    setJobDetails(jobDetails.concat(jobDetail))
    setCurrentJobDetail('')
  }

  const removeJobDetail = (detailId) => {
    const updatedDetails = jobDetails.filter((detail) => detail.id !== detailId)
    setJobDetails(updatedDetails)
  }

  const renderJobDetails = () => {
    if (jobDetails.length > 0) {
      return jobDetails.map((jobDetail) => {
        return (
          <Stack direction='row' style={{ justifyContent: 'space-between', width: '80%', margin: 5 }}>
            <Typography>{jobDetail.value}</Typography>
            <Stack>
              <Button
                onClick={() => removeJobDetail(jobDetail.id)}
                style={{ height: 30, width: 30, backgroundColor: colors.blazeRed }}
              >
                <Typography style={{ color: 'white' }}>X</Typography>
              </Button>
            </Stack>
          </Stack>
        )
      })
    }
  }

  const addExperienceWorkHistory = () => {

    const userExperienceConfig = {
      userId: authedUserId,
      title: jobTitle,
      companyName: companyName,
      employmentType: employmentTypeOptions[employmentType],
      location: location,
      locationType: workplaceLocationOptions[locationType],
      startDate: startDate,
      endDate: endDate,
      jobDetails: jobDetails,
      currentEmployer: currentEmployer
    }

    dispatch(addJobToWorkHistory(userExperienceConfig, token, handleClose))
  }

  return (
    <div>
      <Modal
        open={isVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title" variant="h6" component="h2"
            style={{ textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5, fontSize: 20 }}>

            CAREER DETAILS
          </Typography>
          <Stack style={{ marginTop: 20 }}>
            <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: '700' }}>Title<span style={{ color: colors.blazeRed, fontWeight: 'bold', fontSize: 18 }}>*</span></Typography>
            <TextField
              onChange={(event) => setJobTitle(event.target.value)}
              value={jobTitle}
              style={{ borderWidth: 1, width: '80%' }}
            />
          </Stack>
          <Stack style={{ marginTop: 20 }}>
            <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: '700' }}>Company Name</Typography>
            <TextField
              onChange={(event) => setCompanyName(event.target.value)}
              value={companyName}
              style={{ borderWidth: 1, width: '80%' }}
            />
          </Stack>
          <Stack style={{ marginTop: 20 }}>
            <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: '700' }}>Employment Type</Typography>
            <Select
              labelId="employment-type-label"
              id="employment-type-id"
              value={employmentType}
              style={{ width: '80%' }}
              // label="Interview"
              onChange={(event) => handleEmploymentTypeChange(event)}
            >
              {renderEmploymentTypes()}
            </Select>
          </Stack>
          <Stack style={{ marginTop: 20 }}>
            <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: '700' }}>Office Location</Typography>
            <TextField
              onChange={(event) => setLocation(event.target.value)}
              value={location}
              style={{ borderWidth: 1, width: '80%' }}
            />
          </Stack>
          <Stack style={{ marginTop: 20 }}>
            <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: '700' }}>Employment Type</Typography>
            <Select
              labelId="employment-type-label"
              id="employment-type-id"
              value={locationType}
              // label="Interview"
              style={{ width: '80%' }}
              onChange={(event) => handleOfficeLocationTypeChange(event)}
            >
              {renderWorkplaceLocationOptions()}
            </Select>
          </Stack>

          <Stack style={{ marginTop: 20 }}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: '700' }}>Is {companyName ? companyName : 'this company '} your current employer?</Typography>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={currentEmployer}
                name="radio-buttons-group"
                style={{ flexDirection: 'row' }}
                onChange={(event) => {
                  setCurrentEmployer(event.target.value)
                }}
              >
                <FormControlLabel key={1} value={false} control={<Radio />} label="No" />
                <FormControlLabel key={2} value={true} control={<Radio />} label="Yes" />
              </RadioGroup>
            </FormControl>
          </Stack>
          <Stack direction='row' style={{ marginTop: 20, marginBottom: 20 }}>
            <Stack style={{ height: 60, zIndex: 2 }}>
              <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: '700' }}>When did you start?<span style={{ color: colors.blazeRed, fontWeight: 'bold', fontSize: 18 }}>*</span></Typography>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                popperPlacement="top-end"
                popperModifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [5, 10],
                    },
                  },
                  {
                    name: "preventOverflow",
                    options: {
                      rootBoundary: "viewport",
                      tether: false,
                      altAxis: true,
                    },
                  },
                ]}
              />
            </Stack>

            {currentEmployer === 'false' ? (<Stack style={{ height: 60, zIndex: 2, marginLeft: 30 }}>
              <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: '700' }}>When was your last day?</Typography>
              <DatePicker
                selected={endDate}
                minDate={startDate}
                onChange={(date) => setEndDate(date)}
                disabled={!currentEmployer}
                popperPlacement="top-end"
                popperModifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [5, 10],
                    },
                  },
                  {
                    name: "preventOverflow",
                    options: {
                      rootBoundary: "viewport",
                      tether: false,
                      altAxis: true,
                    },
                  },
                ]}

              />
            </Stack>) : null}
          </Stack>

          <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: '700' }}>Add details about your role. Each detail represents a bullet point on your resume.</Typography>
          <Stack direction='row' style={{ justifyContent: 'space-between', alignItems: 'center', width: '80%' }}>

            <TextField
              onChange={(event) => setCurrentJobDetail(event.target.value)}
              value={currentJobDetail}
              multiline
              rows={3}
              style={{ borderWidth: 1, width: '80%', }}
            />
            <Button
              style={{ backgroundColor: colors.lightGray, height: 45, width: 45 }}
              onClick={() => addJobDetail()}
            >
              <Typography style={{ color: 'black' }}>ADD</Typography>
            </Button>
          </Stack>

          {renderJobDetails()}

          <Stack direction='row' style={{ justifyContent: 'space-around', alignItems: 'center', marginTop: 20 }}>
            <Stack>
              <Button variant='contained'
                style={{ backgroundColor: 'silver', height: 45, width: 150 }}
                onClick={() => handleClose()}
              >
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>CANCEL</Typography>
              </Button>
            </Stack>
            <Stack>
              <Button variant='contained'
                style={{ backgroundColor: 'purple', height: 45, width: 150 }}
                onClick={() => addExperienceWorkHistory()}
              >
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>{triggerText ? triggerText : 'SAVE'}</Typography>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>

  );
}

AddExperienceModal.propTypes = {
  isVisible: PropTypes.bool,
  handleClose: PropTypes.func,
  triggerAction: PropTypes.func,
  triggerText: PropTypes.string,
}