import React, { useState } from "react";

import Cookies from 'js-cookie';

import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";

import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button'

import Stack from "@mui/material/Stack";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close';

import StandardButton from "../buttons/StandardButton";
import { colors } from "../../constants/Colors";

import { useSelector } from "react-redux";

import BUG_IMAGE from '../../assets/bug-image.png'
import CONSTRUCTION_ICON from '../../assets/construction-icon.png'
import LIGHT_BULB from '../../assets/light-bulb.png'
import FRUSTRATION_ICON from '../../assets/frustration-icon.png'
import WINS_ICON from '../../assets/wins-icon.png'
import COLLABORATION_ICON from '../../assets/paired-icon.png'
import CONFUSION_ICON from '../../assets/confusion-icon.png'
import INTERVIEW_ICON from '../../assets/interview-icon.png'

import styles from './CheckInDetailsModal.module.css'

export default function CheckInDetailsModal(props) {

  const {
    open,
    handleClose,
    addToCheckIn,
    checkInCategory,
  } = props;


  const token = Cookies.get('token');

  const [selected, setSelected] = useState([]);

  const [helperTextVisible, setHelperTextVisible] = useState(false)
  const [interviewType, setInterviewType] = useState(null)
  const [technicalLevel, setTechnicalLevel] = useState(null)
  const [programmingLanguage, setProgrammingLanguage] = useState(null)

  const [selectedIndustry, setSelectedIndustry] = useState(null)
  const [role, setRole] = useState('')
  const [jobDescription, setJobDescription] = useState('')
  const [interviewer, setInterviewer] = useState(null)



  const [checkInDetail, setCheckInDetail] = useState(null)



  const isItemSelected = (id) => !!selected.find((el) => el === id);


  const handleChange = (event, type) => {


    const {
      target: { value },
    } = event;

    switch (type) {
      case 'interviewType':
        setInterviewType(
          typeof value === 'string' ? value.split(',') : value,
        );
        break;
      case 'technicalLevel':
        setTechnicalLevel(
          typeof value === 'string' ? value.split(',') : value,
        );
        break;
      case 'programmingLanguage':
        setProgrammingLanguage(
          typeof value === 'string' ? value.split(',') : value,
        );
        break;
      default:
        break;
    }
  };

  const renderHelperText = () => {
    switch(checkInCategory) {
      case 'AHA_MOMENT':
        return <Typography className={styles.helperText}>Have a break through this week? Share some details on the challenge(s) you overcame and how you found clarity.</Typography>
      case 'BUG':
        return <Typography className={styles.helperText}>Did something unexpectedly break or not go as planned? Share some details on what happened and the steps you took to debug.</Typography>
      case 'COLLABORATION':
        return <Typography className={styles.helperText}>Teamwork makes the dream work. Discuss a collaborative interaction you had this week that made an impact you.</Typography>
      case 'CONFUSION':
        return <Typography className={styles.helperText}>Did something leave you stumped? Talk through the details and the steps you took to help get you back on track.</Typography>
      case 'FRUSTRATION':
        return <Typography className={styles.helperText}>Not everything can be sunshine and rainbows. What made you frustrated? Did your actions lead to this frustration? If possible, how could you have mitigated this frustration? </Typography>
      case 'INTERVIEW':
        return <Typography className={styles.helperText}>Congrats on landing an interview! What were your key takeaways? Looking back do you recognize any opportunities for improvement?</Typography>
      case 'PROJECT':
        return <Typography className={styles.helperText}>Discuss your progress on a project you're working on. What progress have you made and share key learnings? </Typography>
      case 'WIN':
        return <Typography className={styles.helperText}>SUCCESS! What achievement or milestone did you reach? What led to this accomplishment?</Typography>

    }

   
  }


  const submitCheckInDetail = () => {
    if (checkInDetail != null) {
      const checkInDetailConfig = {
        tempId: Math.floor(Math.random() * 100),
        category: checkInCategory,
        value: checkInDetail
      }
  
      addToCheckIn(checkInDetailConfig)
      setHelperTextVisible(false)
    }
    else {
      setHelperTextVisible(true)
    }
  
  }

  const renderDetailTypeIcon = () => {
    switch (checkInCategory) {
      case 'PROJECT':
        return (
          <img 
            src={CONSTRUCTION_ICON}
            style={{height: 50, width: 50}}
          />
        )
      case 'BUG':
        return (
          <img 
            src={BUG_IMAGE}
            style={{height: 50, width: 50}}
          />
        )  
      case 'FRUSTRATION':
        return (
          <img 
            src={FRUSTRATION_ICON}
            style={{height: 50, width: 50}}
          />
        )
      case 'WIN':
        return (
          <img 
            src={WINS_ICON}
            style={{height: 50, width: 50}}
          />
        )
      case 'COLLABORATION':
        return (
          <img 
            src={COLLABORATION_ICON}
            style={{height: 50, width: 50}}
          />
        )
      case 'AHA_MOMENT':
        return (
          <img 
            src={LIGHT_BULB}
            style={{height: 50, width: 50}}
          />
        )
        case 'CONFUSION':
          return (
            <img 
              src={CONFUSION_ICON}
              style={{height: 50, width: 50}}
            />
          )
          case 'INTERVIEW':
            return (
              <img 
                src={INTERVIEW_ICON}
                style={{height: 50, width: 50}}
              />
            )
      default:
        break;
    }
  }

  



  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {
        setHelperTextVisible(false)
        handleClose()
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Stack direction='row' style={{justifyContent: 'space-between'}}>
            {renderDetailTypeIcon()}
            <Button 
              variant="text"
              onClick={() => handleClose()}
              >
              <CloseIcon />
            </Button>
          </Stack>
          <Stack style={{ height: '60%' }}>
            <Typography style={{ paddingBottom: 10 }}>{renderHelperText()}</Typography>
            <Box >
              <TextField
                onChange={(event) => setCheckInDetail(event.target.value)}
                id="outlined-multiline-static"
                multiline
                maxRows={12}
                minRows={12}
                placeholder=""
                // style={{ height: 300 }}
                // size='large'
                fullWidth={true}
                
              />
             
            </Box>
          </Stack>
          <Stack style={{ justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
            <StandardButton
              label={'SAVE'}
              buttonWidth={'100%'}
              onPress={() => submitCheckInDetail()}
              backgroundColor={colors.oceanBlue}
            />
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
}



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: '70vh',
  width: 850,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

CheckInDetailsModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleChange: PropTypes.func,
  checkInCategory: PropTypes.string,
  addToCheckIn: PropTypes.func,
};
