import React from 'react'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { timeSinceMessageSent } from '../../utils/DateUtils'

import PropTypes from 'prop-types'

import styles from './HighFiveTile.module.css'

export default function HighFiveTile(props) {

  const { dateCreated, value, senderFirstName, senderLastName, senderEmail } = props

  return (
    <Stack className={styles.highFiveWrapper}>
      <Stack direction='row' style={{textWrap: 'wrap'}}>
        <span className={styles.highFiveSenderText}>{senderFirstName ? senderFirstName : senderEmail}&nbsp; 
        {senderLastName ? senderLastName.substring(0, 1) : null}:
        <span className={styles.highFiveValue}>{value}</span></span>
      </Stack>
      <Typography className={styles.highFiveDateText}>{timeSinceMessageSent(dateCreated)} ago</Typography>
    </Stack>
  )
}


HighFiveTile.propTypes = {
  sender: PropTypes.string,
  dateCreated: PropTypes.string,
  value: PropTypes.string,
  senderFirstName: PropTypes.string,
  senderLastName: PropTypes.string,
  senderEmail: PropTypes.string
}