import {
  CREATING_TAG,
  CREATED_TAG,
  ERROR_CREATING_TAG,
  RETRIEVING_USER_TAGS,
  RETRIEVED_USER_TAGS,
  ERROR_RETRIEVING_USER_TAGS,
  CREATING_INDIVIDUAL_CHECK_IN,
  CREATED_INDIVIDUAL_CEHCK_IN,
  ERROR_CREATING_INDIVIDUAL_CHECK_IN,
  RETRIEVING_INDIVIDUAL_CHECK_INS,
  RETRIEVED_INDIVIDUAL_CHECK_INS,
  ERROR_RETRIEVING_INDIVIDUAL_CHECK_INS,
  CREATING_DIRECT_REPORT_TAG,
  CREATED_DIRECT_REPORT_TAG,
  ERROR_CREATING_DIRECT_REPORT_TAG,
  RETRIEVING_DIRECT_REPORT_TAGS,
  RETRIEVED_DIRECT_REPORT_TAGS,
  ERROR_RETRIEVING_DIRECT_REPORT_TAGS,
  UPDATING_WEEKLY_CHECK_IN,
  UPDATED_WEEKLY_CHECK_IN,
  ERROR_UPDATING_WEEKLY_CHECK_IN,
  RETRIEVING_COHORT_STATS,
  RETRIEVED_COHORT_STATS,
  ERROR_RETRIEVING_COHORT_STATS,
  RETRIEVING_WEEKLY_CHECKIN_SUMMARIES,
  RETRIEVED_WEEKLY_CHECKIN_SUMMARIES,
  RETRIEVING_HIGH_FIVES_FOR_COHORT,
  RETRIEVED_HIGH_FIVES_FOR_COHORT,
  ERROR_RETRIEVING_HIGH_FIVES_FOR_COHORT, 
  SUBMITTED_COMMENT_ON_CHECK_IN_DETAIL,
  SUBMITTING_COMMENT_ON_CHECK_IN_DETAIL,
  ERROR_SUBMITTING_COMMENT_ON_CHECK_IN_DETAIL,
  ADDING_COMMENT_TO_SUGGESTION,
  ADDED_COMMENT_TO_SUGGESTION,
  ERROR_ADDING_COMMENT_TO_SUGGESTION

} from '../constants/checkInActionTypes'

import {
  getAxiosInstance
} from '../../utils/ApiUtils'

import axios from 'axios'

// const BASE_URI = 'http://localhost:5000'
// const PROD_SERVER_URI = process.env.REACT_APP_PROD_SERVER_URI

import config from '../../env.json'
import { showErrorToast, showSuccessToast } from '../../utils/ToastUtils'

const { BASE_URI } = config


// Create Tag for Individual Check In
export const creatingTag = () => {
  return {
    type: CREATING_TAG
  }
}

export const createdTag = (tags) => {
  return {
    type: CREATED_TAG,
    payload: tags
  }
}

export const errorCreatingTag = (error) => {
  return {
    type: ERROR_CREATING_TAG,
    payload: error
  }
}

export const createTagForCheckIn = (tagConfig, token, closeModal, showSuccessToast) => {
  return function (dispatch, getState) {
    dispatch(creatingTag())
    return getAxiosInstance(token).post(`${BASE_URI}/checkIns/tags/create`, tagConfig)
      .then(res => {
        if (res.status === 200) {
          dispatch(createdTag(res.data))
          closeModal()
          showSuccessToast()
        }
      })
      .catch(err => {
        dispatch(errorCreatingTag())
      })
  }
}

// Create Tag for Individual Check In
export const creatingDirectReportTag = () => {
  return {
    type: CREATING_DIRECT_REPORT_TAG
  }
}

export const createdDirectReportTag = (tags) => {
  return {
    type: CREATED_DIRECT_REPORT_TAG,
    payload: tags
  }
}

export const errorCreatingDirectReportTag = (error) => {
  return {
    type: ERROR_CREATING_DIRECT_REPORT_TAG,
    payload: error
  }
}

export const createTagForPulseCheck = (tagConfig, existingTags, token, closeModal, showSuccessToast) => {
  return function (dispatch, getState) {
    let updatedTags = [...existingTags]
    dispatch(creatingDirectReportTag())

    return getAxiosInstance(token).post(`${BASE_URI}/checkIns/tags/pulse/create`, tagConfig)
      .then(res => {
        if (res.status === 200) {
          updatedTags.push(res.data)
          dispatch(createdDirectReportTag(updatedTags))
          closeModal()
          showSuccessToast()
        }
      })
      .catch(err => {
        dispatch(errorCreatingDirectReportTag())
      })
  }
}


// RETRIEVE USER TAGS
export const retrievingUserTags = () => {
  return {
    type: RETRIEVING_USER_TAGS
  }
}

export const retrievedUserTags = (tags) => {
  return {
    type: RETRIEVED_USER_TAGS,
    payload: tags
  }
}

export const errorRetrievingUserTags = (error) => {
  return {
    type: ERROR_RETRIEVING_USER_TAGS,
    payload: error
  }
}

export const retrieveUserTags = (userId, token,) => {
  return function (dispatch, getState) {
    dispatch(retrievingUserTags())
    return getAxiosInstance(token).get(`${BASE_URI}/checkIns/tags/${userId}`)
      .then(res => {
        if (res.status === 200) {
          dispatch(retrievedUserTags(res.data))
        }
      })
      .catch(err => {
        dispatch(errorRetrievingUserTags())
      })
  }
}

// CREATE INDIVIDUAL CHECK IN
export const creatingIndividualCheckIn = () => {
  return {
    type: CREATING_INDIVIDUAL_CHECK_IN
  }
}

export const createdIndividualCheckIn = (checkIn) => {
  return {
    type: CREATED_INDIVIDUAL_CEHCK_IN,
    payload: checkIn
  }
}

export const errorCreatingIndividualCheckIn = (error) => {
  return {
    type: ERROR_CREATING_INDIVIDUAL_CHECK_IN,
    payload: error
  }
}

export const createIndividualCheckIn = (checkInConfig, token, currentCheckIns, navigateToPage) => {
  return function (dispatch, getState) {
    dispatch(creatingIndividualCheckIn())
    return getAxiosInstance(token).post(`${BASE_URI}/checkIns/create`, checkInConfig)
      .then(res => {
        if (res.status === 200) {
          dispatch(createdIndividualCheckIn(res.data))
          navigateToPage()
        }
      })
      .catch(err => {
        dispatch(errorCreatingIndividualCheckIn())
      })
  }
}

// RETRIEVE INDIVIDUAL CHECK INS
export const retrieveIndividualCheckins = () => {
  return {
    type: RETRIEVING_INDIVIDUAL_CHECK_INS
  }
}

export const retrievedIndividualCheckIns = (checkIns) => {
  return {
    type: RETRIEVED_INDIVIDUAL_CHECK_INS,
    payload: checkIns
  }
}

export const errorRetrievingIndividualCheckIns = (error) => {
  return {
    type: ERROR_RETRIEVING_INDIVIDUAL_CHECK_INS,
    payload: error
  }
}

export const retrieveIndividualCheckIns = (userId, token) => {
  return function (dispatch, getState) {
    dispatch(retrieveIndividualCheckins())
    return getAxiosInstance(token).get(`${BASE_URI}/checkIns/user/${userId}`)
      .then(res => {
        if (res.status === 200) {
          dispatch(retrievedIndividualCheckIns(res.data))
        }
      })
      .catch(err => {
        dispatch(errorRetrievingIndividualCheckIns())
      })
  }
}


// RETRIEVE DIRECT REPORT TAGS
export const retrievingDirectReportTags = () => {
  return {
    type: RETRIEVING_DIRECT_REPORT_TAGS
  }
}

export const retrievedDirectReportTags = (tags) => {
  return {
    type: RETRIEVED_DIRECT_REPORT_TAGS,
    payload: tags
  }
}

export const errorRetrievingDirectReportTags = (error) => {
  return {
    type: ERROR_RETRIEVING_DIRECT_REPORT_TAGS,
    payload: error
  }
}

export const retrieveDirectReportTags = (userId, token) => {
  return function (dispatch, getState) {
    dispatch(retrievingDirectReportTags())
    return getAxiosInstance(token).get(`${BASE_URI}/checkIns/tags/pulseCheck/${userId}`)
      .then(res => {
        if (res.status === 200) {
          dispatch(retrievedDirectReportTags(res.data))
        }
      })
      .catch(err => {
        dispatch(errorRetrievingDirectReportTags())
      })
  }
}


// UPDATE WEEKLY CHECK IN
export const updatingWeeklyCheckIn = () => {
  return {
    type: UPDATING_WEEKLY_CHECK_IN
  }
}

export const updatedWeeklyCheckIn = (tags) => {
  return {
    type: UPDATED_WEEKLY_CHECK_IN,
    payload: tags
  }
}

export const errorUpdatingWeeklyCheckIn = (error) => {
  return {
    type: ERROR_UPDATING_WEEKLY_CHECK_IN,
    payload: error
  }
}

export const updateWeeklyCheckIn = (checkInConfig, token, navigateToCheckIns) => {
  return function (dispatch, getState) {
    dispatch(updatingWeeklyCheckIn())
    return getAxiosInstance(token).put(`${BASE_URI}/checkIns/user/update`, checkInConfig)
      .then(res => {
        if (res.status === 200) {
          dispatch(updatedWeeklyCheckIn(res.data))
          showSuccessToast('Successfully finished your check-in!')
          navigateToCheckIns()
        }
      })
      .catch(err => {
        dispatch(errorUpdatingWeeklyCheckIn())
        showErrorToast('Uh oh. There was a probelm creating your check-in. Please try again later.')
      })
  }
}


// RETRIEVE COHORT STATS
export const retrievingCohortStats = () => {
  return {
    type: RETRIEVING_COHORT_STATS
  }
}

export const retrievedCohortStats = (tags) => {
  return {
    type: RETRIEVED_COHORT_STATS,
    payload: tags
  }
}

export const errorRetrievingCohortStats = (error) => {
  return {
    type: ERROR_RETRIEVING_COHORT_STATS,
    payload: error
  }
}

export const retrieveCohortStats = (cohortId, startDate, endDate, token) => {
  return function (dispatch, getState) {
    dispatch(retrievingCohortStats())
    return getAxiosInstance(token).get(`${BASE_URI}/checkIns/stats/${cohortId}`, {params: {
      startDate: startDate, 
      endDate: endDate
    }})
      .then(res => {
        if (res.status === 200) {
          dispatch(retrievedCohortStats(res.data))
        }
      })
      .catch(err => {
        dispatch(errorRetrievingCohortStats())
      })
  }
}


// RETRIEVE WEEKLY CHECK IN SUMMARIES
export const retrievingWeeklyCheckInSummaries = () => {
  return {
    type: RETRIEVING_WEEKLY_CHECKIN_SUMMARIES
  }
}

export const retrievedWeeklyCheckInSummaries = (summaries) => {
  return {
    type: RETRIEVED_WEEKLY_CHECKIN_SUMMARIES,
    payload: summaries
  }
}

export const errorRetrievingWeeklyCheckInSummaries = (error) => {
  return {
    type: ERROR_RETRIEVING_COHORT_STATS,
    payload: error
  }
}

export const retrieveWeeklyCheckInSummaries = (cohortId, token) => {
  return function (dispatch, getState) {
    dispatch(retrievingWeeklyCheckInSummaries())
    return getAxiosInstance(token).get(`${BASE_URI}/checkIns/stats/summary/${cohortId}`)
      .then(res => {
        if (res.status === 200) {
          dispatch(retrievedWeeklyCheckInSummaries(res.data))
        }
      })
      .catch(err => {
        dispatch(errorRetrievingWeeklyCheckInSummaries())
      })
  }
}

// RETRIEVE HIGH FIVES FOR COHORT
export const retrievingHighFivesForCohort = () => {
  return {
    type: RETRIEVING_HIGH_FIVES_FOR_COHORT
  }
}

export const retrievedHighFivesForCohort = (highFives) => {
  return {
    type: RETRIEVED_HIGH_FIVES_FOR_COHORT,
    payload: highFives
  }
}

export const errorRetrievingHighFivesForCohort = (error) => {
  return {
    type: ERROR_RETRIEVING_HIGH_FIVES_FOR_COHORT,
    payload: error
  }
}

export const retrieveHighFivesForCohort = (cohortId, token) => {
  return function (dispatch, getState) {
    dispatch(retrievingHighFivesForCohort())
    return getAxiosInstance(token).get(`${BASE_URI}/checkIns/highFives/${cohortId}`)
      .then(res => {
        if (res.status === 200) {
          dispatch(retrievedHighFivesForCohort(res.data))
        }
      })
      .catch(err => {
        dispatch(errorRetrievingHighFivesForCohort())
      })
  }
}


// SUBMIT COMMENT ON CHECK_IN_DETAIL
export const submittingCommentOnCheckInDetail = () => {
  return {
    type: SUBMITTING_COMMENT_ON_CHECK_IN_DETAIL
  }
}

export const submittedCommentOnCheckInDetail = (submittedComment) => {
  return {
    type: SUBMITTED_COMMENT_ON_CHECK_IN_DETAIL,
    payload: submittedComment
  }
}

export const errorSubmittingCommentOnCheckInDetail = (error) => {
  return {
    type: ERROR_SUBMITTING_COMMENT_ON_CHECK_IN_DETAIL,
    payload: error
  }
}

export const submitCommentOnCheckInDetail = (commentConfig, token, checkInItems, setCheckInItems, setCheckInDetailComment) => {
  return function (dispatch, getState) {
    dispatch(submittingCommentOnCheckInDetail())
    return getAxiosInstance(token).post(`${BASE_URI}/checkIns/detail/comment`, commentConfig)
      .then(res => {
        if (res.status === 200) {
          dispatch(submittedCommentOnCheckInDetail(res.data))

          let updatedCheckInItems = checkInItems

          setCheckInItems((updatedCheckInItems) => {
            return updatedCheckInItems.map((checkInItem) => {
              if (checkInItem.id === res.data.checkInDetailId) {
                return {
                  ...checkInItem,
                  checkInDetailComments: checkInItem.checkInDetailComments.concat(res.data),
                };
              }
              return checkInItem;
            });
          });
          setCheckInDetailComment('')
          showSuccessToast('Successfully added your comment!')
        }
      })
      .catch(err => {
        dispatch(errorSubmittingCommentOnCheckInDetail())
        showErrorToast('Error adding your comment.')
      })
  }
}

// SUBMIT COMMENT ON CHECK_IN_DETAIL
export const addingCommentToSuggestion = () => {
  return {
    type: ADDING_COMMENT_TO_SUGGESTION
  }
}

export const addedCommentToSuggestion = (suggestionComment) => {
  return {
    type: ADDED_COMMENT_TO_SUGGESTION,
    payload: suggestionComment
  }
}

export const errorAddingCommentToSuggestion = (error) => {
  return {
    type: ERROR_ADDING_COMMENT_TO_SUGGESTION,
    payload: error
  }
}

export const addCommentToSuggestion = (commentConfig, token, checkInSuggestion, setCheckInSuggestion, setSuggestionComment) => {
  return function (dispatch, getState) {
    dispatch(addingCommentToSuggestion())
    return getAxiosInstance(token).post(`${BASE_URI}/checkIns/suggestion/comment`, commentConfig)
      .then(res => {
        if (res.status === 200) {
          dispatch(addedCommentToSuggestion(res.data))

          let updatedCheckInSuggestionComments = checkInSuggestion.suggestionComments
          updatedCheckInSuggestionComments.concat(res.data)

          setCheckInSuggestion({...checkInSuggestion, suggestionComments: checkInSuggestion.suggestionComments.concat(res.data)})
          setSuggestionComment('')
          showSuccessToast('Successfully added your comment!')
        }
      })
      .catch(err => {
        dispatch(errorAddingCommentToSuggestion())
        showErrorToast('Error adding your comment.')
      })
  }
}

// SUBMIT COMMENT ON SENTIMET
export const addingCommentToSentiment = () => {
  return {
    type: ADDING_COMMENT_TO_SUGGESTION
  }
}

export const addedCommentToSentiment = (suggestionComment) => {
  return {
    type: ADDED_COMMENT_TO_SUGGESTION,
    payload: suggestionComment
  }
}

export const errorAddingCommentToSentiment = (error) => {
  return {
    type: ERROR_ADDING_COMMENT_TO_SUGGESTION,
    payload: error
  }
}

export const addCommentToSentiment = (commentConfig, token, checkInSentiment, setCheckInSentiment, setSentimentComment) => {
  return function (dispatch, getState) {
    dispatch(addingCommentToSentiment())
    return getAxiosInstance(token).post(`${BASE_URI}/checkIns/sentiment/comment`, commentConfig)
      .then(res => {
        if (res.status === 200) {
          dispatch(addedCommentToSentiment(res.data))

          let updatedCheckInSentimentComments = checkInSentiment.checkInSentimentComments
          updatedCheckInSentimentComments.concat(res.data)

          setCheckInSentiment({...checkInSentiment, checkInSentimentComments: checkInSentiment.checkInSentimentComments.concat(res.data)})
          setSentimentComment('')
          showSuccessToast('Successfully added your comment!')
        }
      })
      .catch(err => {
        dispatch(errorAddingCommentToSentiment())
        showErrorToast('Error adding your comment.')
      })
  }
}
