import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import PropTypes from 'prop-types'

import FAVICON from '../../assets/interviewable-favicon.png'
import PENCIL_ICON from '../../assets/pencil-icon.png'

import { colors } from '../../constants/Colors'

import styles from './ResumeHeader.module.css'
import { getAxiosInstance, getMultipartAxiosInstance, getStreamingAttachmentAxiosInstance, getStreamingAxiosInstance } from '../../utils/ApiUtils'

export default function ResumeHeader(props) {

  const { preferredTitle, showModal, firstName, lastName, avatarURI, userProfileId, authedUserId } = props

  const renderAvatar = () => {
    if (avatarURI == null) {
      return <img src={FAVICON} className={styles.avatarStyle} />
    }
    else {
      return <img src={avatarURI} className={styles.avatarStyle} />
    }
  }

  return (
      <Stack style={{ height: 200, width: '100%', backgroundColor: colors.oceanBlue, borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
        <Stack direction='row'>
          <Box style={{ height: 200, width: 200, borderRadius: '50%', position: 'absolute', left: 320, top: 250 }}>
            {renderAvatar()}
          </Box>
        </Stack>
        <Stack style={{ marginTop: 155, marginLeft: 290 }}>
          <Stack direction='row'>
          <Typography style={{ fontSize: 30, color: 'white', textShadow: '1px 1px 3px black' }}>{firstName ? firstName : null} {lastName ? lastName : null }</Typography>
          {authedUserId === userProfileId ?  <Button
              variant='text'
              onClick={() => showModal(true)}
            >
              <img 
                src={PENCIL_ICON} 
                style={{ height: 20, width: 20, backgroundColor: 'white', borderRadius: 5, height: 30, width: 30, padding: 5 }} />
            </Button> : null} 
         
          </Stack>
          
          <Typography style={{ fontSize: 20, paddingTop: 0, paddingLeft: 5 }}>{preferredTitle}</Typography>
        </Stack>
      </Stack>
  )
}


ResumeHeader.propTypes = {
  preferredTitle: PropTypes.string,
  showModal: PropTypes.func,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  preferredTitle: PropTypes.string,
  userProfileId: PropTypes.string,
  authedUserId: PropTypes.string
}