import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Cookies from 'js-cookie';

import Stack from '@mui/material/Stack'
import Popover from '@mui/material/Popover';
import Typography from "@mui/material/Typography";
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField'
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button'

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Select from 'react-select';

import SidebarNav from '../../components/navigation/SidebarNav'
import CCDatePicker from '../../components/pickers/CCDatePicker';
import StandardButton from '../../components/buttons/StandardButton';
import { colors } from '../../constants/Colors';
import { useSelector } from 'react-redux';
import { createNewCohort } from '../../redux/actions/organizationActions';
import { getDatesInclusive } from '../../utils/DateUtils';
import { getAxiosInstance } from '../../utils/ApiUtils';

import config from '../../env.json'
import { showErrorToast } from '../../utils/ToastUtils';

const { BASE_URI } = config

export default function CreateCohortScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const organizationId = useSelector((state) => state.org.id)
  const token = Cookies.get('token')

  const [cohortName, setCohortName] = useState('')
  const [focusArea, setFocusArea] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [createCheckInSelected, setCreateCheckInsSelected] = useState(false)
  const [noCheckInsSelected, setNoCheckInsSelected] = useState(false)

  const [isEntryLevel, setIsEntryLevel] = useState('no');
  const [checkInDayOfWeek, setCheckInDayofWeek] = useState(null)
  const [checkInStartDate, setCheckInStartDate] = useState(null)
  const [checkInEndDate, setCheckInEndDate] = useState(null)
  const [cohortMembers, setCohortMembers] = useState(null)
  const [cohortFocusAreas, setCohortFocusAreas] = useState([])
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const handlePopOverClicked = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopOverClose = () => {
    setPopoverAnchorEl(null);
  };

  const open = Boolean(popoverAnchorEl);
  const id = open ? 'simple-popover' : undefined;


  const handleFocusAreaChange = (selectedOption) => {
    setFocusArea(selectedOption)
  };

  const handleDayOfWeekChange = (dayOfWeekOption) => {
    setCheckInDayofWeek(dayOfWeekOption)
  }

  useEffect(() => {
    getAxiosInstance(token).get(`${BASE_URI}/org/cohorts/focusAreaValues`)
      .then((res) => {
        if (res.status == 200) {

          let focusAreaList = []
          res.data.map((focusArea) => {
            focusAreaList.push({ value: focusArea, label: focusArea })
          })
          setCohortFocusAreas(focusAreaList)
        }
      })
  }, [token])


  const daysOfWeek = [
    { value: 'MONDAY', label: 'Monday' },
    { value: 'TUESDAY', label: 'Tuesday' },
    { value: 'WEDNESDAY', label: 'Wednesday' },
    { value: 'THURSDAY', label: 'Thursday' },
    { value: 'FRIDAY', label: 'Friday' },
    { value: 'SATURDAY', label: 'Saturday' },
    { value: 'SUNDAY', label: 'Sunday' },
  ]

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 250,
    }),
  };

  const handleCheckInStateChange = (type, value) => {
    switch (type) {
      case 'no':
        setNoCheckInsSelected(true)
        setCreateCheckInsSelected(false)
        break;
      case 'yes':
        setNoCheckInsSelected(false)
        setCreateCheckInsSelected(true)
        break;

      default:
        break;
    }
  }

  const handleChange = (event, newAlignment) => {
    setIsEntryLevel(newAlignment);
  };

  const handleCohortStartDateChange = (date) => {
    setStartDate(date.$d)
  }

  const handleCohortEndDateChange = (date) => {
    setEndDate(date.$d)
  }

  const handleWeeklyCheckInStartDateChange = (date) => {
    setCheckInStartDate(date.$d)
  }

  const handleWeeklyCheckInEndDatChange = (date) => {
    setCheckInEndDate(date.$d)
  }

  const renderCheckInSetup = () => {
    if (createCheckInSelected) {
      return (
        <Stack>
          <Stack>
            <Typography>What day of the week are check-ins due?<span style={{color: colors.blazeRed}}>*</span></Typography>
            <Select
              value={checkInDayOfWeek ? checkInDayOfWeek : null}
              onChange={handleDayOfWeekChange}
              options={daysOfWeek} />
          </Stack>
          <Stack direction='row' style={{ width: '100%', justifyContent: 'space-between', marginTop: 10 }}>
            <FormGroup className="mb-3" controlId="formBasicEmail">
              <Typography>Check In Start Date<span style={{color: colors.blazeRed}}>*</span></Typography>
              <CCDatePicker
                setDate={handleWeeklyCheckInStartDateChange}
              />
            </FormGroup>
            <FormGroup className="mb-3" controlId="formBasicEmail">
              <Typography>Check In End Date<span style={{color: colors.blazeRed}}>*</span></Typography>
              <CCDatePicker
                setDate={handleWeeklyCheckInEndDatChange}
              />
            </FormGroup>
          </Stack>

        </Stack>
      )
    }
  }

  const parseInvitees = (input) => {
    // Remove commas from the input string
    var cleanedInput = input.replace(/,/g, '');

    // Split the cleaned input at each new line character ("\n")
    var items = cleanedInput.split('\n');

    return items;
  }

  const parseToCohortMembers = (input) => {

    let cohortMemberList = []

    if (input && input.length > 0) {
      let stringCohortMembers = parseInvitees(input)
      stringCohortMembers.map((member) => {
        cohortMemberList.push(
          {
            user: {
              email: member
            }
          }
        )
      })
    }

    return cohortMemberList
  }

  const getCheckInDates = () => {

    if (createCheckInSelected == true) {
      const dayOfWeek = checkInDayOfWeek.label;
      const startDate = new Date(checkInStartDate)
      const endDate = new Date(checkInEndDate)

      const result = getDatesInclusive(dayOfWeek, startDate, endDate);

      return result
    }
    return null;
  }

  const navigateToOrgScreen = () => {
    navigate('/org')
  }

  const createCohort = () => {
    if (cohortName === '') {
      showErrorToast('Cohort name is required.')
    }
    if (focusArea === '') {
      showErrorToast('Cohort type is required.')
    }
    if (startDate == null) {
      showErrorToast('Cohort start date is required.')
    }
    if (endDate == null) {
      showErrorToast('Cohort end date is required.')
    }
    if (isEntryLevel === '') {
      showErrorToast('Identify whether cohort will be applying to entry-level roles.')
    }
    if (createCheckInSelected) {
      if (checkInStartDate == null) {
        showErrorToast('Check-In start date is required.')
      }
      else if (checkInEndDate == null) {
        showErrorToast('Check-In end date is required.')
      }
      else if (checkInDayOfWeek == null) {
        showErrorToast('Check-In day of the week is required.')
      }
    }

    const cohortConfig = {
      name: cohortName,
      startDate: startDate,
      endDate: endDate,
      organizationId: organizationId,
      focusArea: focusArea.value,
      isEntryLevel: isEntryLevel === 'yes' ? true : false,
      weeklyCheckInEnabled: createCheckInSelected,
      checkInStartDate: createCheckInSelected == true ? checkInStartDate : null,
      checkInEndDate: createCheckInSelected == true ? checkInEndDate : null,
      checkInDayOfWeek: createCheckInSelected == true ? checkInDayOfWeek.value : null,
      checkInDates: getCheckInDates(),
      cohortMembers: parseToCohortMembers(cohortMembers)

    }

    dispatch(createNewCohort(cohortConfig, token, navigateToOrgScreen))
  }

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <SidebarNav />
      <Stack style={{ marginLeft: 50, marginTop: 50, width: '40%', }}>
        <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4 }}>COHORT DETAILS</Typography>
        <form>
          <Stack direction='row' style={{ width: '100%', justifyContent: 'space-between', marginTop: 20 }}>
            <FormGroup className="mb-3" controlId="formBasicEmail">
              <Typography>Cohort Name<span style={{color: colors.blazeRed}}>*</span></Typography>
              <TextField
                // label="Company Name"
                type="text"
                value={cohortName}
                onChange={(event) => setCohortName(event.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </FormGroup>
            <FormGroup className="mb-3" controlId="formBasicEmail">
              <Typography style={{ marginBottom: 15 }}>Cohort Type<span style={{color: colors.blazeRed}}>*</span></Typography>
              <Select
                value={focusArea}
                onChange={handleFocusAreaChange}
                options={cohortFocusAreas}
                styles={customStyles}
              />
            </FormGroup>

          </Stack>
          <Stack direction='row' style={{ width: '100%', justifyContent: 'space-between' }}>
            <FormGroup className="mb-3" controlId="formBasicEmail">
              <Typography>Start Date<span style={{color: colors.blazeRed}}>*</span></Typography>
              <CCDatePicker
                setDate={handleCohortStartDateChange}
              />
            </FormGroup>
            <FormGroup className="mb-3" controlId="formBasicEmail">
              <Typography>End Date<span style={{color: colors.blazeRed}}>*</span></Typography>
              <CCDatePicker
                setDate={handleCohortEndDateChange}
              />
            </FormGroup>
          </Stack>
          <FormGroup className="mb-3" controlId="formBasicEmail">
            <Typography>Invite Cohort Members (add each email address on a new line)</Typography>
            <TextField
              onChange={(event) => setCohortMembers(event.target.value)}
              multiline
              minRows={10}
              maxRows={10}
            />
          </FormGroup>
          <Stack style={{ marginBottom: 10 }}>
            <Stack>
              <Stack direction='row' style={{ height: 40, alignItems: 'center' }}>
                <Typography style={{ marginBottom: 15 }}>Will cohort members be applying to entry-level roles?<span style={{color: colors.blazeRed}}>*</span></Typography>
                <Button aria-describedby={id} variant='text' onClick={handlePopOverClicked} style={{height: 20, bottom: 8, right: 15}} >
                  <InfoIcon style={{color: colors.oceanBlue, height: 25, width: 25}} />
                </Button>
              </Stack>

              <Popover
                id={id}
                open={open}
                anchorEl={popoverAnchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Typography sx={{ p: 2, width: '20dvw' }}>Is yes, interview responses will be evaluated at the entry level role for their respective discipline (e.g. Software Engineer I). The option for cohort members to be assessed at higher job levels will be inaccessable when they create interviews.</Typography>
              </Popover>

            </Stack>

            <ToggleButtonGroup
              color="primary"
              value={isEntryLevel}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value="no">No</ToggleButton>
              <ToggleButton value="yes">Yes</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <Stack>
            <Typography style={{ marginBottom: 15 }}>Set up weekly Check Ins for cohort members?<span style={{color: colors.blazeRed}}>*</span></Typography>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel value="no" control={<Radio checked={noCheckInsSelected} onChange={(value) => handleCheckInStateChange('no', value)} />} label="No" />
              <FormControlLabel value="yes" control={<Radio checked={createCheckInSelected} onChange={(value) => handleCheckInStateChange('yes', value)} />} label="Yes" />
            </RadioGroup>
          </Stack>

          <Stack>
            {createCheckInSelected ? renderCheckInSetup() : null}
          </Stack>


        </form>
        <Stack style={{ marginTop: 40, marginBottom: 60 }}>
          <StandardButton
            label='CREATE COHORT'
            backgroundColor={colors.oceanBlue}
            onPress={() => createCohort()}
          />
        </Stack>

      </Stack>
    </div>
  )
}