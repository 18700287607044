import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SidebarNav from '../../components/navigation/SidebarNav'

import styles from './HelpScreen.module.css'


export default function HelpScreen() {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.freshworks.com/widgets/153000000836.js'
    script.async = true
  
    document.body.appendChild(script)
  
    return () => {
      document.body.removeChild(script)
    }
  })
  
  return (
    <Grid container spacing={2}>
      <SidebarNav />
      <Grid className={styles.pageWrapper} xs={8}>
        <Stack>
          <Stack className={styles.helperTextWrapper}>
            <Typography>Have some questions? Hopefully we've got an answer for you below. If not, please user the contact form below and we'll get back to you. </Typography>
          </Stack>

          <Stack className={styles.headingWrapper}>
            <Typography className={styles.headingText}>Interviews</Typography>
          </Stack>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={styles.accordianHeading}>When clicking ANSWER during my interview the app isn't recording my answer.</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                In order for the us to record your voice you need to accept the prompts from your browser to allow Interviewable to have access to your microphone. If you already declined access, no sweat.
                If you're using Chrome navigate to Settings - Privacy and Security - Site Settings - Microphone. If you see Interviewable under the 'Not allow to use your microphone' section just change those settings to allow access. 
              </Typography>
              <Typography>
                In Safari you must accept the prompt to allow Access to Speech Recognition. 
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={styles.accordianHeading}>I am unable to start an interview. What gives?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                After you've used the credits as part of your monthly plan, you must purchase new credits to create a new interview. Credits are reflected on the right-hand side of the Interview page. Once you've 
                verified that you have enough credits ensure you have provided all the information required on the pop seen after you click 'START AN INTERVIEW' found at the top of the Interview page. 
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={styles.accordianHeading}>I got an error when stopping the recording of my response to an interview question.</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We apologize for the hiccup. Try resubmitting your same response. The issue usually resolves itself on its own. 
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Stack className={styles.headingWrapper}>
            <Typography className={styles.headingText}>Billing</Typography>
          </Stack>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={styles.accordianHeading}>How do subscriptions work?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                When you sign up for a paid plan, your subscription begins the day you sign up. It will automatically renew one month from your sign up date. For example you sign up on January 1st. Your subscription will automatically renew on February 1st. 
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={styles.accordianHeading}>How do I cancel my subscription?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                To cancel your subscription navigate to the Billing page. Under the Current Plan section click the cancel button and confirm cancellation in the next pop up menu.
                You will have full access to Interviewable and all of its features until the plan end date listed after you cancel. 
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Grid>
    </Grid>
  )
}