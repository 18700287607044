import axios from 'axios'

// import BASE_URI from '../env.json'
const  BASE_URI = 'http://localhost:5000'




export const getAxiosInstanceWithoutAuth = () => {
  return axios.create({
    baseURL: BASE_URI,
    timeout: 20000,
    headers: { 
      'Content-Type': 'application/json',
      Accept: 'application/json', 
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      
    }
  })
}

export const getAxiosInstance = (token) => {
  return axios.create({
    baseURL: BASE_URI,
    timeout: 30000,
    headers: { 
      Authorization: `Bearer ${token}`, 
      'Content-Type': 'application/json',
      Accept: 'application/json', 
      'Access-Control-Allow-Origin': '*',
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    }
  })
}


export const getMultipartAxiosInstance = (token) => {
  return axios.create({
    timeout: 20000,
    headers: { 
      Authorization: `Bearer ${token}`, 
      'Content-type': 'multipart/form-data'
    }
  })
}

export const getStreamingAxiosInstance = (token) => {
  return axios.create({
    baseURL: BASE_URI,
    timeout: 20000,
    responseType: 'arraybuffer',
    headers: { 
      Authorization: `Bearer ${token}`, 
      'Content-Type': 'multipart/form-data' }
  })
}

export const getStreamingAttachmentAxiosInstance = (token) => {
  return axios.create({
    baseURL: BASE_URI,
    timeout: 20000,
    responseType: 'arraybuffer',
    headers: { 
      Authorization: `Bearer ${token}`, 
      'Content-Type': 'application/json',
    }
  })
}

