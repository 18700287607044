export const SAVING_USER_PROFILE_PREFERENCES = 'SAVING_USER_PROFILE_PREFERENCES'
export const SAVED_USER_PROFILE_PREFERENCECS = 'SAVED_USER_PROFILE_PREFERENCECS'
export const ERROR_SAVING_USER_PROFILE_PREFERENCES = 'ERROR_SAVING_USER_PROFILE_PREFERENCES'

export const SET_USER_PREFERENCES = 'SET_USER_PREFERENCES';

export const SET_USER_META_DATA = 'SET_USER_META_DATA'

export const UPDATING_USER_META_DATA = 'UPDATING_USER_META_DATA'
export const UPDATED_USER_META_DATA = 'UPDATED_USER_META_DATA'
export const ERROR_UPDATING_USER_META_DATA = 'ERROR_UPDATING_USER_META_DATA'

export const RETRIEVING_WORK_HISTORY = 'RETRIEVING_WORK_HISTORY'
export const RETRIEVED_WORK_HISTORY = 'RETRIEVED_WORK_HISTORY'
export const ERROR_RETRIEVING_WORK_HISTORY = 'ERROR_RETRIEVING_WORK_HISTORY'

export const ADDING_JOB_TO_WORK_HISTORY = 'ADDING_JOB_TO_WORK_HISTORY'
export const ADDED_JOB_TO_WORK_HISTORY = 'ADDED_JOB_TO_WORK_HISTORY'
export const ERROR_ADDING_JOB_TO_WORK_HISTORY = 'ERROR_ADDING_JOB_TO_WORK_HISTORY'

export const ADDING_EDUCATION_HISTORY = 'ADDING_EDUCATION_HISTORY'
export const ADDED_EDUCATION_HISTORY = 'ADDED_EDUCATION_HISTORY'
export const ERROR_ADDING_EDUCATION_HISTORY = 'ERROR_ADDING_EDUCATION_HISTORY'

export const UPDATING_PROFILE_AVATAR = 'UPDATING_PROFILE_AVATAR'
export const UPDATED_PROFILE_AVATAR = 'UPDATED_PROFILE_AVATAR'
export const ERROR_UPDATING_PROFILE_AVATAR = 'ERROR_UPDATING_PROFILE_AVATAR'

export const UPDATING_JOB_ON_WORK_HISTORY = 'UPDATING_JOB_ON_WORK_HISTORY'
export const UPDATED_JOB_ON_WORK_HISTORY = 'UPDATED_JOB_ON_WORK_HISTORY'
export const ERROR_UPDATING_JOB_ON_WORK_HISTORY = 'ERROR_UPDATING_JOB_ON_WORK_HISTORY'

export const DELETING_JOB_FROM_WORK_HISTORY = 'DELETING_JOB_FROM_WORK_HISTORY'
export const DELETED_JOB_FROM_WORK_HISOTRY = 'DELETED_JOB_FROM_WORK_HISOTRY'
export const ERROR_DELETING_JOB_FROM_WORK_HISTORY = 'ERROR_DELETING_JOB_FROM_WORK_HISTORY' 

export const DELETING_JOB_DETAIL = 'DELETING_JOB_DETAIL'
export const DELETED_JOB_DETAIL = 'DELETED_JOB_DETAIL'
export const ERROR_DELETING_JOB_DETAIL = 'ERROR_DELETING_JOB_DETAIL'

export const UPDATING_EDUCATION_RECORD = 'UPDATING_EDUCATION_RECORD'
export const UPDATED_EDUCATION_RECORD = 'UPDATED_EDUCATION_RECORD'
export const ERROR_UPDATING_EDUCATION_RECORD = 'ERROR_UPDATING_EDUCATION_RECORD'

export const DELETING_EDUCATION_RECORD = 'DELETING_EDUCATION_RECORD'
export const DELETED_EDUCATION_RECORD = 'DELETED_EDUCATION_RECORD'
export const ERROR_DELETING_EDUCATION_RECORD = 'ERROR_DELETING_EDUCATION_RECORD'

export const DELETING_EDUCATION_ACTIVITY = 'DELETING_EDUCATION_ACTIVITY'
export const DELETED_EDUCATION_ACTIVITY = 'DELETED_EDUCATION_ACTIVITY'
export const ERROR_DELETING_EDUCATION_ACTIVITY = 'ERROR_DELETING_EDUCATION_ACTIVITY' 

export const RETRIEVING_USER_RESUMES = 'RETRIEVING_USER_RESUMES'
export const RETRIEVED_USER_RESUMES = 'RETRIEVED_USER_RESUMES'
export const ERROR_RETRIEVING_USER_RESUMES  = 'ERROR_RETRIEVING_USER_RESUMES'

export const UPLOADING_USER_RESUME = 'UPLOADING_USER_RESUME'
export const UPLOADED_USER_RESUME = 'UPLOADED_USER_RESUME'
export const ERROR_UPLOADING_USER_RESUME = 'ERROR_UPLOADING_USER_RESUME'

export const DELETING_USER_RESUME = 'DELETING_USER_RESUME'
export const DELETED_USER_RESUME = 'DELETED_USER_RESUME'
export const ERROR_DELETING_USER_RESUME = 'ERROR_DELETING_USER_RESUME'

