import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import { useSelector, useDispatch } from "react-redux";
import FormGroup from '@mui/material/FormGroup';

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { FormControl, InputLabel } from '@mui/material'

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Button from '@mui/material/Button'


import StandardButton from "../buttons/StandardButton";
import { colors } from "../../constants/Colors";


import { softwareEngineeringLevels } from "../../constants/TechnicalRoles";
import { programmingLanguages } from "../../constants/ProgrammingLanguages";

import {
  retrieveTechnicalFocusAreasByProgrammingLanguage, retrieveTechnicalFocusAreasByRole,
} from '../../redux/actions/interviewActions'

import { getAxiosInstance } from "../../utils/ApiUtils";
import ControlledCheckbox from "../checkBoxes/ControlledCheckBox";

import config from '../../env.json'
import { retrieveCreditsForAccount } from "../../redux/actions/accountActions";
import { showErrorToast } from "../../utils/ToastUtils";


import styles from './NewInterviewModal.module.css'

export default function NewInterviewModal(props) {
  const { BASE_URI } = config
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    open,
    handleClose,
  } = props;


  const token = Cookies.get('token');

  const authedUserId = useSelector((state) => state.auth.id)
  const preferredRole = useSelector(state => state.user.preferredRole)
  const resumes = useSelector((state) => state.user.resumes)
  const isEntryLevelTechnicalCareerCandidate = useSelector((state) => state.user.isEntryLevelTechnicalCareerCandidate)

  const [selected, setSelected] = useState([]);
  const [interviewType, setInterviewType] = useState('')
  const [technicalLevel, setTechnicalLevel] = useState('')
  const [programmingLanguage, setProgrammingLanguage] = useState(null)
  const [behavioralFocusAreas, setBehavioralFocusAreas] = useState([])
  const [technicalFocusAreas, setTechnicalFocusAreas] = useState([])
  const [professionalFocusAreas, setProfessionalFocusAreas] = useState([])

  const [selectedTechnicalAreasOfFocus, setSelectedTechnicalAreasOfFocus] = useState([])
  const [selectedTechnicalFocusAreaIds, setSelectedTechnicalFocusAreaIds] = useState([])

  const [selectedProfessionalAreasOfFocus, setSelectedProfessionalAreasOfFocus] = useState([])
  const [selectedProfessionalFocusAreaIds, setSelectedProfessionalFocusAreaIds] = useState([])
  
  const [selectedBehavioralAreasOfFocus, setSelectBehavioralAreasOfFocus] = useState([])
  const [selectedBehavioralFocusAreaIds, setSelectedBehavioralFocusAreaIds] = useState([])
  
  const [selectedResumeForEvaluation, setSelectedResumeForEvaluation] = useState(null)

  const [roleLevels, setRoleLevels] = useState([])
  const [interviewer, setInterviewer] = useState(null)

  
  useEffect(() => {
    if (preferredRole) {
      getLevelsForRole()
    }
  }, [interviewType, selectedTechnicalAreasOfFocus, selectedBehavioralAreasOfFocus, selectedProfessionalAreasOfFocus])

  const technicalInterviewTypes = [
    'Behavioral',
    'Technical',
    'Behavioral & Technical'
  ]

  const professionalInterviewTypes = [
    'Behavioral',
    'Professional',
    'Behavioral & Professional'
  ]

  const getLevelsForRole = () => {
    return getAxiosInstance(token).get(`${BASE_URI}/interviews/levels/${preferredRole}`)
      .then((res) => {
        if (res.status == 200) {
          if (interviewType[0] === 'Technical' && isEntryLevelTechnicalCareerCandidate == true) {
            const filteredLevel = res.data.filter((item) => item.level === 'Software Engineer I')
            setRoleLevels(filteredLevel)
          }
          else {
            setRoleLevels(res.data)
          }
        }
      })
      .catch((error) => {

      })
  }

  const handleChange = (event, type) => {
    const {
      target: { value },
    } = event;

    switch (type) {
      case 'interviewType':
        setInterviewType(
          typeof value === 'string' ? value.split(',') : value,
        );

        if (value === 'Behavioral' || value === 'Behavioral & Technical' || 'Behavioral & Professional') {
          queryBehavioralFocusAreas()
        }
        if (value === 'Technical' || value === 'Behavioral & Technical') {
          queryTechnicalFocusAreasByRole()
        }

        break;
      case 'technicalLevel':
        setTechnicalLevel(
          typeof value === 'string' ? value.split(',') : value,
        );
        queryProfessionalFocusAreasByRole(preferredRole)
        break;
      case 'resume':
        setSelectedResumeForEvaluation(
          typeof value === 'string' ? value.split(',') : value,
        )
        break;
      default:
        break;
    }
  };

  const queryTechnicalFocusAreasByRole = () => {
    dispatch(retrieveTechnicalFocusAreasByRole(preferredRole, token, setTechnicalFocusAreas))
  }

  const queryProfessionalFocusAreasByRole = (role) => {

    getAxiosInstance(token).get(`${BASE_URI}/interviews/professionalfocusareas/${role}`)
      .then((res) => {
        if (res.status == 200) {
          setProfessionalFocusAreas(res.data)
        }
      })
      .catch(err => {

      })
  }

  const queryBehavioralFocusAreas = () => {
    getAxiosInstance(token).get(`${BASE_URI}/interviews/behavioralfocusareas`)
      .then((res) => {
        if (res.status == 200) {
          setBehavioralFocusAreas(res.data)
        }
      })
      .catch((error) => {

      })
  }

  const renderInterviewTypes = (preferredRole) => {
    switch (preferredRole) {
      case 'Software Engineer Frontend JavaScript':
      case 'Software Engineer Backend Java':
      case 'Data Engineer':
      case 'Machine Learning Engineer':

        return technicalInterviewTypes.map((type) => {
          return (
            <MenuItem key={type} value={type}>{type}</MenuItem>
          )
        })
      
      default:
        return professionalInterviewTypes.map((type) => {
          return (
            <MenuItem  key={type} value={type}>{type}</MenuItem>
          )
        })
    }

  }

  const renderTechnicalLevel = () => {
    if (roleLevels.length > 0) {
      return roleLevels.map((level) => {
        return (
          <MenuItem key={level} value={level.id}>{level.level}</MenuItem>
        )
      })
    }

  }

  const renderAvailableResumes = () => {
    return resumes.map((resume, index) => {
      return (
        <MenuItem key={index} value={resume.title}>{resume.title}</MenuItem>
      )
    })
  }

  const renderResumeSelector = () => {
    if (resumes) {
      if (resumes.length > 0) {
        return (
          <Stack style={{ marginTop: 20 }}>
            <Typography style={{ paddingTop: 10, paddingBottom: 10, fontWeight: 'bold', color: colors.plum }}>Choose a resume to be used to evaluate your interview responses.</Typography>
            <FormControl fullWidth>
              <Select
                labelId="resume-label-type"
                id="simple-select"
                value={selectedResumeForEvaluation}
                onChange={(event) => handleChange(event, 'resume')}
              >
                {renderAvailableResumes()}
              </Select>
            </FormControl>
          </Stack>
        )
      }
      else {
        return (
          <Stack style={{ marginTop: 20 }}>
            <Typography style={{ paddingTop: 10, paddingBottom: 10, fontWeight: 'bold', color: colors.plum }}>Upload your resume to to help us provide more feedback on your interview. </Typography>
            <Button
              variant='text'
              className={styles.resumeUploadButton}
              onClick={() => navigate(`/resume/${authedUserId}`)}
            >
              <Typography className={styles.resumeUploadButtonText}>UPLOAD</Typography>
            </Button>
          </Stack>
        )
      }
    }

  }

  const updateSelectedTechnicalFocusAreas = (value, id) => {

    if (selectedTechnicalAreasOfFocus.includes(value)) {
      const updatedList = selectedTechnicalAreasOfFocus.filter((tfa => tfa !== value))
      const updateTechnicalFocusAreaIds = selectedTechnicalFocusAreaIds.filter((tfaId) => tfaId !== id)
      setSelectedTechnicalAreasOfFocus(updatedList)
      setSelectedTechnicalFocusAreaIds(updateTechnicalFocusAreaIds)
    }
    else {
      setSelectedTechnicalAreasOfFocus(selectedTechnicalAreasOfFocus.concat(value))
      setSelectedTechnicalFocusAreaIds(selectedTechnicalFocusAreaIds.concat(id))
    }
  }

  const updateSelectedBehavioralFocusAreas = (value, id) => {

    if (selectedBehavioralAreasOfFocus.includes(value)) {
      const updatedList = selectedBehavioralAreasOfFocus.filter((bfa => bfa !== value))
      const updatedBehavioralFOcusAreaIds = selectedBehavioralFocusAreaIds.filter((bfaId) => bfaId !== id)
      setSelectBehavioralAreasOfFocus(updatedList)
      setSelectedBehavioralFocusAreaIds(updatedBehavioralFOcusAreaIds)

    }
    else {
      setSelectBehavioralAreasOfFocus(selectedBehavioralAreasOfFocus.concat(value))
      setSelectedBehavioralFocusAreaIds(selectedBehavioralFocusAreaIds.concat(id))
    }
  }

  const updateSelectedProfessionalFocusAreas = (value, id) => {
    if (selectedProfessionalAreasOfFocus.includes(value)) {
      const updatedList = selectedProfessionalAreasOfFocus.filter((pfa => pfa !== value))
      const updatedProfessionalFocusAreaIds = selectedProfessionalFocusAreaIds.filter((pfaId) => pfaId !== id)

      setSelectedProfessionalAreasOfFocus(updatedList)
      setSelectedProfessionalFocusAreaIds(updatedProfessionalFocusAreaIds)
    }
    else {
      setSelectedProfessionalAreasOfFocus(selectedProfessionalAreasOfFocus.concat(value))
      setSelectedProfessionalFocusAreaIds(selectedProfessionalFocusAreaIds.concat(id))
    }
  }

  const renderTechnicalFocusAreas = () => {
    let javaScriptFocusAreas = []
    let javaFocusAreas = []
    let dataEngineerFocusAreas = []
    let machineLearningFocusAreas = []

    technicalFocusAreas.map((tfa) => {
      if (tfa.role === 'SOFTWARE_ENGINEER_FRONTEND_JAVASCRIPT') {
        javaScriptFocusAreas.push(tfa)
      }
      else if (tfa.role === 'SOFTWARE_ENGINEER_BACKEND_JAVA') {
        javaFocusAreas.push(tfa)
      }
      else if (tfa.role === 'DATA_ENGINEER') {
        dataEngineerFocusAreas.push(tfa)
      }
      else if (tfa.role === 'MACHINE_LEARNING_ENGINEER') {
        machineLearningFocusAreas.push(tfa)
      }
    })

  
    if (isEntryLevelTechnicalCareerCandidate) {
      switch(preferredRole) {
        case 'Software Engineer Frontend JavaScript':
        case 'Software Engineer Backend Java':
          return (
            <Stack>
              <Stack>
                <Typography className={styles.technicalFocusAreaLabel}>JavaScript Focus Areas</Typography>
                {javaScriptFocusAreas.map((focusArea) => {
                  return <ControlledCheckbox value={focusArea.name} id={focusArea.id} updateItems={updateSelectedTechnicalFocusAreas} />
                })}
              </Stack>
              <Stack>
                <Typography className={styles.technicalFocusAreaLabel}>Java Focus Areas</Typography>
                {javaFocusAreas.map((focusArea) => {
                  return <ControlledCheckbox value={focusArea.name} id={focusArea.id} updateItems={updateSelectedTechnicalFocusAreas} />
                })}
              </Stack>
            </Stack>
          )

      
        case 'Data Engineer':
        case 'Machine Learning Engineer':
          return (
            <Stack>
              <Stack>
                <Typography className={styles.technicalFocusAreaLabel}>Data Engineer Focus Areas</Typography>
                {dataEngineerFocusAreas.map((focusArea) => {
                  return <ControlledCheckbox value={focusArea.name} id={focusArea.id} updateItems={updateSelectedTechnicalFocusAreas} />
                })}
              </Stack>
              <Stack>
                <Typography className={styles.technicalFocusAreaLabel}>Machine Learning Areas</Typography>
                {machineLearningFocusAreas.map((focusArea) => {
                  return <ControlledCheckbox value={focusArea.name} id={focusArea.id} updateItems={updateSelectedTechnicalFocusAreas} />
                })}
              </Stack>
            </Stack>
          )

        break;
        default: 

        break;
      }
     

      // return (
      //   <Stack>
      //     <Stack>
      //       <Typography className={styles.technicalFocusAreaLabel}>JavaScript Focus Areas</Typography>
      //       {javaScriptFocusAreas.map((focusArea) => {
      //         return <ControlledCheckbox value={focusArea.name} id={focusArea.id} updateItems={updateSelectedTechnicalFocusAreas} />
      //       })}
      //     </Stack>
      //     <Stack>
      //       <Typography className={styles.technicalFocusAreaLabel}>Java Focus Areas</Typography>
      //       {javaFocusAreas.map((focusArea) => {
      //         return <ControlledCheckbox value={focusArea.name} id={focusArea.id} updateItems={updateSelectedTechnicalFocusAreas} />
      //       })}
      //     </Stack>
      //   </Stack>
      // )

    }

    if (technicalFocusAreas) {
      return technicalFocusAreas.map((focusArea) => {
        return <ControlledCheckbox value={focusArea.name} id={focusArea.id} updateItems={updateSelectedTechnicalFocusAreas} />
      })
    }
  }

  const renderProfessionalFocusAreas = () => {
    if (professionalFocusAreas) {
      return professionalFocusAreas.map((focusArea) => {
        return <ControlledCheckbox value={focusArea.name} id={focusArea.id} updateItems={updateSelectedProfessionalFocusAreas} />
      })
    }
  }

  const renderBehavioralFocusAreas = () => {
    if (behavioralFocusAreas) {
      return behavioralFocusAreas.map((focusArea) => {
        return <ControlledCheckbox value={focusArea.name} id={focusArea.id} updateItems={updateSelectedBehavioralFocusAreas} />
      })
    }
  }



  const renderTechnicalLevelSelector = () => {
    return (
      <Stack>
        <Typography style={{ paddingTop: 10, paddingBottom: 10, fontWeight: 'bold', color: colors.plum }}>What level are you applying for? <span style={{ color: colors.blazeRed }}>*</span> </Typography>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="technical-selector"
            id="selector"
            value={technicalLevel}
            label="Interview"
            onChange={(event) => handleChange(event, 'technicalLevel')}
          >
            {renderTechnicalLevel()}
          </Select>
        </FormControl>
      </Stack>
    )
  }

  const renderBehavioralFocusAreaSelector = () => {
    return (
      <Stack style={{ marginTop: 20 }}>
        <Typography style={{ paddingTop: 10, paddingBottom: 10, fontWeight: 'bold', color: colors.plum }}>Choose the behavioral areas you'd like to focus on during the interview:<span style={{ fontWeight: 'bold', color: colors.blazeRed, fontSize: 18 }}>*</span>  </Typography>
        <FormControl fullWidth>
          <FormGroup>
            {renderBehavioralFocusAreas()}
          </FormGroup>
        </FormControl>
      </Stack>
    )
  }

  const renderProfessionalFocusAreaSelector = () => {
    return (
      <Stack style={{ marginTop: 20 }}>
        <Typography style={{ paddingTop: 10, paddingBottom: 10, fontWeight: 'bold', color: colors.plum }}>Choose the professional areas you'd like to focus on during the interview:<span style={{ fontWeight: 'bold', color: colors.blazeRed, fontSize: 18 }}>*</span>  </Typography>
        <FormControl fullWidth>
          <FormGroup>
            {renderProfessionalFocusAreas()}
          </FormGroup>
        </FormControl>
      </Stack>
    )
  }

  const renderTechncialFocusAreaSelector = () => {
    return (
      <Stack style={{ marginTop: 20 }}>
        <Typography style={{ paddingBottom: 10, fontWeight: 'bold', color: colors.plum }}>Choose the technical areas you'd like to focus on during the interview:<span style={{ fontWeight: 'bold', color: colors.blazeRed, fontSize: 18 }}>*</span> </Typography>
        <FormControl fullWidth>
          <FormGroup>
            {renderTechnicalFocusAreas()}
          </FormGroup>
        </FormControl>
      </Stack>
    )
  }

  const renderProfessionalOrTechnicalFocusAreas = () => {
    if (interviewType) {
      switch (interviewType[0]) {
        case 'Behavioral':
          break;
        case 'Technical':
          return renderTechncialFocusAreaSelector()
        case 'Behavioral & Technical':
          return renderTechncialFocusAreaSelector()
        case 'Professional':
          return renderProfessionalFocusAreaSelector()
        case 'Behavioral & Professional':
          return renderProfessionalFocusAreaSelector()
        default:
          break;
      }
    }

  }

  const deactiveSubmitButton = () => {
    if (!interviewType || !technicalLevel) {
      return true
    }
    if (interviewType) {
      const type = getInterviewType();
      switch (type) {
        case 'BEHAVIORAL':
          return selectedBehavioralAreasOfFocus.length == 0 ? true : false
        case 'TECHNICAL':
          return selectedTechnicalAreasOfFocus.length == 0 ? true : false
        case 'BEHAVIORAL_TECHNICAL':
          return selectedBehavioralAreasOfFocus.length == 0 || selectedTechnicalAreasOfFocus.length == 0
        case 'PROFESSIONAL':
          return selectedProfessionalAreasOfFocus.length == 0 ? true : false
        case 'BEHAVIORAL_PROFESSIONAL':
          return selectedBehavioralAreasOfFocus.length == 0 || selectedProfessionalAreasOfFocus.length == 0
      }
    }

    return false;
  }

  const getInterviewType = () => {
    let candidateInterviewType = ''
    switch (interviewType[0]) {
      case 'Behavioral':
        candidateInterviewType = 'BEHAVIORAL'
        break;
      case 'Technical':
        candidateInterviewType = 'TECHNICAL'
        break;
      case 'Behavioral & Technical':
        candidateInterviewType = 'BEHAVIORAL_TECHNICAL'
        break;
      case 'Professional':
        candidateInterviewType = 'PROFESSIONAL'
        break;
      case 'Behavioral & Professional':
        candidateInterviewType = 'BEHAVIORAL_PROFESSIONAL'
      default:
        break;
    }

    return candidateInterviewType
  }


  const createCandidateInterview = () => {

    let candidateInterviewType = getInterviewType()

    let selectedResumeId;
    if (resumes && selectedResumeForEvaluation) {
      if (resumes.length > 0) {
        const selectedResumeObj = resumes.filter((resume) => resume.title === selectedResumeForEvaluation[0])
        selectedResumeId = selectedResumeObj[0].id
      }
    }

    const candidateInterviewConfig = {
      interviewType: candidateInterviewType,
      level: technicalLevel[0],
      role: preferredRole,
      programmingLanguage: (candidateInterviewType == 'TECHNICAL' || candidateInterviewType == 'BEHAVIORAL_TECHNICAL') && (preferredRole === 'Software Engineer') ? programmingLanguage[0].toUpperCase() : null,
      technicalFocusAreas: selectedTechnicalFocusAreaIds,
      behavioralFocusAreas: selectedBehavioralFocusAreaIds,
      professionalFocusAreas: selectedProfessionalFocusAreaIds,
      resumeId: selectedResumeId
    }

    console.log('Interview config: ', candidateInterviewConfig)

    getAxiosInstance(token).post(`${BASE_URI}/interviews/new`, candidateInterviewConfig)
      .then((res) => {
        if (res.status == 200) {

          handleClose()
          dispatch(retrieveCreditsForAccount(authedUserId, token))
          navigate('/interview/' + res.data.id,
            {
              state: {
                interviewType: candidateInterviewType,
                technicalLevel: technicalLevel,
                programmingLanguage: programmingLanguage,
                interviewer: interviewer,
              }
            })
        }
      })
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {
        setInterviewType(null)
        setTechnicalLevel(null)
        setProgrammingLanguage(null)
        setSelectBehavioralAreasOfFocus([])
        setSelectedTechnicalAreasOfFocus([])
        setSelectedResumeForEvaluation(null)
        setSelectedTechnicalFocusAreaIds([])
        setSelectedBehavioralFocusAreaIds([])
        setSelectedProfessionalFocusAreaIds([])
        handleClose()
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Stack>
            <Typography style={{ paddingBottom: 10, fontWeight: 'bold', color: colors.plum }}>Choose your interview type:<span style={{ color: colors.blazeRed, paddingLeft: 2 }}>*</span> </Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={interviewType}
                label="Interview"
                onChange={(event) => handleChange(event, 'interviewType')}
              >
                {renderInterviewTypes(preferredRole)}
              </Select>
            </FormControl>
          </Stack>

          {renderTechnicalLevelSelector()}

          {(interviewType == 'Behavioral' || interviewType == 'Behavioral & Technical' || interviewType == 'Behavioral & Professional') ? renderBehavioralFocusAreaSelector() : null}

          {/* {(interviewType == 'Technical' || interviewType == 'Behavioral & Technical') && (preferredRole == 'Software Engineer') ? renderProgrammingLanguageSelector() : null} */}

          {/* {(interviewType == 'Technical' || interviewType == 'Behavioral & Technical') ? renderTechncialFocusAreaSelector() : null} */}

          {renderProfessionalOrTechnicalFocusAreas()}

          {renderResumeSelector()}

          <Stack style={{ justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
            <StandardButton
              inactiveCriteria={deactiveSubmitButton()}
              label='START INTERVIEW'
              buttonWidth={'100%'}
              onPress={() => createCandidateInterview()}
              backgroundColor={deactiveSubmitButton() == true ? 'silver' : colors.oceanBlue}
            />
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
}



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: '80vh',
  width: 850,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
  overflowY: 'auto',
};

NewInterviewModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleChange: PropTypes.func,
};
