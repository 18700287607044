import {
  CREATING_TAG,
  CREATED_TAG,
  ERROR_CREATING_TAG,
  RETRIEVING_USER_TAGS,
  RETRIEVED_USER_TAGS,
  ERROR_RETRIEVING_USER_TAGS,
  CREATING_INDIVIDUAL_CHECK_IN,
  CREATED_INDIVIDUAL_CEHCK_IN,
  ERROR_CREATING_INDIVIDUAL_CHECK_IN,
  RETRIEVING_INDIVIDUAL_CHECK_INS,
  RETRIEVED_INDIVIDUAL_CHECK_INS,
  ERROR_RETRIEVING_INDIVIDUAL_CHECK_INS,

  CREATING_DIRECT_REPORT_TAG,
  CREATED_DIRECT_REPORT_TAG,
  ERROR_CREATING_DIRECT_REPORT_TAG,
  RETRIEVING_DIRECT_REPORT_TAGS,
  RETRIEVED_DIRECT_REPORT_TAGS,
  ERROR_RETRIEVING_DIRECT_REPORT_TAGS,

  UPDATING_WEEKLY_CHECK_IN,
  UPDATED_WEEKLY_CHECK_IN,
  ERROR_UPDATING_WEEKLY_CHECK_IN,

  RETRIEVING_COHORT_STATS,
  RETRIEVED_COHORT_STATS,
  ERROR_RETRIEVING_COHORT_STATS,

  RETRIEVING_WEEKLY_CHECKIN_SUMMARIES,
  RETRIEVED_WEEKLY_CHECKIN_SUMMARIES,
  ERROR_RETRIEVED_WEEKLY_CHECKIN_SUMMARIES,

  RETRIEVING_HIGH_FIVES_FOR_COHORT,
  RETRIEVED_HIGH_FIVES_FOR_COHORT,
  ERROR_RETRIEVING_HIGH_FIVES_FOR_COHORT,

  SUBMITTING_COMMENT_ON_CHECK_IN_DETAIL,
  SUBMITTED_COMMENT_ON_CHECK_IN_DETAIL,
  ERROR_SUBMITTING_COMMENT_ON_CHECK_IN_DETAIL,

  ADDING_COMMENT_TO_SUGGESTION,
  ADDED_COMMENT_TO_SUGGESTION,
  ERROR_ADDING_COMMENT_TO_SUGGESTION

} from '../constants/checkInActionTypes'

const initialState = {
  isLoading: false,
  tags: [],
  checkIns: [],
  checkInSummaries: [],
  cohortStats: {},
  pulseCheckTags: [],
  highFives: [],
}

function checkInReducer(state = initialState, action) {
  switch (action.type) {
    case CREATING_TAG:
      return {
        ...state,
        isLoading: true
      }
    case CREATED_TAG:
      return {
        ...state,
        isLoading: false,
        tags: action.payload

      }
    case ERROR_CREATING_TAG:
      return {
        ...state,
        isLoading: false,
      }
    case RETRIEVING_USER_TAGS:
      return {
        ...state,
        isLoading: true
      }
    case RETRIEVED_USER_TAGS:
      return {
        ...state,
        isLoading: false,
        tags: action.payload
      }
    case ERROR_RETRIEVING_USER_TAGS:
      return {
        ...state,
        isLoading: false,
      }
    case  CREATING_INDIVIDUAL_CHECK_IN:
      return {
        ...state,
        isLoading: true
      }
    case CREATED_INDIVIDUAL_CEHCK_IN:
      return {
        ...state,
        isLoading: false,
        checkIns: state.checkIns.concat(action.payload)
      }
    case ERROR_CREATING_INDIVIDUAL_CHECK_IN:
      return {
        ...state,
        isLoading: false
      }
    case  RETRIEVING_INDIVIDUAL_CHECK_INS:
      return {
        ...state,
        isLoading: true
      }
    case RETRIEVED_INDIVIDUAL_CHECK_INS:
      return {
        ...state,
        isLoading: false,
        checkIns: action.payload
      }
    case ERROR_RETRIEVING_INDIVIDUAL_CHECK_INS:
      return {
        ...state,
        isLoading: false
      }

    case  CREATING_DIRECT_REPORT_TAG:
      return {
        ...state,
        isLoading: true
      }
    case CREATED_DIRECT_REPORT_TAG:
      return {
        ...state,
        isLoading: false,
        pulseCheckTags: action.payload
      }
    case ERROR_CREATING_DIRECT_REPORT_TAG:
      return {
        ...state,
        isLoading: false,
      }
    case RETRIEVING_DIRECT_REPORT_TAGS:
      return {
        ...state,
        isLoading: true
      }
    case RETRIEVED_DIRECT_REPORT_TAGS:
      return {
        ...state,
        isLoading: false,
        pulseCheckTags: action.payload
      }
    case ERROR_RETRIEVING_DIRECT_REPORT_TAGS:
      return {
        ...state,
        isLoading: false
      }
    case UPDATING_WEEKLY_CHECK_IN:
      return {
        ...state,
        isLoading: true
      }
    case UPDATED_WEEKLY_CHECK_IN:
      return {
        ...state,
        isLoading: false,
        checkIns: state.checkIns.concat(action.payload)
      }
    case ERROR_UPDATING_WEEKLY_CHECK_IN:
      return {
        ...state,
        isLoading: false
      }
    case RETRIEVING_COHORT_STATS:
      return {
        ...state,
        isLoading: true
      }
    case RETRIEVED_COHORT_STATS:
      return {
        ...state,
        isLoading: false,
        cohortStats: action.payload
      }
    case ERROR_RETRIEVING_COHORT_STATS:
      return {
        ...state,
        isLoading: false
      }

    case RETRIEVING_WEEKLY_CHECKIN_SUMMARIES:
      return {
        ...state,
        isLoading: true
      }
    case RETRIEVED_WEEKLY_CHECKIN_SUMMARIES:
      return {
        ...state,
        isLoading: false,
        checkInSummaries: action.payload
      }
    case ERROR_RETRIEVED_WEEKLY_CHECKIN_SUMMARIES:
      return {
        ...state,
        isLoading: false
      }
    case RETRIEVING_HIGH_FIVES_FOR_COHORT:
      return {
        ...state,
        isLoading: true
      }
    case RETRIEVED_HIGH_FIVES_FOR_COHORT:
      return {
        ...state,
        isLoading: false,
        highFives: action.payload
      }
    case ERROR_RETRIEVING_HIGH_FIVES_FOR_COHORT:
      return {
        ...state,
        isLoading: false
      }
    case SUBMITTING_COMMENT_ON_CHECK_IN_DETAIL:
      return {
        ...state,
        isLoading: true
      }
    case SUBMITTED_COMMENT_ON_CHECK_IN_DETAIL:
      return {
        ...state,
        isLoading: false
      }
    case ERROR_SUBMITTING_COMMENT_ON_CHECK_IN_DETAIL:
      return {
        ...state,
        isLoading: false
      }
    case ADDING_COMMENT_TO_SUGGESTION:
      return {
        ...state,
        isLoading: true
      }
    case ADDED_COMMENT_TO_SUGGESTION:
      return {
        ...state,
        isLoading: false
      }
    case ERROR_ADDING_COMMENT_TO_SUGGESTION:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

export default checkInReducer