import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Cookies from 'js-cookie';

import SidebarNav from '../../components/navigation/SidebarNav'

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ButtonBase from '@mui/material/ButtonBase';

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { getAxiosInstance } from '../../utils/ApiUtils'
import { retrieveCohortsForOrg } from '../../redux/actions/organizationActions'
import CohortTile from '../../components/org/CohortTile';
import { colors } from '../../constants/Colors';

import config from '../../env.json'
import { showErrorToast } from '../../utils/ToastUtils';

export default function TeamManagementScreen() {
  const { BASE_URI } = config

  const navigate = useNavigate()


  const token = Cookies.get('token')
  const cohorts = useSelector((state) => state.org.cohorts)
  const organizationId = useSelector((state) => state.org.id)

  const [localCohorts, setLocalCohorts] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getAxiosInstance(token).get(`${BASE_URI}/org/cohorts/${organizationId}`)
      .then(res => {
        if (res.status === 200) {
          setIsLoading(false)
          setLocalCohorts(res.data)
        }
      })
      .catch(err => {
        setIsLoading(false)
        showErrorToast('Error retrieving cohorts. Please try again later.')
      })
  }, [])

  const renderCohorts = () => {
    if (localCohorts.length > 0) {
      return localCohorts.map((cohort) => {
        return (
          <CohortTile
            key={cohort.id}
            cohortId={cohort.id}
            cohortName={cohort.name}
            focusArea={cohort.focusArea}
            startDate={cohort.startDate}
            cohortMembers={cohort.cohortMembers}
            endDate={cohort.endDate}
            weeklyCheckInEnabled={cohort.weeklyCheckInEnabled}
            checkInDayOfWeek={cohort.checkInDayOfWeek}
            checkInStartDate={cohort.checkInStartDate}
            checkInEndDate={cohort.checkInEndDate}
          />
        )
      })
    }
  }


  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <SidebarNav />
      <Stack style={{ width: '100%' }}>
        <Stack direction='row' style={{ marginTop: 50, marginLeft: 50 }}>
          <Typography
            style={{ textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5, fontSize: 14 }}>
            COHORTS
          </Typography>
          <ButtonBase
            variant="text"
            style={{ marginLeft: 10 }}
            onClick={() => navigate('/org/cohort/new')}
          >
            <AddCircleIcon style={{ color: 'purple' }} />
          </ButtonBase>
        </Stack>
        {isLoading ? (<Stack style={{ justifyContent: 'center', alignItems: 'center', width: '100dsw' }} spacing={2}>
          <CircularProgress color="info" />
        </Stack>) : null}
        <Stack style={{ width: '60%', marginLeft: 50, marginTop: 20 }}>
          {renderCohorts()}
        </Stack>
      </Stack>


    </div>
  )
}