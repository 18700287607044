import axios from 'axios'
import config from '../env.json'

import { isValidEmailFormat} from './PasswordUtils';

import DOT_LOADER from '../assets/dot-loader.gif'
import GREEN_CHECK_MARK from '../assets/green-check-mark.png'
import RED_X from '../assets/red-x.png'

const { BASE_URI } = config

export const checkUniqueness = (email, setIsCheckingUniqueness, setIsUniqueEmail) => {
  if (email) {
    setIsCheckingUniqueness(true)
    axios.get(`${BASE_URI}/auth/email/uniqueness/${email}`)
      .then((res) => {
        if (res.status == 200) {
          setIsCheckingUniqueness(false)
          setIsUniqueEmail(res.data)

        }
      })
  }
}

export const renderEmailUniqueness = (isCheckingUniqueness, isUniqueEmail, email) => {
  if (isCheckingUniqueness) {
    return <img src={DOT_LOADER} style={{ height: 30, width: 30 }} />
  }
  if (isUniqueEmail && isValidEmailFormat(email)) {
    return <img src={GREEN_CHECK_MARK} style={{ height: 30, width: 30 }} />
  }
  if (isUniqueEmail == false) {
    return <img src={RED_X} style={{ height: 30, width: 30 }} />
  }
}