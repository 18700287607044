import React, { useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import styles from './SoloAccountTokenValidationScreen.module.css'
import StandardButton from '../../components/buttons/StandardButton'
import { colors } from '../../constants/Colors'

import INTERVIEWABLE_LOGO from '../../assets/interviewable-logo.png'
import { useDispatch } from 'react-redux'
import { verifySoloAccount } from '../../redux/actions/authenticationActions'

export default function SoloAccountTokenValidationScreen({ onLogin }) {

  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '']);
  const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];

  const { userId } = params

  const handleInputChange = (index, value) => {
    if (value.match(/^[0-9a-zA-Z]$/)) {
      // Only allow alphanumeric characters
      const newVerificationCode = [...verificationCode];
      newVerificationCode[index] = value;
      setVerificationCode(newVerificationCode);

      // Move focus to the next TextField
      if (index < inputRefs.length - 1 && value !== '') {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const inactiveCriteria = () => {
    if (verificationCode.join('').length <= 4) {
      return true
    }
    return false
  }

  
  const validateAccount = () => {
    const config = {
      id: userId, 
      registrationToken: verificationCode.join('')
    }

    dispatch(verifySoloAccount(config, onLogin))
    
  }

  return (
    <div className={styles.bodyWrapper}>
      <Stack>
        <Stack className={styles.innerWrapper}>
        <img src={INTERVIEWABLE_LOGO} className={styles.logoStyle} />
          <Typography className={styles.helperText}>Enter the five digit code you received in your email to complete your account verification. </Typography>
        </Stack>
        <Stack direction='row' className={styles.inputWrapper}>
          {verificationCode.map((value, index) => (
            <TextField
              key={index}
              inputRef={inputRefs[index]}
              value={value}
              className={styles.inputStyle}
              onChange={(e) => handleInputChange(index, e.target.value)}
              inputProps={{
                style: { textAlign: 'center'},
                maxLength: 1,
              }}
            />
          ))}

        </Stack>
        <Stack className={styles.submitButtonWrapper}>
          <StandardButton 
            label='VERIFY ACCOUNT'
            backgroundColor={verificationCode.join('').length <= 4 ? 'silver' : colors.oceanBlue}
            buttonWidth={300}
            inactiveCriteria={inactiveCriteria()}
            onPress={() => validateAccount()}

          />
        </Stack>
      </Stack>
    </div>
  )
}