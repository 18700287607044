import { 
  CREATING_INTERVIEW,
  CREATED_INTERVIEW,
  ERROR_CREATING_INTERVIEW,
  SENDING_INTERVIEW_PROMPT,
  SENT_INTERVIEW_PROMPT,
  ERROR_SENDING_INTERVIEW_PROMPT,
  CONTINUING_INTERVIEW,
  CONTINUED_INTERVIEW,
  ERROR_CONTINUING_INTERVIEW,
  RETRIEVING_ACTIVE_INTERVIEW,
  RETRIEVED_ACTIVE_INTERVIEW,
  ERROR_RETRIEVING_ACTIVE_INTERVIEW,
  RETRIEVING_INTERVIEW,
  RETRIEVED_INTERVIEW,
  ERROR_RETRIEVING_INTERVIEW,
  RETRIEVING_TECHNICAL_INTERVIEW_FOCUS_AREAS,
  RETRIEVED_TECHNICAL_INTERVIEW_FOCUS_AREAS,
  ERROR_RETRIEVING_TECHNICAL_INTERVIEW_FOCUS_AREAS,
  SUBMITTING_INTERVIEW_RESPONSE_FOR_EVALUATION,
  SUBMITTED_INTERVIEW_RESPONSE_FOR_EVALUATION,
  ERROR_SUBMITTING_INTERVIEW_RESPONSE_FOR_EVALUATION,
  RETRIEVING_COMPLETED_INTERVIEWS,
  RETRIEVED_COMPLETED_INTERVIEWS,
  ERROR_RETRIEVING_COMPLETED_INTERVIEWS,
  COMPLETING_ACTIVE_INTERVIEW,
  COMPLETED_ACTIVE_INTERVIEW,
  ERROR_COMPLETING_ACTIVE_INTERVIEW,
  CREATING_INTERVIEW_QUESTION,
  CREATED_INTERVIEW_QUESTION,
  ERROR_CREATING_INTERVIEW_QUESTION,
  DELETING_INTERVIEW_QUESTION,
  DELETED_INTERVIEW_QUESTION,
  ERROR_DELETING_INTERVIEW_QUESTION,
  EDITING_INTERVIEW_QUESTION,
  EDITED_INTERVIEW_QUESTION,
  ERROR_EDITING_INTERVIEW_QUESTION
} from '../constants/interviewActionTypes'

const initialState = {
  currentCompletedInterview: null,
  adminInterviewQuestions: [],
  activeCandidateInterview: [],
  completedInterviews: [],
  isLoading: false,
  isLoadingCompletedInterviews: false,
}

function interviewReducer (state = initialState, action) {
  switch (action.type) {
    case CREATING_INTERVIEW:
      return {
        ...state,
        isLoading: true
      }
    case CREATED_INTERVIEW:
      return {
        ...state,
        isLoading: false,
        activeCandidateInterview: action.payload
      }
    case ERROR_CREATING_INTERVIEW:
      return {
        ...state,
        isLoading: false,
      }
    case SENDING_INTERVIEW_PROMPT:
      return {
        ...state,
        isLoading: true
      }
    case SENT_INTERVIEW_PROMPT:
      return {
        ...state,
        isLoading: false,
       interviews: action.payload
      }
    case ERROR_SENDING_INTERVIEW_PROMPT: 
      return {
        ...state,
        isLoading: false,
      }
      case CONTINUING_INTERVIEW:
        return {
          ...state,
          isLoading: true
        }
      case CONTINUED_INTERVIEW:
        return {
          ...state,
          isLoading: false,
         interviews: action.payload
        }
      case ERROR_CONTINUING_INTERVIEW: 
        return {
          ...state,
          isLoading: false,
        }
      case RETRIEVING_ACTIVE_INTERVIEW:
        return {
          ...state,
          isLoading: true
        }
      case RETRIEVED_ACTIVE_INTERVIEW:
        return {
          ...state,
          isLoading: false,
         activeCandidateInterview: action.payload
        }
      case ERROR_RETRIEVING_ACTIVE_INTERVIEW:
        return {
          ...state,
          isLoading: false
        }
      case RETRIEVING_INTERVIEW:
        return {
          ...state,
          isLoading: true
        }
      case RETRIEVED_INTERVIEW:
        return {
          ...state,
          isLoading: false,
          activeCandidateInterview: action.payload
        }
      case ERROR_RETRIEVING_INTERVIEW:
        return {
          ...state,
          isLoading: false
        }
      case RETRIEVING_TECHNICAL_INTERVIEW_FOCUS_AREAS:
        return {
          ...state,
          isLoading: true
        }
      case RETRIEVED_TECHNICAL_INTERVIEW_FOCUS_AREAS:
        return {
          ...state,
          isLoading: false
        }
      case ERROR_RETRIEVING_TECHNICAL_INTERVIEW_FOCUS_AREAS:
        return {
          ...state,
          isLoading: false
        }
      case SUBMITTING_INTERVIEW_RESPONSE_FOR_EVALUATION:
        return {
          ...state,
          isLoading: true
        }
      case SUBMITTED_INTERVIEW_RESPONSE_FOR_EVALUATION:
        return {
          ...state,
          isLoading: false,
          activeInterview: action.payload
        }
      case ERROR_SUBMITTING_INTERVIEW_RESPONSE_FOR_EVALUATION:
        return {
          ...state,
          isLoading: false
        }
      case RETRIEVING_COMPLETED_INTERVIEWS:
        return {
          ...state,
          isLoadingCompletedInterviews: true,
        }
      case RETRIEVED_COMPLETED_INTERVIEWS:
        return {
          ...state,
          isLoadingCompletedInterviews: false,
          completedInterviews: action.payload
        }
      case ERROR_RETRIEVING_COMPLETED_INTERVIEWS:
        return {
          ...state,
          isLoadingCompletedInterviews: false,
        }
      case COMPLETING_ACTIVE_INTERVIEW:
        return {
          ...state,
          isLoading: true
        }
      case COMPLETED_ACTIVE_INTERVIEW:
        return {
          ...state,
          isLoading: false,
          completedInterviews: state.completedInterviews.concat(action.paylad),
          activeCandidateInterview: {}
        }
      case ERROR_COMPLETING_ACTIVE_INTERVIEW:
        return {
          ...state,
          isLoading: false
        }
      case CREATING_INTERVIEW_QUESTION:
        return {
          ...state,
          isLoading: true
        }
      case CREATED_INTERVIEW_QUESTION:
        return {
          ...state,
          isLoading: false,
          adminInterviewQuestions: state.adminInterviewQuestions.concat(action.payload)
        }
      case ERROR_CREATING_INTERVIEW_QUESTION:
        return {
          ...state,
          isLoading: false
        }
      case DELETING_INTERVIEW_QUESTION:
        return {
          ...state,
          isLoading: true
        }
      case DELETED_INTERVIEW_QUESTION:
        return {
          ...state,
          isLoading: false,
          adminInterviewQuestions: action.payload
        }
      case ERROR_DELETING_INTERVIEW_QUESTION:
        return {
          ...state,
          isLoading: false
        }
      case EDITING_INTERVIEW_QUESTION:
        return {
          ...state,
          isLoading: true
        }
      case EDITED_INTERVIEW_QUESTION:
        return {
          ...state,
          isLoading: false,
          adminInterviewQuestions: [...action.payload]
        }
      case ERROR_EDITING_INTERVIEW_QUESTION:
        return {
          ...state,
          isLoading: false
        }
    default:
      return state
  }
}

export default interviewReducer

