import React from 'react'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import PropTypes from 'prop-types'
import { colors } from '../../constants/Colors'

import JAVA_ICON from '../../assets/java-logo.png'
import PYTHON_ICON from '../../assets/python-logo.png'

import { getCalendarDate } from '../../utils/DateUtils'
import { useNavigate } from 'react-router-dom'


export default function CohortTile(props) {

  const navigate = useNavigate()

  const {
    cohortName,
    focusArea,
    startDate,
    endDate,
    cohortMembers,
    cohortId,
    weeklyCheckInEnabled,
    checkInDayOfWeek,
    checkInStartDate,
    checkInEndDate
  } = props

  const renderCohortLogo = () => {
    switch (focusArea) {
      case 'JAVA':
        return <img src={JAVA_ICON} style={{ height: 50, width: 50 }} />
      case 'DATA_ENGINEERING':
        return <img src={PYTHON_ICON} style={{ height: 50, width: 50 }} />
      default:
        break;
    }
  }
  
  return (
    <Button
      variant='outlined' 
      style={{ width: '70%', margin: 10, minHeight: 50, justifyContent: 'flex-start', alignItems: 'center', paddingTop: 0, paddingRight: 0, paddingBottom: 0, borderColor: colors.oceanBlue, borderWidth: 2 }}
      onClick={() => 
        navigate(`/org/cohort/${cohortId}`,  {state: { 
          startDate: startDate,
          endDate: endDate,
          focusArea: focusArea,
          cohortName: cohortName,
          cohortMembers: cohortMembers,
          weeklyCheckInEnabled: weeklyCheckInEnabled,
          checkInDayOfWeek: checkInDayOfWeek,
          checkInStartDate: checkInStartDate,
          checkInEndDate: checkInEndDate
      }})
      }
    >
       <Stack>
            {renderCohortLogo()}
          </Stack>
      <Stack direction='row' style={{width: '90%'}}>
        <Stack>
          <Stack>
            <Stack direction='row'>
              <Typography style={{color: colors.coolBlack, fontSize: 14, fontWeight: 'bold'}}>Name: 
              <span style={{color: 'black', fontWeight: '300', paddingLeft: 57}}>
                {cohortName}  
              </span>
            </Typography>
            </Stack>
            <Stack direction='row'>
            <Typography style={{color: colors.coolBlack, fontSize: 14, fontWeight: 'bold'}}>Start Date: </Typography>
              <span style={{color: 'black', fontWeight: '300', paddingLeft: 10}}>
                {getCalendarDate(startDate)}
              </span>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack style={{backgroundColor: colors.lightGray, height: '100%', width: '10%', padding: 0, justifyContent: 'center', borderTopRightRadius: 5, borderBottomRightRadius: 5}}>
      <Typography>
              {cohortMembers ? cohortMembers.length : 0}
            </Typography>
      </Stack>


    </Button>
  )

}

CohortTile.propTypes = {
  cohortId: PropTypes.string,
  cohortName: PropTypes.string,
  focusArea: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  students: PropTypes.array,

}