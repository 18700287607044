export const CREATING_INDIVIDUAL_ACCOUNT = 'CREATING_INDIVIDUAL_ACCOUNT'
export const CREATED_INDIVIDUAL_ACCOUNT = 'CREATED_INDIVIDUAL_ACCOUNT'
export const ERROR_CREATING_INDIVIDUAL_ACCOUNT = 'ERROR_CREATING_INDIVIDUAL_ACCOUNT'

export const SET_LOGIN_TOKEN = 'SET_LOGIN_TOKEN'

export const LOGGING_IN_USER = 'LOGGING_IN_USER'
export const LOGGED_IN_USER = 'LOGGED_IN_USER'
export const ERROR_LOGGING_IN_USER = 'ERROR_LOGGING_IN_USER'

export const FINALIZING_USER_REGISTRATION = 'FINALIZING_USER_REGISTRATION'
export const FINALIZED_USER_REGISTRATION = 'FINALIZED_USER_REGISTRATION'
export const ERROR_FINALIZING_USER_REGISTRATION = 'ERROR_FINALIZING_USER_REGISTRATION'

export const LOG_OUT_USER = 'LOG_OUT_USER'

export const CHANGING_PASSWORD = 'CHANGING_PASSWORD'
export const CHANGED_PASSWORD = 'CHANGED_PASSWORD'
export const ERROR_CHANGING_PASSWORD = 'ERROR_CHANGING_PASSWORD'

export const VERIFYING_SOLO_ACCOUNT = 'VERIFYING_SOLO_ACCOUNT'
export const VERIFIED_SOLO_ACCOUNT = 'VERIFIED_SOLO_ACCOUNT'
export const ERROR_VERIFYING_SOLO_ACCOUNT = 'ERROR_VERIFYING_SOLO_ACCOUNT'

