import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import INTERVIEWABLE_LOGO from '../../assets/interviewable-logo.png'

import { logOutUser } from '../../redux/actions/authenticationActions';

import SETTINGS_ICON from '../../assets/settings-icon.png'
import INTERVIEWS_ICON from '../../assets/job-interview-icon.png'
import BILLING_ICON from '../../assets/billing-icon.png'
import CHECK_IN_ICON from '../../assets/check-in-icon.png'
import LOGOUT_ICON from '../../assets/logout-icon.png'
import ADMIN_ICON from '../../assets/admin-icon.png'
import COHORTS_ICON from '../../assets/cohorts-icon.png'
import RESUME_ICON from '../../assets/resume-icon.png'
import HELP_ICON from '../../assets/help-icon.png'
import TEST_ICON from '../../assets/test-icon.png'
import SPY_ICON from '../../assets/spy-icon.png'

const drawerWidth = 240;

function SidebarNav({onLogout}, props) {

  const navigate =  useNavigate()
  const dispatch = useDispatch()


  const token = Cookies.get('token')
  const planType = sessionStorage.getItem('planType')
  const role = sessionStorage.getItem('role')
  const authedUserId = useSelector((state) => state.auth.id)
  const hasMockInterviewAssessmentEnabled = Boolean(useSelector((state) => state.auth.featureFlags.MOCK_INTERVIEW_ASSESSMENTS_ENABLED));

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigateToRoute = (path) => {
    switch(path) {
      case 'Billing':
        navigate('/billing')
        break;
      // case 'Dashboard':
      //   navigate('/dashboard')
      //   break;
      case 'Interviews':
        navigate('/interviews');
        break;
      case 'Test':
        switch(role) {
          case 'ADMIN': 
            navigate('/assessments/admin');
            break;
          case 'STUDENT': 
            navigate('/assessments');
            break;
        }
        break;
      case 'Cohorts': 
        navigate('/org')
        break;
      case 'Check Ins':
        navigate('/checkIns');
        break;
      case 'Settings': 
        navigate('/settings');
        break;
      case 'Admin':
        navigate('/admin')
        break; 
      case 'Impersonate': 
        navigate('/admin/impersonate')
        break;
      case 'Resume':
        navigate(`/resume/${authedUserId}`)
        break;
      case 'Help':
        navigate('/help')
        break;
      case 'Logout':
        dispatch(logOutUser())
        // sessionStorage.removeItem('token')

        Cookies.remove('token', { path: '/', });

        sessionStorage.removeItem('role')
        sessionStorage.removeItem('planType')
        navigate('/login')
        break;
      default: 

    }
  }

  const renderIcon = (text) => {
    switch(text) {
      case 'Billing':
        return <img src={BILLING_ICON} style={{height: 30, width: 30}} />
      case 'Test':
          return <img src={TEST_ICON} style={{height: 30, width: 30}} />
      case 'Interviews':
        return <img src={INTERVIEWS_ICON} style={{height: 30, width: 30}} />
      case 'Settings':
        return <img src={SETTINGS_ICON} style={{height: 30, width: 30}} />
      case 'Logout':
        return <img src={LOGOUT_ICON} style={{height: 30, width: 30}} />
      case 'Check Ins':
          return <img src={CHECK_IN_ICON} style={{height: 30, width: 30}} />
      case 'Admin':
          return <img src={ADMIN_ICON} style={{height: 30, width: 30}} />
      case 'Cohorts':
          return <img src={COHORTS_ICON} style={{height: 30, width: 30}} />
      case 'Resume':
          return <img src={RESUME_ICON} style={{height: 30, width: 30}} />
      case 'Help': 
          return <img src={HELP_ICON} style={{height: 30, width: 30}} />
        case 'Impersonate': 
          return <img src={SPY_ICON} style={{height: 30, width: 30}} />
      default: 

    }
  }
  
  const renderSideBarOptions = () => {

    const TEAM_ADMIN = ['Cohorts','Test', 'Billing', 'Resume','Settings', 'Help','Logout']
    const TEAM_FACULTY_STAFF = ['Cohorts','Settings', 'Resume', 'Logout']
    const TEAM_STUDENT = ['Interviews','Check Ins', 'Resume', 'Settings', 'Help','Logout']
    const INDIVIDUAL_ADMIN = ['Interviews', 'Check Ins', 'Resume', 'Billing','Settings', 'Help', 'Logout']
    const SUPER_ADMIN = ['Interviews', 'Check Ins', 'Resume', 'Billing','Settings', 'Help','Admin', 'Impersonate','Logout']

    let sideBarOptions = [];
    if (planType && role) {
      if (planType == 'TEAM' && role == 'ADMIN') {
        sideBarOptions = TEAM_ADMIN
      }
      else if (planType == 'TEAM' && (role == 'FACULTY' || role == 'STAFF')) {
        sideBarOptions = TEAM_FACULTY_STAFF
      }
      else if (planType == 'TEAM' && role == 'STUDENT') {
        sideBarOptions = TEAM_STUDENT
      }
      else if (planType == 'INDIVIDUAL' && role == 'ADMIN') {
        sideBarOptions = INDIVIDUAL_ADMIN
      }
      else if (planType == 'INDIVIDUAL' && role == 'SUPER_ADMIN') {
        sideBarOptions = SUPER_ADMIN
      }
    }

    return sideBarOptions
  }


  const drawer = (
    <div>
      {/* <Toolbar /> */}
      <Stack style={{justifyContent: 'center', alignItems: 'center'}}>
      <img src={INTERVIEWABLE_LOGO} style={{ height: 40, marginTop: 20}} />
      </Stack>
      
      {/* <Divider /> */}
      <Stack style={{justifyContent: 'center', alignItems: 'center'}}>
      <List style={{marginTop: 10}}>
        {renderSideBarOptions().map((text, index) => {
          
          return (
          
            <ListItem key={text} disablePadding>
              <ListItemButton
               onClick={() => {
                navigateToRoute(text)
               }}
              >
                <ListItemIcon>
                  {renderIcon(text)}
                </ListItemIcon>
                <Typography  
                  
                  style={{fontWeight: '500', fontFamily: "Arial", fontSize: 18, }}
                  >
                {text}
                </Typography>
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
      </Stack>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  
  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 },  }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

SidebarNav.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SidebarNav;