import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Cookies from 'js-cookie';

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Backdrop from "@mui/material/Backdrop";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';

import PropTypes from 'prop-types'
import { colors } from '../../../constants/Colors';

import styles from './AssessmentResultsModal.module.css'
import { getAxiosInstance } from '../../../utils/ApiUtils';

import config from '../../../env.json'

export default function AssessmentResultsModal(props) {

  const { BASE_URI } = config

  const token = Cookies.get('token');

  const {
    isVisible,
    handleClose,
    assessmentId,
  } = props


  const [needsImprovementPercentage, setNeedsImprovementPercentage] = useState(0.0)
  const [meetsExpectationsPercentage, setMeetsExpectationsPercentage] = useState(0.0)
  const [exceedsExpectationsPercentage, setExceedsExpectationsPercentage] = useState(0.0)
  const [totalComplete, setTotalCompelte] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (assessmentId) {
      setIsLoading(true)

      getAxiosInstance(token).get(`${BASE_URI}/assessments/interview/stats/${assessmentId}`)
      .then((res) => {
        if (res.status == 200) {
          setIsLoading(false)
          setNeedsImprovementPercentage(res.data.needsImprovement)
          setMeetsExpectationsPercentage(res.data.meetsExpectations)
          setExceedsExpectationsPercentage(res.data.exceedsExpectations)
          setTotalCompelte(res.data.totalComplete)
        }
      })
      .catch((error) => setIsLoading(false))
    }
    
  }, [assessmentId])



  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    padding: 40,
    // height: '40dvh',
    overflowY: 'scroll',
    p: 4,
  };

  
  const labels = [
    {name: 'Needs Improvement', value: needsImprovementPercentage},
    {name: 'Meets Expectations', value: meetsExpectationsPercentage},
    {name: 'Exceeds Expectations', value: exceedsExpectationsPercentage},
    {name: 'Assessments Completed', value: totalComplete}
  ]

  const renderStats = () => {
    if (isLoading) {
      return (
        <Box className={styles.loaderWrapper}>
          <CircularProgress color="secondary" />
        </Box>
      )
    }
    else {
     return labels.map((label) => {
        return (
          <TableRow
            key={label.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <Typography
                className={styles.interviewTypeTableText}
              >
                {label.name}
              </Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography
                className={styles.interviewTypeTableText}
              >
                {label.value}{label.name === 'Assessments Completed' ? null : '%'}
              </Typography>
            </TableCell>
          </TableRow>
      )
      })
      
    }

  }

  return (
    <div>
      <Modal
        open={isVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Stack className={styles.closeButtonWrapper}>
            <Button
              onClick={() => handleClose()}
              variant='text'>
              <CloseIcon 
                className={styles.closeButton}
                />
            </Button>
          </Stack>
          <Typography
            id="modal-modal-title" variant="h6" component="h2"
            style={{ textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5, fontSize: 20 }}>

            Assessment Details
          </Typography>
          <Stack>
            <Grid style={{ alignItems: 'flex-start', marginTop: 10 }}>
              <TableContainer sx={{ maxHeight: 440 }} component={Paper}>
                <Table stickyHeader style={{ minWidth: 400, }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>Evaluation</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Percetage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ width: '100%' }}>
                    {renderStats()}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Stack>
        </Box>
      </Modal>
    </div>

  );
}

AssessmentResultsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  assessmentId: PropTypes.string.isRequired,
}