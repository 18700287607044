import {
  SAVING_USER_PROFILE_PREFERENCES,
  SAVED_USER_PROFILE_PREFERENCECS,
  ERROR_SAVING_USER_PROFILE_PREFERENCES,
  SET_USER_PREFERENCES,
  SET_USER_META_DATA,
  ADDING_JOB_TO_WORK_HISTORY,
  ADDED_JOB_TO_WORK_HISTORY,
  ERROR_ADDING_JOB_TO_WORK_HISTORY,
  RETRIEVING_WORK_HISTORY,
  RETRIEVED_WORK_HISTORY,
  ERROR_RETRIEVING_WORK_HISTORY,
  ADDING_EDUCATION_HISTORY,
  ADDED_EDUCATION_HISTORY,
  ERROR_ADDING_EDUCATION_HISTORY,
  UPDATING_PROFILE_AVATAR,
  UPDATED_PROFILE_AVATAR,
  ERROR_UPDATING_PROFILE_AVATAR,
  UPDATING_USER_META_DATA,
  UPDATED_USER_META_DATA,
  ERROR_UPDATING_USER_META_DATA,
  UPDATING_JOB_ON_WORK_HISTORY,
  UPDATED_JOB_ON_WORK_HISTORY,
  ERROR_UPDATING_JOB_ON_WORK_HISTORY,
  DELETING_JOB_FROM_WORK_HISTORY,
  DELETED_JOB_FROM_WORK_HISOTRY,
  ERROR_DELETING_JOB_FROM_WORK_HISTORY,
  DELETING_JOB_DETAIL,
  DELETED_JOB_DETAIL,
  ERROR_DELETING_JOB_DETAIL,
  UPDATING_EDUCATION_RECORD,
  UPDATED_EDUCATION_RECORD,
  ERROR_UPDATING_EDUCATION_RECORD,
  DELETING_EDUCATION_RECORD,
  DELETED_EDUCATION_RECORD,
  ERROR_DELETING_EDUCATION_RECORD,
  DELETING_EDUCATION_ACTIVITY,
  DELETED_EDUCATION_ACTIVITY,
  ERROR_DELETING_EDUCATION_ACTIVITY,
  RETRIEVING_USER_RESUMES,
  RETRIEVED_USER_RESUMES,
  ERROR_RETRIEVING_USER_RESUMES,
  UPLOADING_USER_RESUME,
  UPLOADED_USER_RESUME,
  ERROR_UPLOADING_USER_RESUME,
  DELETING_USER_RESUME,
  DELETED_USER_RESUME,
  ERROR_DELETING_USER_RESUME
} from '../constants/userActionTypes'

const initialState = {
  email: '',
  plan: '',
  token: null,
  isLoading: false,
  completedOnboardingV1: false,
  id: '',
  firstName: '',
  lastName: '',
  isVerified: false,
  role: '',
  preferredRole: '',
  preferredTitle: '',
  isEntryLevelTechnicalCareerCandidate: false,
  isCohortMember: false,
  cohortId: '',
  biography: '',
  tagline: '',
  avatarURI: '',
  videoBiographyURI: '',
  areasOfImprovement: [],
  jobs: [],
  education: [],
  resumes: []
}

function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_META_DATA:
      return {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        avatarURI: action.payload.userProfile.avatarURI,
        isEntryLevelTechnicalCareerCandidate: action.payload.isEntryLevelTechnicalCareerCandidate,
        isCohortMember: action.payload.isCohortMember,
        role: action.payload.role,
        preferredTitle: action.payload.userProfile.preferredTitle,
        preferredRole: action.payload.userProfile.preferredRole,
        cohortId: action.payload.cohortId,
        // completedOnboardingV1: action.payload.userPreferences.completedOnboardingV1
      }
    case SAVING_USER_PROFILE_PREFERENCES:
      return {
        ...state,
        isLoading: true,
      }
    case SAVED_USER_PROFILE_PREFERENCECS:
      return {
        ...state,
        preferredRole: action.payload.userProfile.preferredRole,
        biography: action.payload.userProfile.biography,
        tagline: action.payload.userProfile.tagline,
        videoBiographyURI: action.payload.userProfile.videoBiographyURI,
        areasOfImprovement: action.payload.userProfile.areasOfImprovement

      }
    case ERROR_SAVING_USER_PROFILE_PREFERENCES:
      return {
        ...state,
        isLoading: false,
      }
    case SET_USER_PREFERENCES:
      return {
        ...state,
        preferredRole: action.payload.preferredRole,
        biography: action.payload.biography,
        videoBiographyURI: action.payload.videoBiographyURI,
        tagline: action.payload.tagline,
        areasOfImprovement: action.payload.areasOfImprovement
      }
    case ADDING_JOB_TO_WORK_HISTORY:
      return {
        ...state,
        isLoading: true
      }
    case ADDED_JOB_TO_WORK_HISTORY:
      return {
        ...state,
        isLoading: false,
        jobs: state.jobs.concat(action.payload)
      }
    case ERROR_ADDING_JOB_TO_WORK_HISTORY:
      return {
        ...state,
        isLoading: false
      }
    case RETRIEVING_WORK_HISTORY:
      return {
        ...state,
        isLoading: true
      }
    case RETRIEVED_WORK_HISTORY:
      return {
        ...state,
        isLoading: false,
        jobs: action.payload.jobs,
        education: action.payload.education
      }
    case ERROR_RETRIEVING_WORK_HISTORY:
      return {
        ...state,
        isLoading: false
      }
    case ADDING_EDUCATION_HISTORY:
      return {
        ...state,
        isLoading: true
      }
    case ADDED_EDUCATION_HISTORY:
      return {
        ...state,
        isLoading: false,
        education: state.education.concat(action.payload)
      }
    case ERROR_ADDING_EDUCATION_HISTORY:
      return {
        ...state,
        isLoading: false
      }
    case UPDATING_PROFILE_AVATAR:
      return {
        ...state,
        isLoading: true
      }
    case UPDATED_PROFILE_AVATAR:
      return {
        ...state,
        isLoading: false,
        avatarURI: action.payload
      }
    case ERROR_UPDATING_PROFILE_AVATAR:
      return {
        ...state,
        isLoading: false
      }
    case UPDATING_USER_META_DATA:
      return {
        ...state,
        isLoading: true
      }

    case UPDATED_USER_META_DATA:
      return {
        ...state,
        isLoading: false,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        preferredTitle: action.payload.userProfile.preferredTitle
      }
    case ERROR_UPDATING_USER_META_DATA:
      return {
        ...state,
        isLoading: false
      }
    case UPDATING_JOB_ON_WORK_HISTORY:
      return {
        ...state,
        isLoading: true
      }
    case UPDATED_JOB_ON_WORK_HISTORY:
      return {
        ...state,
        isLoading: false,
        jobs: action.payload
      }
    case ERROR_UPDATING_JOB_ON_WORK_HISTORY:
      return {
        ...state,
        isLoading: false
      }
    case DELETING_JOB_FROM_WORK_HISTORY:
      return {
        ...state,
        isLoading: true
      }
    case DELETED_JOB_FROM_WORK_HISOTRY:
      return {
        ...state,
        isLoading: false,
        jobs: action.payload
      }
    case ERROR_DELETING_JOB_FROM_WORK_HISTORY:
      return {
        ...state,
        isLoading: false
      }
    case DELETING_JOB_DETAIL:
      return {
        ...state,
        isLoading: true
      }
    case DELETED_JOB_DETAIL:
      return {
        ...state,
        isLoading: false,
        jobs: action.payload
      }
    case ERROR_DELETING_JOB_DETAIL:
      return {
        ...state,
        isLoading: false
      }
    case UPDATING_EDUCATION_RECORD:
      return {
        ...state,
        isLoading: true
      }
    case UPDATED_EDUCATION_RECORD:
      return {
        ...state,
        isLoading: false,
        education: action.payload
      }
    case ERROR_UPDATING_EDUCATION_RECORD:
      return {
        ...state,
        isLoading: false
      }
    case DELETING_EDUCATION_RECORD:
      return {
        ...state,
        isLoading: true
      }
    case DELETED_EDUCATION_RECORD:
      return {
        ...state,
        isLoading: false,
        education: action.payload
      }
    case ERROR_DELETING_EDUCATION_RECORD:
      return {
        ...state,
        isLoading: false
      }
    case DELETING_EDUCATION_ACTIVITY:
      return {
        ...state,
        isLoading: true
      }
    case DELETED_EDUCATION_ACTIVITY:
      return {
        ...state,
        isLoading: false,
        education: action.payload
      }
    case ERROR_DELETING_EDUCATION_ACTIVITY:
      return {
        ...state,
        isLoading: false,
      }
    case RETRIEVING_USER_RESUMES:
      return {
        ...state,
        isLoading: true,
      }
    case RETRIEVED_USER_RESUMES:
      return {
        ...state,
        isLoading: false,
        resumes: action.payload
      }
    case ERROR_RETRIEVING_USER_RESUMES:
      return {
        ...state,
        isLoading: false,
      }
    case UPLOADING_USER_RESUME:
      return {
        ...state,
        isLoading: true
      }
    case UPLOADED_USER_RESUME:
      return {
        ...state,
        isLoading: false,
        resumes: state.resumes.concat(action.payload)
      }
    case ERROR_UPLOADING_USER_RESUME:
      return {
        ...state,
        isLoading: false
      }
    case DELETING_USER_RESUME:
      return {
        ...state,
        isLoading: true
      }
    case DELETED_USER_RESUME:
      return {
        ...state,
        isLoading: false,
        resumes: action.payload
      }
    case ERROR_DELETING_USER_RESUME:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

export default userReducer