import {
  CREATING_ORG_ACCOUNT,
  CREATED_ORG_ACCOUNT,
  ERROR_CREATING_ORG_ACCOUNT,
  CREATING_INDIVIDUAL_ACCOUNT,
  CREATED_INDIVIDUAL_ACCOUNT,
  ERROR_CREATING_INDIVIDUAL_ACCOUNT,
  SET_LOGIN_TOKEN,
  LOGGING_IN_USER,
  LOGGED_IN_USER,
  ERROR_LOGGING_IN_USER,
  LOG_OUT_USER,
  FINALIZING_USER_REGISTRATION,
  FINALIZED_USER_REGISTRATION,
  ERROR_FINALIZING_USER_REGISTRATION,
  CHANGING_PASSWORD,
  CHANGED_PASSWORD,
  ERROR_CHANGING_PASSWORD
} from '../constants/authenticationActionTypes'

const initialState = {
  email: '',
  plan: '',
  token: '',
  isLoading: false,
  id: '',
  planType: '',
  status: '',
  firstName: '',
  lastName: '',
  isVerified: false,
  role: '',
  priceId: '',
  stripeCustomerId: '',
  featureFlags: ''
}

function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    case CREATING_INDIVIDUAL_ACCOUNT:
      return {
        ...state,
        isLoading: true
      }
    case CREATED_INDIVIDUAL_ACCOUNT:
      return {
        ...state,
        isLoading: false,
        email: action.payload.admin.email,
        token: action.payload.admin.token,
      }
    case ERROR_CREATING_INDIVIDUAL_ACCOUNT:
      return {
        ...state,
        isLoading: false
      }
    case SET_LOGIN_TOKEN:
      return {
        ...state,
        token: action.payload
      }
    case LOGGING_IN_USER:
      return {
        ...state,
        isLoading: true
      }
    case LOGGED_IN_USER:
      return {
        ...state,
        isLoading: false,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        id: action.payload.id,
        planType: action.payload.planType,
        role: action.payload.role,
        stripeCustomerId: action.payload.stripeCustomerId,
        featureFlags: action.payload.featureFlags
      }
    case ERROR_LOGGING_IN_USER:
      return {
        ...state,
        isLoading: false
      }
    case LOG_OUT_USER:
      return {
        ...state,
        email: '',
        plan: '',
        token: '',
        isLoading: false,
        id: '',
        companyName: '',
        addressOne: '',
        addressTwo: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
        planType: '',
        status: '',
        firstName: '',
        lastName: '',
        isVerified: false,
        role: '',
        featureFlags: ''
      }
    case FINALIZING_USER_REGISTRATION:
      return {
        ...state,
        isLoading: true
      }
    case FINALIZED_USER_REGISTRATION:
      return {
        ...state,
        isLoading: false,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        role: action.payload.role,
      }
    case ERROR_FINALIZING_USER_REGISTRATION:
      return {
        ...state,
        isLoading: false
      }
    case CHANGING_PASSWORD:
      return {
        ...state,
        isLoading: true
      }
    case CHANGED_PASSWORD:
      return {
        ...state,
        isLoading: false
      }
    case ERROR_CHANGING_PASSWORD:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

export default authenticationReducer