import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import debounce from "lodash/debounce";
import Grid from "@mui/material/Grid";
import axios from 'axios'

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { Modal } from '@mui/material';
import { Button } from '@mui/material';

import { isIdentifcal, isValidEmailFormat, isValidPassword } from '../../utils/PasswordUtils';


import INTERVIEWABLE_LOGO from '../../assets/interviewable-logo.png'


import { getAxiosInstance } from '../../utils/ApiUtils';
import StandardButton from '../../components/buttons/StandardButton';

import { colors } from '../../constants/Colors'

import { createIndividualAccount } from '../../redux/actions/authenticationActions';

import config from '../../env.json'
import { showErrorToast } from '../../utils/ToastUtils';
import styles from './IndividualSignUpScreen.module.css'
import { checkUniqueness, renderEmailUniqueness } from '../../utils/AuthUtils';
import TermsAndPrivacy from './TermsAndPrivacy.pdf';

const { BASE_URI } = config

export default function IndividualSignUpScreen(props) {
  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmedPassword, setConfirmedPassword] = useState('')
  const [isCheckingUniqueness, setIsCheckingUniqueness] = useState(false)
  const [isUniqueEmail, setIsUniqueEmail] = useState(null)
  const [termsModalVisible, setTermsModalVisible] = useState(false)
  const { planId } = location.state

  async function handleCheckout(sessionToken, stripeCustId) {
    let planType = 'SOLO'

    if (planId) {
      getAxiosInstance(sessionToken).post(`${BASE_URI}/payments/createCheckoutSession/${planId}/${email}/${stripeCustId}/${planType}`)
      .then((res) => {
        if (res.status == 200) {
          window.location.href = res.data;
        }
      })
    }
  }

  const createAccount = (event) => {

    if (email === '') {
      showErrorToast('Email address is required!')
    }
    else if (!isValidEmailFormat(email)) {
      showErrorToast('Please provide a valid email.')
      return;
    }

    if (!agree) {
      showErrorToast('Please accept Terms of Service and Privacy Policy')
      return;
    }

    if (!isValidPassword(password)) {
      showErrorToast('Password must be at leat 8 characters, contain at least 1 number, and at least 1 symbol.')
      return;
    }
    if (password === '' || confirmedPassword === '') {
      showErrorToast('Password is required!!')
      return;
    }

    if (!isIdentifcal(password, confirmedPassword)) {
      showErrorToast('Passwords do not match!')
      return;
    }

    const adminConfig = {
      email: email,
      password: password,
      role: 'ADMIN',
    };

    const accountConfig = {
      type: 'INDIVIDUAL',
      planType: 'PAID',
      planId: '6364a409-cb91-4461-9afa-574c8e27da11', // FREE MONTHLY RECURRING SUBSCRIPTION
      admin: adminConfig
    }

    dispatch(createIndividualAccount(accountConfig, () => navigate('/interviews')))
  };

  const [agree, setAgree] = useState(false);

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree);
    // Don't miss the exclamation mark
  }

  const renderTermsAndConditions = () => {
    const style =
    {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: '70vh',
      width: 850,
      bgcolor: "background.paper",
      border: "1px solid #000",
      boxShadow: 24,
      borderRadius: 5,
      p: 4,
    }

    return (
      <div>
        <input type="checkbox" id="agree" onChange={checkboxHandler} />
        <label style={{ paddingLeft: '10px' }} htmlFor="agree"> I agree to the
          <Button onClick={() => setTermsModalVisible(true)}>Terms of Service & Privacy Policy</Button>
          <Modal
            open={termsModalVisible}
            onClose={() => setTermsModalVisible(false)}
          >
            <Box sx={style}>
              <iframe src={TermsAndPrivacy} width='100%' height='100%' />
            </Box>
          </Modal>
        </label>
      </div>
    );
  }


  return (
    <div className={styles.bodyWrapper}>
      <Stack>
        <Stack className={styles.logoWrapper}>
          <img src={INTERVIEWABLE_LOGO} className={styles.logoStyle} />
        </Stack>
        <Box className={styles.formWrapper}>
          <form>
            <Stack direction='row' className={styles.emailOuterWrapper}>
              <Stack className={styles.emailWrapper}>
                <FormGroup className="mb-3">
                  <Typography>Email</Typography>
                  <TextField
                    type="email"
                    className={styles.emailInputStyle}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    onBlur={() => checkUniqueness(email, setIsCheckingUniqueness, setIsUniqueEmail)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />

                </FormGroup>
              </Stack>
              <Stack className={styles.uniquenessWrapper}>
                {renderEmailUniqueness(isCheckingUniqueness, isUniqueEmail, email)}
              </Stack>
            </Stack>
            <Stack className={styles.passwordWrapper}>
              <FormGroup className="mb-3">
                <Typography>Password</Typography>
                <TextField
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  className={styles.inputStyle}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Typography>Confirm Password</Typography>
                <TextField
                  label="Confirm Password"
                  type="password"
                  value={confirmedPassword}
                  onChange={(event) => setConfirmedPassword(event.target.value)}
                  className={styles.inputStyle}
                  variant="outlined"
                />
              </FormGroup>
            </Stack>
          </form>
        </Box>
        <Stack>
          {renderTermsAndConditions()}
        </Stack>

        <Stack style={{ width: '40sw', marginTop: 10, justifyContent: 'center', alignItems: 'center' }}>
          <StandardButton
            label={'CREATE ACCOUNT'}
            onPress={() => createAccount()}
            buttonWidth={400}
            backgroundColor={colors.oceanBlue}
          />
        </Stack>
      </Stack>
    </div>
  );
}