export const CREATING_INTERVIEW = 'CREATING_INTERVIEW'
export const CREATED_INTERVIEW = 'CREATED_INTERVIEW'
export const ERROR_CREATING_INTERVIEW = 'ERROR_CREATING_INTERVIEW'

export const SENDING_INTERVIEW_PROMPT = 'SENDING_INTERVIEW_PROMPT'
export const SENT_INTERVIEW_PROMPT = 'SENT_INTERVIEW_PROMPT'
export const ERROR_SENDING_INTERVIEW_PROMPT = 'ERROR_SENDING_INTERVIEW_PROMPT'

export const CONTINUING_INTERVIEW = 'CONTINUING_INTERVIEW'
export const CONTINUED_INTERVIEW = 'CONTINUED_INTERVIEW'
export const ERROR_CONTINUING_INTERVIEW = 'ERROR_CONTINUING_INTERVIEW'

export const RETRIEVING_ACTIVE_INTERVIEW = 'RETRIEVING_ACTIVE_INTERVIEW'
export const RETRIEVED_ACTIVE_INTERVIEW = 'RETRIEVED_ACTIVE_INTERVIEW'
export const ERROR_RETRIEVING_ACTIVE_INTERVIEW = 'ERROR_RETRIEVING_ACTIVE_INTERVIEW'

export const RETRIEVING_COMPLETED_INTERVIEWS = 'RETRIEVING_COMPLETED_INTERVIEWS'
export const RETRIEVED_COMPLETED_INTERVIEWS = 'RETRIEVED_COMPLETED_INTERVIEWS'
export const ERROR_RETRIEVING_COMPLETED_INTERVIEWS = 'ERROR_RETRIEVING_COMPLETED_INTERVIEWS'

export const RETRIEVING_INTERVIEW = 'RETRIEVING_INTERVIEW'
export const RETRIEVED_INTERVIEW = 'RETRIEVED_INTERVIEW'
export const ERROR_RETRIEVING_INTERVIEW = 'ERROR_RETRIEVING_INTERVIEW'

export const RETRIEVING_TECHNICAL_INTERVIEW_FOCUS_AREAS = 'RETRIEVING_TECHNICAL_INTERVIEW_FOCUS_AREAS'
export const RETRIEVED_TECHNICAL_INTERVIEW_FOCUS_AREAS = 'RETRIEVED_TECHNICAL_INTERVIEW_FOCUS_AREAS'
export const ERROR_RETRIEVING_TECHNICAL_INTERVIEW_FOCUS_AREAS = 'ERROR_RETRIEVING_TECHNICAL_INTERVIEW_FOCUS_AREAS'

export const SUBMITTING_INTERVIEW_RESPONSE_FOR_EVALUATION = 'SUBMITTING_INTERVIEW_RESPONSE_FOR_EVALUATION'
export const SUBMITTED_INTERVIEW_RESPONSE_FOR_EVALUATION = 'SUBMITTED_CINTERVIEWRESPONSE_FOR_EVALUATION'
export const ERROR_SUBMITTING_INTERVIEW_RESPONSE_FOR_EVALUATION = 'ERROR_SUBMITTING_INTERVIEW_RESPONSE_FOR_EVALUATION'

export const COMPLETING_ACTIVE_INTERVIEW = 'COMPLETING_ACTIVE_INTERVIEW'
export const COMPLETED_ACTIVE_INTERVIEW = 'COMPLETED_ACTIVE_INTERVIEW'
export const ERROR_COMPLETING_ACTIVE_INTERVIEW = 'ERROR_COMPLETING_ACTIVE_INTERVIEW'

export const RETRIEVING_TECHNICAL_FOCUS_AREAS_BY_PROGRAMMING_LANGUAGUE = 'RETRIEVING_TECHNICAL_FOCUS_AREAS_BY_PROGRAMMING_LANGUAGUE'
export const RETRIEVED_TECHNICAL_FOCUS_AREAS_BY_PROGRAMMING_LANGUAGUE = 'RETRIEVED_TECHNICAL_FOCUS_AREAS_BY_PROGRAMMING_LANGUAGUE'
export const ERROR_RETRIEVING_TECHNICAL_FOCUS_AREAS_BY_PROGRAMMING_LANGUAGUE = 'ERROR_RETRIEVING_TECHNICAL_FOCUS_AREAS_BY_PROGRAMMING_LANGUAGUE'

export const CREATING_INTERVIEW_QUESTION = 'CREATING_INTERVIEW_QUESTION'
export const CREATED_INTERVIEW_QUESTION = 'CREATED_INTERVIEW_QUESTION'
export const ERROR_CREATING_INTERVIEW_QUESTION = 'ERROR_CREATING_INTERVIEW_QUESTION'

export const DELETING_INTERVIEW_QUESTION = 'DELETING_INTERVIEW_QUESTION'
export const DELETED_INTERVIEW_QUESTION = 'DELETED_INTERVIEW_QUESTION'
export const ERROR_DELETING_INTERVIEW_QUESTION = 'ERROR_DELETING_INTERVIEW_QUESTION'

export const EDITING_INTERVIEW_QUESTION = 'EDITING_INTERVIEW_QUESTION'
export const EDITED_INTERVIEW_QUESTION = 'EDITED_INTERVIEW_QUESTION'
export const ERROR_EDITING_INTERVIEW_QUESTION = 'ERROR_EDITING_INTERVIEW_QUESTION'