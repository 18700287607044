import { Modal } from "@mui/material"
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie';

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Popover from '@mui/material/Popover';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField'

import { colors } from '../../constants/Colors'
import Backdrop from "@mui/material/Backdrop";
import { showErrorToast, showSuccessToast } from '../../utils/ToastUtils'
import { getAxiosInstance, getMultipartAxiosInstance } from '../../utils/ApiUtils'
import config from '../../env.json'
import { createNewGroup } from '../../redux/actions/organizationActions';

import StandardButton from '../../components/buttons/StandardButton';

import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from '@mui/material/Box';
const { BASE_URI } = config


export default function CreateGroupModal(props) {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [groupName, setGroupName] = useState('')
  const [selected, setSelected] = useState([]);

  const {
    isVisible,
    handleClose,
  } = props
  const token = Cookies.get('token')
  const authedUserId = useSelector((state) => state.auth.id)
  const cohortId = params.id

  const navigateToCohortScreen = () => {
    navigate(`/org/cohort/${cohortId}`)
  }

  const createGroup = () => {

    // Match the selected group members against the students list by name to create array of ids for request
    const groupMembers = [];
    selected.map((x) => groupMembers.push(students.find((student) => student.user.email === x).id));

    const groupConfig = {
      cohortId: cohortId,
      creatorId: authedUserId,
      groupName: groupName,
      groupMembers: groupMembers,
    }

    dispatch(createNewGroup(groupConfig, token, navigateToCohortScreen))
  }

  const { students } = props

  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    padding: 40,
    height: 600,
    overflowY: 'scroll',
    p: 4,
  };

  const styles = {
    formControl: {
      margin: '10px',
      width: 300
    },
    indeterminateColor: {
      color: "#f50057"
    },
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    variant: "menu"
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelected(value);
  };

  const formatNamesSelected = () => {
    if (selected.length > 1) {
      return selected.join(", ")
    }
    return selected[0]
  }

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <Modal
        open={isVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Box sx={boxStyle}>
          <Stack style={{ marginLeft: 50, marginTop: 50, width: '40%', }}>
            <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4 }}>GROUP DETAILS</Typography>
            <form>
              <Stack direction='row' style={{ width: '100%', justifyContent: 'space-between', marginTop: 20 }}>
                <FormGroup className="mb-3" controlId="formBasicEmail">
                  <Stack>
                    <Typography>Group Name<span style={{ color: colors.blazeRed }}>*</span></Typography>
                    <TextField
                      style={{width: 400}}
                      type="text"
                      value={groupName}
                      onChange={(event) => setGroupName(event.target.value)}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                  </Stack>
                  <Stack>
                    <InputLabel id="mutiple-select-label" >Select group members</InputLabel>
                    <Select
                      labelId="mutiple-select-label"
                      multiple
                      value={selected}
                      onChange={handleChange}
                      renderValue={formatNamesSelected}
                      MenuProps={MenuProps}
                      sx={{ marginTop: "10px" }}
                    >
                      {students?.map((student) => (
                        <MenuItem key={student.user.id} name={student.user.id} data-id={student.user.id} value={student.user.email}>
                          <ListItemIcon>
                            <Checkbox checked={selected.includes(student.user.email)} />
                          </ListItemIcon>
                          <ListItemText primary={student.user.email} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                  <Stack style={{ marginTop: 40, marginBottom: 60 }}>
                    <StandardButton
                      label='CREATE GROUP'
                      backgroundColor={colors.oceanBlue}
                      onPress={() => createGroup()}
                    />
                  </Stack>
                </FormGroup>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Modal>
    </div>
  )
}