import {
  CREATING_ORG_ACCOUNT,
  CREATED_ORG_ACCOUNT,
  ERROR_CREATING_ORG_ACCOUNT,
  SET_ORGANIZATION_ID,
  CREATING_COHORT,
  CREATED_COHORT,
  ERROR_CREATING_COHORT,
  RETRIEVING_COHORTS,
  RETRIEVED_COHORTS,
  ERROR_RETRIEVING_COHORTS,
  RETRIEVING_COHORT,
  RETRIEVED_COHORT,
  ERROR_RETRIEVING_COHORT,
  CREATING_GROUP,
  CREATED_GROUP,
  ERROR_CREATING_GROUP,
  RETRIEVING_GROUPS,
  RETRIEVED_GROUPS,
  ERROR_RETRIEVING_GROUPS,
  RETRIEVING_ALL_USERS_FOR_ORG,
  RETRIEVED_ALL_USERS_FOR_ORG,
  ERROR_RETRIEVING_ALL_USERS_FOR_ORG,
  ADDING_SEAT_TO_SUBSCRIPTION,
  ADDED_SEAT_TO_SUBSCRIPTION,
  ERROR_ADDING_SEAT_TO_SUBSCRIPTION,
  CANCELLING_SUBSCRIPTION,
  CANCELLED_SUBSCRIPTION,
  ERROR_CANCELLING_SUBSCRIPTION,
  CHECKING_SUBSCRIPTION_ON_ACCOUNT,
  CHECKED_SUBSCRIPTION_ON_ACCOUNT,
  ERROR_CHECKING_SUBSCRIPTION_ON_ACCOUNT,
  UPDATING_COHORT_DETAILS,
  UPDATED_COHORT_DETAILS,
  ERROR_UPDATING_COHORT_DETAILS,
  REMOVING_MEMBER_FROM_TEAM_ACCOUNT,
  REMOVED_MEMBER_FROM_TEAM_ACCOUNT,
  ERROR_REMOVING_MEMBER_FROM_TEAM_ACCOUNT
} from '../constants/organizationActionTypes'

const initialState = {
  plan: '',
  priceId: '',
  planId: '',
  isLoading: false,
  users: [],
  admin: {},
  id: '',
  companyName: '',
  addressOne: '',
  addressTwo: '',
  city: '',
  state: '',
  country: '',
  zipCode: '',
  planType: '',
  status: '',
  cohorts: [],
  currentCohort: {},
  stripeCustomerId: '',
  stripeSubscriptionId: '',
  defaultCardBrand: '',
  defaultCardLastFour: '',
  defaultCardExpiryMonth: '',
  defaultCardExpiryYear: '',
  currentPeriodEnd: '',
  invoices: [],
  planCost: 0,
  numberOfSeats: 0,
  planStatus: '',
  priorPlanPriceId: '',
  priorPlanType: '',
}

function organizationReducer(state = initialState, action) {
  switch (action.type) {
    case CREATING_ORG_ACCOUNT:
      return {
        ...state,
        isLoading: true
      }
    case CREATED_ORG_ACCOUNT:
      return {
        ...state,
        id: action.payload.id,
        companyName: action.payload.name,
        addressOne: action.payload.addressOne,
        addressTwo: action.payload.addressTwo,
        city: action.payload.city,
        state: action.payload.state,
        country: action.payload.country,
        zipCode: action.payload.zipCode,
        planType: action.payload.planType,
        plan: action.payload.plan,
        status: action.payload.account.status,
        priceId: action.payload.account.priceId,
        planId: action.payload.account.planId,
        stripeCustomerId: action.payload.account.admin.stripeCustomerId,
        isLoading: false,
      }
    case ERROR_CREATING_ORG_ACCOUNT:
      return {
        ...state,
        isLoading: false,
      }
    case SET_ORGANIZATION_ID:
      return {
        ...state,
        id: action.payload
      }
    case CREATING_COHORT:
      return {
        ...state,
        isLoading: true
      }
    case CREATED_COHORT:
      return {
        ...state,
        isLoading: false,
        cohorts: state.cohorts.concat(action.payload)
      }
    case ERROR_CREATING_COHORT:
      return {
        ...state,
        isLoading: false,
      }
    case RETRIEVING_COHORTS:
      return {
        ...state,
        isLoading: true
      }
    case RETRIEVED_COHORTS:
      return {
        ...state,
        isLoading: false,
        cohorts: action.payload
      }
    case ERROR_RETRIEVING_COHORTS:
      return {
        ...state,
        isLoading: false
      }
    case RETRIEVING_COHORT:
      return {
        ...state,
        isLoading: true
      }
    case RETRIEVED_COHORT:
      return {
        ...state,
        isLoading: false,
        currentCohort: action.payload
      }
    case ERROR_RETRIEVING_COHORT:
      return {
        ...state,
        isLoading: false
      }

    case CREATING_GROUP:
      return {
        ...state,
        isLoading: true
      }
    case CREATED_GROUP:
      return {
        ...state,
        isLoading: false,
        groupMembers: state.groupMembers
      }
    case ERROR_CREATING_GROUP:
      return {
        ...state,
        isLoading: false,
      }

    case RETRIEVING_GROUPS:
      return {
        ...state,
        isLoading: true
      }
    case RETRIEVED_GROUPS:
      return {
        ...state,
        isLoading: false,
        currentGroups: action.payload
      }
    case ERROR_RETRIEVING_GROUPS:
      return {
        ...state,
        isLoading: false
      }

    case RETRIEVING_ALL_USERS_FOR_ORG:
      return {
        ...state,
        isLoading: false,
      }
    case RETRIEVED_ALL_USERS_FOR_ORG:
      return {
        ...state,
        users: action.payload,
        isLoading: false
      }
    case ERROR_RETRIEVING_ALL_USERS_FOR_ORG:
      return {
        ...state,
        isLoading: false
      }
    case ADDING_SEAT_TO_SUBSCRIPTION:
      return {
        ...state,
        isLoading: true
      }
    case ADDED_SEAT_TO_SUBSCRIPTION:
      return {
        ...state,
        isLoading: false,
        users: state.users.concat(action.payload)
      }
    case ERROR_ADDING_SEAT_TO_SUBSCRIPTION:
      return {
        ...state,
        isLoading: false,
      }
    case CANCELLING_SUBSCRIPTION:
      return {
        ...state,
        isLoading: true
      }
    case CANCELLED_SUBSCRIPTION:
      return {
        ...state,
        isLoading: false
      }
    case ERROR_CANCELLING_SUBSCRIPTION:
      return {
        ...state,
        isLoading: false
      }
    case CHECKING_SUBSCRIPTION_ON_ACCOUNT:
      return {
        ...state,
        isLoading: false
      }
    case CHECKED_SUBSCRIPTION_ON_ACCOUNT:
      return {
        ...state,
        isLoading: false,
        defaultCardBrand: action.payload.cardBrand,
        defaultCardLastFour: action.payload.cardLastFour,
        currentPeriodEnd: action.payload.currentPeriodEnd,
        stripeSubscriptionId: action.payload.id,
        defaultCardExpiryMonth: action.payload.expiryMonth,
        defaultCardExpiryYear: action.payload.expiryYear,
        invoices: action.payload.invoices,
        planCost: action.payload.planCost,
        numberOfSeats: action.payload.numberOfSeats,
        planStatus: action.payload.planStatus,
        priorPlanPriceId: action.payload.priorPlanPriceId,
        priorPlanType: action.payload.priorPlanType

      }
    case ERROR_CHECKING_SUBSCRIPTION_ON_ACCOUNT:
      return {
        ...state,
        isLoading: false
      }
    case UPDATING_COHORT_DETAILS:
      return {
        ...state,
        isLoading: true
      }
    case UPDATED_COHORT_DETAILS:
      return {
        ...state,
        isLoading: false,
        cohorts: action.payload
      }
    case ERROR_UPDATING_COHORT_DETAILS:
      return {
        ...state,
        isLoading: false
      }
    case REMOVING_MEMBER_FROM_TEAM_ACCOUNT:
      return {
        ...state,
        isLoading: true
      }
    case REMOVED_MEMBER_FROM_TEAM_ACCOUNT:
      return {
        ...state,
        isLoading: false,
        users: action.payload
      }
    case ERROR_REMOVING_MEMBER_FROM_TEAM_ACCOUNT:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

export default organizationReducer