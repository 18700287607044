import React from 'react'

import PropTypes from 'prop-types'
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function CCDatePicker(props) {

  const { setDate, defaultDate, updatedDate } = props

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker 
          defaultValue={dayjs(defaultDate)}
          onChange={(value, context) => setDate(value)} />
      </DemoContainer>
    </LocalizationProvider>
  )
}

DatePicker.propTypes = {
  setDate: PropTypes.func,
  defaultDate: PropTypes.string,
  updatedDate: PropTypes.string,
  
}