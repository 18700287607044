import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import FilterableTable from 'react-filterable-table';

import PropTypes from 'prop-types'
import { Typography } from '@mui/material';
import GenericTable from './GenericTable';


export default function CohortStudentsTable(props) {

  const navigate = useNavigate()

  const { students } = props

  const [cohortStudents, setCohortStudents] = useState([])

  // useEffect(() => {
  //   let studentArray = []
  //   if (students) {
  //     students.map((student) => {
  //       if (student !== '') {
  //         studentArray.push(student.user)
  //       }
  //     })

  //     setCohortStudents(studentArray)
  //   }

  // }, [cohortStudents.length])


  // const fields = [
  //   { name: 'firstName', displayName: "First Name", inputFilterable: true, sortable: true, render: renderClickableStudent },
  //   { name: 'lastName', displayName: "Last Name", inputFilterable: true, exactFilterable: true, sortable: true, render: renderClickableStudent },
  //   { name: 'email', displayName: "Email", inputFilterable: true, exactFilterable: true, sortable: true, render: renderClickableStudent }
  // ];

  // function renderClickableStudent(props) {
  //   const { id, email, firstName, lastName } = props.record
  //   return (
  //     <Button
  //       variant='text'
  //       onClick={() => navigate(`/org/student/${props.record.id}`, { state: { id: id, firstName: firstName, lastName: lastName, email: email } })}
  //     >
  //       {props.value}</Button>
  //   )

  // }

  return (
    <div style={{ maxHeight: 500, width: '100%', overflow: 'scroll' }}>
      <GenericTable 
        students={students ? students : null}
      />
    </div>
  );
}

CohortStudentsTable.propTypes = {
  students: PropTypes.array
}