import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { MentionsInput, Mention } from 'react-mentions'

import Cookies from 'js-cookie';

import { Box, Stack, Button, ButtonGroup, Typography, TextField, IconButton, Switch } from '@mui/material'
import SidebarNav from '../../components/navigation/SidebarNav';

import { ToastContainer, toast } from 'react-toastify';

import { colors } from '../../constants/Colors';
import { getCalendarDate } from '../../utils/DateUtils'
import { AddAPhoto, DeleteForever } from '@mui/icons-material';


import { createIndividualCheckIn, updateWeeklyCheckIn } from '../../redux/actions/checkInActions'
import CheckInButton from '../../components/buttons/CheckInButton';
import CheckInDetailsModal from '../../components/modals/CheckInDetailsModal';
import EditableCheckInReportModal from '../../components/modals/EditableCheckInReportModal';

import BUG_IMAGE from '../../assets/bug-image.png'
import CONSTRUCTION_ICON from '../../assets/construction-icon.png'
import LIGHT_BULB from '../../assets/light-bulb.png'
import FRUSTRATION_ICON from '../../assets/frustration-icon.png'
import WINS_ICON from '../../assets/wins-icon.png'
import COLLABORATION_ICON from '../../assets/paired-icon.png'
import CONFUSION_ICON from '../../assets/confusion-icon.png'
import INTERVIEW_ICON from '../../assets/interview-icon.png'
import defaultStyle from '../../components/mentions/defaultStyle';
import defaultMentionStyle from '../../components/mentions/defaultMentionStyle';
import SentimentRating from '../../components/scales/SentimentRating';
import ViewHighFivePreview from '../../components/highFives/ViewHighFivePreview';



export default function CreateCheckInScreen(props) {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const { id } = params

  const [currentCheckInDueDate, setCurrentCheckInDueDate] = useState(null);


  const planType = sessionStorage.getItem('planType')

  const [sentiment, setSentiment] = useState(null)
  const [sentimentRating, setSentimentRating] = useState(null)
  const [sentimentDetail, setSentimentDetail] = useState(null)
  const [improvementSuggestion, setImprovementSuggestion] = useState(null)
  const [checkinItemModalVisible, setCheckinItemModalVisible] = useState(false)
  const [accomplishments, setAccomplishments] = useState([])
  const [currentChallenge, setCurrentChallenge] = useState(null)
  const [currentChallenges, setCurrentChallenges] = useState([])
  const [isPublic, setIsPublic] = useState(false)
  const [highFiveUsers, setHighFiveUsers] = useState([])

  const [checkInCategory, setCheckInCategory] = useState('')
  const [checkInDetailsModalVisible, setCheckInDetailModalVisible] = useState(false)
  const [checkInItems, setCheckInItems] = useState([])
  const [checkInReportOpen, setCheckInReportOpen] = useState(false)
  const [highFives, setHighFives] = useState([])
  const [currentHighFive, setCurrentHighFive] = useState('')

  const token = Cookies.get('token')
  const checkIns = useSelector((state => state.checkIns.checkIns))
  const authedUserId = useSelector((state) => state.auth.id)
  const users = useSelector((state) => state.org.users)


  useEffect(() => {
    if (users) {
      formatUsersForHighFives()
    }

    if (checkIns.length > 0) {
      let checkIn = checkIns.filter((checkIn) => checkIn.id === id)[0]
      if (checkIn != null) {
        setCurrentCheckInDueDate(checkIn.dueDate)
      }
    }
  }, [users])

  const clearCheckInCategory = () => {
    setCheckInCategory('')
    setCheckInDetailModalVisible(false)
  }

  // const renderSentiment = () => {
  //   switch (sentiment) {
  //     case 'HAPPY':
  //       return 'happy'
  //     case 'NEUTRAL':
  //       return 'meh'
  //     case 'SAD':
  //       return 'sad'
  //   }
  // }

  // const addAccomplishment = () => {
  //   let currAccomplishments = accomplishments

  //   const accomplishment = {
  //     tempId: Math.floor(Math.random() * 100001)
  //   }

  //   setAccomplishments(currAccomplishments.concat(accomplishment))
  // }

  const showTagSuccessToast = () => {
    toast.success('Tag successfully created!', {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  // const renderAccomplishments = () => {
  //   if (accomplishments && accomplishments.length > 0) {
  //     return accomplishments.map((accomplishment, index) => {
  //       return (
  //         <Stack>
  //           <AccomplishmentsInput
  //             key={index}
  //             showSuccessToast={showTagSuccessToast}
  //             currentAccomplishments={accomplishments}
  //             setCurrentAccomplishments={setAccomplishments}
  //             tempId={accomplishment.tempId}
  //             checkInType='INDIVIDUAL'
  //           />
  //         </Stack>
  //       )
  //     })
  //   }
  // }

  // const addChallenge = () => {
  //   if (currentChallenge) {
  //     let currChallenges = currentChallenges

  //     const challenge = {
  //       value: currentChallenge,
  //       tempId: Math.floor(Math.random() * 100000),
  //       postToFeed: false
  //     }
  //     setCurrentChallenges(currChallenges.concat(challenge))
  //     setCurrentChallenge('')
  //   }

  // }

  // const renderChallenges = () => {
  //   if (currentChallenges.length > 0) {
  //     return currentChallenges.map(currentChallenge => {
  //       return <CheckInChallenge
  //         value={currentChallenge.value}
  //         tempId={currentChallenge.tempId}
  //         deleteChallenge={deleteChallenge}
  //         challenges={currentChallenges}
  //         updateChallenges={setCurrentChallenges}
  //       />
  //     })
  //   }
  // }

  const deletCheckInItem = (id) => {
    const remainingCheckInItems = checkInItems.filter((checkInItem) => checkInItem.tempId !== id);
    setCheckInItems(remainingCheckInItems)
  }



  const navigateToCheckInsHome = () => {
    navigate('/checkins')
  }

  const addToCheckIn = (checkInDetail) => {

    setCheckInItems(checkInItems.concat(checkInDetail))
    setCheckInCategory('')
    setCheckInDetailModalVisible(false)
  }

  const formatUsersForHighFives = () => {

    let highFiveList = []
    if (users) {
      users.map((user) => {
        highFiveList.push({
          id: user.id,
          display: `${user.firstName} ${user.lastName}`
        })
      })

      setHighFiveUsers(highFiveList)
    }
  }

  function extractValueInSquareBrackets(input) {
    const regex = /\[(.*?)\]/;
    const matches = regex.exec(input);
    if (matches && matches.length > 1) {

      return matches[1].replace(/\s/g, '');
    } else {
      return null;
    }
  }

  function extractTextInParentheses(input) {
    const regex = /\((.*?)\)/;
    const matches = regex.exec(input);
    if (matches && matches.length > 1) {
      return matches[1];
    } else {
      return null;
    }
  }

  function removeTextWithFormat(input) {
    const regex = /@\[.*?\]\(.*?\)/g;
    return input.replace(regex, '');
  }

  const addHighFive = () => {

    let highFiveRecipientId = extractTextInParentheses(currentHighFive)
    let username = extractValueInSquareBrackets(currentHighFive)


    let remainingText = removeTextWithFormat(currentHighFive)
    let highFiveText = `@${username}${remainingText}`

    const highFive = {
      id: Math.floor(Math.random() * 1000),
      value: highFiveText,
      recipient: {
        id: highFiveRecipientId
      },
      isPublic: false,
    }

    setHighFives(highFives.concat(highFive))
    setCurrentHighFive('')
  }



  const updateHighFiveVisibility = (highFiveId, visibility) => {

    let currHighFives = highFives

    if (currHighFives) {
      currHighFives.map((highFive) => {
        if (highFive.id == highFiveId) {
          highFive.isPublic = visibility
        }
      })

      setHighFives(currHighFives)
    }
  }

  const deleteHighFive = (highFiveId) => {
    let updatedHighFives = highFives.filter((highFive) => highFive.id != highFiveId)
    setHighFives(updatedHighFives)
  }

  const renderHighFives = () => {
    if (highFives.length > 0) {
      return highFives.map(highFive => {
        return (
          <ViewHighFivePreview
            key={highFive.id}
            id={highFive.id}
            value={highFive.value}
            onPress={deleteHighFive}
            isPublic={highFive.isPublic}
            setIsPublic={updateHighFiveVisibility}
          />
        )
      })
    }
  }

  const updateCheckInDetail = (id, detail) => {
    let detailList = checkInItems
    detailList.map((checkIn) => {

      if (checkIn.tempId === id) {
        checkIn.value = detail
      }
    })


    setCheckInItems(detailList)


  }

  const navigateToCheckIns = () => {
    return navigate('/checkIns')
  }



  const saveCheckIn = () => {

    const checkInSentimentConfig = {
      sentiment: sentimentRating,
      details: sentimentDetail,
    }


    const checkInConfig = {
      id: id,
      userId: authedUserId,
      checkInSentiment: checkInSentimentConfig,
      improvementSuggestion: improvementSuggestion,
      inputs: checkInItems,
      highFives: highFives
    }
    
    dispatch(updateWeeklyCheckIn(checkInConfig, token, navigateToCheckIns))

  }



  return (
    <div
      style={{ display: 'flex', flex: 1, paddingBottom: 50 }}

    >
      <SidebarNav />
      <Stack style={{ paddingLeft: 100, marginTop: 70, width: '75dvw'}}>
        <Typography style={{ fontSize: 32, fontWeight: 'bold' }}>Check-In For {getCalendarDate(currentCheckInDueDate)}</Typography>
        <Stack direction='row' style={{ justifyContent: 'space-between' }}>
          <Stack style={{ marginLeft: 10, }} direction={'column'}>
            <Typography style={{ fontSize: 20, marginTop: 10 }}>How are you feeling?</Typography>
            <SentimentRating
              sentimentRating={sentimentRating ? sentimentRating : 0}
              setSentimentRating={setSentimentRating}
            />
    
          </Stack>
          <Stack
            style={{ border: '1px solid #93bae9', height: 120, width: 200, padding: 5, marginTop: 10, justifyContent: 'center', alignItems: 'center', borderRadius: 5 }}
          >
            <Typography style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'center' }}>
              Check-In Details
            </Typography>
            <Typography style={{ fontSize: 30, fontWeight: 'bold', textAlign: 'center', color: colors.coolBlue, textAlign: 'center' }}>
              {checkInItems ? checkInItems.length : 0}
            </Typography>

            <Button
              type='text'
              style={{ backgroundColor: `${colors.plum}`, height: 30, width: '80%', marginTop: 3 }}
              onClick={() => setCheckInReportOpen(true)}
            >
              <Typography style={{ color: 'white' }}>
                REVIEW
              </Typography>
            </Button>
          </Stack>
        </Stack>
        <Stack>
          <Typography style={{ fontSize: 20 }}>
            Any particular reason you're feeling the way you are today?
          </Typography>
        </Stack>
        <Stack direction='column' style={{ width: '60%' }}>
          <TextField
            onChange={(event) => setSentimentDetail(event.target.value)}
            id="outlined-multiline-static"
            multiline
            placeholder="I'm feeling..."
            minRows={4}
            row={6}
            size='large'
            fullWidth={true}
          />
        </Stack>

        <Stack style={{marginTop: 10}}>
          <Typography style={{ fontSize: 20 }}>Choose a category below to add to your check-in:</Typography>
        </Stack>
        <Stack direction='row'>
          <CheckInButton
            label='AHA! MOMENT'
            icon={LIGHT_BULB}
            checkInCategory={checkInCategory}
            onPress={() => {
              setCheckInDetailModalVisible(true)
              setCheckInCategory('AHA_MOMENT')
            }}
          />
          <CheckInButton
            label='BUG'
            icon={BUG_IMAGE}
            onPress={() => {
              setCheckInDetailModalVisible(true)
              setCheckInCategory('BUG')
            }}
          />
          <CheckInButton
            label='COLLABORATION'
            icon={COLLABORATION_ICON}
            onPress={() => {
              setCheckInDetailModalVisible(true)
              setCheckInCategory('COLLABORATION')
            }}
          />
          <CheckInButton
            label='CONFUSION'
            icon={CONFUSION_ICON}
            onPress={() => {
              setCheckInDetailModalVisible(true)
              setCheckInCategory('CONFUSION')
            }}
          />
        </Stack>
        <Stack direction='row'>
          <CheckInButton
            label='FRUSTRATION'
            icon={FRUSTRATION_ICON}
            onPress={() => {
              setCheckInDetailModalVisible(true)
              setCheckInCategory('FRUSTRATION')
            }}
          />
          <CheckInButton
            label='INTERVIEW'
            icon={INTERVIEW_ICON}
            onPress={() => {
              setCheckInDetailModalVisible(true)
              setCheckInCategory('INTERVIEW')
            }}
          />
          <CheckInButton
            label='PROJECT'
            icon={CONSTRUCTION_ICON}
            onPress={() => {
              setCheckInDetailModalVisible(true)
              setCheckInCategory('PROJECT')
            }}
          />
          <CheckInButton
            label='WIN'
            icon={WINS_ICON}
            onPress={() => {
              setCheckInDetailModalVisible(true)
              setCheckInCategory('WIN')
            }}
          />
        </Stack>

        <Stack style={{ marginTop: 20 }}>
          <Typography style={{ fontSize: 20 }}>
            Is there anything that you'd do to change or improve your experience this week?
          </Typography>
        </Stack>
        <Stack direction='column' style={{ width: '60%' }}>
          <TextField
            onChange={(event) => setImprovementSuggestion(event.target.value)}
            id="outlined-multiline-static"
            multiline
            placeholder="I'm feeling..."
            minRows={4}
            row={6}
            size='large'
            fullWidth={true}
          />
        </Stack>

        <Stack style={{ width: '80%', marginTop: 20 }}>
          {planType === 'TEAM' && (
            <Stack>
              <Typography style={{ fontSize: 20, }}>
                Show appreciation for your teammates by sharing the impact they had on you this week. Only public high fives will be shared to the feed.
              </Typography>
              <Stack direction='row' >
                <MentionsInput
                  placeholder="Use '@' symbol for mentions...ex. @mansi you did a great job breaking down a technically complex topic. I have a much better understanding. Thanks! "
                  value={currentHighFive}
                  onChange={(event) => setCurrentHighFive(event.target.value)}
                  style={defaultMentionStyle}
                  a11ySuggestionsListLabel={'Suggested mentions'}
                >
                  <Mention style={defaultStyle} data={highFiveUsers} />
                </MentionsInput>
                <Button
                  style={{ marginLeft: 20 }}
                  variant='outlined'
                  onClick={() => addHighFive()}
                >
                  <Typography>ADD</Typography>
                </Button>
              </Stack>
              <Stack style={{ marginTop: 10 }}>
                {renderHighFives()}
              </Stack>
            </Stack>
          )}

        </Stack>
        <Stack direction='row' style={{ marginTop: 30 }}>
          <Button
            onClick={() => saveCheckIn()}
            variant='contained'
            style={{ width: 250, height: 50, backgroundColor: '#2caeba' }}
          >
            <Typography
              style={{ fontSize: 16, fontWeight: 'bold', color: 'white' }}
            >SUBMIT CHECK-IN</Typography>
          </Button>


        </Stack>
      </Stack>


      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <CheckInDetailsModal
        open={checkInDetailsModalVisible}
        handleClose={() => clearCheckInCategory()}
        checkInCategory={checkInCategory}
        addToCheckIn={addToCheckIn}
      />
      <EditableCheckInReportModal
        open={checkInReportOpen}
        handleClose={() => setCheckInReportOpen(false)}
        deletCheckInItem={deletCheckInItem}
        checkInItems={checkInItems ? checkInItems : null}
        sentiment={sentimentRating}
        sentimentDetail={sentimentDetail}
        updateCheckInDetail={updateCheckInDetail}
      />
    </div>
  )
}
