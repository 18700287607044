import React from 'react'

import { useNavigate } from "react-router-dom";

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import styles from './ErrorScreen.module.css'

import WIRES_CROSSED from '../../assets/wires-crossed.png'


export default function ErrorScreen() {

  const navigate = useNavigate()

  return (
    <div className={styles.wrapper}>
      <Stack className={styles.heroWrapper}>
      <img src={WIRES_CROSSED} className={styles.heroImage} />
      <h6 className={styles.heroText}>Uh uh... it's looks like we've got a wire crossed. Sorry about the inconvenience.
      Please <span onClick={() => navigate('/login')} className={styles.errorLoginText} >login</span> again.
      </h6>
      </Stack>
        
    </div>
  )
}