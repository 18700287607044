import React, { useState } from 'react'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { colors } from '../../constants/Colors'

import PropTypes from 'prop-types'

export default function SentimentRating(props) {

  const { setSentimentRating, sentimentRating } = props


  return (
    <div style={{ display: 'flex', }}>
      <Stack 
        direction='row' style={{ width: '100%', justifyContent: 'space-evenly', display: 'flex' }}>
        <Stack style={{justifyContent: 'center', alignItems: 'center'}}>
          <Typography style={{fontSize: 22, paddingRight: 10}}>
            Awful
          </Typography>
        </Stack>
        <Button 
          variant='outlined'
          onClick={() => setSentimentRating(1)}
          style={{ height: 50, width: 50, border: '1px solid gray', borderRadius: '15%', justifyContent: 'center', alignItems: 'center', backgroundColor: sentimentRating == 1 ? colors.blazeRed : 'white', margin: 5 }}
          >
          <Typography style={{ fontSize: 22, color: sentimentRating == 1 ? 'white' : 'gray' }}>
            1
          </Typography>
        </Button>
        <Button 
          style={{ height: 50, width: 50, border: '1px solid gray', borderRadius: '15%', justifyContent: 'center', alignItems: 'center', backgroundColor: sentimentRating == 2 ? colors.blazeRed : 'white', margin: 5 }}
          onClick={() => setSentimentRating(2)}
        >
          <Typography style={{ fontSize: 22, color: sentimentRating == 2 ? 'white' : 'gray' }}>
            2
          </Typography>
        </Button>
        <Button 
          style={{ height: 50, width: 50, border: '1px solid gray', borderRadius: '15%', justifyContent: 'center', alignItems: 'center', backgroundColor: sentimentRating == 3 ? colors.blazeRed : 'white', margin: 5 }}
          onClick={() => setSentimentRating(3)}
        >
          <Typography style={{ fontSize: 22, color: sentimentRating == 3 ? 'white' : 'gray' }}>
            3
          </Typography>
        </Button>
        <Button 
          style={{ height: 50, width: 50, border: '1px solid gray', borderRadius: '15%', justifyContent: 'center', alignItems: 'center', backgroundColor: sentimentRating == 4 ? colors.blazeRed : 'white', margin: 5 }}
          onClick={() => setSentimentRating(4)}
        >
          <Typography style={{ fontSize: 22, color: sentimentRating == 4 ? 'white' : 'gray' }}>
            4
          </Typography>
        </Button>
        <Button 
          style={{ height: 50, width: 50, border: '1px solid gray', borderRadius: '15%', justifyContent: 'center', alignItems: 'center', backgroundColor: sentimentRating == 5 ? colors.blazeRed : 'white', margin: 5 }}
          onClick={() => setSentimentRating(5)}

          >
          <Typography style={{ fontSize: 22, color: sentimentRating == 5 ? 'white' : 'gray' }}>
            5
          </Typography>
        </Button>
        <Stack style={{justifyContent: 'center', alignItems: 'center'}}>
          <Typography style={{fontSize: 22, paddingLeft: 10}}>
            Incredible
          </Typography>
        </Stack>
        
      </Stack>
    </div>

  )



}

SentimentRating.propTypes = {
  setSentimentRating: PropTypes.func,
  sentimentRating: PropTypes.number
}