export const addOns = [
  {
    stripePriceId: 'price_1OKLIZFmIdBJHCdM6rXMQz3q',
    name: 'Interview 2 Pack',
    details: 'Includes (2) mock interviews',
    price: '$10'
  },
  {
    stripePriceId: 'price_1OKLITFmIdBJHCdMfhGylJNb',
    name: 'Interview 4 Pack',
    details: 'Includes (4) mock interviews',
    price: '$18'
  },
  {
    stripePriceId: 'price_1OKLIDFmIdBJHCdMV6NsNIs3',
    name: 'Interview 6 Pack',
    details: 'Includes (6) mock interviews',
    price: '$24'
  },

]