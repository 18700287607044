import React from 'react'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography';

import MAGNIFYING_GLASS from '../../assets/sad-magnifying-glass.png'

import PropTypes from 'prop-types'


import styles from './CompletedInterviewsTable.module.css'

export default function CompletedInterviewsTable(props) {

  const { 
    completedInterviews, 
    renderInterviewSummary 
  } = props

  
   return <Grid style={{ alignItems: 'flex-start', marginTop: 10 }}>
      <TableContainer  sx={{ maxHeight: 440 }} component={Paper}>
        <Table stickyHeader style={{ minWidth: 650, }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{fontWeight: 'bold'}}>Interview Type</TableCell>
              <TableCell sx={{fontWeight: 'bold'}} align="left">Level</TableCell>
              <TableCell sx={{fontWeight: 'bold'}} align="left">Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{width: '100%'}}>
            {completedInterviews && completedInterviews.length > 0 ? renderInterviewSummary() : 
              <Stack className={styles.emptyStateWrapper}>
                <img src={MAGNIFYING_GLASS} style={{height: 45, width: 45}} />
                <Typography className={styles.emptyStateText}>No assessments to display.</Typography>
              </Stack>
            }
          </TableBody>          
        </Table>
      </TableContainer>
    </Grid>

}


CompletedInterviewsTable.propTypes = {
  completedInterviews: PropTypes.array,
  renderInterviewSummary: PropTypes.func
}