import {
 SET_ACCOUNT_DETAILS,
 SET_STRIPE_CUSTOMER_ID,
 RETRIEVING_CREDITS_FOR_ACCOUNT,
 RETRIEVED_CREDITS_FOR_ACCOUNT,
 ERROR_RETRIEVING_CREDITS_FOR_ACCOUNT,
} from '../constants/accountActionTypes'

const initialState = {
  isLoading: false,
  status: '',
  stripePriceId: '',
  stripeCustomerId: '',
  creditsRemaining: 0,
  totalCredits: 0

}

function accountReducer (state = initialState, action) {
  switch (action.type) {
    case SET_ACCOUNT_DETAILS:
      return {
        ...state,
        status: action.payload.status
      }
    case SET_STRIPE_CUSTOMER_ID:
      return {
        ...state,
        stripeCustomerId: action.payload
      }
    case  RETRIEVING_CREDITS_FOR_ACCOUNT:
      return { 
        ...state,
        isLoading: true,
      }
    case RETRIEVED_CREDITS_FOR_ACCOUNT:
      return {
        ...state,
        isLoading: false,
        creditsRemaining: action.payload.remaining,
        totalCredits: action.payload.total
      }
    case ERROR_RETRIEVING_CREDITS_FOR_ACCOUNT:
    default:
      return state
  }
}

export default accountReducer