import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFilePicker } from 'use-file-picker';
import { useParams } from 'react-router-dom'

import Cookies from 'js-cookie';

import Stack from '@mui/material/Stack'

import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

import MenuItem from '@mui/material/MenuItem';
import SidebarNav from '../../components/navigation/SidebarNav'
import Button from '@mui/material/Button'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import ResumeUploadModal from './ResumeUploadModal'
import EditEducationHistoryModal from '../../components/modals/career/EditEducationHistoryModal'
import EditJobHistoryDetailModal from '../../components/modals/career/EditJobHistoryDetailModal'
import EditProfileDetailsModal from '../../components/modals/career/EditProfileDetailsModal'
import AddExperienceModal from '../../components/modals/career/AddExperienceModal'
import AddEducationModal from '../../components/modals/career/AddEducationModal'
import ResumeHeader from '../../components/career/ResumeHeader'

import PENCIL_ICON from '../../assets/pencil-icon.png'
import MAGNIFYING_GLASS from '../../assets/sad-magnifying-glass.png'
import { colors } from '../../constants/Colors'

import { getMultipartAxiosInstance, getStreamingAttachmentAxiosInstance } from '../../utils/ApiUtils'
import { deleteUserResume, retrieveUserResumes, retrieveWorkHistory } from '../../redux/actions/userActions'
import { getResumeDate } from '../../utils/DateUtils'

import config from '../../env.json'

import {
  FileAmountLimitValidator,
  FileTypeValidator,
  FileSizeValidator,
  ImageDimensionsValidator,
} from 'use-file-picker/validators';


import styles from './UserProfileScreen.module.css'

import DOWN_TRIANGLE from '../../assets/down-triangle.png'
import UP_TRIANGLE from '../../assets/up-triangle.png'
import ResumeTile from './ResumeTile';
import ViewResumeModal from '../../components/modals/career/ViewResumeModal';
import ResumeAiModal from '../../components/modals/career/ResumeAiModal';
import DeleteConfirmationModal from '../../components/modals/billing/DeleteConfirmationModal';


export default function UserProfileScreen() {

  const { BASE_URI } = config
  const dispatch = useDispatch()

  const { userId } = useParams()

  const token = Cookies.get('token')
  const authedUserId = useSelector((state) => state.auth.id)
  const s3AvatarURI = useSelector((state) => state.user.avatarURI)
  const jobs = useSelector((state) => state.user.jobs)
  const education = useSelector((state) => state.user.education)
  const firstName = useSelector((state) => state.user.firstName)
  const lastName = useSelector((state) => state.user.lastName)
  const preferredTitle = useSelector((state) => state.user.preferredTitle)
  const resumes = useSelector((state) => state.user.resumes)

  const [editJobHistoryDetailModalOpen, setEditJobHistoryDetailModalOpen] = useState(false)
  const [editProfileDetailsModalOpen, setEditProfileDetailsModalOpen] = useState(false)
  const [editEducationHistoryModalOpen, setEditEducationHistoryModalOpen] = useState(false)
  const [addExperienceModalOpen, setAddExperienceModalOpen] = useState(false)
  const [addEducationModalOpen, setAddEducationModalOpen] = useState(false)
  const [resumeUploadModalOpen, setresumeUploadModalOpen] = useState(false)
  const [viewResumeModalVisible, setViewResumeModalVisible] = useState(false)
  const [resumeAiModalVisible, setResumeAiModalVisible] = useState(false)
  const [deleteConfirmationModalVisible, setDeleteConfirmationModalVisible] = useState(false)
  const [newCareerEntries, setNewCareerEntries] = useState([])
  const [avatarURI, setAvatarURI] = useState(null)
  const [selectedJobHistoryDetail, setSelectedJobHistoryDetail] = useState(null)
  const [selectedEducationHistoryDetail, setSelectedEducationHistoryDetail] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null);
  const [alignment, setAlignment] = useState('upload');
  const [selectedResume, setSelectedResume] = useState(null)
  const [resumeUploading, setResumeUploading] = useState(false)

  const addNewCareerEntry = (careerConfig) => {

    let currentCareerEntries = newCareerEntries
    currentCareerEntries.push(careerConfig)

    setNewCareerEntries(careerConfig)
  }

  useEffect(() => {

    if (authedUserId === userId) {
      dispatch(retrieveWorkHistory(userId, token))
      dispatch(retrieveUserResumes(authedUserId, token))

      if (s3AvatarURI) {
        const assestConfig = {
          fileType: "profile-attachment",
          path: s3AvatarURI
        }

        getStreamingAttachmentAxiosInstance(token).post(`${BASE_URI}/assets/download`, assestConfig)
          .then(response => {
            let newImageBlob = new Blob([response.data], { type: 'image/jpeg' })
            setAvatarURI(URL.createObjectURL(newImageBlob))
          })
          .catch((error) => {

          })
      }
    }

  }, [authedUserId])

  const renderEducationActivities = (activities) => {
    if (activities) {
      return activities.map((activity) => {
        return (
          <li key={activity.id}>
            <Typography>{activity.value}</Typography>
          </li>
        )
      })
    }
  }

  const renderJobDetails = (details) => {
    if (details) {
      return details.map((detail) => {
        return (
          <li key={detail.id}>
            <Typography>{detail.value}</Typography>
          </li>
        )
      })
    }
  }

  const renderJobDateDetails = (startDate, endDate, currentEmployer) => {
    return currentEmployer ? <Typography>{getResumeDate(startDate)} - Current</Typography> : <Typography>{getResumeDate(startDate)} - {getResumeDate(endDate)} </Typography>
  }

  const renderCareerHistory = () => {
    if (jobs) {
      if (jobs.length > 0) {
        return jobs.map((job) => {
          const { startDate, endDate, currentEmployer, companyName, employmentType, location, locationType, id, jobDetails, title } = job
          return (
            <Stack key={id}>
              <Stack>
                <Stack direction='row' style={{ alignItems: 'center' }}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>{title}</Typography>
                  {authedUserId === userId ? <Button
                    variant='text'
                    onClick={() => {
                      setSelectedJobHistoryDetail(job)
                      setEditJobHistoryDetailModalOpen(true)
                    }}
                  >
                    <img
                      src={PENCIL_ICON}
                      style={{ height: 20, width: 20, backgroundColor: 'white', borderRadius: 5, height: 30, width: 30, padding: 5 }} />
                  </Button> : null}

                </Stack>
                <Typography style={{ color: colors.plum, fontSize: 16, fontWeight: '700' }}>
                  {companyName}
                  <span>{location ? <span>&nbsp; &#8226; {location}</span> : null}</span>
                  <span>{employmentType ? <span>&nbsp; &#8226; {employmentType}</span> : null}</span>
                  <span>{locationType ? <span>&nbsp; &#8226; {locationType} &nbsp;</span> : null}</span>
                </Typography>
                <Typography> {renderJobDateDetails(startDate, endDate, currentEmployer)} </Typography>
              </Stack>
              <Stack style={{ paddingLeft: 10 }}>
                <ul>
                  {jobDetails ? renderJobDetails(jobDetails) : null}
                </ul>
              </Stack>

            </Stack>
          )
        })
      }
    }
  }


  const renderEducationHistory = () => {
    if (education) {
      if (education.length > 0) {
        return education.map((educationEntry) => {
          const { id, school, degree, fieldOfStudy, attendanceType, startDate, endDate, grade, activities } = educationEntry
          return (
            <Stack key={id}>
              <Stack>
                <Stack direction='row' style={{ alignItems: 'center' }}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>{school}</Typography>
                  {authedUserId === userId ? <Button
                    variant='text'
                    onClick={() => {
                      setSelectedEducationHistoryDetail(educationEntry)
                      setEditEducationHistoryModalOpen(true)
                    }}
                  >
                    <img
                      src={PENCIL_ICON}
                      style={{ height: 20, width: 20, backgroundColor: 'white', borderRadius: 5, height: 30, width: 30, padding: 5 }} />
                  </Button> : null}

                </Stack>
                <Typography style={{ color: colors.plum, fontSize: 16, fontWeight: '700' }}>
                  {degree}
                  <span>{fieldOfStudy ? <span>&nbsp; &#8226; {fieldOfStudy}</span> : null}</span>
                  <span>{attendanceType ? <span>&nbsp; &#8226; {attendanceType}</span> : null}</span>
                </Typography>
                {grade ? <Typography>GPA: {grade ? parseFloat(grade).toFixed(1) : null}</Typography> : null}
                <Typography>{getResumeDate(startDate)} - {getResumeDate(endDate)}</Typography>
              </Stack>
              <Stack style={{ paddingLeft: 10 }}>
                <ul>
                  {activities ? renderEducationActivities(activities) : null}
                </ul>
              </Stack>
            </Stack>
          )
        })
      }
    }
  }

  const { openFilePicker, filesContent, loading, errors } = useFilePicker({
    readAs: 'DataURL',
    accept: ['.pdf', '.docx'],
    multiple: false,
    onFilesRejected: ({ errors }) => {
      // this callback is called when there were validation errors
      // console.log('onFilesRejected', errors);
    },
    onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
      // this callback is called when there were no validation errors
      // console.log('onFilesSuccessfullySelected', plainFiles);
      // setUpdatedAvatarSelected(true)
      // console.log('Files content: ', filesContent[0])
    },
    validators: [
      new FileAmountLimitValidator({ max: 1 }),
      new FileTypeValidator(['pdf', 'docx']),
      new FileSizeValidator({ maxFileSize: 50 * 1024 * 1024 /* 50 MB */ }),
      new ImageDimensionsValidator({
        maxHeight: 1024, // in pixels
        maxWidth: 1024,
        minHeight: 400,
        minWidth: 400,
      }),
    ],
  });

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }


  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderProfileEditActions = () => {
    if (authedUserId === userId) {
      return (
        <Stack className={styles.actionButtonWrapper}>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            className={styles.resumeActionButtonStyle}
          >
            <Stack direction='row' className={styles.buttonItems}>
              <Typography>Resume Actions</Typography>
              {open ? <img src={UP_TRIANGLE} className={styles.triangleIcon} /> : <img src={DOWN_TRIANGLE} className={styles.triangleIcon} />}

            </Stack>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              onClick={() => {
                setresumeUploadModalOpen(true)
                handleClose()
              }}
            >Upload Resume</MenuItem>
            <MenuItem
              onClick={() => {
                setAddExperienceModalOpen(true)
                handleClose()
              }}>Add Work History</MenuItem>
            <MenuItem
              onClick={() => {
                setAddEducationModalOpen(true)
                handleClose()
              }}>Add Education History</MenuItem>
          </Menu>
        </Stack>
      )
    }

    return <Stack direction='row' style={{ margin: 30, width: 420, height: 50 }} />
  }

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const renderResumes = () => {
    if (resumes) {
      if (resumes.length > 0) {
        return resumes.map((resume) => {
          return <ResumeTile resume={resume} setSelectedResume={setSelectedResume} openModal={setViewResumeModalVisible} setResumeAiModalVisible={setResumeAiModalVisible} setDeleteModalVisible={setDeleteConfirmationModalVisible} />
        })
      }
      else {
        return (
          <Stack className={styles.emptyStateWrapper}>
            <img src={MAGNIFYING_GLASS} style={{ height: 45, width: 45 }} />
            <Typography className={styles.emptyStateText}>No resumes found. </Typography>
          </Stack>
        )
      }
    }

  }

  const renderResumeUploads = () => {

    return (
      <Stack className={styles.uploadsWrapper}>
        <Stack direction='row' className={styles.resumeUploadWrapper}>
          <Typography className={styles.headerText}>RESUMES</Typography>
          <Stack
            className={styles.resumeActionButtonStyle}
            onClick={() => setresumeUploadModalOpen(true)}
          >
            <Typography className={styles.resumeButtonText}>ADD RESUME</Typography>
          </Stack>
        </Stack>
        <Stack className={styles.resumeWrapper}>
          {resumeUploading ? (
             <Stack sx={{ color: 'grey.500', margin: 2 }} spacing={2}>
                <LinearProgress color="secondary" />
             <Typography style={{color: colors.plum, paddingTop: 10}}>We're generating your document! We'll be just another moment...</Typography>
           </Stack>
          ) : null}
          {renderResumes()}
        </Stack>
      </Stack>
    )
  }

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <SidebarNav />
      <Stack style={{ width: '100%', paddingBottom: 80, paddingTop: 30, paddingLeft: 40 }}>
        <Stack className={styles.helperTextWrapper}>
          <Typography className={styles.helperText}>
            Manage your resumes here. We'll use your resume as additional inputs while evaluating your interview responses.
          </Typography>
        </Stack>
        <hr className={styles.horizontalLine} />
        {/* {renderProfileEditActions()} */}

        {/* <Typography>RESUMES</Typography>
        <Stack>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value="upload">Uploads</ToggleButton>
            <ToggleButton value="manual">Manual Input</ToggleButton>
          </ToggleButtonGroup>
        </Stack> */}

        <Stack style={{ width: '80%', border: '1px solid silver', borderRadius: 10 }}>
          <ResumeHeader
            showModal={() => setEditProfileDetailsModalOpen(true)}
            avatarURI={avatarURI}
            firstName={firstName}
            lastName={lastName}
            preferredTitle={preferredTitle}
            userProfileId={userId}
            authedUserId={authedUserId}
          />
          {alignment === 'upload' ? renderResumeUploads() : (
            <Stack style={{ marginTop: 20, marginLeft: 50, height: '100%', paddingBottom: 50 }} direction='row'>
              <Stack>
                <Stack style={{ marginTop: 50, paddingLeft: 30 }}>
                  <Typography style={{ fontSize: 28, textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5 }}>
                    CAREER
                  </Typography>
                </Stack>

                <Stack style={{ width: '80%', marginTop: 20, paddingLeft: 30 }}>
                  {renderCareerHistory()}
                </Stack>
                <Stack style={{ marginTop: 20, paddingLeft: 30 }}>
                  <Typography style={{ fontSize: 28, textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5 }}>EDUCATION</Typography>
                </Stack>
                <Stack style={{ width: '80%', marginTop: 20, paddingLeft: 30 }}>
                  {renderEducationHistory()}
                </Stack>
              </Stack>
              {/* Save this code to display interviews users have selected to display to recruiters */}
              {/* <Stack style={{width: '20%', height: '100%', paddingLeft: 20 }}>
              <Typography style={{ textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5, fontSize: 20 }}>FEATURED INTERVIEWS</Typography>
              <Stack style={{ justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
                <Box style={{ height: 200, width: 200, border: '1px solid silver', borderRadius: 10, margin: 20 }} />
                <Box style={{ height: 200, width: 200, border: '1px solid silver', borderRadius: 10, margin: 20 }} />
                <Box style={{ height: 200, width: 200, border: '1px solid silver', borderRadius: 10, margin: 20 }} />
                <Box style={{ height: 200, width: 200, border: '1px solid silver', borderRadius: 10, margin: 20 }} />
              </Stack>
            </Stack> */}
            </Stack>
          )}
        </Stack>




      </Stack>
      <EditJobHistoryDetailModal
        isVisible={editJobHistoryDetailModalOpen}
        handleClose={() => setEditJobHistoryDetailModalOpen(false)}
        selectedJobHistoryDetail={selectedJobHistoryDetail || null}
        jobs={jobs}

      />
      <EditEducationHistoryModal
        isVisible={editEducationHistoryModalOpen}
        handleClose={() => setEditEducationHistoryModalOpen(false)}
        selectedEducationHistoryDetail={selectedEducationHistoryDetail}
      />
      <EditProfileDetailsModal
        isVisible={editProfileDetailsModalOpen}
        handleClose={() => setEditProfileDetailsModalOpen(false)}
        avatarURI={avatarURI}
        firstName={firstName}
        lastName={lastName}
        preferredTitle={preferredTitle}
      />
      <AddExperienceModal
        isVisible={addExperienceModalOpen}
        handleClose={() => setAddExperienceModalOpen(false)}
      />
      <AddEducationModal
        isVisible={addEducationModalOpen}
        handleClose={() => setAddEducationModalOpen(false)}
      />
      <ResumeUploadModal
        isVisible={resumeUploadModalOpen}
        handleClose={() => setresumeUploadModalOpen(false)}
      />
      <ViewResumeModal
        isVisible={viewResumeModalVisible}
        handleClose={() => setViewResumeModalVisible(false)}
        attachmentURI={selectedResume ? selectedResume.attachmentURI : null}
        fileExtension={selectedResume ? selectedResume.fileExtension : null}
        setSelectedResume={setSelectedResume}
      />
      <ResumeAiModal
        isVisible={resumeAiModalVisible}
        handleClose={() => setResumeAiModalVisible(false)}
        resume={selectedResume}
        triggerAction={() => dispatch(deleteUserResume(userId, selectedResume.id, token, resumes, setDeleteConfirmationModalVisible))}
        modalBody={selectedResume ? `Are you sure you want to delete the resume named: ${selectedResume.title}?` : null}
        triggerText='DELETE'
        setResumeUploading={setResumeUploading}
      />
      <DeleteConfirmationModal
        isVisible={deleteConfirmationModalVisible}
        handleClose={() => setDeleteConfirmationModalVisible(false)}
        triggerAction={() => dispatch(deleteUserResume(userId, selectedResume.id, token, resumes, setDeleteConfirmationModalVisible))}
        modalBody={selectedResume ? `Are you sure you want to delete the resume named: ${selectedResume.title}?` : null}
        triggerText='DELETE'
      />
    </div>
  )
}
