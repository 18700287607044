export const CREATING_ORG_ACCOUNT = 'CREATING_ORG_ACCOUNT'
export const CREATED_ORG_ACCOUNT = 'CREATED_ORG_ACCOUNT'
export const ERROR_CREATING_ORG_ACCOUNT = 'ERROR_CREATING_ORG_ACCOUNT'

export const SET_ORGANIZATION_ID = 'SET_ORGANIZATION_ID'

export const CREATING_COHORT = 'CREATING_COHORT'
export const CREATED_COHORT = 'CREATED_COHORT'
export const ERROR_CREATING_COHORT = 'ERROR_CREATING_COHORT'

export const RETRIEVING_COHORTS = 'RETRIEVING_COHORTS'
export const RETRIEVED_COHORTS = 'RETRIEVED_COHORTS'
export const ERROR_RETRIEVING_COHORTS = 'ERROR_RETRIEVING_COHORTS'

export const RETRIEVING_COHORT = 'RETRIEVING_COHORT'
export const RETRIEVED_COHORT = 'RETRIEVED_COHORT'
export const ERROR_RETRIEVING_COHORT = 'ERROR_RETRIEVING_COHORT'

export const CREATING_GROUP = 'CREATING_GROUP'
export const CREATED_GROUP = 'CREATED_GROUP'
export const ERROR_CREATING_GROUP = 'ERROR_CREATING_GROUP'

export const RETRIEVING_GROUPS = 'RETRIEVING_GROUPS'
export const RETRIEVED_GROUPS = 'RETRIEVED_GROUPS'
export const ERROR_RETRIEVING_GROUPS = 'ERROR_RETRIEVING_GROUPS'

export const RETRIEVING_ALL_USERS_FOR_ORG = 'RETRIEVING_ALL_USERS_FOR_ORG'
export const RETRIEVED_ALL_USERS_FOR_ORG = 'RETRIEVED_ALL_USERS_FOR_ORG'
export const ERROR_RETRIEVING_ALL_USERS_FOR_ORG = 'ERROR_RETRIEVING_ALL_USERS_FOR_ORG'

export const ADDING_SEAT_TO_SUBSCRIPTION = 'ADDING_SEAT_TO_SUBSCRIPTION'
export const ADDED_SEAT_TO_SUBSCRIPTION = 'ADDED_SEAT_TO_SUBSCRIPTION'
export const ERROR_ADDING_SEAT_TO_SUBSCRIPTION = 'ERROR_ADDING_SEAT_TO_SUBSCRIPTION'

export const CANCELLING_SUBSCRIPTION = 'CANCELLING_SUBSCRIPTION'
export const CANCELLED_SUBSCRIPTION = 'CANCELLED_SUBSCRIPTION'
export const ERROR_CANCELLING_SUBSCRIPTION = 'ERROR_CANCELLING_SUBSCRIPTION'

export const CHECKING_SUBSCRIPTION_ON_ACCOUNT = 'CHECKING_SUBSCRIPTION_ON_ACCOUNT'
export const CHECKED_SUBSCRIPTION_ON_ACCOUNT = 'CHECKED_SUBSCRIPTION_ON_ACCOUNT'
export const ERROR_CHECKING_SUBSCRIPTION_ON_ACCOUNT = 'ERROR_CHECKING_SUBSCRIPTION_ON_ACCOUNT'

export const UPDATING_COHORT_DETAILS = 'UPDATING_COHORT_DETAILS'
export const UPDATED_COHORT_DETAILS = 'UPDATED_COHORT_DETAILS'
export const ERROR_UPDATING_COHORT_DETAILS = 'ERROR_UPDATING_COHORT'

export const REMOVING_MEMBER_FROM_TEAM_ACCOUNT = 'REMOVING_MEMBER_FROM_TEAM_ACCOUNT'
export const REMOVED_MEMBER_FROM_TEAM_ACCOUNT = 'REMOVED_MEMBER_FROM_TEAM_ACCOUNT'
export const ERROR_REMOVING_MEMBER_FROM_TEAM_ACCOUNT = 'ERROR_REMOVING_MEMBER_FROM_TEAM_ACCOUNT'