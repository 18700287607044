import React, { useEffect } from 'react';
import { Routes, Route, NavLink, Navigate, useNavigate, } from 'react-router-dom';
import Cookies from 'js-cookie';
import "./App.css";

import AuthProvider from "./hooks/AuthProvider";


import ActiveInterviewScreen from "./screens/interviews/ActiveInterviewScreen";
import SignUpScreen from "./screens/authentication/SignUpScreen";
import LoginScreen from "./screens/authentication/LoginScreen";

import InterviewsHomeScreen from './screens/interviews/InterviewsHomeScreen';
import CheckInsHomeScreen from './screens/checkIns/CheckInsHomeScreen';
import CreateCheckInScreen from './screens/checkIns/CreateCheckInScreen';
import IndividualSignUpScreen from './screens/authentication/IndividualSignUpScreen';
import OrganizationSignUpScreen from './screens/authentication/OrganizationSignUpScreen';
import Dashboard from './screens/dashboard/Dashboard';
import TeamManagementScreen from './screens/org/TeamManagementScreen';
import CreateCohortScreen from './screens/org/CreateCohortScreen';
import ViewCohortScreen from './screens/org/ViewCohortScreen';
import CompleteRegistrationScreen from './screens/authentication/CompleteRegistrationScreen';
import ViewCompleteCheckIn from './screens/checkIns/ViewCompletedCheckIn';
import ViewUserScreen from './screens/org/ViewUserScreen';
import CheckOutScreen from './screens/authentication/CheckOutScreen';
import SettingsScreen from './screens/settings/SettingsScreen';
import InterviewSummaryScreen from './screens/interviews/InterviewSummaryScreen';
import BillingHomeScreen from './screens/billing/BillingHomeScreen';
import AdminHomeScreen from './screens/admin/AdminHomeScreen';
import UserProfileScreen from './screens/profile/UserProfileScreen';
import SoloPlanPricingPage from './screens/authentication/SoloPlanPricingPage';
import HelpScreen from './screens/help/HelpScreen';
import ErrorScreen from './screens/error/ErrorScreen';
import SoloAccountTokenValidationScreen from './screens/authentication/SoloAccountTokenValidationScreen';
import AssessmentAdminHomeScreen from './screens/assessments/AssessmentAdminHomeScreen';
import CreateMockInterviewAssessmentScreen from './screens/assessments/CreateMockInterviewAssessmentScreen';
import EditInterviewAssessmentScreen from './screens/assessments/EditInterviewAssessmentScreen';
import PasswordResetNotificationScreen from './screens/authentication/PasswordResetNotificationScreen';
import CreateNewPasswordScreen from './screens/authentication/CreateNewPasswordScreen';
import AdminInterviewsHomeScreen from './screens/interviews/AdminInterviewHomeScreen';
import ImpersonateActiveInterviewScreen from './screens/interviews/ImpersonateActiveInterviewScreen';



function RequireAuth({ children, redirectTo, token, planType, role }) {
  let isAuthenticated = token != null
  return isAuthenticated ? children : <Navigate to={redirectTo="/login"} />;
}

function App() {
  const navigate = useNavigate()

  const token = Cookies.get('token');


  const handleLogin = (planType, role) => {
    
    if (token == null) {
      navigate('../login', { replace: true})
    }
    
    if (planType && role) {
      if (planType == 'TEAM' && role == 'ADMIN') {
          navigate('../org', {replace: true})
      }
      else if (planType == 'TEAM' && (role == 'FACULTY' || role == 'STAFF')) {
        navigate('../org', {replace: true})
      }
      else if (planType == 'TEAM' && role == 'STUDENT') {
          navigate('../interviews', {replace: true})
      }
      else if (planType == 'INDIVIDUAL' && role == 'ADMIN') {
        navigate('../interviews', { replace: true})
      }
      else if (planType == 'INDIVIDUAL' && role == 'SUPER_ADMIN') {
        navigate('../interviews', {replace: true})
      }
    }
  };


 
  return (
    <AuthProvider>
      <Routes>
         {/* <Route
          path="/"
          element={
            <RequireAuth redirectTo="/login" token>
              <LoginScreen />
            </RequireAuth>
          }
        /> */}
        <Route path="/login" element={<LoginScreen onLogin={handleLogin}  />} />
        <Route path="/signup/:userId/:registrationToken" element={<CompleteRegistrationScreen onLogin={handleLogin} />} />
        <Route path="/signup" element={<SignUpScreen />}  />
        <Route path='/payment' element={<CheckOutScreen />} />
        <Route path='/plans/solo' element={<SoloPlanPricingPage />} />
        <Route path="/signup/individual" element={<IndividualSignUpScreen />} />
        <Route path="/signup/organization" element={<OrganizationSignUpScreen />} />
        <Route path='/?success=true' element={<LoginScreen /> } />
        <Route path='/reset/password' element={<PasswordResetNotificationScreen />} />
        <Route path='/new/password' element={<CreateNewPasswordScreen />} />
        <Route path="/verify/:userId" element={<SoloAccountTokenValidationScreen onLogin={handleLogin} />}  />
        <Route path="*" element={<ErrorScreen />} />
        <Route
          path='/'
          element={
            <RequireAuth redirectTo="/login" token>
              <InterviewsHomeScreen />
            </RequireAuth>
          }
        />
        <Route
          path='/interviews'
          element={
            <RequireAuth redirectTo="/login" token>
              <InterviewsHomeScreen />
            </RequireAuth>
          }
        />
        <Route
          path='/interview/:id'
          element={
            <RequireAuth redirectTo="/login" token>
              <ActiveInterviewScreen />
            </RequireAuth>
          }
        />

        <Route
          path='/checkins'
          element={
            <RequireAuth redirectTo="/login" token>
              <CheckInsHomeScreen />
            </RequireAuth>
          }
        />
         <Route
          path='/assessments/new/interview'
          element={
            <RequireAuth redirectTo="/login" token>
              <CreateMockInterviewAssessmentScreen />
            </RequireAuth>
          }
        />
        <Route
          path='/checkins/new/:id'
          element={
            <RequireAuth redirectTo="/login" token>
              <CreateCheckInScreen />
            </RequireAuth>
          }
        />
        <Route
          path='/checkins/:id'
          element={
            <RequireAuth redirectTo="/login" token> 
              <ViewCompleteCheckIn />
            </RequireAuth>
          }
        />
        <Route
          path='/org'
          element={
            <RequireAuth redirectTo="/login" token>
              <TeamManagementScreen />
            </RequireAuth>
          }
        />
         <Route
          path='/assessments/admin'
          element={
            <RequireAuth redirectTo="/login" token>
              <AssessmentAdminHomeScreen />
            </RequireAuth>
          }
        />

        <Route
          path='/assessments/interviews/:interviewAssessmentId'
          element={
            <RequireAuth redirectTo="/login" token>
              <EditInterviewAssessmentScreen />
            </RequireAuth>
          }
        />
        <Route
          path='/org/cohort/new'
          element={
            <RequireAuth redirectTo="/login" token>
              <CreateCohortScreen />
            </RequireAuth>
          }
        />
        <Route
          path='/org/student/:studentId'
          element={
            <RequireAuth redirectTo="/login" token>
              <ViewUserScreen />
            </RequireAuth>
          }
        />
        <Route
          path='/org/cohort/:id'
          element={
            <RequireAuth redirectTo="/login" token>
              <ViewCohortScreen />
            </RequireAuth>
          }
        />
        <Route
          path='/resume/:userId'
          element={
            <RequireAuth redirectTo="/login" token>
              <UserProfileScreen />
            </RequireAuth>
          }
        />
        
        <Route
          path="/settings"
          element={
            <RequireAuth redirectTo="/login" token>
              <SettingsScreen />
            </RequireAuth>
          }
        />

        <Route
          path='/interview/summary/:id'
          element={
            <RequireAuth redirectTo="/login" token>
              <InterviewSummaryScreen />
            </RequireAuth>
          }
        />
        <Route
          path='/billing'
          element={
            <RequireAuth redirectTo="/login" token>
              <BillingHomeScreen />
            </RequireAuth>
          }
        />

        <Route
          path='/help'
          element={
            <RequireAuth redirectTo="/login" token>
              <HelpScreen />
            </RequireAuth>
          }
        />


        <Route
          path='/admin'
          element={
            <RequireAuth redirectTo="/login" token>
              <AdminHomeScreen />
            </RequireAuth>
          }
        />

        <Route
          path='/admin/impersonate'
          element={
            <RequireAuth redirectTo="/login" token>
              <AdminInterviewsHomeScreen />
            </RequireAuth>
          }
        />


        <Route
          path='/impersonate/interview/:id'
          element={
            <RequireAuth redirectTo="/login" token>
              <ImpersonateActiveInterviewScreen />
            </RequireAuth>
          }
        />


      </Routes>


    </AuthProvider>




  );
}

export default App;






