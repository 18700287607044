import React, { useState, useEffect } from 'react'
import SidebarNav from "../../components/navigation/SidebarNav"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import Cookies from 'js-cookie';

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table';
import Button from '@mui/material/Button'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CreateIcon from '@mui/icons-material/Create';
import BarChart from '../../components/charts/BarChart';
import { getAxiosInstance } from '../../utils/ApiUtils'
import { getAbbreviateMonthAndDay, getCalendarDate, getFormattedDate } from '../../utils/DateUtils'
import { colors } from '../../constants/Colors';


import CompletedInterviewsTable from '../../components/interviews/CompletedInterviewsTable';

import config from '../../env.json'
import StandardButton from '../../components/buttons/StandardButton';
import DeleteConfirmationModal from '../../components/modals/billing/DeleteConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import { removeMemberFromTeamAccount } from '../../redux/actions/organizationActions';



export default function ViewUserScreen() {

  const {BASE_URI} = config
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const token = Cookies.get('token')

  
  const { studentId } = useParams()
  const { id, email, firstName, lastName } = location.state

  const authedUserId = useSelector((state) => state.auth.id)
  const teamUsers = useSelector((state) => state.org.users)
  const organizationId = useSelector((state) => state.org.id)

  const [userCheckIns, setUserCheckIns] = useState([])
  const [chartData, setChartData] = useState([])
  const [chartLabels, setChartLabels] = useState([])
  const [completedInterviews, setCompletedInterviews] = useState([])
  const [deleteConfirmationModalVisible, setDeleteConfirmationModalVisible] = useState(false)

  useEffect(() => {

    getAxiosInstance(token).get(`${BASE_URI}/checkIns/user/${studentId}`)
      .then(res => {
        if (res.status === 200) {
          setUserCheckIns(res.data)
          parseCheckInSummaries(res.data)
        }
      })
      .catch(err => {

      })

      getAxiosInstance(token).get(`${BASE_URI}/interviews/completed/${studentId}`)
      .then((res) => setCompletedInterviews(res.data))
      .catch((error) => {

      })


  }, [studentId])


  const parseCheckInSummaries = (summaries) => {
    let labels = []
    let sentiments = []

    if (summaries.length > 0) {
      summaries.map((checkIn) => {

        labels.push(getAbbreviateMonthAndDay(checkIn.dueDate))
        sentiments.push(checkIn.sentiment ? checkIn.sentiment : 0)
      })

      setChartLabels(labels)
      setChartData(sentiments)


    }
  }

  const parseCompletedCheckIns = (userCheckIns) => {
    let completdCheckIns = []
    if (userCheckIns) {
      completdCheckIns = userCheckIns.filter(checkIn => checkIn.checkInStatus === 'COMPLETE')
    }

    return completdCheckIns
  }

  const renderCompletedCheckIns = () => {

    let completedCheckIns = parseCompletedCheckIns(userCheckIns)

    if (completedCheckIns.length > 0) {
      return userCheckIns.map((checkIn) => {
        if (checkIn.checkInStatus === 'COMPLETE') {
          return (
            <TableRow
              key={checkIn.id}
              style={{ width: '70%' }}
              hover
              onClick={() => navigate(`/checkins/${checkIn.id}`, { state: { checkIn } })}
            >
              <TableCell align="center">{getCalendarDate(checkIn.dueDate)}</TableCell>
              <TableCell align="center">{checkIn.sentiment}</TableCell>
              <TableCell align="center">{checkIn.inputs ? checkIn.inputs.length : 0}</TableCell>
              <TableCell align="center">{checkIn.highFives ? checkIn.highFives.length : 0}</TableCell>
            </TableRow>
          )
        }
      })
    }
    else {
      return <Typography style={{ paddingLeft: 40, paddingTop: 20 }}>No check-ins completed.</Typography>
    }
  }

  const headerStyle = {
    fontWeight: 'bold',
    fontSize: 16,
    color: colors.plum,
  }

  function renderInterviewSummary() {
    if (completedInterviews) {
      return completedInterviews.map((completedInterview) => {
        if (completedInterview) {
          return (
            <TableRow
              key={completedInterview.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Button
                  variant='text' style={{ color: colors.blazeRed }}
                  onClick={() => navigate(`/interview/summary/${completedInterview.id}`)}
                >
                  {completedInterview.interviewType}
                </Button>
              </TableCell>
              <TableCell align="Left">{getFormattedDate(completedInterview.interviewDate)}</TableCell>
            </TableRow>
          )
        }
      })
    }
  }

  const deleteCohortMember = () => {
    const memberConfig = {
      id: authedUserId,
      email: email,
      organizationId: organizationId
    }

    dispatch(removeMemberFromTeamAccount(memberConfig, token, teamUsers, setDeleteConfirmationModalVisible, null, () => navigate(-1) ))
    
  }



  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <SidebarNav />
      <Stack style={{ marginTop: 50, width: '100%', height: 500, marginLeft: 50, marginRight: 50, }}>
        <Stack direction='row' style={{ height: 60, padding: 10, marginBottom: 20, alignItems: 'center' }}>
          <span style={{ fontWeight: 'bold', color: colors.plum, paddingRight: 10 }}>COHORT MEMBER | </span>
          <Typography style={{ fontSize: 20, fontWeight: '500' }}>{firstName || lastName ? `${firstName} ${lastName}` : email}</Typography>
          <Button
              variant='text'
              onClick={() => {
               
              }}
            >
              <CreateIcon
                style={{ color: colors.plum }}
              />
            </Button>
        </Stack>
        <Stack style={{ width: '70%' }}>
          <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4, paddingBottom: 20 }}>CHECK-IN SENTIMENT BY WEEK</Typography>
          <BarChart
            chartData={chartData}
            chartLabels={chartLabels}
          />
        </Stack>

        <Stack style={{ width: '100%', marginTop: 50 }}>
          <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4, paddingBottom: 20 }}>WEEKLY CHECK-INS</Typography>
          <TableContainer >
            <Table style={{ width: '70%' }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ width: '70%' }}>
                  <TableCell component="th" scope="row" align="center"> <Typography style={headerStyle}>Due Date</Typography></TableCell>
                  <TableCell align="center"> <Typography style={headerStyle}>Sentiment</Typography></TableCell>
                  <TableCell align="center"> <Typography style={headerStyle}>Details</Typography></TableCell>
                  <TableCell align="center"> <Typography style={headerStyle}>High Fives</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderCompletedCheckIns()}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        <Stack style={{ marginTop: 50, paddingBottom: 10, width: '70%' }}>
          <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4, paddingBottom: 20 }}>RECENT INTERVIEWS</Typography>
          <CompletedInterviewsTable 
            completedInterviews={completedInterviews}
            renderInterviewSummary={renderInterviewSummary}
          />
        </Stack>
        <Stack style={{height: 120, paddingBottom: 50}} >
        <hr />
        <Typography 
            style={{textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5, fontSize: 20, paddingBottom: 15}}>
            User Actions
          </Typography>
          <Typography>Removing the user will immediately remove them from your organization and increase your overall seat count.</Typography>
          <Stack style={{marginTop: 20, paddingBottom: 70}}>
               <StandardButton 
            label='REMOVE MEMBER'
            labelColor={colors.blazeRed}
            buttonWidth={250}
            onPress={() => setDeleteConfirmationModalVisible(true)}
          />
          </Stack>
        </Stack>
        <DeleteConfirmationModal 
          isVisible={deleteConfirmationModalVisible}
          ba
          handleClose={() => setDeleteConfirmationModalVisible(false)}
          triggerAction={() => deleteCohortMember()}
          modalBody='Are you sure you want to remove this user?'
          triggerText='REMOVE'
        />
      </Stack>
    </div>
  )
}