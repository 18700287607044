import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';

import Backdrop from "@mui/material/Backdrop";

import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";

import Stack from "@mui/material/Stack";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box'

import StandardButton from "../../buttons/StandardButton";
import { colors } from "../../../constants/Colors";

import { PropTypes } from 'prop-types'
import { editInterviewQuestion } from '../../../redux/actions/interviewActions';

export default function EditInterviewQuestionModal(props) {

  const dispatch = useDispatch()

  const [updatedQuestionText, setUpdatedQuestionText] = useState('')
  const [updatedQuestionURI, setUpdatedQuestionURI] = useState('')
  const [updatedFocusArea, setUpdatedFocusArea] = useState('')

  const {
    open,
    handleClose,
    currentFocusArea,
    currentInterviewQuestion,
    renderFocusAreas,
    focusAreas,
    token,
    adminInterviewQuestions
  } = props

  useEffect(() => {
    if (currentFocusArea !== undefined && focusAreas.length > 0) {
      const selectedFocusArea = (element) => element.name == currentFocusArea;  
      setUpdatedFocusArea(focusAreas[focusAreas.findIndex(selectedFocusArea)].id)
    }

    
  }, [focusAreas])

  const handleFocusAreaChange = (event) => {
    const {
      target: { value },
    } = event;
    setUpdatedFocusArea(value)
  }

  const updateInterviewQuestionClicked = () => {
    const interviewQuestionConfig = {
      id: currentInterviewQuestion.id,
      type: currentInterviewQuestion.type.toLowerCase(),
      role: currentInterviewQuestion.role,
      question: updatedQuestionText,
      questionURI: updatedQuestionURI,
      focusArea: updatedFocusArea
    }
    
    dispatch(editInterviewQuestion(interviewQuestionConfig, token, adminInterviewQuestions, handleClose))
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {
        setUpdatedFocusArea('')
        setUpdatedQuestionText('')
        setUpdatedQuestionURI('')
        handleClose()

      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Stack style={{ height: '60%' }}>
          <Stack>
          <Typography style={{ paddingBottom: 10 }}>Focus Area</Typography>
            <Select
              id="focus-area-select"
              value={updatedFocusArea}
              onChange={(event) => handleFocusAreaChange(event)}
            >
              {renderFocusAreas()}
            </Select>
          </Stack>
            <Stack>
            <Typography style={{ paddingBottom: 10 }}>Interview Question</Typography>
              <TextField
                onChange={(event) => setUpdatedQuestionText(event.target.value)}
                id="outlined-multiline-static"
                multiline
                maxRows={2}
                minRows={1}
                fullWidth={true}
                placeholder={currentInterviewQuestion ? currentInterviewQuestion.question : null}
                value={updatedQuestionText}

              />
            </Stack>
            <Stack>
            <Typography style={{ paddingBottom: 10 }}>Interiew Question URL</Typography>
              <TextField
                onChange={(event) => setUpdatedQuestionURI(event.target.value)}
                id="outlined-multiline-static"
                multiline
                maxRows={2}
                minRows={1}
                fullWidth={true}
                placeholder={currentInterviewQuestion? currentInterviewQuestion.questionURI : null}
                value={updatedQuestionURI}
              />
            </Stack>
          </Stack>
          <Stack style={{ justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
            <StandardButton
              label={'SAVE'}
              buttonWidth={'100%'}
              onPress={() => updateInterviewQuestionClicked()}
              backgroundColor={colors.slateBlue}
            />
          </Stack>
        </Box>
      </Fade>
    </Modal>
  )
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: '80vh',
  width: 850,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

EditInterviewQuestionModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleChange: PropTypes.func,
  currentFocusArea: PropTypes.string,
  currentInterviewQuestion: PropTypes.string,
  currentInterviewQuestionURI: PropTypes.string,
  renderFocusAreas: PropTypes.func,
  token: PropTypes.string,
  adminInterviewQuestions: PropTypes.array,
};
