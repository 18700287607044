import React from "react";
import { useNavigate } from "react-router-dom";

import Cookies from 'js-cookie';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import INSTITUTION_ICON from '../../assets/institution-icon.png'

import { colors } from "../../constants/Colors";
import StandardButton from "../../components/buttons/StandardButton";

import INTERVIEWABLE_LOGO from '../../assets/interviewable-logo.png'
import { getAxiosInstance } from "../../utils/ApiUtils";

import { useSelector } from "react-redux";
import config from '../../env.json'
const { BASE_URI } = config

export default function SoloPlanPricingPage() {

  const SOLO_STARTER_ID_DEV = 'price_1OFK9PFmIdBJHCdMjDyWEQRk'
  const SOLO_GROWTH_ID_DEV = 'price_1OFKAvFmIdBJHCdMrHKRTP51'
  const SOLO_PRO_ID_DEV = 'price_1OFKDSFmIdBJHCdMsdSZR1uH'


  const navigate = useNavigate()

  const token = Cookies.get('token')
  const stripeCustomerId = useSelector(state => state.account.stripeCustomerId)
  const email = useSelector((state) => state.auth.email)

  async function handleCheckout(priceId) {
    let planType = 'SOLO'
    getAxiosInstance(token).post(`${BASE_URI}/payments/createCheckoutSession/${priceId}/${email}/${stripeCustomerId}/${planType}`)
    .then((res) => {
      if (res.status == 200) { 
        window.location.href = res.data;
      }
    })
   
  }

  return (
    <div style={{ marginTop: 30, paddingBottom: 60, }}>
      
      <img src={INTERVIEWABLE_LOGO} style={{ height: 50, marginLeft: 20 }} />
      
      <div style={{  display: 'flex',justifyContent: 'center', alignItems: 'center', marginTop: 50 }}>
      <Stack className="flex-xl-nowrap">
        <Box>
          <Stack direction='row'>
            <Stack
              style={{ height: 500, width: 350, boxShadow: '1px 2px 5px black', border: '1px solid silver', margin: 10, flexDirection: 'column', borderRadius: 5, padding: 15 }}
              variant='outlined'
            >
              <Stack style={{ height: 180 }}>
                <Stack style={{ borderWidth: 1, padding: 10, borderColor: 'black', border: '1px solid silver', borderRadius: 5, height: 30, width: 80, justifyContent: 'center', alignItems: 'center', backgroundColor: 'silver' }}>
                  <Typography style={{ fontWeight: '500', fontSize: 14, color: colors.coolBlack }}>
                    STARTER
                  </Typography>

                </Stack>

                <Typography style={{ fontWeight: '500', fontSize: 50, color: 'black' }}>
                  $12
                </Typography>
                <Typography style={{ fontWeight: '300', fontSize: 18, color: 'black' }}>
                  per user per month
                </Typography>
                <Typography style={{ fontWeight: '300', fontSize: 18, color: 'black' }}>
                  Knock off the interview rust with a quick interview refresher to assess your readiness.
                </Typography>
              </Stack>
              <Stack style={{ marginTop: 50, justifyContent: 'center', alignItems: 'center' }}>
                <Button 
                  style={{width: '80%', borderRadius: 15, backgroundColor: colors.navyBlue}}
                  onClick={() => handleCheckout(SOLO_STARTER_ID_DEV)}  
                >
                  <Typography style={{color: 'white', fontWeight: 'bold'}}>BUY NOW</Typography>
                </Button>
               
              </Stack>
              <Stack style={{ marginTop: 30 }}>
                <Typography style={{ fontWeight: 'bold' }}>Includes:</Typography>
              </Stack>
              <Stack style={{ height: '100%' }}>
                <Typography style={{fontSize: 18}}>{'\u2713'} 2 interview credits </Typography>
                <Typography style={{fontSize: 18}}>{'\u2713'} Personalized interview feedback</Typography>
                <Typography style={{fontSize: 18}}>{'\u2713'} Weekly brag book check-ins</Typography>
              </Stack>
            </Stack>

            <Stack
              style={{ height: '60dvh', width: 350, boxShadow: '1px 2px 5px black', border: '1px solid silver', margin: 10, flexDirection: 'column', borderRadius: 5, padding: 15 }}
              variant='outlined'
            >
              <Stack style={{ height: 180 }}>
                <Stack style={{ borderWidth: 1, padding: 10, borderColor: 'black', border: '1px solid silver', borderRadius: 5, height: 30, width: 100, justifyContent: 'center', alignItems: 'center', backgroundColor: 'silver' }}>
                  <Typography style={{ fontWeight: '500', fontSize: 14, color: colors.coolBlack }}>
                    GROWTH
                  </Typography>
                </Stack>

                <Typography style={{ fontWeight: '500', fontSize: 50, color: 'black' }}>
                  $20
                </Typography>
                <Typography style={{ fontWeight: '300', fontSize: 18, color: 'black' }}>
                  per user per month
                </Typography>
                <Typography style={{ fontWeight: '300', fontSize: 18, color: 'black' }}>
                  Great for getting in more reps to improve your interview presence. 
                </Typography>
              </Stack>
              <Stack style={{ marginTop: 50, justifyContent: 'center', alignItems: 'center' }}>
                <Button 
                  style={{width: '80%', borderRadius: 15, backgroundColor: colors.oceanBlue}}
                  onClick={() => handleCheckout(SOLO_GROWTH_ID_DEV)}
                >
                  <Typography style={{color: 'white', fontWeight: 'bold'}}>BUY NOW</Typography>
                </Button>
               
              </Stack>
              <Stack style={{ marginTop: 30 }}>
                <Typography style={{ fontWeight: 'bold' }}>Includes:</Typography>
              </Stack>
              <Stack style={{ height: '100%' }}>
              <Typography style={{fontSize: 18}}>{'\u2713'} 5 interview credits </Typography>
                <Typography style={{fontSize: 18}}>{'\u2713'} Personalized interview feedback</Typography>
                <Typography style={{fontSize: 18}}>{'\u2713'} Weekly brag book check-ins</Typography>
              </Stack>
            </Stack>

            <Stack
              style={{ height: 500, width: 350, boxShadow: '1px 2px 5px black', border: '1px solid silver', margin: 10, flexDirection: 'column', borderRadius: 5, padding: 15 }}
              variant='outlined'
            >
              <Stack style={{ height: 180 }}>
                <Stack style={{ borderWidth: 1, padding: 10, borderColor: 'black', border: '1px solid silver', borderRadius: 5, height: 30, width: 100, justifyContent: 'center', alignItems: 'center', backgroundColor: 'silver' }}>
                  <Typography style={{ fontWeight: '500', fontSize: 14, color: colors.coolBlack }}>
                    PRO
                  </Typography>
                </Stack>

                <Typography style={{ fontWeight: '500', fontSize: 50, color: 'black' }}>
                  $40
                </Typography>
                <Typography style={{ fontWeight: '300', fontSize: 18, color: 'black' }}>
                  per user per month
                </Typography>
                <Typography style={{ fontWeight: '300', fontSize: 18, color: 'black' }}>
                More mock interviews, more opportunities to get laser focused to stand out during your next interview.
                </Typography>
              </Stack>
              <Stack style={{ marginTop: 50, justifyContent: 'center', alignItems: 'center' }}>
                <Button 
                  style={{width: '80%', borderRadius: 15, backgroundColor: colors.navyBlue}}
                  onClick={() => handleCheckout(SOLO_PRO_ID_DEV)}
                >
                  <Typography style={{color: 'white', fontWeight: 'bold'}}>BUY NOW</Typography>
                </Button>
               
              </Stack>
              <Stack style={{ marginTop: 30 }}>
                <Typography style={{ fontWeight: 'bold' }}>Includes:</Typography>
              </Stack>
              <Stack style={{ height: '100%' }}>
              <Typography style={{fontSize: 18}}>{'\u2713'} 12 interview credits </Typography>
                <Typography style={{fontSize: 18}}>{'\u2713'} Personalized interview feedback</Typography>
                <Typography style={{fontSize: 18}}>{'\u2713'} Weekly brag book check-ins</Typography>
              </Stack>
            </Stack>

          </Stack>
        </Box>
      </Stack>
      </div>
    </div>
  );
};
