import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import PropTypes from 'prop-types'
export default function ControlledCheckbox(props) {
  const [checked, setChecked] = React.useState(true);

  const { updateItems, items, value, id } = props

  const handleChange = (event) => {
    setChecked(event.target.checked);
    updateItems(value, id)
  };

  return (
    <FormControlLabel 
    control={<Checkbox onChange={(event) => handleChange(event)} inputProps={{ 'aria-label': 'controlled' }}/>} 
    label={value} />
);
   
}

ControlledCheckbox.propTypes = {
  updateItems: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.string,
}