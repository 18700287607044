export const CREATING_TAG = 'CREATING_TAG'
export const CREATED_TAG = 'CREATED_TAG'
export const ERROR_CREATING_TAG = 'ERROR_CREATING_TAG'

export const RETRIEVING_USER_TAGS = 'RETRIEVING_USER_TAGS'
export const RETRIEVED_USER_TAGS = 'RETRIEVED_USER_TAGS'
export const ERROR_RETRIEVING_USER_TAGS = 'ERROR_RETRIEVING_USER_TAGS'

export const CREATING_INDIVIDUAL_CHECK_IN = 'CREATING_INDIVIDUAL_CHECK_IN'
export const CREATED_INDIVIDUAL_CEHCK_IN = 'CREATED_INDIVIDUAL_CEHCK_IN'
export const ERROR_CREATING_INDIVIDUAL_CHECK_IN = 'ERROR_CREATING_INDIVIDUAL_CHECK_IN'

export const RETRIEVING_INDIVIDUAL_CHECK_INS = 'RETRIEVING_INDIVIDUAL_CHECK_INS'
export const RETRIEVED_INDIVIDUAL_CHECK_INS = 'RETRIEVED_INDIVIDUAL_CHECK_INS'
export const ERROR_RETRIEVING_INDIVIDUAL_CHECK_INS = 'ERROR_RETRIEVING_INDIVIDUAL_CHECK_INS'

export const RETRIEVING_FUTURE_CHECK_INS = 'RETRIEVING_FUTURE_CHECK_INS'
export const RETRIEVED_FUTURE_CHECK_INS = 'RETRIEVED_FUTURE_CHECK_INS'
export const ERROR_RETRIEVING_FUTURE_CHECK_INS = 'RETRIEVING_FUTURE_CHECK_INS'

export const CREATING_DIRECT_REPORT_TAG = 'CREATING_DIRECT_REPORT_TAG'
export const CREATED_DIRECT_REPORT_TAG = 'CREATED_DIRECT_REPORT_TAG'
export const ERROR_CREATING_DIRECT_REPORT_TAG = 'ERROR_CREATING_DIRECT_REPORT_TAG'

export const RETRIEVING_DIRECT_REPORT_TAGS = 'RETRIEVING_DIRECT_REPORT_TAGS'
export const RETRIEVED_DIRECT_REPORT_TAGS = 'RETRIEVED_DIRECT_REPORT_TAGS'
export const ERROR_RETRIEVING_DIRECT_REPORT_TAGS = 'ERROR_RETRIEVING_DIRECT_REPORT_TAGS'

export const UPDATING_WEEKLY_CHECK_IN = 'UPDATING_WEEKLY_CHECK_IN'
export const UPDATED_WEEKLY_CHECK_IN = 'UPDATED_WEEKLY_CHECK_IN'
export const ERROR_UPDATING_WEEKLY_CHECK_IN = 'ERROR_UPDATING_WEEKLY_CHECK_IN'

export const RETRIEVING_COHORT_STATS = 'RETRIEVING_COHORT_STATS'
export const RETRIEVED_COHORT_STATS = 'RETRIEVED_COHORT_STATS'
export const ERROR_RETRIEVING_COHORT_STATS = 'ERROR_RETRIEVING_COHORT_STATS'

export const RETRIEVING_WEEKLY_CHECKIN_SUMMARIES = 'RETRIEVING_WEEKLY_CHECKIN_SUMMARIES'
export const RETRIEVED_WEEKLY_CHECKIN_SUMMARIES = 'RETRIEVED_WEEKLY_CHECKIN_SUMMARIES'
export const ERROR_RETRIEVED_WEEKLY_CHECKIN_SUMMARIES = 'ERROR_RETRIEVED_WEEKLY_CHECKIN_SUMMARIES'

export const RETRIEVING_HIGH_FIVES_FOR_COHORT = 'RETRIEVING_HIGH_FIVES_FOR_COHORT'
export const RETRIEVED_HIGH_FIVES_FOR_COHORT = 'RETRIEVED_HIGH_FIVES_FOR_COHORT'
export const ERROR_RETRIEVING_HIGH_FIVES_FOR_COHORT = 'ERROR_RETRIEVING_HIGH_FIVES_FOR_COHORT'

export const SUBMITTING_COMMENT_ON_CHECK_IN_DETAIL = 'SUBMITTING_COMMENT_ON_CHECK_IN_DETAIL'
export const SUBMITTED_COMMENT_ON_CHECK_IN_DETAIL = 'SUBMITTED_COMMENT_ON_CHECK_IN_DETAIL'
export const ERROR_SUBMITTING_COMMENT_ON_CHECK_IN_DETAIL = 'ERROR_SUBMITTING_COMMENT_ON_CHECK_IN_DETAIL'

export const ADDING_COMMENT_TO_SUGGESTION = 'ADDING_COMMENT_TO_SUGGESTION'
export const ADDED_COMMENT_TO_SUGGESTION = 'ADDED_COMMENT_TO_SUGGESTION'
export const ERROR_ADDING_COMMENT_TO_SUGGESTION = 'ERROR_ADDING_COMMENT_TO_SUGGESTION'

export const RETRIEVING_UNREVIEWED_CHECK_INS_BY_COHORT = 'RETRIEVING_UNREVIEWED_CHECK_INS_BY_COHORT'
export const RETRIEVED_UNREVIEWED_CHECK_INS_BY_COHORT = 'RETRIEVED_UNREVIEWED_CHECK_INS_BY_COHORT'
export const ERROR_RETRIEVING_UNREVIEWED_CHECK_INS_BY_COHORT = 'ERROR_RETRIEVING_UNREVIEWED_CHECK_INS_BY_COHORT'
