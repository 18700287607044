import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Cookies from 'js-cookie';

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField"
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DatePicker from "react-datepicker";

import PropTypes from 'prop-types'
import { colors } from '../../../constants/Colors';
import { addJobToWorkHistory, deleteJobDetail, deleteJobFromWorkHistory, updateJobOnWorkHistory } from '../../../redux/actions/userActions';

export default function EditJobHistoryDetailModal(props) {

  const dispatch = useDispatch()

  const {
    isVisible,
    handleClose,
    jobs,
    selectedJobHistoryDetail
  } = props

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    padding: 40,
    height: '80%',
    overflowY: 'scroll',
    p: 4,
  };

  const employmentTypeOptions = [
    'Contractor',
    'Full-Time',
    'Part-Time',
  ]

  const workplaceLocationOptions = [
    'Hybrid',
    'Onsite',
    'Remote',
  ]

  // const token = Cookies.get('token')
  const token = Cookies.get('token');

  const authedUserId = useSelector((state) => state.auth.id)

  
  
  const [companyName, setCompanyName] = useState('')
  

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState(null)
 
  const [currentEmployer, setCurrentEmployer] = useState("false")
  const [updatedJobTitle, setUpdatedJobTitle] = useState(null)
  const [updatedEmploymentType, setUpdatedEmploymentType] = useState(null)
  const [updatedCompanyName, setUpdatedCompanyName] = useState(null)
  const [updatedLocation, setUpdatedLocation] = useState(null)
  const [updatedLocationType, setUpdatedLocationType] = useState(null)
  const [updatedStartDate, setUpdatedStartDate]= useState(null)
  const [updatedCurrentEmployer, setUpdatedCurrentEmployer] = useState(null)
  const [updatedEndDate, setUpdatedEndDate] = useState(null)
  const [updatedCurrentJobDetail, setUpdatedCurrentJobDetail] = useState(null)
  const [updatedJobDetails, setUpdatedJobDetails] = useState([])
  const [editingJobDetail, setEditingJobDetail] = useState(null);
  const [selectedJobDetail, setSelectedJobDetail] = useState(false)
    
  if (selectedJobHistoryDetail != null && !selectedJobDetail) {
    setSelectedJobDetail(true)
    const { startDate, endDate, currentEmployer, companyName, employmentType, location, locationType, id, jobDetails, title } = selectedJobHistoryDetail

    
    setStartDate(startDate)
    setEndDate(endDate)
    setCurrentEmployer(currentEmployer)
  
    setUpdatedCompanyName(companyName ? companyName : '')
    setUpdatedEmploymentType(employmentType ? employmentTypeOptions.indexOf(employmentType) : null)
    setUpdatedJobTitle(title)
    setUpdatedLocation(location ? location : '')
    setUpdatedLocationType(updatedLocationType ? updatedLocationType : workplaceLocationOptions.indexOf(locationType))
    setUpdatedJobDetails(jobDetails || [])
  }
  
   
  const handleEmploymentTypeChange = (event, type) => {
    
    const {
      target: { value },
    } = event;

    setUpdatedEmploymentType(value)
  }

  const handleOfficeLocationTypeChange = (event, type) => {
    const {
      target: { value },
    } = event;

    setUpdatedLocationType(value)
  }

  const renderEmploymentTypes = () => {
    if (employmentTypeOptions.length > 0) {
      return employmentTypeOptions.map((option, index) => {
        return (
          <MenuItem key={index} value={index}>{option}</MenuItem>
        )
      })
    }
  }

  const renderWorkplaceLocationOptions = () => {
    if (workplaceLocationOptions.length > 0) {
      return workplaceLocationOptions.map((option, index) => {
        return (
          <MenuItem key={index} value={index}>{option}</MenuItem>
        )
      })
    }
  }

  const addJobDetail = () => {
    const jobDetail = {
      id: crypto.randomUUID(),
      value: updatedCurrentJobDetail,
      temp: true
    }

    setUpdatedJobDetails(updatedJobDetails ? updatedJobDetails.concat(jobDetail) : [jobDetail])
    setUpdatedCurrentJobDetail('')
  }  

  const filterDeletedJobDetail = (detailId) => {
    const updatedDetails = updatedJobDetails.filter((detail) => detail.id !== detailId)
    setUpdatedJobDetails(updatedDetails)
  }

  const removeJobDetail = (detailId) => {

    if (updatedJobDetails) {
      updatedJobDetails.map((jobDetail) => {
        if (jobDetail.id === detailId && jobDetail.temp) {
          filterDeletedJobDetail(detailId)
        }
        else if (jobDetail.id === detailId && jobDetail.temp == null) {
          dispatch(deleteJobDetail(detailId, jobs, token, setSelectedJobDetail ,clearSelections))
        }
      }) 
    } 
  } 


const updateJobDetail = (text) => {
      let tempDetail = editingJobDetail
      tempDetail.value = text

      setEditingJobDetail(tempDetail)
   
  };

  const clearDetail = (editingJobDetail) => {
    let stateJobDetails = updatedJobDetails
      stateJobDetails = stateJobDetails.map((detail) => detail.id === editingJobDetail.id ? editingJobDetail: detail);
      setUpdatedJobDetails(stateJobDetails)

      setEditingJobDetail(null)
  }

  const renderJobDetails = () => {
    if (updatedJobDetails) {
      if (updatedJobDetails.length > 0) {
        return updatedJobDetails.map((jobDetail) => {
          return (
            <Stack 
              key={jobDetail.id}
              direction='row' 
              style={{justifyContent: 'space-between', width: '80%', margin: 5, alignItems: 'center'}}
              >
              <TextField
            style={{ width: '80%' }}
            multiline
            rows={2}
            onChange={(event) => updateJobDetail(event.target.value)}
            defaultValue={
              editingJobDetail && editingJobDetail.id === jobDetail.id
                ? editingJobDetail.value
                : jobDetail.value || ''
            }
            onFocus={() => setEditingJobDetail(jobDetail)}
            onBlur={() => clearDetail(editingJobDetail)}
          />
              <Stack>
                <Button
                  onClick={() => removeJobDetail(jobDetail.id)}
                  style={{height: 30, width: 30, backgroundColor: colors.blazeRed}}
                >
                  <Typography style={{color: 'white'}}>X</Typography>
                </Button>
              </Stack>
            </Stack>
          )
        })
      }
    }
  }

  const saveJobHistoryDetails = () => {

    const { id } = props.selectedJobHistoryDetail
    
    const userExperienceConfig = {
      id: id,
      userId: authedUserId,
      title: updatedJobTitle,
      companyName: updatedCompanyName,
      employmentType: employmentTypeOptions[updatedEmploymentType],
      location: updatedLocation,
      locationType: workplaceLocationOptions[updatedLocationType],
      startDate: updatedStartDate,
      endDate: updatedEndDate,
      jobDetails: updatedJobDetails,
      currentEmployer: updatedCurrentEmployer
    }
 
    dispatch(updateJobOnWorkHistory(userExperienceConfig, jobs, token, clearSelections, setSelectedJobDetail))
    
  }

  const clearSelections = () => {
    setUpdatedJobTitle(null)
    setUpdatedEmploymentType(null)
    setUpdatedCompanyName(null)
    setUpdatedLocation(null)
    setUpdatedLocationType(null)
    setUpdatedCurrentEmployer(null)
    setUpdatedStartDate(null)
    setUpdatedEndDate(null)
    setUpdatedCurrentJobDetail(null)
    setUpdatedJobDetails(null)

    setSelectedJobDetail(false)

    handleClose()
  }

  const renderEndDatePicker = () => {
    if (updatedCurrentEmployer) {
      if (updatedCurrentEmployer == 'false') {
        return (

          <Stack style={{ height: 60, zIndex: 2, marginLeft: 30 }}>
            <Typography style={{fontSize: 16, color: colors.plum, fontWeight: '700'}}>When was your last day?</Typography>
            <DatePicker
              selected={updatedEndDate ? updatedEndDate : new Date(endDate)}
              minDate={new Date(startDate)}
              onChange={(date) => setUpdatedEndDate(date)}
            />
          </Stack>
        )
      }
     
    }
    else if (currentEmployer == false) {
      return (
        <Stack style={{ height: 60, zIndex: 2, marginLeft: 30 }}>
          <Typography style={{fontSize: 16, color: colors.plum, fontWeight: '700'}}>When was your last day?</Typography>
          <DatePicker
            selected={new Date(endDate)}
            minDate={new Date(startDate)}
            onChange={(date) => setUpdatedEndDate(date)}
          />
        </Stack>
      )
    }
  }

  return (
    <div>
      <Modal
        open={isVisible}
        onClose={() => clearSelections()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Typography 
            id="modal-modal-title" variant="h6" component="h2"
            style={{textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5, fontSize: 20}}>
          
            EDIT CAREER DETAILS
          </Typography>
          <Stack style={{marginTop: 20}}>
            <Typography style={{fontSize: 16, color: colors.plum, fontWeight: '700'}}>Title<span style={{color: colors.blazeRed, fontWeight: 'bold', fontSize: 18}}>*</span></Typography>
            <TextField
              onChange={(event) => setUpdatedJobTitle(event.target.value)}
              value={updatedJobTitle ? updatedJobTitle : ''}
              style={{ borderWidth: 1, width: '80%' }}
            />
          </Stack>
          <Stack style={{marginTop: 20}}>
            <Typography style={{fontSize: 16, color: colors.plum, fontWeight: '700'}}>Company Name</Typography>
            <TextField
              onChange={(event) => setUpdatedCompanyName(event.target.value)}
              value={updatedCompanyName ? updatedCompanyName : '' }
              style={{ borderWidth: 1, width: '80%' }}
            />
          </Stack>
          <Stack style={{marginTop: 20}}>
            <Typography style={{fontSize: 16, color: colors.plum, fontWeight: '700'}}>Employment Type</Typography>
            <Select
              value={updatedEmploymentType}
              style={{ width: '80%' }}
              onChange={(event) => handleEmploymentTypeChange(event)}
            >
              {renderEmploymentTypes()}
            </Select>
          </Stack>
          <Stack style={{marginTop: 20}}>
            <Typography style={{fontSize: 16, color: colors.plum, fontWeight: '700'}}>Office Location</Typography>
            <TextField
              onChange={(event) => setUpdatedLocation(event.target.value)}
              value={updatedLocation ? updatedLocation : '' }
              style={{ borderWidth: 1, width: '80%' }}
            />
          </Stack>
          <Stack style={{marginTop: 20}}>
            <Typography style={{fontSize: 16, color: colors.plum, fontWeight: '700'}}>Office Location Type</Typography>
            <Select
              labelId="employment-type-label"
              id="employment-type-id"
              value={updatedLocationType}
              // label="Interview"
              style={{ width: '80%' }}
              onChange={(event) => handleOfficeLocationTypeChange(event)}
            >
              {renderWorkplaceLocationOptions()}
            </Select>
          </Stack>

          <Stack style={{marginTop: 20}}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                <Typography style={{fontSize: 16, color: colors.plum, fontWeight: '700'}}>Is {companyName ? companyName : 'this company '} your current employer?</Typography>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={updatedCurrentEmployer ? updatedCurrentEmployer : currentEmployer.toString()}

                name="radio-buttons-group"
                style={{ flexDirection: 'row' }}
                onChange={(event) => {
                  setUpdatedCurrentEmployer(event.target.value)
                }}
              >
                <FormControlLabel key={1} value={false}  control={<Radio />} label="No" />
                <FormControlLabel key={2} value={true} control={<Radio />} label="Yes" />
              </RadioGroup>
            </FormControl>
          </Stack>
          <Stack direction='row' style={{marginTop: 20, marginBottom: 20}}>
            <Stack style={{ height: 60, zIndex: 2 }}>
              <Typography style={{fontSize: 16, color: colors.plum, fontWeight: '700'}}>When did you start?<span style={{color: colors.blazeRed, fontWeight: 'bold', fontSize: 18}}>*</span></Typography>
              <DatePicker
                selected={updatedStartDate ? updatedStartDate : new Date(startDate)}
                onChange={(date) => setUpdatedStartDate(date)}
              />
            </Stack>
            {renderEndDatePicker()}
          </Stack>

          <Typography style={{fontSize: 16, color: colors.plum, fontWeight: '700'}}>Add details about your role. Each detail represents a bullet point on your resume.</Typography>
          <Stack direction='row' style={{justifyContent: 'space-between', alignItems: 'center', width: '80%'}}>
            
            <TextField 
              onChange={(event) => setUpdatedCurrentJobDetail(event.target.value)}
              value={updatedCurrentJobDetail ? updatedCurrentJobDetail : ''}
              multiline
              rows={3}
              style={{borderWidth: 1, width: '80%',}}
            />
            <Button
               style={{ backgroundColor: colors.lightGray, height: 45, width: 45 }}
               onClick={() => addJobDetail()}
            >
                <Typography style={{color: 'black'}}>ADD</Typography>
              </Button>
          </Stack>

          {renderJobDetails()}

          <Stack direction='row' style={{ justifyContent: 'space-around', alignItems: 'center', marginTop: 20 }}>
            <Stack>
              <Button variant='contained'
                style={{ backgroundColor: 'silver', height: 45, width: 150 }}
                onClick={() => clearSelections()}
              >
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>CANCEL</Typography>
              </Button>
            </Stack>
            <Stack>
              <Button variant='contained'
                style={{ backgroundColor: colors.oceanBlue, height: 45, width: 150 }}
                onClick={() => saveJobHistoryDetails()}
              >
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>SAVE</Typography>
              </Button>
            </Stack>
          </Stack>
          <Stack>
            <hr />
            <Stack>
              <Typography>Once deleted your job and its related details cannot be recovered.</Typography>
              <Button variant='contained'
                style={{ backgroundColor: colors.blazeRed, height: 45, width: 150, marginTop: 10 }}
                onClick={() => dispatch(deleteJobFromWorkHistory(props.selectedJobHistoryDetail.id, jobs, token, clearSelections))}
              >
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>DELETE JOB</Typography>
              </Button>
            </Stack>

          </Stack>
        </Box>
      </Modal>
    </div>

  );
}

EditJobHistoryDetailModal.propTypes = {
  isVisible: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedJobHistoryDetail: PropTypes.object
}