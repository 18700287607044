import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Cookies from 'js-cookie';

import { Grid, Box, Tabs, Tab, Typography, Stack, Button } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import SidebarNav from '../../components/navigation/SidebarNav';

import { getFormattedDate } from '../../utils/DateUtils';

import { RingLoader } from "react-spinners";
import { colors } from "../../constants/Colors";
import { getAxiosInstance } from "../../utils/ApiUtils";

import config from '../../env.json'

import styles from './InterviewsHomeScreen.module.css'
import { ColorLens } from "@mui/icons-material";

export default function InterviewSummaryScreen() {
  const { BASE_URI } = config
  const params = useParams()

  const navigate = useNavigate()

  const token = Cookies.get('token')

  const [currentInterview, setCurrentInterview] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [value, setValue] = useState(0);
  const [needsImprovementResponses, setNeedsImprovementResponses] = useState([])
  const [meetsExpectationsResponses, setMeetsExpectationsResponses] = useState([])
  const [exceedsExpectationsResponses, setExceedsExpectationsResponses] = useState([])

  useEffect(() => {

    async function fetchInterviewSummary() {
      const response = await getAxiosInstance(token).get(`${BASE_URI}/interviews/completed/summary/${params.id}`)
        .then((res) => {
          if (res.status == 200) {
            setIsLoading(false)
            return res.data
          }
        })
        .catch((error) => {
          setIsLoading(false)
        })

      if (response) {
        setCurrentInterview(response)
        parseInterviewSummary(response)
      }

    }

    fetchInterviewSummary()

  }, [isLoading])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: '80vh',
    width: 850,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
    overflowY: 'auto',
  };

  const parseInterviewSummary = () => {
    if (currentInterview) {
      return currentInterview.interviewResponses.map((interviewResponse) => {
        if (interviewResponse.evaluation === 'DOES_NOT_MEET') {
          let currentNeedsImprovementResponses = needsImprovementResponses
          currentNeedsImprovementResponses.push(interviewResponse)
          setNeedsImprovementResponses(currentNeedsImprovementResponses)
        }
        else if (interviewResponse.evaluation === 'MEETS_EXPECTATIONS') {
          let currentMeetsExpectationResponses = meetsExpectationsResponses
          currentMeetsExpectationResponses.push(interviewResponse)
          setMeetsExpectationsResponses(currentMeetsExpectationResponses)
        }
        else if (interviewResponse.evaluation === 'EXCEEDS_EXPECTATIONS') {
          let currentExceedsExpectationResponses = exceedsExpectationsResponses
          currentExceedsExpectationResponses.push(interviewResponse)
          setExceedsExpectationsResponses(currentExceedsExpectationResponses)
        }
      })
    }
  }


  const renderFocusAreaByType = (heading, focusAreas) => {
    if (currentInterview && focusAreas.length > 0) {
      return (
      
        <Stack>
           <Stack>
                <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: 'bold' }}>
                  {heading}
                </Typography>
              </Stack>
              <Stack direction='row'>
                {focusAreas.map((focusArea) => {
                  return (
                    <Stack style={{height: 32, border: '2px solid silver', padding: 5, borderRadius: 5,  boxShadow: '1px 1px 1px silver', margin: 3, justifyContent: 'center', alignItems: 'center' }}>
                      <Typography style={{color: 'black', fontWeight: '500'}}>{focusArea}</Typography>
                    </Stack>
                  )
                })}
              </Stack>
        </Stack>
      )
    }
   
  }

  const renderInterviewResponseByValue = (value) => {
    let interviewResponses = []

    switch (value) {
      case 0:
        interviewResponses = needsImprovementResponses;
        break;
      case 1:
        interviewResponses = meetsExpectationsResponses;
        break;
      case 2:
        interviewResponses = exceedsExpectationsResponses
        break;
      default:
        break;
    }

    if (interviewResponses.length > 0) {
      return interviewResponses.map((response, index) => {
        return (
          <Stack key={index} style={{ padding: 20 }}>
            <Grid container xs={12} sx={{ padding: 2 }}>
              <Grid item xs={6} sx={{ textAlign: "left", fontWeight: "bold" }}>
                {response.candidateInterviewQuestion.type.toUpperCase()}
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right", fontWeight: "bold" }}>
                <Typography style={{color: colors.plum}}>
                {response.candidateInterviewQuestion.focusArea}
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={12} sx={{ marginTop: 3, paddingBottom: 3 }}>
              <Grid item xs={12}>
                <b style={{ color: colors.oceanBlue }}>Question: </b>
                {response.candidateInterviewQuestion.question}
              </Grid>
              <Grid item xs={12}>

                <b style={{ color: colors.plum }} >Candidate Response: </b>
                {response.response}
              </Grid>
              <Grid item xs={12}>
                <b style={{ color: colors.oceanBlue }}>Recommendations for Improvement:</b>
                {response.suggestedImprovements.map((improvement, index) => { return (<li key={index}> {improvement.suggestedImprovement}</li>) })}
              </Grid>
              {index != interviewResponses.length - 1 ? <Grid container xs={12} sx={{
                borderBottom: "1px solid silver", fontWeight: "bold", justifyContent: "center", paddingBottom: 2
              }} /> : null}

            </Grid>
          </Stack>
        )
      })
    }
    else {
      return (
        <Stack style={{ padding: 20, }}>
          <Grid container xs={12} sx={{ padding: 2 }}>
            <Grid item xs={12}>
              <b style={{ color: colors.plum }} >  <Typography>No responses to display. </Typography> </b>
            </Grid>
          </Grid>
        </Stack>
      )
    }
  }

  
  return (
    <Grid container spacing={2}>
      <SidebarNav />
      {isLoading ?   <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      ><RingLoader size={100} color={colors.oceanBlue} /> </Grid> : (
      <Grid style={{ marginTop: 50, marginLeft: 50 }} xs={8}>
        <Button
          variant='text'
          onClick={
            () => {
              navigate(-1)
            }
          }
          sx={{
            color: colors.oceanBlue,
          }}
          startIcon={<ArrowLeftIcon />}
          style={{ float: "right" }}
        >
          <Typography style={{ fontSize: 16 }}>INTERVIEWS</Typography>
        </Button>
        <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4 }}>INTERVIEW SUMMARY</Typography>
        <Stack sx={{ marginTop: "16px",  padding: '4px', borderRadius: 2, padding: 2 }}>
            <Stack direction='row' sx={{ fontWeight: 'bold' }} >
              <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: 'bold' }}>Type:</Typography>
              <Typography style={{marginLeft: 5}}>
              {currentInterview.interviewType[0].toUpperCase() + currentInterview.interviewType.substring(1).toLowerCase()}
              </Typography>
            </Stack>
            <Stack direction='row' sx={{ fontWeight: 'bold' }}>
              <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: 'bold' }}>Date:</Typography>
              <Typography style={{marginLeft: 8}}>
                {getFormattedDate(currentInterview.interviewResponses[0].suggestedImprovements[0]?.suggestionDate)} 
              </Typography>
            </Stack>
            <Stack direction='row' sx={{ fontWeight: 'bold' }}>
              <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: 'bold' }}>Number of Questions:</Typography>
              <Typography style={{marginLeft: 8}}>
                {currentInterview ? currentInterview.interviewResponses.length : 0} 
              </Typography>
            </Stack>
          </Stack>
          <hr />
          <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4 }}>Focus Areas</Typography>
        <><Grid container spacing={2} sx={{ marginTop: "2x", marginLeft: 1}}>
          <Grid container xs={8} sx={{ marginTop: 2, padding: 2  }}>
            <Grid item>       
              {renderFocusAreaByType('Behavioral', currentInterview ? currentInterview.behavioralFocusAreas : null)}
              {renderFocusAreaByType('Technical', currentInterview ? currentInterview.technicalFocusAreas : null)}
              {renderFocusAreaByType('Professional', currentInterview ? currentInterview.professionalFocusAreas : null)}
             
            </Grid>
          </Grid>
        </Grid>
        <hr />
        <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4 }}>Performance Evaluation</Typography>
          <Grid container xs={12} sx={{ marginTop: "16px" }}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label={`Needs Improvement(${needsImprovementResponses.length})`} {...a11yProps(0)} />
                  <Tab label={`Meets Expectations(${meetsExpectationsResponses.length})`} {...a11yProps(0)} />
                  <Tab label={`Exceeds Expectations(${exceedsExpectationsResponses.length})`} {...a11yProps(0)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={value}>
                <Grid container spacing={2} sx={{ marginTop: "16px", border: '1px solid silver', padding: '4px', borderRadius: 2, boxShadow: '3px 2px 8px 1px silver' }}>
                  {renderInterviewResponseByValue(value)}
                </Grid>
              </CustomTabPanel>
            </Box >
          </Grid>
        </>
      </Grid>)}
    </Grid>
  )
}