import React from 'react';

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types'

export default function CheckInButton(props) {

  const { label, onPress, backgroundColor, icon} = props

  return (
    
      <Button 
        variant='outlined'
        onClick={() => onPress()} 
        style={{
          display: 'flex',
          height: 150, 
          width: 150, borderRadius: 5, margin: 10 }}
      >
        <Stack 
          direction='column'
          style={{justifyContent: 'center', alignItems: 'center'}}
        >
        <img
           src={icon} 
           style={{height: 75, width: 75}}
           />
        <Typography
          style={{fontWeight: 'bold', fontSize: 14, paddingTop: 5}}
        >
            {label}
        </Typography>
        </Stack>
       
      </Button>
    
  )
}

CheckInButton.propTypes = {
  label: PropTypes.string,
  onPress: PropTypes.func,
  backgroundColor: PropTypes.string
}