import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';

import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select';
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DatePicker from "react-datepicker";
import Modal from '@mui/material/Modal';
import Backdrop from "@mui/material/Backdrop";

import "react-datepicker/dist/react-datepicker.css";

import Stack from '@mui/material/Stack'

import jobTitles from '../../constants/job-titles';
import { MenuItem } from '@mui/material';

import PropTypes from 'prop-types'
import StandardButton from '../buttons/StandardButton';

import { saveUserProfilePreferences } from '../../redux/actions/userActions';
import ResumeUploadModal from '../../screens/profile/ResumeUploadModal';
import { colors } from '../../constants/Colors';

import {
  getAxiosInstance
} from '../../utils/ApiUtils'

import config from '../../env.json'



export default function RoleSelectorModal(props) {
  const { BASE_URI } = config
  const dispatch = useDispatch()

  const token = Cookies.get('token');
  const authedUserId = useSelector((state) => state.auth.id)
  const preSelectedPreferredRole = useSelector(state => state.user.preferredRole)

  const resumes = useSelector((state) => state.user.resumes)

  const [preferredRole, setPreferredRole] = useState(preSelectedPreferredRole)
  const [areasOfImprovement, setAreasOfImprovement] = useState([])
  const [alertVisible, setAlertVisible] = useState(false)
  const [interviewDate, setInterviewDate] = useState(null);
  const [company, setCompany] = useState('')
  const [upcomingInterview, setUpcomingInterview] = useState(false)

  const [resumeUploadModalVisible, setResumeUploadModalVisible] = useState(false)

  const [availableRoles, setAvaiableRoles] = useState([])

  useEffect(() => {

    async function fetchData() {
      const response = await getAxiosInstance(token).get(`${BASE_URI}/interviews/availableroles`)
        .then((res) => {
          if (res.status == 200) {
            return res.data
          }
        })
        .catch((error) => {
        })

      if (response) {
        setAvaiableRoles(response)
      }
    }

    fetchData();

  }, [authedUserId])

  const competency_list = [
    'Effective Communication',
    'Active Listening',
    'Problem Solving',
    'Adaptability',
    'Time Management',
    'Teamwork and Collaboration',
    'Critical Thinking',
    'Leadership',
    'Emotional Intelligence',
    'Self Confidence',
    'Professionalism',
    'Research and Preparation',
    'Negotiation Skills',
    'Creativity',
    'Decision Making',
    'Flexibility',
    'Presentation Skills',
    'Interpersonal Skills',
    'Attention to Detail',
    'Stress Management'
  ]



  const handleChange = (event) => {
    setPreferredRole(event.target.value);
  };

  const handleButtonPress = (skill) => {
    if (areasOfImprovement.includes(skill)) {
      setAreasOfImprovement((prevSelectedButtons) =>
        prevSelectedButtons.filter((item) => item !== skill)
      );
    } else {
      setAreasOfImprovement((prevSelectedButtons) => [...prevSelectedButtons, skill]);
    }
  };

  const submitBaselineInterviewConfig = () => {

    if (isCohortMember == false ) {
      if (preferredRole == '') {
        setAlertVisible(true)
        return
      }
      else {
        setAlertVisible(false)
      }
    }

    const userConfig = {
      id: userId,
      isCohortMember: isCohortMember ? isCohortMember : false,
      preferredRole: preferredRole,
      upcomingInterview: upcomingInterview ? upcomingInterview === 'true' : false,
      company: company,
      interviewDate: interviewDate,
      areasOfImprovement: areasOfImprovement.map((area) => area.replace(/[- ]/g, '_').toUpperCase())
    }

    dispatch(saveUserProfilePreferences(userConfig, token, handleClose))
  }

  const { handleClose, isVisible, isCohortMember, userId } = props

  const renderRoleSelector = () => {

    if (!isCohortMember || isCohortMember == false) {
      return (
        <Stack>
          {alertVisible ? <Alert severity="error">Role you are interviewing for is required!</Alert> : null}
          <Typography>Select Your Desired Role: <span style={{ color: colors.blazeRed, fontSize: 20 }}>*</span> </Typography>
          <Select
            labelId="career-selector"
            id="career-selector-id"
            value={preferredRole}
            label="Role"
            onChange={handleChange}
            style={{ width: 350, marginRight: 30 }}
          >
            {availableRoles.map((title) => {
              return <MenuItem key={title} value={title}>{title}</MenuItem>
            })}
          </Select>
        </Stack>
      )
    }
  }



  return (
    <div>
      <Modal
        open={isVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box
          flexDirection={'row'}
          sx={style}
          alignContent='space-between'
        >
          {renderRoleSelector()}
          <Stack>
            <Stack>
              <FormControl>
                <FormLabel>
                  <Typography style={{color: 'black'}}>Do you have an upcoming interview?</Typography>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={upcomingInterview}
                  name="radio-buttons-group"
                  style={{ flexDirection: 'row' }}
                  onChange={(event) => {
                    setUpcomingInterview(event.target.value)
                  }}
                >
                  <FormControlLabel key={1} value={false} control={<Radio />} label="No" />
                  <FormControlLabel key={2} value={true} control={<Radio />} label="Yes" />
                </RadioGroup>
              </FormControl>
            </Stack>
            {upcomingInterview == 'true' ? (<Stack> <Stack style={{ height: 60, zIndex: 2 }}>
              <Typography>Set Interview Date (We'll send you reminders ahead of the big day)</Typography>
              <DatePicker
                selected={interviewDate}
                onChange={(date) => setInterviewDate(date)}
                disabled={!upcomingInterview}
              />
            </Stack>
              <Stack>
                <Typography>What company are you interviewing with?</Typography>
                <TextField
                  label="Company Name" variant="outlined"
                  onChange={(event) => setCompany(event.target.value)}
                />
              </Stack></Stack>) : null}
          </Stack>
          <Stack style={{marginTop: 10, marginBottom: 10}}>
            <Typography>Upload your resume:</Typography>
            <StandardButton 
              label='UPLOAD'
              labelColor={colors.oceanBlue}
              onPress={() => setResumeUploadModalVisible(true)}
            />
            <ResumeUploadModal 
              isVisible={resumeUploadModalVisible}
              handleClose={() => setResumeUploadModalVisible()}
              
            
            />
          </Stack>
          <hr />
          <Stack>
            <Typography>Choose skills you'd like us to help you improve: </Typography>
          </Stack>
          <Stack direction={'row'} style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {competency_list.map((value, index) => (
              <Button
                key={index}
                onClick={() => handleButtonPress(value)}
                label={value}
                style={{

                  backgroundColor: areasOfImprovement.includes(value)
                    ? colors.oceanBlue // color when selected
                    : 'white', // Default blue color
                  borderWidth: 1,
                  borderRadius: 5,
                  margin: 3,
                  padding: 10,
                  boxShadow: '1px 2px 5px gray'
                }

                }
              >
                <Typography style={{
                  color: areasOfImprovement.includes(value)
                    ? 'white' // color when selected
                    : 'black', // Default blue color
                  fontWeight: 'bold',
                  fontSize: 14,
                  shadowColor: 'black',
                  shadowOffset: {
                    height: 2,
                    width: 1
                  },
                  shadowOpacity: .85,
                  shadowRadius: 1,
                }}>
                  {value}
                </Typography>
              </Button>
            ))}
          </Stack>
          <Stack style={{ marginTop: 50 }}>
            <StandardButton
              label='SAVE PREFERENCES'
              onPress={() => submitBaselineInterviewConfig()}
              labelColor={colors.coolBlack}
              buttonWidth={350}

            />
          </Stack>
        </Box>
      </Modal>

    </div>



  )
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

RoleSelectorModal.propTypes = {
  isVisible: PropTypes.bool,
  setModalOpen: PropTypes.func,
  handleClose: PropTypes.func,
  isCohortMember: PropTypes.bool,
  userId: PropTypes.string
}

