import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Cookies from 'js-cookie';

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField"
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "react-datepicker";

import PropTypes from 'prop-types'
import { colors } from '../../../constants/Colors';
import { addEducationHistory, deleteEducationRecord, deleteEducationActivity, updatedEducationRecord, updateEducationRecord } from '../../../redux/actions/userActions';

export default function AddEducationHistoryModal(props) {

  const dispatch = useDispatch()

  const {
    isVisible,
    handleClose,
    selectedEducationHistoryDetail
  } = props

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    padding: 40,
    height: '80%',
    overflowY: 'scroll',
    p: 4,
  };

  // import Cookies from 'js-cookie';
  const token = Cookies.get('token');

  // const token = Cookies.get('token')
  const authedUserId = useSelector((state) => state.auth.id)
  const educationRecords = useSelector((state) => state.user.education)

  // const [school, setSchool] = useState('')
  // const [degree, setDegree] = useState('')
  // const [fieldOfStudy, setFieldOfStudy] = useState('')
  // const [attendanceType, setAttendanceType] = useState('')
  // const [startDate, setStartDate] = useState(null)
  // const [endDate, setEndDate] = useState(null)
  // const [grade, setGrade] = useState(0)
  // const [currentActivity, setCurrentActivity] = useState('')
  // const [activities, setActivities] = useState([])

  const attendanceTypeOptions = [
    'Full-Time',
    'Part-Time',
  ]
  const [educationRecordId, setEducationRecordId] = useState(null)
  const [updatedSchool, setUpdatedSchool] = useState(null)
  const [updatedDegree, setUpdatedDegree] = useState(null)
  const [updatedFieldOfStudy, setUpdatedFieldOfStudy] = useState(null)
  const [updatedAttendanceType, setUpdatedAttendanceType] = useState(null)
  const [updatedStartDate, setUpdatedStartDate] = useState(null)
  const [updatedEndDate, setUpdatedEndDate] = useState(null)
  const [updatedGrade, setUpdatedGrade] = useState(null)
  const [updatedCurrentActivity, setUpdatedCurrentActivity] = useState(null)
  const [updatedCurrentActivities, setUpdatedCurrentActivities] = useState([])
  const [selectedEducationDetail, setSelectedEducationDetail] = useState(false)

  if (selectedEducationHistoryDetail != null && !selectedEducationDetail) {

    const {
      id,
      school,
      degree,
      fieldOfStudy,
      attendanceType,
      startDate,
      endDate,
      grade,
      activities
    } = props.selectedEducationHistoryDetail

    setEducationRecordId(id)
    setSelectedEducationDetail(true)
    setUpdatedSchool(school || null)
    setUpdatedDegree(degree || null)
    setUpdatedFieldOfStudy(fieldOfStudy || null)
    setUpdatedAttendanceType(attendanceType ? attendanceTypeOptions.indexOf(attendanceType) : null)
    setUpdatedStartDate(new Date(startDate) || null)
    setUpdatedEndDate(new Date(endDate) || null)
    setUpdatedGrade(grade || null)
    setUpdatedCurrentActivities(activities || null)

  }

  const handleAttendanceTypeChange = (event, type) => {
    const {
      target: { value },
    } = event;

    setUpdatedAttendanceType(value)
  }

  const renderAttendanceTypes = () => {
    if (attendanceTypeOptions.length > 0) {
      return attendanceTypeOptions.map((option, index) => {
        return (
          <MenuItem value={index}>{option}</MenuItem>
        )
      })
    }
  }

  const addActivity = () => {
    const activity = {
      id: crypto.randomUUID(),
      value: updatedCurrentActivity
    }

    setUpdatedCurrentActivities(updatedCurrentActivities.concat(activity))
    setUpdatedCurrentActivity('')
  }

  // const removeActivity = (activityId) => {
  //   const updatedActivities = updatedCurrentActivities.filter((activity) => activity.id !== activityId)
  //   setUpdatedCurrentActivities(updatedActivities)
  // }

  const filterDeletedActivities = (detailId) => {
    const updatedActivities = updatedCurrentActivities.filter((detail) => detail.id !== detailId)
    setUpdatedCurrentActivities(updatedActivities)
  }

  const removeActivity = (activityId) => {

    if (updatedCurrentActivities) {
      updatedCurrentActivities.map((activity) => {
        if (activity.id === activityId && activity.temp) {
          filterDeletedActivities(activityId)
        }
        else if (activity.id === activityId && activity.temp == null) {
          dispatch(deleteEducationActivity(activityId, educationRecords, token, setSelectedEducationDetail, clearSelections))
        }
      }) 
    } 
  } 

  const renderActivities = () => {
    if (updatedCurrentActivities) {
      if (updatedCurrentActivities.length > 0) {
        return updatedCurrentActivities.map((activity) => {
          return (
            <Stack direction='row' style={{ justifyContent: 'space-between', width: '80%', margin: 5 }}>
              <Typography>{activity.value}</Typography>
              <Stack>
                <Button
                  onClick={() => removeActivity(activity.id)}
                  style={{ height: 30, width: 30, backgroundColor: colors.blazeRed }}
                >
                  <Typography style={{ color: 'white' }}>X</Typography>
                </Button>
              </Stack>
            </Stack>
          )
        })
      }
    }
  }

  const saveEducationRecord = () => {

    const educationConfig = {
      id: educationRecordId,
      userId: authedUserId,
      school: updatedSchool,
      degree: updatedDegree,
      fieldOfStudy: updatedFieldOfStudy,
      attendanceType: attendanceTypeOptions[updatedAttendanceType],
      startDate: updatedStartDate,
      endDate: updatedEndDate,
      grade: updatedGrade,
      activities: updatedCurrentActivities
    }
    
    dispatch(updateEducationRecord(educationConfig, educationRecords, token, clearSelections, setSelectedEducationDetail))
  }

  const clearSelections = () => {
    setSelectedEducationDetail(false)
    setUpdatedSchool(null)
    setUpdatedDegree(null)
    setUpdatedFieldOfStudy(null)
    setUpdatedAttendanceType(null)
    setUpdatedStartDate(null)
    setUpdatedEndDate(null)
    setUpdatedGrade(null)
    setUpdatedCurrentActivities(null)

    handleClose()
  }


  return (
    <div>
      <Modal
        open={isVisible}
        onClose={clearSelections}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title" variant="h6" component="h2"
            style={{ textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5, fontSize: 20 }}>

            EDUCATION DETAILS
          </Typography>
          <Stack style={{ marginTop: 20 }}>
            <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: '700' }}>School<span style={{ color: colors.blazeRed, fontWeight: 'bold', fontSize: 18 }}>*</span></Typography>
            <TextField
              onChange={(event) => setUpdatedSchool(event.target.value)}
              value={updatedSchool || null}
              style={{ borderWidth: 1, width: '80%' }}
            />
          </Stack>
          <Stack style={{ marginTop: 20 }}>
            <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: '700' }}>Degree</Typography>
            <TextField
              onChange={(event) => setUpdatedDegree(event.target.value)}
              value={updatedDegree || null}
              style={{ borderWidth: 1, width: '80%' }}
            />
          </Stack>
          <Stack style={{ marginTop: 20 }}>
            <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: '700' }}>Field of Study</Typography>
            <TextField
              onChange={(event) => setUpdatedFieldOfStudy(event.target.value)}
              value={updatedFieldOfStudy || null}
              style={{ borderWidth: 1, width: '80%' }}
            />
          </Stack>
          <Stack style={{ marginTop: 20 }}>
            <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: '700' }}>Attendance Type</Typography>
            <Select
              labelId="attendance-type-label"
              id="attendance-type-id"
              value={updatedAttendanceType}
              // label="Interview"
              style={{ width: '80%' }}
              onChange={(event) => handleAttendanceTypeChange(event)}
            >
              {renderAttendanceTypes()}
            </Select>
          </Stack>

          <Stack direction='row' style={{ marginTop: 20, marginBottom: 20, alignItems: 'center' }}>
            <Stack style={{ height: 60, zIndex: 2 }}>
              <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: '700' }}>Start Date<span style={{ color: colors.blazeRed, fontWeight: 'bold', fontSize: 16 }}>*</span></Typography>
              <DatePicker
                selected={updatedStartDate || null}
                onChange={(date) => setUpdatedStartDate(date)}
                popperPlacement="top-end"
                popperModifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [5, 10],
                    },
                  },
                  {
                    name: "preventOverflow",
                    options: {
                      rootBoundary: "viewport",
                      tether: false,
                      altAxis: true,
                    },
                  },
                ]}
              />
            </Stack>

            <Stack style={{ height: 60, zIndex: 3, marginLeft: 30 }}>
              <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: '700' }}>End date (or expected)</Typography>
              <DatePicker
                selected={updatedEndDate || null}
                minDate={updatedStartDate}
                onChange={(date) => setUpdatedEndDate(date)}
                popperPlacement="top-end"
                popperModifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [5, 10],
                    },
                  },
                  {
                    name: "preventOverflow",
                    options: {
                      rootBoundary: "viewport",
                      tether: false,
                      altAxis: true,
                    },
                  },
                ]}
              />
            </Stack>
          </Stack>

          <Stack style={{ marginTop: 20 }}>
            <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: '700' }}>Grade Point Average</Typography>
            <TextField
              onChange={(event) => setUpdatedGrade(event.target.value)}
              value={updatedGrade || null}
              style={{ borderWidth: 1, width: '80%' }}
            />
          </Stack>

          <Typography style={{ fontSize: 16, color: colors.plum, fontWeight: '700', paddingTop: 20 }}>Add details about your experience including any activities or societies you participated in.</Typography>
          <Stack direction='row' style={{ justifyContent: 'space-between', alignItems: 'center', width: '80%' }}>

            <TextField
              onChange={(event) => setUpdatedCurrentActivity(event.target.value)}
              value={updatedCurrentActivity}
              multiline
              rows={3}
              style={{ borderWidth: 1, width: '80%', }}
            />
            <Button
              style={{ backgroundColor: colors.lightGray, height: 45, width: 45 }}
              onClick={() => addActivity()}
            >
              <Typography style={{ color: 'black' }}>ADD</Typography>
            </Button>
          </Stack>

          {renderActivities()}

          <Stack direction='row' style={{ justifyContent: 'space-around', alignItems: 'center', marginTop: 50 }}>
            <Stack>
              <Button variant='contained'
                style={{ backgroundColor: 'silver', height: 45, width: 150 }}
                onClick={() => clearSelections()}
              >
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>CANCEL</Typography>
              </Button>
            </Stack>
            <Stack>
              <Button variant='contained'
                style={{ backgroundColor: colors.oceanBlue, height: 45, width: 150 }}
                onClick={() => saveEducationRecord()}
              >
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>SAVE</Typography>
              </Button>
            </Stack>
          </Stack>
          <Stack>
            <hr />
            <Stack>
              <Typography>Once deleted your job and its related details cannot be recovered.</Typography>
              <Button variant='contained'
                style={{ backgroundColor: colors.blazeRed, height: 45, width: 165, marginTop: 10 }}
              onClick={() => dispatch(deleteEducationRecord(educationRecordId, educationRecords, token, clearSelections))}
              >
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>DELETE RECORD</Typography>
              </Button>
            </Stack>

          </Stack>
        </Box>
      </Modal>
    </div>

  );
}

AddEducationHistoryModal.propTypes = {
  isVisible: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedEducationHistoryDetail: PropTypes.object
}