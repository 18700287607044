import moment from 'moment';
export function getLastDayOfMonth (year, month) {
  const date = new Date(year, month + 1, 0)
  return date.getDate()
}

export function formatDateSuffix (date) {
  if (date === 31) {
    return 'st'
  }
  return 'th'
}

export function timeSinceMessageSent (date) {
  if (typeof date !== 'object') {
    date = new Date(date);
  }
  var seconds = Math.floor((new Date() - date) / 1000);
  var intervalType;
  var interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    intervalType = 'year';
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = 'month';
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = 'day';
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = "hour";
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = "minute";
          } else {
            interval = seconds;
            intervalType = "second";
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    intervalType += 's';
  }
  
  return interval + ' ' + intervalType;
};

export function getFormattedDate (date) {
  const parsedDate = moment(date).format('MMMM Do, YYYY')
  return parsedDate;
}

export function getFormattedDateLong (date) {
  const parsedDate = moment(date).format('MMMM Do YYYY h:mm:ss a')
  return parsedDate;
}

export function getMonth(date) {
  return moment(date).format('MMMM')
}

export function getNextMonth() {
  return moment(new Date()).add(1, 'months').format('MMMM')
}

export function getTenseForCancellation (currentPeriodEnd) {
    let currentDate = moment(new Date())

    return currentDate.isSameOrBefore(currentPeriodEnd) ? 'ends' : 'ended'

}

export function getCalendarDate (date) {
  const parsedDate = moment(date).format('MMM Do, YYYY')
  return parsedDate;
}

export function getAbbreviateMonthAndDay(date) {
  const parsedDate = moment(date).format('MMM Do')
  return parsedDate;
}

export function getMonthFromName(month) {
  return moment().month(month).format("M");
}

export function getResumeDate(date) {
  return moment(date).format("MMMM YYYY")
}

export function getDatesInclusive(dayOfWeek, startDate, endDate) {
  const dates = [];
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  
  // Find the first occurrence of the specified day of the week after the start date
  let currentMoment = startMoment.clone().startOf('week').day(dayOfWeek);
  
  // Add the dates to the list until reaching the end date
  while (currentMoment.isSameOrBefore(endMoment)) {
    dates.push(currentMoment.toDate());
    currentMoment.add(1, 'week');
  }
  
  return dates;
}

export function getDateAWeekFromNow(startDate) {
  // const currentDate = moment(startDate);
  // 2023-10-30T10:28:18.838Z
  // MMMM Do YYYY h:mm:ss a
  return moment().add(1, 'week').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');


}

export function isDateLessThanOrEqualToCurrentPlusSixDays(date) {
  // Create a Moment object for the current date
  const currentDate = moment();

  // Add 6 days to the current date
  const futureDate = currentDate.add(6, 'days');

  // Create a Moment object for the input date
  const inputDate = moment(date);

  // Compare the input date with the future date
  return inputDate.isSameOrBefore(futureDate, 'day');
}

export function getDaysAway(date) {
  // Create a Moment object for the current date
  const currentDate = moment();

  // Create a Moment object for the input date
  const inputDate = moment(date);

  // Calculate the difference in days between the current date and the input date
  const daysAway = inputDate.diff(currentDate, 'days');

  return daysAway;
}