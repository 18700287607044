import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import StandardButton from '../../components/buttons/StandardButton'
import { colors } from '../../constants/Colors'
import { useParams } from 'react-router-dom'
import { isIdentifcal, isValidEmailFormat, isValidPassword } from '../../utils/PasswordUtils';
import { finalizeUserRegistration } from '../../redux/actions/authenticationActions'
import TermsAndPrivacy from './TermsAndPrivacy.pdf';

import INTERVIEWABLE_LOGO from '../../assets/interviewable-logo.png'
import { showErrorToast } from '../../utils/ToastUtils'

import styles from './CompleteRegistrationScreen.module.css'

export default function CompleteRegistrationScreen({ onLogin }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { userId, registrationToken } = useParams()

  useEffect(() => {
    setStudentId(userId)
  })

  const [studentId, setStudentId] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmedPassword, setConfirmedPassword] = useState('')
  const [agree, setAgree] = useState(false);
  const [termsModalVisible, setTermsModalVisible] = useState(false)

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree);
    // Don't miss the exclamation mark
  }


  const completeRegistration = () => {

    if (firstName === '') {
      showErrorToast('First name is required.')
      return;
    }
    if (lastName === '') {
      showErrorToast('Last name is required.')
      return;
    }
    if (email === '') {
      showErrorToast('Email address is required.')
      return;
    }
    if (!isValidEmailFormat(email)) {
      showErrorToast('Please provide a valid email.')
      return;
    }
    if (!agree) {
      showErrorToast('Please accept Terms of Service and Privacy Policy')
      return;
    }
    if (password === '') {
      showErrorToast('Password is required.')
      return;
    }
    if (!isValidPassword(password)) {
      showErrorToast('Password must be at leat 8 characters, contain at least 1 number, and at least 1 symbol.')
      return;
    }
    if (confirmedPassword === '') {
      showErrorToast('Password confirmation is required.')
      return;
    }

    if (!isIdentifcal(password, confirmedPassword)) {
      showErrorToast('Passwords do not match!')
      return;
    }

    const userConfig = {
      id: userId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      token: registrationToken,
    }

    dispatch(finalizeUserRegistration(userConfig, onLogin))
  }

  const deactiveSubmitButton = () => {
    if (firstName === '' || lastName === '' || email === '' || password === '' || confirmedPassword === '') {
      return true;
    }
    else if (agree == false) {
      return true;
    }
    return false;
  }

  const renderTermsAndConditions = () => {
      const style =
      {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        height: '70vh',
        width: 850,
        bgcolor: "background.paper",
        border: "1px solid #000",
        boxShadow: 24,
        borderRadius: 5,
        p: 4,
      }
  
  
      return (
        <div>
          <input type="checkbox" id="agree" onChange={checkboxHandler} className={styles.inputStyle} />
          <label style={{ paddingLeft: '10px' }} htmlFor="agree"> I agree to the
            <Button onClick={() => setTermsModalVisible(true)}>Terms of Service & Privacy Policy</Button>
            <Modal
              open={termsModalVisible}
              onClose={() => setTermsModalVisible(false)}
            >
              <Box sx={style}>
                <iframe src={TermsAndPrivacy} width='100%' height='100%' />
              </Box>
            </Modal>
          </label>
        </div>
      );
    
  }



  return (
    <div style={{ top: '50%', left: '50%', display: 'flex', flex: 1, justifyContent: 'center', alignContent: 'center' }}>
      <Stack style={{ width: '50%' }}>
        <Stack style={{ marginBottom: 30 }}>
          <img src={INTERVIEWABLE_LOGO} style={{ width: 250 }} />
        </Stack>
        <form>
        <Typography className={styles.inputWrapper}>Confirm your email address</Typography>
        <Stack>
          <TextField
            value={email}
            type='email'
            onChange={(event) => setEmail(event.target.value)}
            style={{ width: '100%' }}
          />
        </Stack>
        <Typography className={styles.inputWrapper}>First Name</Typography>
        <Stack> 
          <TextField
            value={firstName}
            type='text'
            onChange={(event) => setFirstName(event.target.value)}
            style={{ width: '100%' }}
          />
        </Stack>
        <Typography className={styles.inputWrapper}>Last Name</Typography>
        <Stack>
          <TextField
            value={lastName}
            type='text'
            onChange={(event) => setLastName(event.target.value)}
            style={{ width: '100%' }}
          />
        </Stack>
        <Typography className={styles.inputWrapper}>Enter a password:</Typography>
        <Stack>
          <TextField
            value={password}
            type='password'
            onChange={(event) => setPassword(event.target.value)}
            style={{ width: '100%' }}
          />
        </Stack>
        <Typography className={styles.inputWrapper}>Confirm your password:</Typography>
        <Stack>
          <TextField
            value={confirmedPassword}
            type='password'
            onChange={(event) => setConfirmedPassword(event.target.value)}
            style={{ width: '100%' }}
          />
        </Stack>
        <Stack className={styles.checkboxWrapper}>
          {renderTermsAndConditions()}
        </Stack>
        </form>
        <Stack style={{ marginTop: 30 }}>
          <StandardButton
            inactiveCriteria={deactiveSubmitButton()}
            label='COMPLETE SIGN UP'
            backgroundColor={deactiveSubmitButton() === true ? 'silver' : colors.oceanBlue}
            buttonWidth={'100%'}
            onPress={() => completeRegistration()}

          />
        </Stack>

      </Stack>

    </div>
  )
}