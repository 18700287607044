import {
  CREATING_INDIVIDUAL_ACCOUNT,
  CREATED_INDIVIDUAL_ACCOUNT,
  ERROR_CREATING_INDIVIDUAL_ACCOUNT,
  SET_LOGIN_TOKEN,
  LOGGING_IN_USER,
  LOGGED_IN_USER,
  ERROR_LOGGING_IN_USER,
  LOG_OUT_USER,
  FINALIZING_USER_REGISTRATION,
  FINALIZED_USER_REGISTRATION,
  ERROR_FINALIZING_USER_REGISTRATION,
  CHANGING_PASSWORD,
  CHANGED_PASSWORD,
  ERROR_CHANGING_PASSWORD,
  VERIFYING_SOLO_ACCOUNT,
  VERIFIED_SOLO_ACCOUNT,
  ERROR_VERIFYING_SOLO_ACCOUNT
} from '../constants/authenticationActionTypes'

import Cookies from 'js-cookie';

import { Resend } from 'resend';

import { retrieveIndividualCheckIns } from './checkInActions'

import { checkSubscriptionOnAccount, retrieveCohortsForOrg, retrieveUsersForOrg, setOrganizationId } from './organizationActions'
import { setUserPreferences, setUserMetaData, retrieveUserResumes } from './userActions'

import axios from 'axios'


import { getAxiosInstance } from '../../utils/ApiUtils'
import { showErrorToast, showSuccessToast } from '../../utils/ToastUtils'
import { retrieveCreditsForAccount, setAccountDetails, setStripeCustomerId } from './accountActions'

import config from '../../env.json'
const { BASE_URI, RESEND_KEY } = config


export const logOutUser = () => {
  return {
    type: LOG_OUT_USER
  }
}

export const setLoginToken = (token) => {
  return {
    type: SET_LOGIN_TOKEN,
    payload: token
  }
}

// LOG IN USEER
export const loggingInUser = () => {
  return {
    type: LOGGING_IN_USER
  }
}

export const loggedInUser = (userDate) => {
  return {
    type: LOGGED_IN_USER,
    payload: userDate
  }
}

export const errorLoggingInUser = (error) => {
  return {
    type: ERROR_LOGGING_IN_USER,
    payload: error
  }
}

export const logInUser = (userConfig, onLogin) => {
  return function (dispatch, getState) {
    dispatch(loggingInUser())

    return axios.post(`${BASE_URI}/auth/login`, userConfig)
      .then(res => {
        if (res.status === 200) {
          // console.log('Auth: ', res.data)
          const { token, planType, role, featureFlags } = res.data
          sessionStorage.setItem('token', token)
          sessionStorage.setItem('planType', planType)
          sessionStorage.setItem('role', role)
          sessionStorage.setItem('featureFlags', featureFlags)
          // console.log("FF", featureFlags)
          if (role === 'ADMIN' || role === 'SUPER_ADMIN') {
            if (planType === 'TEAM') {
              dispatch(setOrganizationId(res.data.organizationId))
              dispatch(retrieveCohortsForOrg(res.data.organizationId, res.data.token))
              dispatch(retrieveUsersForOrg(res.data.organizationId, res.data.token))
            }
            else if (planType === 'INDIVIDUAL') {
              dispatch(retrieveIndividualCheckIns(res.data.id, res.data.token))
              dispatch(retrieveUserResumes(res.data.id, token))
              // dispatch(checkSubscriptionOnAccount(res.data.id, token))
              dispatch(setAccountDetails(res.data.account))
              dispatch(setStripeCustomerId(res.data.stripeCustomerId))
              dispatch(retrieveCreditsForAccount(res.data.id, token))
            }

            dispatch(setUserMetaData(res.data))
            dispatch(setUserPreferences(res.data.userProfile))

          }
          else if (role === 'STUDENT') {
            dispatch(retrieveIndividualCheckIns(res.data.id, res.data.token))
            dispatch(retrieveUsersForOrg(res.data.organizationId, res.data.token))
            dispatch(setUserPreferences(res.data.userProfile))
            dispatch(setAccountDetails(res.data.account))
            dispatch(setUserMetaData(res.data))
          }
          Cookies.set('token', token, { path: '/', expires: 7, secure: true, });
          dispatch(loggedInUser(res.data))
          onLogin(planType, role)

        }
      })
      .catch(err => {
        showErrorToast('Uh oh! Please check your email address and/or password.')
        dispatch(errorLoggingInUser())

      })
  }
}


// Create individual account
export const creatingIndividualAccount = () => {
  return {
    type: CREATING_INDIVIDUAL_ACCOUNT
  }
}

export const createdIndividualAccount = (orgData) => {
  return {
    type: CREATED_INDIVIDUAL_ACCOUNT,
    payload: orgData
  }
}

export const errorCreatingIndividualAccount = (error) => {
  return {
    type: ERROR_CREATING_INDIVIDUAL_ACCOUNT,
    payload: error
  }
}

export const createIndividualAccount = (userConfig, navigate) => {
  return function (dispatch, getState) {
    dispatch(creatingIndividualAccount())

    return axios.post(`${BASE_URI}/accounts/individual/create`, userConfig)
      .then(res => {
        if (res.status === 200) {
          dispatch(createdIndividualAccount(res.data))
          dispatch(loggedInUser(res.data.admin))
          navigate();

        }
      })
      .catch(err => {
        dispatch(errorCreatingIndividualAccount())

      })
  }
}


// Finalize User Registration
export const finalizingUserRegistration = () => {
  return {
    type: FINALIZING_USER_REGISTRATION
  }
}

export const finalizedUserRegistration = (user) => {
  return {
    type: FINALIZED_USER_REGISTRATION,
    payload: user
  }
}

export const errorFinalizingUserRegistration = (error) => {
  return {
    type: ERROR_FINALIZING_USER_REGISTRATION,
    payload: error
  }
}

export const finalizeUserRegistration = (userConfig, onLogin) => {
  return function (dispatch, getState) {
    // dispatch(finalizingUserRegistration())

    const { id } = userConfig

    return axios.post(`${BASE_URI}/accounts/users/complete-registration`, userConfig)
      .then(res => {
        if (res.status === 200) {
          // dispatch(finalizedUserRegistration(res.data))
          dispatch(logInUser(userConfig, onLogin))
          // dispatch(setOrganizationId(res.data.organizationId))
          // Cookies.set('token', res.data.token, { path: '/', expires: 7, secure: true, });
          // navigateHome()  
        }
      })
      .catch(err => {
        showErrorToast('Error validating your account. Please reach out to support for assistance.')
        // dispatch(errorFinalizingUserRegistration())

      })
  }
}

// Change Password
export const changingPassword = () => {
  return {
    type: CHANGING_PASSWORD
  }
}

export const changedPassword = () => {
  return {
    type: CHANGED_PASSWORD
  }
}

export const errorChangingPassword = (error) => {
  return {
    type: ERROR_CHANGING_PASSWORD,
    payload: error
  }
}

export const changeUserPassword = (passwordConfig, token, onLogout) => {
  return function (dispatch, getState) {
    dispatch(changingPassword())

    return getAxiosInstance(token).put(`${BASE_URI}/auth/password`, passwordConfig)
      .then(res => {
        if (res.data == true) {
          dispatch(changedPassword())
          showSuccessToast('Successfully changed password!')
          onLogout()
        }
      })
      .catch(err => {
        dispatch(errorChangingPassword())
        showErrorToast('Error changing password. Please try again later.')
      })
  }
}


// Verifying Solo Account
export const verifyingSoloAccount = () => {
  return {
    type: VERIFYING_SOLO_ACCOUNT
  }
}

export const verifiedSoloAccount = () => {
  return {
    type: VERIFIED_SOLO_ACCOUNT
  }
}

export const errorVerifyingSoloAccount = (error) => {
  return {
    type: ERROR_VERIFYING_SOLO_ACCOUNT,
    payload: error
  }
}

export const verifySoloAccount = (config, onLogin) => {
  return function (dispatch, getState) {
    dispatch(verifyingSoloAccount())

    return axios.put(`${BASE_URI}/auth/verify`, config)
      .then(res => {
        if (res.status === 200) {

          showSuccessToast('Successfully verified your account!')
          loginAdminAfterVerification(res, onLogin, dispatch)
          // dispatch(loggedInUser(res.data))
          // navigateToHome()
        }
      })
      .catch(err => {
        dispatch(errorVerifyingSoloAccount())
        showErrorToast('Error verifying your account. Please contact support if you continue to run into this issue.')
      })
  }
}


// Login In w/out Password
const loginAdminAfterVerification = (res, onLogin, dispatch) => {
  const { token, planType, role } = res.data
  sessionStorage.setItem('token', token)
  sessionStorage.setItem('planType', planType)
  sessionStorage.setItem('role', role)

  if (role === 'ADMIN' || role === 'SUPER_ADMIN') {
    if (planType === 'TEAM') {
      dispatch(setOrganizationId(res.data.organizationId))
      dispatch(retrieveCohortsForOrg(res.data.organizationId, res.data.token))
      dispatch(retrieveUsersForOrg(res.data.organizationId, res.data.token))
    }
    else if (planType === 'INDIVIDUAL') {
      dispatch(retrieveIndividualCheckIns(res.data.id, res.data.token))
      dispatch(setUserMetaData(res.data))
      dispatch(retrieveUserResumes(res.data.id, token))
      // dispatch(checkSubscriptionOnAccount(res.data.id, token))
      dispatch(setAccountDetails(res.data.account))
      dispatch(setStripeCustomerId(res.data.stripeCustomerId))
      dispatch(retrieveCreditsForAccount(res.data.id, token))
    }

    dispatch(setUserPreferences(res.data.userProfile))

  }
  else if (role === 'STUDENT') {
    dispatch(retrieveIndividualCheckIns(res.data.id, res.data.token))
    dispatch(retrieveUsersForOrg(res.data.organizationId, res.data.token))
    dispatch(setUserPreferences(res.data.userProfile))
    dispatch(setAccountDetails(res.data.account))
    dispatch(setUserMetaData(res.data))
  }
  Cookies.set('token', token, { path: '/', expires: 7, secure: true, });
  dispatch(loggedInUser(res.data))
  onLogin(planType, role)

}