import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Cookies from 'js-cookie';

import { TextField, Container } from '@mui/material';
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack'
import StandardButton from "../../components/buttons/StandardButton";

import { Link, Navigate } from 'react-router-dom'
import { logInUser } from '../../redux/actions/authenticationActions'
import { colors } from "../../constants/Colors";

import LOGO from '../../assets/interviewable-logo.png'

const LoginScreen = ({ onLogin }) => {

  const token = Cookies.get('token')

  useEffect(() => {

  }, [token])

  const dispatch = useDispatch()


  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const role = sessionStorage.getItem('role')
  const planType = sessionStorage.getItem('planType')

  const signIn = () => {
    const loginData = {
      email: email,
      password: password
    }

    dispatch(logInUser(loginData, onLogin))

  }

  const remainUnlessAuthed = () => {
    if (token == null) {
      return <Navigate to="/login" />
    }
  }
  return (
    <Stack style={{ display: 'flex', marginTop: '15%' }}>
      {/* {remainUnlessAuthed()} */}
      {/* {token !== '' && (
        <Navigate to="/" replace={true} />
      )} */}
      <Stack style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Stack>
          <img src={LOGO} style={{ width: '100%', height: 100 }} />
        </Stack>
        <form>
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <Stack style={{ width: '100%', marginTop: 10 }}>
            <StandardButton
              label='SIGN IN'
              onPress={signIn}
              backgroundColor={colors.oceanBlue}
              buttonWidth='100%'
            />
          </Stack>
        </form>


        <Stack direction='row' style={{ marginTop: 40, justifyContent: 'space-between'}} md={8} sm={4} spacing={2}>
          <Link to={"/signup"} style={{ color: 'black', fontWeight: 'bold', textShadow: '1,2,3', textDecoration: 'none' }}>Create Account</Link>
          <Link to={"/reset/password"} style={{ color: 'black', fontWeight: '200', textShadow: '1,2,3', textDecoration: 'none' }}>Reset Password</Link>
        </Stack>

      </Stack>

    </Stack>
  );
};

export default LoginScreen;
