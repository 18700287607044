import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { Stack, Button, Container, Icon, Typography } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import SidebarNav from '../../components/navigation/SidebarNav'


import { getAbbreviateMonthAndDay, getCalendarDate, getDaysAway, isDateLessThanOrEqualToCurrentPlusSixDays } from '../../utils/DateUtils'
import { colors } from '../../constants/Colors';

import BarChart from '../../components/charts/BarChart'
import HighFiveTile from '../../components/checkIns/HighFiveTile';
import { retrieveHighFivesForCohort } from '../../redux/actions/checkInActions';


export default function CheckInsHomeScreen(props) {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const planType = sessionStorage.getItem('planType')
  const token = Cookies.get('token');

  const checkIns = useSelector((state) => state.checkIns.checkIns)
  const highFives = useSelector((state) => state.checkIns.highFives)
  const cohortId = useSelector((state) => state.user.cohortId)

  const [chartData, setChartData] = useState([])
  const [chartLabels, setChartLabels] = useState([])

  useEffect(() => {
    let data = []
    let labels = []
    if (checkIns) {
      checkIns.map((checkIn) => {
        labels.push(getAbbreviateMonthAndDay(checkIn.dueDate))
        if (checkIn.checkInStatus === 'COMPLETE') {
          data.push(checkIn.sentiment)

        }
      })
      setChartData(data)
      setChartLabels(labels)
    }
    if (planType === 'TEAM') {
      dispatch(retrieveHighFivesForCohort(cohortId, token))
    }

  }, [checkIns])

  const renderCheckInDueDate = (nextCheckIn) => {

    const checkInDaysAway = getDaysAway(nextCheckIn.dueDate)
    if (checkInDaysAway < 0) {
      return <Typography>Your check-in is overdue by <span style={{ color: colors.plum, fontWeight: 'bold' }}>{getDaysAway(nextCheckIn.dueDate) + (-checkInDaysAway * 2)}</span> days.</Typography>
    }
    else if (checkInDaysAway > 0) {
      return <Typography>Your next check-in is due in <span style={{ color: colors.plum, fontWeight: 'bold' }}>{getDaysAway(nextCheckIn.dueDate) + 1}</span> days.</Typography>
    }
    else if (checkInDaysAway == 0) {
      return <Typography>Your next check-in is due today.</Typography>
    }
  }

  const renderNextCheckIn = () => {
    if (checkIns.length > 0) {

      let checkInList = checkIns.filter((checkIn) => isDateLessThanOrEqualToCurrentPlusSixDays(checkIn.dueDate))
      let nextCheckIn;
      if (checkInList.length > 0) {
        nextCheckIn = checkInList[checkInList.length - 1]
      }
      if (nextCheckIn && nextCheckIn.checkInStatus == 'NOT_COMPLETE') {
        return (
          <Stack>
            {renderCheckInDueDate(nextCheckIn)}
            <Stack style={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button
                onClick={() => navigate(`/checkins/new/${nextCheckIn.id}`)}
                variant='contained'
                style={{ backgroundColor: `${colors.plum}`, height: 30, width: '80%', marginTop: 10 }}
              >
                <Typography>
                  Complete Now
                </Typography>
              </Button>
            </Stack>

          </Stack>
        )
      }
      else {
        return (
          <Stack>
            <Typography>All done! You've completed your check-in for this week.</Typography>
          </Stack>
        )

      }

    }
  }


  const headerStyle = {
    fontWeight: 'bold',
    fontSize: 16,
    color: colors.plum,
  }

  const renderCompletedCheckIns = () => {
    if (checkIns) {
      if (checkIns.length > 0) {
        return checkIns.map((checkIn) => {
          if (checkIn.checkInStatus === 'COMPLETE') {
            return (
              <TableRow
                key={checkIn.id}
                style={{ width: '70%' }}
                hover
                onClick={() => navigate(`/checkins/${checkIn.id}`, { state: { checkIn } })}
              >
                <TableCell align="center">{getCalendarDate(checkIn.dueDate)}</TableCell>
                <TableCell align="center">{checkIn.checkInSentiment.sentiment}</TableCell>
                <TableCell align="center">{checkIn.inputs ? checkIn.inputs.length : 0}</TableCell>
                {planType === 'TEAM' ? <TableCell align="center">{checkIn.highFives ? checkIn.highFives.length : 0}</TableCell> : null}

              </TableRow>
            )
          }
        })
      }
      else {
        return <Typography>No completed check-ins to show.</Typography>
      }

    }

  }

  const renderHighFives = () => {
    
      if (highFives.length > 0) {
        return highFives.map((highFive) => {
          return <HighFiveTile 
            key={highFive.id} 
            dateCreated={highFive.dateSent} 
            value={highFive.value} 
            senderFirstName={highFive.sender.firstName} 
            senderLastName={highFive.sender.lastName}
            senderEmail={highFive.sender.email} 
          />
        })
      }
      else {
        return <Typography>No shout outs to share. Be the first to give props for someone going above and beyond on your next check-in.</Typography>
      }
  }

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <SidebarNav />
      <Stack style={{ width: '100%' }}>
        <Stack style={{ width: '40dvw', marginLeft: 20, marginTop: 20 }}>
          <Typography style={{ fontSize: 18 }}>Weekly Check-Ins provide a great way to track your progress. Measuring change over time helps to objectively quanitfy your indivdual outputs and impact.  </Typography>
        </Stack>
        <hr style={{ width: '45dvw', marginLeft: 10 }} />
        <Stack direction='row' style={{ paddingBottom: 60, width: '100dvw'}}>
          <Stack style={{ width: '65dvw' }}>

            <Stack style={{ marginLeft: 50, width: '100%', marginLeft: 50, marginRight: 20, marginTop: 10 }}>
              <Stack style={{ width: '60dvw' }}>
                <Stack>
                  <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4 }}>WEEKLY SENTIMENT</Typography>
                  <BarChart chartData={chartData} chartLabels={chartLabels} />
                </Stack>
                <Stack style={{ marginLeft: 10, justifyContent: 'flex-start', alignItems: 'center', marginTop: 40 }} direction='row'>
                  <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4 }}>MY COMPLETED CHECK-INS</Typography>
                </Stack>
                <Stack style={{ width: '100%' }}>
                  <TableContainer >
                    <Table style={{ width: '40dvw' }} aria-label="simple table">
                      <TableHead>
                        <TableRow style={{ width: '70%' }}>
                          <TableCell component="th" scope="row" align="center"> <Typography style={headerStyle}>Due Date</Typography></TableCell>
                          <TableCell align="center"> <Typography style={headerStyle}>Sentiment</Typography></TableCell>
                          <TableCell align="center"> <Typography style={headerStyle}>Details</Typography></TableCell>
                          {planType === 'TEAM' ? <TableCell align="center"> <Typography style={headerStyle}>High Fives</Typography></TableCell> : null}

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {renderCompletedCheckIns()}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          
          <Stack style={{ width: '15dvw', alignItems: 'flex-end', marginRight: 20 }}>
            <Stack>
            <Typography style={{ textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5, fontSize: 20 }}>Next Check In</Typography>
            <Stack style={{ border: `1px solid ${colors.lightGray}`, width: '18dvw', height: 150, borderRadius: 10, padding: 20, boxShadow: '1px 2px 10px 2px silver', marginTop: 20 }}>
              <Stack style={{ justifyContent: 'center', alignItems: 'center' }}>
                {renderNextCheckIn()}
              </Stack>
            </Stack>
            </Stack>

           
            <Stack style={{ marginTop: 30 }}>
              <Typography style={{ textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5, fontSize: 20 }}>Shout Outs</Typography>
              <Stack style={{ border: `1px solid ${colors.lightGray}`, width: '18dvw', height: '45dvh', borderRadius: 10, padding: 20, boxShadow: '1px 2px 10px 2px silver', marginTop: 20, overflowY: 'scroll' }}>
                {renderHighFives()}
              </Stack>
            </Stack>

          </Stack>
        </Stack>

      </Stack>

    </div>
  )
}