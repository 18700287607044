import React from 'react'

import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import { colors } from '../../constants/Colors'
import { Typography } from '@mui/material'

const StandardButton = (props) => {

  const { 
    label, 
    onPress, 
    buttonWidth,
    backgroundColor,
    labelColor,
    inactiveCriteria
  } = props

  return (
    <Button
      variant='contained'
      disabled={inactiveCriteria ? inactiveCriteria : false}
      style={{
        width: buttonWidth ? buttonWidth: 210, 
        backgroundColor: backgroundColor ? backgroundColor : 'white',
        height: 60,
        color: 'white',
        fontSize: 18,
      }}
      onClick={() => onPress()}
  >
    <Typography style={{fontWeight: 'bold', fontSize: 20, color: labelColor ? labelColor: 'white'}}>{label}</Typography>
  </Button>
  )
}

const buttonStyle = {
  height: 65,
  color: 'white'
}


StandardButton.propTypes = {
  label: PropTypes.string,
  onPress: PropTypes.func,
  buttonWidth: PropTypes.number,
  backgroundColor: PropTypes.string,
  labelColor: PropTypes.string,
  inactiveCriteria: PropTypes.bool
}



export default StandardButton