import React, { useEffect, useState } from 'react'

import Cookies from 'js-cookie';
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import SidebarNav from '../../components/navigation/SidebarNav'
import { } from '@mui/material'
import StandardButton from '../../components/buttons/StandardButton'
import { useNavigate } from 'react-router-dom'
import { colors } from '../../constants/Colors'

import styles from './AssessmentAdminHomeScreen.module.css'
import { getAxiosInstance } from '../../utils/ApiUtils'
import { useSelector } from 'react-redux';
import AssessmentTable from '../../components/assessments/AssessmentsTable';
import AssessmentResultsModal from '../../components/modals/assessments/AssessmentResultsModal';
import config from '../../env.json'

export default function AssessmentAdminHomeScreen() {

  const navigate = useNavigate()

  const { BASE_URI } = config
  const token = Cookies.get('token');

  const organizationId = useSelector((state) => state.org.id)
  const [assessmentsResultsModalVisible, setAssessmentResultsModalVisible] = useState(false)
  const [completedAssessments, setCompletedAssessments] = useState([])
  const [scheduledAssessments, setScheduledAssessments] = useState([])
  const [inProgressAssessments, setInProgressAssessments] = useState([])
  const [selectedCohortId, setSelectedCohortId] = useState(null)
  const [selectedAssessmentId, setSelectedAssessmentId] = useState(null)
  const [cohorts, setCohorts] = useState([])

  useEffect(() => {
    getAxiosInstance(token).get(`${BASE_URI}/org/cohorts/${organizationId}`)
      .then((res) => {
        if (res.status == 200) {
          setCohorts(res.data)
        }
      })
      .catch((error) => { })

    if (selectedCohortId) {
      getAxiosInstance(token).get(`${BASE_URI}/assessments/interviews/${selectedCohortId}`)
        .then((res) => {
          if (res.status == 200) {
            setCompletedAssessments(res.data.completed)
            setInProgressAssessments(res.data.inProgress)
            setScheduledAssessments(res.data.scheduled)
          }
        })
    }
  }, [selectedCohortId])



  const renderCohorts = () => {
    if (cohorts) {
      return cohorts.map((cohort, index) => {
        return (
          <MenuItem key={index} value={cohort.id}>{cohort.name}</MenuItem>
        )
      })
    }
  }

  return (
    <div className={styles.wrapper} >
      <SidebarNav />
      <Stack className={styles.assessmentWrapper}>
        <Stack className={styles.assessmentActionButtonRow}>
          <StandardButton
            backgroundColor={colors.oceanBlue}
            label='Create Assessment'
            buttonWidth={300}
            onPress={() => navigate('/assessments/new/interview')}
          />
        </Stack>
        <Stack className={styles.selectCohortWrapper}>
          <Typography>Select a Cohort</Typography>
          <Select
            id="simple-select"
            value={selectedCohortId ? selectedCohortId : ''}
            onChange={(event) => setSelectedCohortId(event.target.value)}
            className={styles.selectStyle}
          >
            {renderCohorts()}
          </Select>
        </Stack>
        <Stack className={styles.assessmentTableWrapper}>
          <Typography
            id="modal-modal-title" variant="h6" component="h2"
            style={{ textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5, fontSize: 20 }}>
            In Progress Evaluations

          </Typography>
          <AssessmentTable
            assessments={inProgressAssessments}
            showCompletionPercentage={true}
            setSelectedAssessmentId={setSelectedAssessmentId}
            setReultsModalVisible={setAssessmentResultsModalVisible}
          />
        </Stack>
        <Stack className={styles.assessmentTableWrapper}>
          <Typography
            id="modal-modal-title" variant="h6" component="h2"
            style={{ textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5, fontSize: 20 }}>
            Upcoming Evaluations
          </Typography>
          <AssessmentTable
            assessments={scheduledAssessments}
            showCompletionPercentage={true}
            setSelectedAssessmentId={setSelectedAssessmentId}
            setReultsModalVisible={setAssessmentResultsModalVisible}
          />
        </Stack>
        <Stack className={styles.assessmentTableWrapper}>
          <Typography
            id="modal-modal-title" variant="h6" component="h2"
            style={{ textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5, fontSize: 20 }}>
            Completed Evaluations
          </Typography>
          <AssessmentTable
            assessments={completedAssessments}
            showCompletionPercentage={true}
            setSelectedAssessmentId={setSelectedAssessmentId}
            setReultsModalVisible={setAssessmentResultsModalVisible}
          />
        </Stack>
      </Stack>
      <AssessmentResultsModal 
        assessmentId={selectedAssessmentId}
        isVisible={assessmentsResultsModalVisible}
        handleClose={() => setAssessmentResultsModalVisible(false)}
      />
    </div>
  )
}