import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";

import Cookies from 'js-cookie';

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from '@mui/material/CircularProgress';

import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField';
import config from '../../../env.json'

import StandardButton from '../../buttons/StandardButton';
import { colors } from '../../../constants/Colors';
import { getAxiosInstance } from '../../../utils/ApiUtils';
import { showErrorToast, showSuccessToast } from '../../../utils/ToastUtils';

export default function ResumeAiModal(props) {
  const authedUserId = useSelector((state) => state.auth.id)
  const [isLoading, setIsLoading] = useState(false)
  const [resumeURI, setResumeURI] = useState(null)
  const [jobTitle, setJobTitle] = useState('')
  const [jobDescription, setJobDescription] = useState('')
  const [generateCoverLetter, setGenerateCoverLetter] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = Cookies.get('token');
  const { BASE_URI } = config



  const { isVisible, handleClose, resume, setResumeUploading } = props

  const submitResumeAi = () => {
    const resumeAiConfig = {
      resumeId: resume.id,
      role: jobTitle,
      generateCoverLetter: generateCoverLetter,
      jobDescription: jobDescription
    }
    const documentText = generateCoverLetter ? 'documents' : 'document'
    setResumeUploading(true)
    handleClose()

    getAxiosInstance(token).post(`${BASE_URI}/resume/ai/new`, resumeAiConfig)
      .then((res) => {
        if (res.status == 200) {
          setResumeUploading(false)
          showSuccessToast(`Successfully generated your ${documentText}!`)
          navigate(`/resume/${authedUserId}`)
        }
      })
      .catch((error) => {
        setResumeUploading(false)
        showErrorToast(`Uh oh... there was an issue creating your ${documentText}.`)
      })
  }

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setGenerateCoverLetter(!generateCoverLetter);
    // Don't miss the exclamation mark
  }

  const deactiveSubmitButton = () => {
    if (!jobTitle || !jobDescription) {
      return true
    }
    return false
  }

  return (
    <div>
      <Modal
        open={isVisible}
        onClose={() => {
          handleClose()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Stack>
            {isLoading ?
              <Box>
                <CircularProgress />
              </Box> :
              <Stack>
                <Typography style={{ fontWeight: "bold", marginBottom: 30 }}>
                  With ResumeAI, we will leverage your existing resume to bring it to the top of the stack based on preferences of job board algorithms and talent acquisition teams.
                  <Typography>&nbsp;</Typography>
                  Improve the chances of follow ups from recruiters and hiring managers by creating custom resumes artfully crafted to each role you will be applying to. Fill in the fields below to generate a new resume and cover letter.
                </Typography>
                <Typography style={{ fontSize: 18 }}> Job Title<span style={{color: colors.blazeRed}}>*</span></Typography>
                <TextField
                  type="jobTitle"
                  defaultValue="Product Manager..."
                  value={jobTitle}
                  onChange={(event) => setJobTitle(event.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
                <Typography style={{ fontSize: 18 }}> Job Description<span style={{color: colors.blazeRed}}>*</span></Typography>
                <TextField
                  type="jobDescription"
                  multiline
                  defaultValue="Input a job description for the role here..."
                  rows={4}
                  value={jobDescription}
                  onChange={(event) => setJobDescription(event.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
                <Stack style={{marginTop: 10, alignItems: 'center'}} direction='row'>
                  <input type="checkbox" id="generateCoverLetter" onChange={checkboxHandler} style={{width: 25, height: 25, accentColor: colors.oceanBlue}} />
                  <label htmlFor="agree" style={{paddingLeft: 10}}>Generate Custom Cover Letter</label>
                </Stack>
                <Stack style={{marginTop: 20}}>
                <StandardButton
                  label={'Generate'}
                  buttonWidth={300}
                  onPress={() => submitResumeAi()}
                  inactiveCriteria={deactiveSubmitButton()}
                  style={{ marginTop: '30px', }}
                  backgroundColor={deactiveSubmitButton() == true ? 'silver' : colors.oceanBlue}
                />
                </Stack>
              </Stack>
            }
          </Stack>
        </Box>
      </Modal>
    </div >
  )

}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  padding: 40,
  height: '80%',
  overflowY: 'scroll',
  p: 4,
};


ResumeAiModal.propTypes = {
  isVisible: PropTypes.bool,
  handleClose: PropTypes.func,
  resume: PropTypes.string
}