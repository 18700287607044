import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import Cookies from 'js-cookie';

import { Button, Typography, Grid, } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack'

import AddCircleIcon from '@mui/icons-material/AddCircle';
import ButtonBase from '@mui/material/ButtonBase';

import SidebarNav from '../../components/navigation/SidebarNav';
import DeleteConfirmationModal from '../../components/modals/billing/DeleteConfirmationModal';
import AddTeamMemberModal from '../../components/modals/billing/AddTeamMemberModal'

import { cancelSubscription, checkSubscriptionOnAccount, removeMemberFromTeamAccount } from '../../redux/actions/organizationActions';
import { getFormattedDate, getMonthFromName, getTenseForCancellation } from '../../utils/DateUtils';

import amex from '../../assets/american-express.png'
import discover from '../../assets/discover.png'
import mastercard from '../../assets/mastercard.png'
import visa from '../../assets/visa.png'
import receipt from '../../assets/receipt-icon.png'
import { colors } from '../../constants/Colors';

import config from '../../env.json'
import { getAxiosInstance } from '../../utils/ApiUtils';
import { addOns } from '../../constants/InterviewAddOns';
import styles from './BillingHomeScreen.module.css'

const { BASE_URI } = config

export default function BillingHomeScreen() {

  const dispatch = useDispatch()
  const token = Cookies.get('token')
  const planType = sessionStorage.getItem('planType')

  const teamUsers = useSelector((state) => state.org.users)
  const role = useSelector((state) => state.auth.role)
  
  const authedUserId = useSelector((state) => state.auth.id)
  const authedUserEmail = useSelector((state) => state.auth.email)
  const stripeSubscriptionId = useSelector((state) => state.org.stripeSubscriptionId)
  const currentPeriodEnd = useSelector((state) => state.org.currentPeriodEnd)
  const defaultCardBrand = useSelector((state) => state.org.defaultCardBrand)
  const defaultCardLastFour = useSelector((state) => state.org.defaultCardLastFour)
  const defaultCardExpiryMonth = useSelector((state) => state.org.defaultCardExpiryMonth)
  const defaultCardExpiryYear = useSelector((state) => state.org.defaultCardExpiryYear)
  const planCost = useSelector((state) => state.org.planCost)
  const numberOfSeats = useSelector((state) => state.org.numberOfSeats)
  const invoices = useSelector((state) => state.org.invoices)
  const planStatus = useSelector((state) => state.org.planStatus)
  const priorPlanPriceId = useSelector((state) => state.org.priorPlanPriceId)
  const priorPlanType = useSelector((state) => state.org.priorPlanType)
  const stripeCustomerId = useSelector((state) => state.auth.stripeCustomerId)
  const organizationId = useSelector((state) => state.org.id)

  const [subscribeToPlanConfirmationModalVisible, setSubscribeToPlanConfirmationModalVisible] = useState(false)
  const [cancelAccountConfirmationModalVisible, setCancelAccountConfirmationModalVisible] = useState(false)
  const [removeTeamMemberModalVisible, setRemoveTeamMemberModalVisible] = useState(false)
  const [addTeamMemberModalVisible, setAddTeamMemberModalVisible] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  useEffect(() => {

    if (role == 'ADMIN' || role == 'SUPER_ADMIN') {
      dispatch(checkSubscriptionOnAccount(authedUserId, token))
    }

  }, [stripeSubscriptionId])


  const renderTeamOrIndividualAccountDetails = () => {
    if (role !== '' && planType !== '') {
      if (role == 'ADMIN' && planType == 'TEAM') {
        return (

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell align="center">Last Name</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderTeamUsers(false)}
              </TableBody>
            </Table>
          </TableContainer>

        )
      }
      else {
        // render Account cancel button
      }
    }
  }

  const renderAddOnOptions = () => {
    return addOns.map((addOn) => {
      const { stripePriceId, name, details, price} = addOn
      return (
        <Stack className={styles.addOnWrapper}>
          <Typography className={styles.addOnHeading}>{name}</Typography>
          <Typography className={styles.addOnPrice}>{price}</Typography>
          <Typography className={styles.addOnDetails}>{details}</Typography>
          <Button 
            onClick={() => handleCheckout(addOn.stripePriceId)}
            variant='contained' className={styles.addOnBuyNowButton}>
            <Typography>BUY</Typography>
          </Button>
        </Stack>
      )
    })
  }

  async function handleCheckout(stripePriceId) {
    let planType = 'SOLO'

    if (stripePriceId) {
      getAxiosInstance(token).post(`${BASE_URI}/payments/add-on/${stripePriceId}/${stripeCustomerId}`)
      .then((res) => {
        if (res.status == 200) {
          window.location.href = res.data;
        }
      })
    }
  }

  const removeNonStudentClicked = () => {

    const userConfig = {
      id: authedUserId,
      email: selectedUser.email,
      organizationId: organizationId
    }

    dispatch(removeMemberFromTeamAccount(userConfig, token, teamUsers, setRemoveTeamMemberModalVisible, setSelectedUser, null))
  }


  const renderTeamUsers = (studentsOnly) => {
    if (teamUsers && teamUsers.length > 0) {
      return (
        teamUsers.map((row) => {
          if (row.email !== '' && studentsOnly && row.role == 'STUDENT') {
            return ((
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">{row.firstName}</TableCell>
                <TableCell align="left">{row.lastName}</TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.status}</TableCell>
                <TableCell align="left">{row.role}</TableCell>
                <TableCell align="left"><Button variant='text'>REMOVE</Button></TableCell>
              </TableRow>
            ))
          }
          else if (row.email !== '' && !studentsOnly && row.role !== 'STUDENT') {
            return ((
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">{row.firstName}</TableCell>
                <TableCell align="left">{row.lastName}</TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.status}</TableCell>
                <TableCell align="left">{row.role}</TableCell>
                <TableCell align="left"><Button variant='text' onClick={() => {
                  setSelectedUser({ firstName: row.firstName, lastName: row.lastName, email: row.email })
                  setRemoveTeamMemberModalVisible(true)
                }}>REMOVE</Button></TableCell>
              </TableRow>
            ))
          }
        })
      )
    }
  }

  const renderCardLogo = () => {
    switch (defaultCardBrand) {
      case 'amex':
        return <img className="credit-card" src={amex} alt={"American Express card"} style={{ height: 40, width: 40 }} />
      case 'discover':
        return <img className="credit-card" src={discover} alt={"Discover card"} />
      case 'mastercard':
        return <img className="credit-card" src={mastercard} alt={"Mastercard"} />
      case 'visa':
        return <img className="credit-card" src={visa} alt={"Visa card"} style={{ height: 40, width: 40 }} />
      default:
        break;
    }
  }

  const renderPaidBadge = () => {
    return (
      <Stack style={{ backgroundColor: '#d7f7c2', border: '1px solid #a6eb84', padding: 3, borderRadius: 5, width: 38, height: 20, justifyContent: 'center', alignItems: 'center' }}>
        <Typography style={{ color: '#006908', fontSize: 12 }}>Paid</Typography>
      </Stack>
    )
  }

  const renderInvoices = () => {
    if (invoices) {
      if (invoices.length > 0) {
        return invoices.map((invoice) => {
          return (
            <Stack direction='row' style={{ justifyContent: 'space-around', width: '40%', alignItems: 'center' }}>
              <Typography>{getFormattedDate(invoice.paymentDate)}</Typography>
              <Button variant='text' onClick={() => window.location.href = invoice.invoiceUrl}>
                <img src={receipt} style={{ height: 25, width: 25 }} />
              </Button>
              <Typography>${invoice.amountPaid}.00</Typography>
              <Typography>{invoice.invoicePaid ? renderPaidBadge() : null}</Typography>
              <Typography>{planType}</Typography>
            </Stack>
          )
        })
      }
    }
  }

  const renderPaymentMethod = () => {
    if (defaultCardBrand !== '' || defaultCardLastFour !== '') {
      return (
        <Stack direction='row' style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
          <Stack direction='row' style={{ alignItems: 'center' }}>
            <Typography>{renderCardLogo()}</Typography>
            <Typography style={{ paddingLeft: 10 }}>{defaultCardBrand ? defaultCardBrand[0].charAt(0).toUpperCase() + defaultCardBrand.slice(1) : null}</Typography>
            <Typography>&nbsp;</Typography>
            <Typography> {'\u2022'}{'\u2022'}{'\u2022'}{'\u2022'} </Typography>
            <Typography>&nbsp;</Typography>
            <Typography>{defaultCardLastFour}</Typography>
          </Stack>
          <Stack direction='row' style={{ marginLeft: 50 }}>
            <Typography style={{ color: 'black', fontWeight: '400' }}>Expires:</Typography>
            <Typography>&nbsp;</Typography>
            <Typography>{defaultCardExpiryMonth ? getMonthFromName(defaultCardExpiryMonth) : null}</Typography>
            <Typography>/</Typography>
            <Typography>{defaultCardExpiryYear ? defaultCardExpiryYear : null}</Typography>

          </Stack>
        </Stack>

      )
    }
  }

  const resubscribeToPlan = () => {    
    getAxiosInstance(token).post(`${BASE_URI}/payments/createCheckoutSession/${priorPlanPriceId}/${authedUserEmail}/${stripeCustomerId}/${priorPlanType}`)
    .then((res) => {
      if (res.status == 200) {
        window.location.href = res.data;
      }
    })
  }



  const renderCancelOrSubscribeToAccount = () => {

    if (planStatus === 'CANCELED') {
      return (
        <Stack direction='row'>
          <Stack style={{ width: '30%' }}>
            <Typography>TEAM</Typography>
            <h3 style={{ color: '#3c4257' }}>${planCost * numberOfSeats}.00 per month</h3>
            <Typography>Your plan {getTenseForCancellation(currentPeriodEnd)} on {getFormattedDate(currentPeriodEnd)}.</Typography>
          </Stack>
          <Stack style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Button
              variant='contained'
              style={{ backgroundColor: colors.oceanBlue, color: 'white', width: 300, }}
              onClick={() => resubscribeToPlan()}
            >
              <Typography>SUBSCRIBE</Typography>
            </Button>
          </Stack>
        </Stack>
      )
    }
    else if (planStatus === 'ACTIVE') {
      return (
        <Stack direction='row'>
          <Stack style={{ width: '30%' }}>
            <Typography>TEAM</Typography>
            <h3 style={{ color: '#3c4257' }}>${planCost * numberOfSeats}.00 per month</h3>
            <Typography>Your plan renews on {getFormattedDate(currentPeriodEnd)}.</Typography>
          </Stack>
          <Stack style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Button
              variant='contained'
              style={{ backgroundColor: 'white', color: 'gray', width: 300, }}
              onClick={() => setCancelAccountConfirmationModalVisible(true)}
            >
              <Typography>CANCEL</Typography>
            </Button>
          </Stack>
        </Stack>
      )
    }
  }

  
  return (
    <Grid >
      <SidebarNav />
      {planType && planType === 'TEAM' ? null : (  <Stack style={{ marginTop: 50, marginLeft: 275 }}>
        <Typography style={{ fontSize: 14 }}>ACCOUNT ADD-ONS</Typography>
        <hr style={{ width: '98%' }} />
        <Stack direction='row'>
          {renderAddOnOptions()}
        </Stack>
      </Stack>) }
    
      <Stack style={{ marginTop: 50, marginLeft: 275 }}>
        <Typography style={{ fontSize: 14 }}>CURRENT PLAN</Typography>
        <hr style={{ width: '98%' }} />
        {renderCancelOrSubscribeToAccount()}
      </Stack>
      <Stack style={{ marginTop: 50, marginLeft: 275 }}>
        <Typography style={{ fontSize: 14 }}>PAYMENT METHOD</Typography>
        <hr style={{ width: '98%' }} />
        <Stack>
          {renderPaymentMethod()}
        </Stack>
      </Stack>
      {planType == 'TEAM' ? (<Stack style={{ marginLeft: 275, marginTop: 60 }}>
        <Stack direction='row'>
          <Typography style={{ fontSize: 14 }}>MANAGE USERS</Typography>
          <ButtonBase
            variant="text"
            style={{ marginLeft: 5 }}
            onClick={() => planStatus === 'CANCELED' ? setSubscribeToPlanConfirmationModalVisible(true) : setAddTeamMemberModalVisible(true)}
          >
            <AddCircleIcon style={{ color: 'purple' }} />
          </ButtonBase>
          {/* <Stack style={{marginLeft: 5}}>
            <Typography>({teamUsers.length} of {numberOfSeats}) seats remaining</Typography>
          </Stack> */}
        </Stack>
        <hr style={{ width: '98%' }} />
        <Stack style={{ width: '60%' }}>
          {renderTeamOrIndividualAccountDetails()}
        </Stack>
      </Stack>) : null}
      <Stack style={{ marginLeft: 275, marginTop: 50 }}>

        <Stack style={{ marginTop: 40, paddingBottom: 80 }}>
          <Typography style={{ fontSize: 14 }}>INVOICES</Typography>
          <hr style={{ width: '98%' }} />
          <Stack>
            {renderInvoices()}
          </Stack>


        </Stack>
      </Stack>

      <DeleteConfirmationModal
        isVisible={removeTeamMemberModalVisible}
        handleClose={() => {
          setSelectedUser('')
          setRemoveTeamMemberModalVisible(false)
        }}
        triggerAction={() => removeNonStudentClicked()}
        modalBody={`Are you sure you wish to remove ${selectedUser ? selectedUser.firstName : null} ${selectedUser ? selectedUser.lastName : null} from your account?`}

      />
      <DeleteConfirmationModal
        isVisible={cancelAccountConfirmationModalVisible}
        handleClose={() => setCancelAccountConfirmationModalVisible(false)}
        modalBody={`Are you sure you want to cancel your account? If you cancel today you will continue to have access to Interviewable through ${getFormattedDate(currentPeriodEnd)}`}
        triggerAction={() => dispatch(cancelSubscription(authedUserId, token, () => setCancelAccountConfirmationModalVisible(false)))}
      />

      <DeleteConfirmationModal 
        isVisible={subscribeToPlanConfirmationModalVisible}
        handleClose={() => setSubscribeToPlanConfirmationModalVisible(false)}
        modalBody={`Subscribe to a team plan to add more users to your account.`}
        triggerAction={() => resubscribeToPlan()}
        triggerText='SUBSCRIBE'
      
      />
      <AddTeamMemberModal
        isVisible={addTeamMemberModalVisible}
        closeModal={setAddTeamMemberModalVisible}
        token={token}
        adminId={authedUserId}
      />
    </Grid >
  )
}
