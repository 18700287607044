import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Cookies from 'js-cookie';

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { colors } from '../../constants/Colors'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import { Modal, Select } from '@mui/material';
import Backdrop from "@mui/material/Backdrop";
import { showErrorToast, showSuccessToast } from '../../utils/ToastUtils'
import { getAxiosInstance, getMultipartAxiosInstance } from '../../utils/ApiUtils'
import config from '../../env.json'

import { useFilePicker } from 'use-file-picker';
import {
  FileAmountLimitValidator,
  FileTypeValidator,
  FileSizeValidator,
  ImageDimensionsValidator,
} from 'use-file-picker/validators';


import TRASH_ICON from '../../assets/trash-icon.png'

import styles from './ResumeUploadModal.module.css'
import { uploadUserResume } from '../../redux/actions/userActions';

export default function ResumeUploadModal(props) {

  const dispatch = useDispatch()

  const { BASE_URI } = config
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const {
    isVisible,
    handleClose,
  } = props

  const token = Cookies.get('token')
  const authedUserId = useSelector((state) => state.auth.id)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    padding: 40,
    height: 300,
    overflowY: 'scroll',
    p: 4,
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const { openFilePicker, filesContent, loading, errors } = useFilePicker({
    readAs: 'DataURL',
    accept: ['.doc', '.pdf', '.docx'],
    multiple: false,
    onFilesRejected: ({ errors }) => {
      // console.log('onFilesRejected', errors);
    },
    onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
      // this callback is called when there were no validation errors
      setIsSelected(true)
      setSelectedFile(filesContent[0])
    },
    validators: [
      new FileAmountLimitValidator({ max: 1 }),
      new FileTypeValidator(['pdf', 'doc', 'docx']),
      new FileSizeValidator({ maxFileSize: 50 * 1024 * 1024 /* 50 MB */ }),
    ],
  });

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }


  const handleSubmission = () => {
    var blob = dataURItoBlob(selectedFile.content);
    const formData = new FormData();

    formData.append("file", blob);
    const resumeUploadConfig = {
      ownerId: authedUserId,
      fileExtension: selectedFile.name.split(".").pop(),
      visibility: "PRIVATE",
      title: selectedFile.name
    }

    formData.append("resumeMetaData", JSON.stringify(resumeUploadConfig));

    dispatch(uploadUserResume(formData, token, handleClose))

  };


  return (
    <div>
      <Modal
        open={isVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title" variant="h6" component="h2"
            className={styles.uploadHeader} >
            UPLOAD RESUME
          </Typography>
          <Stack className={styles.bodyWrapper}>
            <Button
              variant='contained'
              style={{ height: 45, width: 360, backgroundColor: 'white', color: colors.plum }}
              onClick={() => openFilePicker()}
            >
              <Typography>Choose File (PDF, DOC, or DOCX)</Typography>
            </Button>
            <Stack className={styles.bodyWrapper}>
              {selectedFile ? (<Stack direction='row'>
                <span className={styles.fileNameLabel}>Name: </span>
                <Typography>{selectedFile ? selectedFile.name : null}</Typography>
                <Stack
                  style={{ marginLeft: 10 }}
                  onClick={() => setSelectedFile(null)}
                >
                  <img src={TRASH_ICON} style={{ height: 20, width: 20 }} />
                </Stack>
              </Stack>) : null}

            </Stack>
            <Button
              disabled={!selectedFile}
              variant='contained'
              style={{ height: 45, width: 120, backgroundColor: selectedFile ? colors.oceanBlue : 'silver', marginTop: 20 }}
              onClick={() => handleSubmission()}>
              <Typography>UPLOAD</Typography>
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  )
}