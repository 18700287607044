import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Cookies from 'js-cookie';

import SidebarNav from '../../components/navigation/SidebarNav'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Select from "@mui/material/Select";
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import { createInterviewQuestion, deleteInterviewQuestion, getInterviewableRoles } from '../../redux/actions/interviewActions'
import { getAxiosInstance } from '../../utils/ApiUtils'



import config from '../../env.json'
import { } from '@mui/material'
import DeleteConfirmationModal from '../../components/modals/billing/DeleteConfirmationModal'
import EditInterviewQuestionModal from '../../components/modals/interviews/EditInterviewQuestionModal'
import { colors } from '../../constants/Colors'

const { BASE_URI } = config


export default function AdminHomeScreen() {

  const dispatch = useDispatch()

  const token = Cookies.get('token')
  const adminInterviewQuestions = useSelector((state) => state.interviews.adminInterviewQuestions)

  const [type, setType] = useState('')
  const [question, setQuestion] = useState('')
  const [questionURI, setQuestionURI] = useState('')
  const [programmingLanguage, setProgrammingLanguage] = useState('')
  const [focusArea, setFocusArea] = useState('')
  const [roleFocusAreas, setRoleFocusAreas] = useState([])
  const [role, setRole] = useState('')
  const [availableRoles, setAvailableRoles] = useState([])
  const [editInterviewQuestionModalVisible, setEditInterviewQuestionModalVisible] = useState(false)
  const [deleteInterviewQuestionModalVisible, setDeleteInterviewQuestionModalVisible] = useState(false)
  const [currentInterviewQuestionSelected, setCurrentInterviewQuestionSelected] = useState({})

  useEffect(() => {
    if (availableRoles.length == 0) {
      getAxiosInstance(token).get(`${BASE_URI}/interviews/roles`)
        .then((res) => {
          if (res.status == 200) {
            setAvailableRoles(res.data)
          }
        })
        .catch(err => {
      
        })
    }
  }, [adminInterviewQuestions])

  const handleInterviewTypeChange = (event) => {
    const {
      target: { value },
    } = event;

    setType(value)
  }

  const handleRoleChange = (event) => {
    const {
      target: { value },
    } = event;

    setRole(value)
    getFocusAreasByRole(type, value)


  }

  const getFocusAreasByRole = (type, role) => {
    if (type === 'PROFESSIONAL') {
      getAxiosInstance(token).get(`${BASE_URI}/interviews/professionalfocusareas/${role}`)
        .then((res) => {
          if (res.status == 200) {
            setRoleFocusAreas(res.data)
          }
        })
        .catch(err => {
        })
    }
    else if (type === 'TECHNICAL') {
      getAxiosInstance(token).get(`${BASE_URI}/interviews/focusAreas/technical/role/${role}`)
      .then((res) => {
        if (res.status == 200) {
          setRoleFocusAreas(res.data)
        }
      })
      .catch(err => {
      })
    }
  }

  const handleFocusAreaChange = (event) => {
    const {
      target: { value },
    } = event;

    setFocusArea(value)
  }

  const handleProgrammingLanguageChange = (event) => {
    const {
      target: { value },
    } = event;

    setProgrammingLanguage(value)

    getAxiosInstance(token).get(`${BASE_URI}/interviews/focusAreas/technical/software/${value}`)
      .then((res) => {
        if (res.status == 200) {
          setRoleFocusAreas(res.data)
        }
      })
      .catch(err => {
      })
  }

  const clearForm = () => {
    setQuestion('')
    setQuestionURI('')
  }

  const createQuestionClicked = () => {
    let interviewType;
    switch(type) {
      case 'TECHNICAL':
        interviewType = 'technical'
        break;
      case 'PROFESSIONAL':
        interviewType = 'professional'
        break;
      case 'BEHAVIORAL':
        interviewType = 'behavioral'
        break;
    } 

    const interviewQuestionConfig = {
      question: question,
      questionURI: questionURI,
      focusArea: focusArea,
      type: interviewType,
      programmingLanguage: programmingLanguage,
      role: role,
    }

    dispatch(createInterviewQuestion(interviewQuestionConfig, token, clearForm))

  }

  const renderInterviewTypes = () => {
    const interviewTypes = ['BEHAVIORAL', 'PROFESSIONAL', 'TECHNICAL']
    return interviewTypes.map((type) => {
      return <MenuItem key={type} value={type}>{type}</MenuItem>
    })
  }

  const renderAvailableRoles = () => {
    return availableRoles.map((role) => {
      return <MenuItem key={type} value={role}>{role}</MenuItem>
    })
  }

  const renderFocusAreas = () => {
    return roleFocusAreas.map((focusArea) => {
      return <MenuItem key={type} value={focusArea.id}>{focusArea.name}</MenuItem>
    })
  }

  const renderProgrammingLanguages = () => {
    return ['JAVA', 'JAVASCRIPT', 'PYTHON'].map((language) => {
      return <MenuItem key={language} value={language}>{language}</MenuItem>
    })

  }

  const renderProgrammingLanguageWrapper = () => {
    console.log('Role: ', role)
    if (role === 'Software Engineer Frontend JavaScript' || role === 'Software Engineer Backend Java') {
      return (
        <Stack>
          <Typography>Programming Language</Typography>
          <Select
            id="interview-type-select"
            value={programmingLanguage}
            onChange={(event) => handleProgrammingLanguageChange(event)}
          >
            {renderProgrammingLanguages()}
          </Select>
        </Stack>
      )
    }
  }

  const deleteInterviewQuestionClicked = () => {
    dispatch(deleteInterviewQuestion(currentInterviewQuestionSelected, type,token, adminInterviewQuestions, () => setDeleteInterviewQuestionModalVisible(false)))
  }

  const renderLatestInterviewQuestions = () => {
    if (adminInterviewQuestions) {
      if (adminInterviewQuestions.length > 0) {
        return adminInterviewQuestions.map((interviewQuestion) => {
          return (
            <TableRow
            key={interviewQuestion.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <Typography>{interviewQuestion.role}</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography>{interviewQuestion.focusArea}</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography>{interviewQuestion.question}</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography>{interviewQuestion.questionURI}</Typography>
            </TableCell>
            <TableCell align="left">
              <Stack direction='row'>
                <Button 
                  variant='text'
                  onClick={() => {
                    getFocusAreasByRole(interviewQuestion.type.toUpperCase(), interviewQuestion.role)
                    setCurrentInterviewQuestionSelected(interviewQuestion)
                    setEditInterviewQuestionModalVisible(true)
                  }}
                  >  
                    <CreateIcon />
                </Button>
                <Button 
                  variant='text'
                  onClick={() => {
                    setCurrentInterviewQuestionSelected(interviewQuestion)
                    setDeleteInterviewQuestionModalVisible(true)
                  }}
                >
                  <DeleteIcon />
                </Button>
              </Stack>
            </TableCell>
          </TableRow>
          )
        })
      }
    }
   
  }

  return (

    <div style={{ display: 'flex', flex: 1 }}>
      <SidebarNav />
      <Stack style={{ marginTop: 50, marginLeft: 50, width: '75%' }}>
        <Typography>Create An Inteview Question</Typography>
        <form>
          <Typography>Interview Type</Typography>
          <Stack>
            <Select
              id="interview-type-select"
              value={type}
              // label="Interview"
              onChange={(event) => handleInterviewTypeChange(event)}
            >
              {renderInterviewTypes()}
            </Select>
          </Stack>
          <Typography>Role</Typography>
          <Stack>
            <Select
              id="interview-type-select"
              value={role}
              onChange={(event) => handleRoleChange(event)}
            >
              {renderAvailableRoles()}
            </Select>
          </Stack>

          {renderProgrammingLanguageWrapper()}

          <Typography>Focus Area</Typography>
          <Stack>
            <Select
              id="focus-area-select"
              value={focusArea}
              onChange={(event) => handleFocusAreaChange(event)}
            >
              {renderFocusAreas()}
            </Select>
          </Stack>
          <Typography>Interview Question</Typography>
          <Stack>
            <TextField
              type="text"
              value={question}
              onChange={(event) => setQuestion(event.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Stack>
          Question Link
          <Stack>
            <TextField
              type="text"
              value={questionURI}
              onChange={(event) => setQuestionURI(event.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Stack>

        </form>
        <Stack>
          <Button
            variant='contained'
            onClick={() => createQuestionClicked()}
            style={{ height: 45, width: 120, backgroundColor: colors.oceanBlue }}
          >
            <Typography>Submit</Typography>
          </Button>
        </Stack>

        <Stack style={{ marginTop: 40, width: '95%', paddingBottom: 60 }}>
          <hr />
          <Typography>Latest Interview Questions</Typography>
          <Stack>
            <TableContainer component={Paper}>
              <Table style={{ minWidth: 650, }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Role </TableCell>
                    <TableCell align="left">Focus Area</TableCell>
                    <TableCell align="left">Question</TableCell>
                    <TableCell align="left">Link</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {renderLatestInterviewQuestions()}
                </TableBody>
              </Table>
            </TableContainer>

          </Stack>
          <Stack>
            
          </Stack>

        </Stack>
        <EditInterviewQuestionModal 
          open={editInterviewQuestionModalVisible}
          handleClose={() => setEditInterviewQuestionModalVisible(false)}
          currentFocusArea={currentInterviewQuestionSelected ? currentInterviewQuestionSelected.focusArea : null}
          currentInterviewQuestion={currentInterviewQuestionSelected ? currentInterviewQuestionSelected : null}
          renderFocusAreas={renderFocusAreas}
          focusAreas={roleFocusAreas}
          token={token}
          adminInterviewQuestions={adminInterviewQuestions}
        />
        <DeleteConfirmationModal 
          isVisible={deleteInterviewQuestionModalVisible}
          handleClose={() => setDeleteInterviewQuestionModalVisible(false)}
          triggerAction={deleteInterviewQuestionClicked}
          modalBody='Are you sure you want to delete this question?'
        />
      </Stack>



    </div>

  )
}