import React from "react";
import { useNavigate } from "react-router-dom";

import Cookies from 'js-cookie';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import INSTITUTION_ICON from '../../assets/institution-icon.png'

import { colors } from "../../constants/Colors";
import StandardButton from "../../components/buttons/StandardButton";

import INTERVIEWABLE_LOGO from '../../assets/interviewable-logo.png'
import { getAxiosInstance } from "../../utils/ApiUtils";

import config from '../../env.json'
const { BASE_URI } = config

export default function SignUpScreen() {

  const SOLO_STARTER_PLAN_ID = 'price_1OKL8fFmIdBJHCdM67vYULNx'
  const SOLO_GROWTH_PLAN_ID = 'price_1OKLB3FmIdBJHCdMEBHPNkYD'
  const SOLO_PRO_PLAN_ID = 'price_1OKLEPFmIdBJHCdMpMkNnS5y'

  const navigate = useNavigate()

  const token = Cookies.get('token')

  return (
    <Stack style={{ display: 'flex', flex: 1 }}>
      <Stack
        sx={{
          "@media screen and (min-width:320px)": {
            width: "60%",
          },
          "@media screen and (min-width:600px)": {
            width: "40%",
          },
          "@media screen and (min-width:900px)": {
            width: "20%",
          },
        }}
      >
        <img src={INTERVIEWABLE_LOGO} style={{ height: 50, marginLeft: 20 }} />
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} style={{ marginTop: '5dvh', alignItems: 'center', justifyContent: 'center' }}>
        <Stack
          style={{ height: 450, width: 350, boxShadow: '1px 2px 5px black', border: '1px solid silver', margin: 10, flexDirection: 'column', borderRadius: 5, padding: 15 }}
          variant='outlined'
        >
          <Stack style={{ height: 180 }}>
            <Stack style={{ borderWidth: 1, padding: 10, borderColor: 'black', border: '1px solid silver', borderRadius: 5, height: 30, width: 150, justifyContent: 'center', alignItems: 'center', backgroundColor: 'silver' }}>
              <Typography style={{ fontWeight: 'bold', fontSize: 14, color: colors.coolBlack }}>
                INDIVIDUAL
              </Typography>
            </Stack>
            <Typography style={{ fontWeight: '500', fontSize: 50, color: 'black' }}>
              FREE
            </Typography>
            <Typography style={{ fontWeight: '300', fontSize: 15, color: 'black', paddingBottom: 10 }}>
              No credit card required to sign up.
            </Typography>
            <Typography style={{ fontWeight: '300', fontSize: 18, color: 'black' }}>
              Knock off the interview rust with a quick interview refresher to assess your readiness.
            </Typography>
          </Stack>
          <Stack style={{ marginTop: 50, justifyContent: 'center', alignItems: 'center' }}>
            <Button
              style={{ width: '80%', borderRadius: 15, backgroundColor: colors.navyBlue }}
              onClick={() => navigate('/signup/individual', { state: { planId: SOLO_STARTER_PLAN_ID } })}
            >
              <Typography style={{ color: 'white', fontWeight: 'bold' }}>CHOOSE PLAN</Typography>
            </Button>

          </Stack>
          <Stack style={{ marginTop: 30 }}>
            <Typography style={{ fontWeight: 'bold' }}>Includes:</Typography>
          </Stack>
          <Stack style={{ height: '100%' }}>
            <Typography style={{ fontSize: 16 }}>{'\u2713'} 2 interview credits </Typography>
            <Typography style={{ fontSize: 16 }}>{'\u2713'} Personalized interview feedback</Typography>
            <Typography style={{ fontSize: 16 }}>{'\u2713'} AI assited custom resume & cover letters</Typography>
            {/* <Typography style={{ fontSize: 18 }}>{'\u2713'} Weekly progress check-ins</Typography> */}
          </Stack>
        </Stack>


        {/* <Stack>
          <Stack style={{ marginTop: -40, marginLeft: 10 }}>
            <Typography style={{ fontSize: 24, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationLine: 'underline', textDecorationThickness: 6, textShadow: '1px 2px 3px silver' }}>MOST POPULAR</Typography>
          </Stack>
          <Stack
            style={{ height: 500, width: 350, boxShadow: '1px 2px 5px black', border: '1px solid silver', margin: 10, flexDirection: 'column', borderRadius: 5, padding: 15 }}
            variant='outlined'
          >
            <Stack style={{ height: 180 }}>
              <Stack style={{ borderWidth: 1, padding: 10, borderColor: 'black', border: '1px solid silver', borderRadius: 5, height: 30, width: 150, justifyContent: 'center', alignItems: 'center', backgroundColor: 'silver' }}>
                <Typography style={{ fontWeight: 'bold', fontSize: 14, color: colors.coolBlack }}>
                  SOLO: GROWTH
                </Typography>
              </Stack>

              <Typography style={{ fontWeight: '500', fontSize: 50, color: 'black' }}>
                $20
              </Typography>
              <Typography style={{ fontWeight: '300', fontSize: 18, color: 'black' }}>
                per month
              </Typography>
              <Typography style={{ fontWeight: '300', fontSize: 18, color: 'black' }}>
                Great for getting in more reps to improve your interview presence.
              </Typography>
            </Stack>
            <Stack style={{ marginTop: 50, justifyContent: 'center', alignItems: 'center' }}>
              <Button
                style={{ width: '80%', borderRadius: 15, backgroundColor: colors.oceanBlue }}
                onClick={() => navigate('/signup/individual', { state: { planId: SOLO_GROWTH_PLAN_ID } })}
              >
                <Typography style={{ color: 'white', fontWeight: 'bold' }}>CHOOSE PLAN</Typography>
              </Button>

            </Stack>
            <Stack style={{ marginTop: 30 }}>
              <Typography style={{ fontWeight: 'bold' }}>Includes:</Typography>
            </Stack>
            <Stack style={{ height: '100%' }}>
              <Typography style={{ fontSize: 18 }}>{'\u2713'} 5 interview credits </Typography>
              <Typography style={{ fontSize: 18 }}>{'\u2713'} Personalized interview feedback</Typography>
              <Typography style={{ fontSize: 18 }}>{'\u2713'} Weekly progress check-ins</Typography>
            </Stack>
          </Stack>
        </Stack> */}
        {/* <Stack
          style={{ height: 500, width: 350, boxShadow: '1px 2px 5px black', border: '1px solid silver', margin: 10, flexDirection: 'column', borderRadius: 5, padding: 15 }}
          variant='outlined'
        >
          <Stack style={{ height: 180 }}>
            <Stack style={{ borderWidth: 1, padding: 10, borderColor: 'black', border: '1px solid silver', borderRadius: 5, height: 30, width: 120, justifyContent: 'center', alignItems: 'center', backgroundColor: 'silver' }}>
              <Typography style={{ fontWeight: 'bold', fontSize: 14, color: colors.coolBlack }}>
                SOLO: PRO
              </Typography>
            </Stack>

            <Typography style={{ fontWeight: '500', fontSize: 50, color: 'black' }}>
              $40
            </Typography>
            <Typography style={{ fontWeight: '300', fontSize: 18, color: 'black' }}>
              per month
            </Typography>
            <Typography style={{ fontWeight: '300', fontSize: 18, color: 'black' }}>
              More mock interviews, more opportunities to get laser focused to stand out during your next interview.
            </Typography>
          </Stack>
          <Stack style={{ marginTop: 50, justifyContent: 'center', alignItems: 'center' }}>
            <Button
              style={{ width: '80%', borderRadius: 15, backgroundColor: colors.navyBlue }}
              onClick={() => navigate('/signup/individual', { state: { planId: SOLO_PRO_PLAN_ID } })}
            >
              <Typography style={{ color: 'white', fontWeight: 'bold' }}>CHOOSE PLAN</Typography>
            </Button>

          </Stack>
          <Stack style={{ marginTop: 30 }}>
            <Typography style={{ fontWeight: 'bold' }}>Includes:</Typography>
          </Stack>
          <Stack style={{ height: '100%' }}>
            <Typography style={{ fontSize: 18 }}>{'\u2713'} 12 interview credits </Typography>
            <Typography style={{ fontSize: 18 }}>{'\u2713'} Personalized interview feedback</Typography>
            <Typography style={{ fontSize: 18 }}>{'\u2713'} Weekly progress check-ins</Typography>
          </Stack>
        </Stack> */}

        <Stack
          style={{ height: 450, width: 350, boxShadow: '1px 2px 5px black', border: '1px solid silver', margin: 10, flexDirection: 'column', borderRadius: 5, padding: 15 }}
          variant='outlined'
        >
          <Stack style={{ height: 150 }}>
            <Stack style={{ borderWidth: 1, padding: 10, borderColor: 'black', border: '1px solid silver', borderRadius: 5, height: 30, width: 100, justifyContent: 'center', alignItems: 'center', backgroundColor: 'silver' }}>
              <Typography style={{ fontWeight: 'bold', fontSize: 14, color: colors.coolBlack }}>
                TEAM
              </Typography>
            </Stack>

            <Typography style={{ fontWeight: '500', fontSize: 50, color: 'black' }}>
              $300
            </Typography>
            <Typography style={{ fontWeight: '300', fontSize: 18, color: 'black' }}>
              per month
            </Typography>
            <Typography style={{ fontWeight: '300', fontSize: 18, color: 'black' }}>
              Great for skills specific programs like bootcamps. Includes 5 users. Each additional user
              is $12/mth.
            </Typography>
          </Stack>
          <Stack style={{ marginTop: 80, justifyContent: 'center', alignItems: 'center' }}>
            <Button
              style={{ width: '80%', borderRadius: 15, backgroundColor: colors.navyBlue }}
              onClick={() => navigate('/signup/organization')}
            >
              <Typography style={{ color: 'white', fontWeight: 'bold' }}>CHOOSE PLAN</Typography>
            </Button>

          </Stack>
          <Stack style={{ marginTop: 30 }}>
            <Typography style={{ fontWeight: 'bold' }}>Includes:</Typography>
          </Stack>
          <Stack style={{ height: '100%' }}>
            <Typography style={{ fontSize: 16 }}>{'\u2713'} Unlimited interviews for candidates</Typography>
            <Typography style={{ fontSize: 16 }}>{'\u2713'} Personalized interview feedback</Typography>
            {/* <Typography style={{ fontSize: 16 }}>{'\u2713'} Weekly progress check-ins</Typography> */}
            <Typography style={{ fontSize: 16 }}>{'\u2713'} Cohort Pulse Checks</Typography>
            <Typography style={{ fontSize: 16 }}>{'\u2713'} AI assited custom resume & cover letters</Typography>
          </Stack>
        </Stack>

      </Stack>
    </Stack>
  );
};
