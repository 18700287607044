import { toast } from 'react-toastify';

export const showSuccessToast = (message) => {
  return (
    toast(`${message}`, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      })
  );
}


export const showErrorToast = (message) => {
  return (
    toast.error(`${message}`, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      })
  );
}