import React, { useState } from 'react'

import { useDispatch } from 'react-redux';

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField";


import PropTypes from 'prop-types'
import { Button } from '@mui/material';
import { addSeatToSubscription } from '../../../redux/actions/organizationActions';
import StandardButton from '../../buttons/StandardButton';
import { colors } from '../../../constants/Colors';
import { getAxiosInstance } from '../../../utils/ApiUtils';

import config from '../../../env.json'
import { showErrorToast, showSuccessToast } from '../../../utils/ToastUtils';

export default function AddCohortMemberModal(props) {

  const { BASE_URI } = config

  const dispatch = useDispatch()

  const { isVisible, closeModal, token, cohortId, numberOfSeats, numberOfUsers, navigate } = props

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [errorText, setErrorText] = useState('')
 
 const clearModal = () => {
    setFirstName('')
    setLastName('')
    setEmail('')

    closeModal()
  }

  const addNewCohortMember = () => {

    if (firstName === '' || lastName === '' || email === '') {
      setErrorText('Please add all required values.')
      return;
    }
    else { 
      setErrorText('')
    }

    const teamMemberConfig = {
      user: {
        firstName: firstName,
        lastName: lastName,
        email: email,
      },
      cohortId: cohortId
    }


    getAxiosInstance(token).post(`${BASE_URI}/org/cohorts/groups/add/member`, teamMemberConfig)
    .then((res) => {
      if (res.status == 200) {
        setEmail('')
        setFirstName('')
        setLastName('')
        closeModal()

        navigate(`/org`)
        showSuccessToast('Successfully added student to cohort!') 
      }
    })
    .catch((error) => {
      showErrorToast('Error adding new student to cohort!')
    })    
  }

  const renderRemainingUsersText = () => {
    if (numberOfSeats > numberOfUsers) {
      return (
        <Typography color={'purple'} style={{ paddingTop: 30 }}>Additional user will be billed at $18/month.</Typography>
      )
    }
    else {
      return (
        <Typography color={'purple'} style={{ paddingTop: 30 }}>You have remaining sets and will not incur an additional charge for this user.</Typography>
      )
    }
  }





  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isVisible}
      onClose={() => {
        closeModal()
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isVisible}>
        <Box sx={style}>
          <Stack>
            <Typography style={{color: colors.blazeRed}}>{errorText}</Typography>
            <FormControl>
              <Typography style={{ paddingBottom: 10 }}>First Name: <span style={{color: colors.blazeRed}}>*</span> </Typography>
              <TextField
                // label="Email"
                type="email"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <Typography style={{ paddingBottom: 10 }}>Last Name: <span style={{color: colors.blazeRed}}>*</span> </Typography>
              <TextField
                type="email"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
              />

              <Typography style={{ paddingBottom: 10 }}>Email: <span style={{color: colors.blazeRed}}>*</span> </Typography>

              <TextField
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
              />

            </FormControl>
            {/* <Stack>
              {renderRemainingUsersText()}
            </Stack> */}
          </Stack>
          <Stack direction='row' spacing={10} style={{ marginTop: 30 }}>
            <Button
              variant='text'
              onClick={() => {
                setEmail('')
                setFirstName('')
                setLastName('')
                closeModal()
              }}
            >CANCEL</Button>
            {/* <Button
              variant='contained'
              onClick={() => addNewCohortMember()}

            >ADD COHORT MEMBER</Button> */}

            <StandardButton 
              label="Add Cohort Member"
              buttonWidth={310}
              labelColor={colors.oceanBlue}
              onPress={() => addNewCohortMember()}

            
            />
          </Stack>
        </Box>
      </Fade>
    </Modal>
  )
}

AddCohortMemberModal.propTypes = {
  isVisible: PropTypes.bool,
  closeModal: PropTypes.func,
  token: PropTypes.string,
  cohortId: PropTypes.string,  
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: '80vh',
  width: 850,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
  overflowY: 'auto',
};