import React, { useState } from 'react'

import { useDispatch } from 'react-redux';

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField";


import PropTypes from 'prop-types'
import { Button } from '@mui/material';
import { addSeatToSubscription } from '../../../redux/actions/organizationActions';
import StandardButton from '../../buttons/StandardButton';
import { colors } from '../../../constants/Colors';

export default function AddTeamMemberModal(props) {

  const dispatch = useDispatch()

  const { isVisible, closeModal, token, adminId, numberOfSeats, numberOfUsers } = props

  const [role, setRole] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setRole(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const renderRoles = () => {
    return ['ADMIN', 'FACULTY', 'STAFF'].map((type) => {
      return (
        <MenuItem value={type}>{type}</MenuItem>
      )
    })
  }

  const clearModal = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setRole('')

    closeModal()
  }

  const addNewTeamMember = () => {
    const teamMemberConfig = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      role: role[0],
      id: adminId
    }

    dispatch(addSeatToSubscription(teamMemberConfig, token, clearModal))
  }

  const renderRemainingUsersText = () => {
    if (numberOfSeats > numberOfUsers) {
      return (
        <Typography color={'purple'} style={{ paddingTop: 30 }}>Additional user will be billed at $18/month.</Typography>
      )
    }
    else {
      return (
        <Typography color={'purple'} style={{ paddingTop: 30 }}>You have remaining sets and will not incur an additional charge for this user.</Typography>
      )
    }
  }





  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isVisible}
      onClose={() => {
        closeModal()
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isVisible}>
        <Box sx={style}>
          <Stack>
            <FormControl>
              <Typography style={{ paddingBottom: 10 }}>First Name: </Typography>
              <TextField
                // label="Email"
                type="email"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
              />


              <Typography style={{ paddingBottom: 10 }}>Last Name: </Typography>
              <TextField
                // label="Email"
                type="email"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
              />

              <Typography style={{ paddingBottom: 10 }}>Email: </Typography>

              <TextField
                // label="Email"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
              />

              <Typography style={{ paddingBottom: 10 }}>Role: </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={role}
                label="Role"
                onChange={(event) => handleChange(event)}
              >
                {renderRoles()}
              </Select>
            </FormControl>
            {/* <Stack>
              {renderRemainingUsersText()}
            </Stack> */}
          </Stack>
          <Stack direction='row' spacing={10} style={{ marginTop: 30 }}>
            <Button
              variant='text'
              onClick={() => {
                setEmail('')
                setFirstName('')
                setLastName('')
                setRole('')
                closeModal()
              }}
            >CANCEL</Button>
            {/* <Button
              variant='contained'
              onClick={() => addNewTeamMember()}

            >ADD TEAM MEMBER</Button> */}
            <StandardButton 
              label='ADD TEAM MEMBER'
              buttonWidth={310}
              labelColor={colors.plum}
              onPress={() => addNewTeamMember()}
            />
          </Stack>
        </Box>
      </Fade>
    </Modal>
  )
}

AddTeamMemberModal.propTypes = {
  isVisible: PropTypes.bool,
  closeModal: PropTypes.func,
  token: PropTypes.string,
  adminId: PropTypes.string,
  numberOfUsers: PropTypes.number,
  numberOfSeats: PropTypes.number
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: '80vh',
  width: 850,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
  overflowY: 'auto',
};