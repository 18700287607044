import { getAxiosInstance, getMultipartAxiosInstance } from '../../utils/ApiUtils'
import {
  SAVING_USER_PROFILE_PREFERENCES,
  SAVED_USER_PROFILE_PREFERENCECS,
  ERROR_SAVING_USER_PROFILE_PREFERENCES,
  SET_USER_PREFERENCES,
  SET_USER_META_DATA,
  ADDING_JOB_TO_WORK_HISTORY,
  ADDED_JOB_TO_WORK_HISTORY,
  ERROR_ADDING_JOB_TO_WORK_HISTORY,
  RETRIEVING_WORK_HISTORY,
  RETRIEVED_WORK_HISTORY,
  ERROR_RETRIEVING_WORK_HISTORY,
  ADDING_EDUCATION_HISTORY,
  ADDED_EDUCATION_HISTORY,
  ERROR_ADDING_EDUCATION_HISTORY,
  UPDATING_USER_META_DATA,
  UPDATED_USER_META_DATA,
  ERROR_UPDATING_USER_META_DATA,
  UPDATING_PROFILE_AVATAR,
  UPDATED_PROFILE_AVATAR,
  ERROR_UPDATING_PROFILE_AVATAR,
  UPDATING_JOB_ON_WORK_HISTORY,
  UPDATED_JOB_ON_WORK_HISTORY,
  ERROR_UPDATING_JOB_ON_WORK_HISTORY,
  DELETING_JOB_FROM_WORK_HISTORY,
  DELETED_JOB_FROM_WORK_HISOTRY,
  ERROR_DELETING_JOB_FROM_WORK_HISTORY,
  DELETING_JOB_DETAIL,
  DELETED_JOB_DETAIL,
  ERROR_DELETING_JOB_DETAIL,
  UPDATING_EDUCATION_RECORD,
  UPDATED_EDUCATION_RECORD,
  ERROR_UPDATING_EDUCATION_RECORD,
  DELETING_EDUCATION_RECORD,
  DELETED_EDUCATION_RECORD,
  ERROR_DELETING_EDUCATION_RECORD,
  DELETING_EDUCATION_ACTIVITY,
  DELETED_EDUCATION_ACTIVITY,
  ERROR_DELETING_EDUCATION_ACTIVITY,
  RETRIEVING_USER_RESUMES,
  RETRIEVED_USER_RESUMES,
  ERROR_RETRIEVING_USER_RESUMES,
  UPLOADING_USER_RESUME,
  UPLOADED_USER_RESUME,
  ERROR_UPLOADING_USER_RESUME,
  DELETING_USER_RESUME,
  DELETED_USER_RESUME,
  ERROR_DELETING_USER_RESUME
} from '../constants/userActionTypes'

// const BASE_URI = 'http://localhost:5000'
// const PROD_SERVER_URI = process.env.REACT_APP_PROD_SERVER_URI

import config from '../../env.json'
import { showErrorToast, showSuccessToast } from '../../utils/ToastUtils'

const { BASE_URI } = config

export const setUserMetaData = (data) => {
  return {
    type: SET_USER_META_DATA,
    payload: data
  }
}

export const setUserPreferences = (preferences) => {
  return {
    type: SET_USER_PREFERENCES,
    payload: preferences
  }
}

// SAVING USER PROFILE PREFERENCES
export const savingUserProfilePreferences = () => {
  return {
    type: SAVING_USER_PROFILE_PREFERENCES
  }
}

export const savedUserProfilePreferences = (userProfile) => {
  return {
    type: SAVED_USER_PROFILE_PREFERENCECS,
    payload: userProfile
  }
}

export const errorSavingUserProfilePreferences = (error) => {
  return {
    type: ERROR_SAVING_USER_PROFILE_PREFERENCES,
    payload: error
  }
}

export const saveUserProfilePreferences = (userProfileConfig, token, setModalVisible) => {
  return function (dispatch, getState) {
    dispatch(savingUserProfilePreferences())

    return getAxiosInstance(token).put(`${BASE_URI}/users/setUserProfilePreferences`, userProfileConfig)
      .then(res => {
        if (res.status === 200) {
          dispatch(savedUserProfilePreferences(res.data))
          setModalVisible(false)
        }
      })
      .catch(err => {
        dispatch(errorSavingUserProfilePreferences())

      })
  }
}

// Add Job to Work Experience
export const addingJobToWorkHistory = () => {
  return {
    type: ADDING_JOB_TO_WORK_HISTORY
  }
}

export const addedJobToWorkHistory = (job) => {
  return {
    type: ADDED_JOB_TO_WORK_HISTORY,
    payload: job
  }
}

export const errorAddingJobToWorkHistory = (error) => {
  return {
    type: ERROR_ADDING_JOB_TO_WORK_HISTORY,
    payload: error
  }
}

export const addJobToWorkHistory = (jobConfig, token, showAddExperienceModal) => {
  return function (dispatch, getState) {
    dispatch(addingJobToWorkHistory())

    return getAxiosInstance(token).post(`${BASE_URI}/resume/addExperience`, jobConfig)
      .then(res => {
        if (res.status === 200) {
          dispatch(addedJobToWorkHistory(res.data))
          showAddExperienceModal(false)
          showSuccessToast('Successfully added new job!')

        }
      })
      .catch(err => {
        dispatch(errorAddingJobToWorkHistory())
        showErrorToast('Uh oh. There was an error adding your job.')

      })
  }
}


// Add Job to Work Experience
export const retrievingWorkHistory = () => {
  return {
    type: RETRIEVING_WORK_HISTORY
  }
}

export const retrievedWorkHistory = (workHistory) => {
  return {
    type: RETRIEVED_WORK_HISTORY,
    payload: workHistory
  }
}

export const errorRetrievingWorkHistory = (error) => {
  return {
    type: ERROR_RETRIEVING_WORK_HISTORY,
    payload: error
  }
}

export const retrieveWorkHistory = (userId, token) => {
  return function (dispatch, getState) {
    dispatch(retrievingWorkHistory())

    return getAxiosInstance(token).get(`${BASE_URI}/resume/workHistory/${userId}`)
      .then(res => {
        if (res.status === 200) {
          dispatch(retrievedWorkHistory(res.data))
        }
      })
      .catch(err => {
      })
  }
}

// Add Education Experience
export const addingEducationHistory = () => {
  return {
    type: ADDING_EDUCATION_HISTORY
  }
}

export const addedEducationHistory = (education) => {
  return {
    type: ADDED_EDUCATION_HISTORY,
    payload: education
  }
}

export const errorAddingEducationHistory = (error) => {
  return {
    type: ERROR_ADDING_EDUCATION_HISTORY,
    payload: error
  }
}

export const addEducationHistory = (educationConfig, token, showAddEducationExperienceModal) => {
  return function (dispatch, getState) {
    dispatch(addingEducationHistory())

    return getAxiosInstance(token).post(`${BASE_URI}/resume/addEducation/`, educationConfig)
      .then(res => {
        if (res.status === 200) {
          dispatch(addedEducationHistory(res.data))
          showAddEducationExperienceModal(false)
          showSuccessToast('Successfully added to education history!')
        }
      })
      .catch(err => {
        dispatch(errorAddingEducationHistory())
        showErrorToast('Error adding to education history.')
      })
  }
}


// Updating User Meta Data
export const updatingUserMetaData = () => {
  return {
    type: UPDATING_USER_META_DATA
  }
}

export const updatedUserMetaData = (education) => {
  return {
    type: UPDATED_USER_META_DATA,
    payload: education
  }
}

export const errorUpdatingUserMetaData = (error) => {
  return {
    type: ERROR_UPDATING_USER_META_DATA,
    payload: error
  }
}

export const updateUserMetaData = (userConfig, token, clearFields) => {
  return function (dispatch, getState) {
    dispatch(updatingUserMetaData())

    return getAxiosInstance(token).put(`${BASE_URI}/users/profileMetaData`, userConfig)
      .then(res => {
        if (res.status === 200) {
          dispatch(updatedUserMetaData(res.data))
          clearFields()
          showSuccessToast('Successfully updated your profile!')
        }
      })
      .catch(err => {
        dispatch(errorUpdatingUserMetaData())
        showErrorToast('Error updating to your profile.')
      })
  }
}


// Updating Profile Avatar
export const updatingProfileAvatar = () => {
  return {
    type: UPDATING_PROFILE_AVATAR
  }
}

export const updatedProfileAvatar = (education) => {
  return {
    type: UPDATED_PROFILE_AVATAR,
    payload: education
  }
}

export const errorUpdatingProfileAvatar = (error) => {
  return {
    type: ERROR_UPDATING_PROFILE_AVATAR,
    payload: error
  }
}

export const updateProfileAvatar = (formData, userId, token, closeModal) => {
  return function (dispatch, getState) {
    dispatch(updatingProfileAvatar())

    return getMultipartAxiosInstance(token).post(`${BASE_URI}/assets/uploads/profileimage/${userId}`, formData)
      .then(res => {
        if (res.status === 200) {
          dispatch(updatedProfileAvatar(res.data))
          closeModal()
          showSuccessToast('Successfully updated your avatar!')
        }
      })
      .catch(err => {
        dispatch(errorUpdatingProfileAvatar())
        showErrorToast('Error updating your avatar.')
      })
  }
}


// Updating Job on Work History
export const updatingJobOnWorkHistory = () => {
  return {
    type: UPDATING_JOB_ON_WORK_HISTORY
  }
}

export const updatedJobOnWorkHistory = (jobs) => {
  return {
    type: UPDATED_JOB_ON_WORK_HISTORY,
    payload: jobs
  }
}

export const errorUpdatingJobOnWorkHistory = (error) => {
  return {
    type: ERROR_UPDATING_JOB_ON_WORK_HISTORY,
    payload: error
  }
}

export const updateJobOnWorkHistory = (jobConfig, jobs, token, closeModal, setSelectedJobDetailSet) => {
  return function (dispatch, getState) {
    dispatch(updatingJobOnWorkHistory())

    return getAxiosInstance(token).put(`${BASE_URI}/resume/workHistory`, jobConfig)
      .then(res => {
        if (res.status === 200) {
          let updatedJobs = jobs
          updatedJobs = updatedJobs.map((job) =>  job.id == res.data.id ? res.data : job);
         
          dispatch(updatedJobOnWorkHistory(updatedJobs))
          setSelectedJobDetailSet(false)
          closeModal()
          showSuccessToast('Successfully updated your resume!')
        }
      })
      .catch(err => {
        dispatch(errorUpdatingJobOnWorkHistory())
        showErrorToast('Error updating your resume.')
      })
  }
}


// Deleting Job From Work History
export const deletingJobFromWorkHistory = () => {
  return {
    type: DELETING_JOB_FROM_WORK_HISTORY
  }
}

export const deletedJobFromWorkHistory = (remainingJobs) => {
  return {
    type: DELETED_JOB_FROM_WORK_HISOTRY,
    payload: remainingJobs
  }
}

export const errorDeletingJobFromWorkHistory = (error) => {
  return {
    type: ERROR_DELETING_JOB_FROM_WORK_HISTORY,
    payload: error
  }
}

export const deleteJobFromWorkHistory = (jobId, allJobs, token, clearSelections) => {
  return function (dispatch, getState) {
    dispatch(deletingJobFromWorkHistory())

    return getAxiosInstance(token).delete(`${BASE_URI}/resume/workHistory/job/${jobId}`)
      .then(res => {
        if (res.status === 200) {
          const updatedJobsList = allJobs.filter((job) => job.id !== jobId)
          dispatch(deletedJobFromWorkHistory(updatedJobsList))
          clearSelections()
          showSuccessToast('Successfully deleted job from your resume!')
        }
      })
      .catch(err => {
        dispatch(errorDeletingJobFromWorkHistory())
        showErrorToast('Error deleting job from your resume.')
      })
  }
}


// Deleting Job Detail From Job
export const deletingJobDetail = () => {
  return {
    type: DELETING_JOB_DETAIL
  }
}

export const deletedJobDetail = (updatedJobsList) => {
  return {
    type: DELETED_JOB_DETAIL,
    payload: updatedJobsList
  }
}

export const errorDeletingJobDetail = (error) => {
  return {
    type: ERROR_DELETING_JOB_DETAIL,
    payload: error
  }
}

export const deleteJobDetail = (jobDetailId, jobs, token, setSelectedJobDetailSet,clearSelections) => {
  return function (dispatch, getState) {
    dispatch(deletingJobDetail())

    return getAxiosInstance(token).delete(`${BASE_URI}/resume/workHistory/jobDetail/${jobDetailId}`)
      .then(res => {
        if (res.status === 200) {
          let updatedJobs = jobs
          updatedJobs = updatedJobs.map((job) => job.id === res.data.id ? res.data : job);
          dispatch(deletedJobDetail(updatedJobs))
          setSelectedJobDetailSet(false)
          clearSelections()
          showSuccessToast('Successfully deleted job detail from your resume!')
        }
      })
      .catch(err => {
        dispatch(errorDeletingJobDetail())
        showErrorToast('Error deleting job detail from your resume.')
      })
  }
}


// ====================== Education Records ======================

// Updating Education Record
export const updatingEducationRecord = () => {
  return {
    type: UPDATING_EDUCATION_RECORD
  }
}

export const updatedEducationRecord = (educationRecords) => {
  return {
    type: UPDATED_EDUCATION_RECORD,
    payload: educationRecords
  }
}

export const errorUpdatingEducationRecord = (error) => {
  return {
    type: ERROR_UPDATING_EDUCATION_RECORD,
    payload: error
  }
}

export const updateEducationRecord = (educationConfig, educationRecords, token, closeModal, setSelectedEducationDetail) => {
  return function (dispatch, getState) {
    dispatch(updatingEducationRecord())
    return getAxiosInstance(token).put(`${BASE_URI}/resume/education`, educationConfig)
      .then(res => {
        if (res.status === 200) {
          let updatedEducationRecords = educationRecords
          updatedEducationRecords = updatedEducationRecords.map((educationRecord) =>  educationRecord.id == res.data.id ? res.data : educationRecord);
          dispatch(updatedEducationRecord(updatedEducationRecords))
          setSelectedEducationDetail(false)
          closeModal()
          showSuccessToast('Successfully updated your education record!')
        }
      })
      .catch(err => {
        dispatch(errorUpdatingEducationRecord())
        showErrorToast('Error updating your education record.')
      })
  }
}


// Delete Education Record
export const deletingEducationRecord = () => {
  return {
    type: DELETING_EDUCATION_RECORD
  }
}

export const deletedEducationRecord = (remainingEducationRecords) => {
  return {
    type: DELETED_EDUCATION_RECORD,
    payload: remainingEducationRecords
  }
}

export const errorDeletingEducationRecord = (error) => {
  return {
    type: ERROR_DELETING_EDUCATION_RECORD,
    payload: error
  }
}

export const deleteEducationRecord = (educationRecordId, educationRecords, token, clearSelections) => {
  return function (dispatch, getState) {
    dispatch(deletingEducationRecord())
    return getAxiosInstance(token).delete(`${BASE_URI}/resume/education/record/${educationRecordId}`)
      .then(res => {
        if (res.status === 200) {
          const updatedRecordsList = educationRecords.filter((record) => record.id !== educationRecordId)
          dispatch(deletedEducationRecord(updatedRecordsList))
          clearSelections()
          showSuccessToast('Successfully deleted education record!')
        }
      })
      .catch(err => {
        dispatch(errorDeletingEducationRecord())
        showErrorToast('Error deleting education record.')
      })
  }
}


// Deleting Education Activity
export const deletingEducationActivity = () => {
  return {
    type: DELETING_EDUCATION_ACTIVITY
  }
}

export const deletedEducationActivity = (updatedJobsList) => {
  return {
    type: DELETED_EDUCATION_ACTIVITY,
    payload: updatedJobsList
  }
}

export const errorDeletingEducationActivity = (error) => {
  return {
    type: ERROR_DELETING_EDUCATION_ACTIVITY,
    payload: error
  }
}

export const deleteEducationActivity = (educationActivityId, educationRecords, token, setSelectedEducationDetail, clearSelections) => {
  return function (dispatch, getState) {
    dispatch(deletingEducationActivity())

    return getAxiosInstance(token).delete(`${BASE_URI}/resume/education/activity/${educationActivityId}`)
      .then(res => {
        if (res.status === 200) {
          
          let updatedEducationRecords = educationRecords
          updatedEducationRecords = updatedEducationRecords.map((educationRecord) => educationRecord.id === res.data.id ? res.data : educationRecord);
          dispatch(deletedEducationActivity(updatedEducationRecords))
          setSelectedEducationDetail(false)
          clearSelections()
          showSuccessToast('Successfully deleted education activity from your resume!')
        }
      })
      .catch(err => {
        dispatch(errorDeletingEducationActivity())
        showErrorToast('Error deleting education activity from your resume.')
      })
  }
}


// Retrieve User Resumes
export const retrievingUserResumes = () => {
  return {
    type: RETRIEVING_USER_RESUMES
  }
}

export const retreivedUserResumes = (updatedJobsList) => {
  return {
    type: RETRIEVED_USER_RESUMES,
    payload: updatedJobsList
  }
}

export const errorRetrievingUserResumes = (error) => {
  return {
    type: ERROR_RETRIEVING_USER_RESUMES,
    payload: error
  }
}

export const retrieveUserResumes = (userId, token) => {
  return function (dispatch, getState) {
    dispatch(retrievingUserResumes())
    return getAxiosInstance(token).get(`${BASE_URI}/resume/uploads/${userId}`)
      .then(res => {
        if (res.status === 200) {
          dispatch(retreivedUserResumes(res.data))
        }
      })
      .catch(err => {
        dispatch(errorRetrievingUserResumes())
      })
  }
}


// Upload User Resume
export const uploadingUserResume = () => {
  return {
    type: UPLOADING_USER_RESUME
  }
}

export const uploadedUserResume = (resume) => {
  return {
    type: UPLOADED_USER_RESUME,
    payload: resume
  }
}

export const errorUploadingUserResume = (error) => {
  return {
    type: ERROR_UPLOADING_USER_RESUME,
    payload: error
  }
}

export const uploadUserResume = (formData, token, handClose) => {
  return function (dispatch, getState) {
    dispatch(uploadingUserResume())
    return getMultipartAxiosInstance(token).post(`${BASE_URI}/resume/upload`, formData)
      .then(res => {
        if (res.status === 200) {
          dispatch(uploadedUserResume(res.data))
          handClose()
          showSuccessToast('Successfully added new resume!')
        }
      })
      .catch(err => {
        dispatch(errorUploadingUserResume())
        showErrorToast('Uh oh. There was an error adding your resume.')
      })
  }
}




// Upload User Resume
export const deletingUserResume = () => {
  return {
    type: DELETING_USER_RESUME
  }
}

export const deletedUserResume = (resume) => {
  return {
    type: DELETED_USER_RESUME,
    payload: resume
  }
}

export const errorDeletingUserResume = (error) => {
  return {
    type: ERROR_DELETING_USER_RESUME,
    payload: error
  }
}

export const deleteUserResume = (userId, resumeId, token, resumes, setConfirmationVisible) => {
  return function (dispatch, getState) {
    dispatch(deletingUserResume())

    return getAxiosInstance(token).delete(`${BASE_URI}/resume/delete/attachment/${userId}/${resumeId}`)
    .then(res => {    
        if (res.status === 200) {

          let updatedResumes = resumes

        updatedResumes = updatedResumes.filter((resume) => resume.id !== resumeId)
          dispatch(deletedUserResume(updatedResumes))
          setConfirmationVisible(false)
          showSuccessToast('Successfully delete yout resume!')
        }
      })
      .catch(err => {
        dispatch(errorDeletingUserResume())
        showErrorToast('Uh oh. There was an error deleting your resume.')
      })
  }
}