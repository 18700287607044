import React, { useState } from 'react'

import Cookies from 'js-cookie';

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Backdrop from "@mui/material/Backdrop";

import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux';

import FAVICON from '../../../assets/interviewable-favicon.png'
import { colors } from '../../../constants/Colors';

import styles from './EditorProfileDetailModal.module.css'

import { useFilePicker } from 'use-file-picker';
import {
  FileAmountLimitValidator,
  FileTypeValidator,
  FileSizeValidator,
  ImageDimensionsValidator,
} from 'use-file-picker/validators';
import { updateProfileAvatar, updateUserMetaData, updatedProfileAvatar } from '../../../redux/actions/userActions';
import axios from 'axios';

import config from '../../../env.json'

const { BASE_URI } = config

export default function EditProfileDetailsModal(props) {

  const dispatch = useDispatch()

  const token = Cookies.get('token');
  // const token = Cookies.get('token')

  const authedUserId = useSelector((state) => state.auth.id)

  const [updatedFirstName, setUpdatedFirstName] = useState(null)
  const [updatedLastName, setUpdatedLastName] = useState(null)
  const [updatedAvatar, setUpdatedAvatar] = useState(null)
  const [updatedPreferredTitle, setUpdatedPreferredTitle] = useState(null)
  const [updatedAvatarSelected, setUpdatedAvatarSelected] = useState(false)

  const {
    isVisible,
    handleClose,
    avatarURI,
    firstName,
    lastName,
    preferredTitle
  } = props

  const saveProfileDetails = () => {

    const userConfig = {
      id: authedUserId,
      firstName: updatedFirstName,
      lastName: updatedLastName,
      preferredTitle: updatedPreferredTitle
    }

    dispatch(updateUserMetaData(userConfig, token, clearSelections))
  }

  const { openFilePicker, filesContent, loading, errors } = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
    onFilesRejected: ({ errors }) => {
      // this callback is called when there were validation errors
      // console.log('onFilesRejected', errors);
    },
    onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
      // this callback is called when there were no validation errors
      // console.log('onFilesSuccessfullySelected', plainFiles);
      setUpdatedAvatarSelected(true)
      // console.log('Files content: ', filesContent[0])
      setUpdatedAvatar(filesContent[0])
    },
    validators: [
      new FileAmountLimitValidator({ max: 1 }),
      new FileTypeValidator(['jpg', 'png', 'jpeg']),
      new FileSizeValidator({ maxFileSize: 50 * 1024 * 1024 /* 50 MB */ }),
      new ImageDimensionsValidator({
        maxHeight: 1024, // in pixels
        maxWidth: 1024,
        minHeight: 400,
        minWidth: 400,
      }),
    ],
  });

  const renderAvatar = () => {

    if (updatedAvatarSelected) {
      return <img className={styles.avatarStyle} alt={updatedAvatar.name} src={updatedAvatar.content}></img>
    }
    else if (avatarURI) {
      return <img src={avatarURI} className={styles.avatarStyle} />
    }
    else {
      return <img src={FAVICON} className={styles.avatarStyle} />
    }
  }

  const clearSelections = () => {
    setUpdatedAvatarSelected(false)
    setUpdatedAvatar(null)
    setUpdatedFirstName(null)
    setUpdatedLastName(null)
    setUpdatedPreferredTitle(null)
    handleClose()
  }

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  const uploadAvatar = () => {

    var blob = dataURItoBlob(updatedAvatar.content);
    var formData = new FormData();
    formData.append("file", blob);

    dispatch(updateProfileAvatar(formData, authedUserId, token, clearSelections))

  }

  return (
    <div>
      <Modal
        open={isVisible}
        onClose={() => clearSelections()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Typography style={{ fontSize: 22, textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5 }}>
            USER DETAILS
          </Typography>
          <Stack direction='row' style={{ alignItems: 'center', marginTop: 30 }}>
            {renderAvatar()}
            <Stack style={{ marginLeft: 10 }} direction='row'>
              <Button
                variant='contained'
                style={{ backgroundColor: 'white', color: colors.plum }}
                onClick={() => openFilePicker()}
              >
                <Typography>CHANGE</Typography>
              </Button>
              {updatedAvatarSelected ? <Button
                variant='contained'
                style={{ backgroundColor: 'white', color: colors.plum }}
                onClick={() => uploadAvatar()}
              >
                <Typography>UPLOAD</Typography>
              </Button> : null}
            </Stack>
          </Stack>
          <Stack style={{ marginTop: 20 }}>
            <Typography style={{ color: colors.plum, fontWeight: 'bold', fontSize: 18 }}>First Name</Typography>
            <TextField
              onChange={(event) => setUpdatedFirstName(event.target.value)}
              defaultValue={firstName}
              value={updatedFirstName}
              style={{ width: '80%', border: 1, height: 45 }}
            />
          </Stack>
          <Stack style={{ marginTop: 20 }}>
            <Typography style={{ color: colors.plum, fontWeight: 'bold', fontSize: 18 }}>Last Name</Typography>
            <TextField
              onChange={(event) => setUpdatedLastName(event.target.value)}
              value={updatedLastName}
              defaultValue={lastName}
              style={{ width: '80%', border: 1, height: 45 }}
            />
          </Stack>
          <Stack style={{ marginTop: 20 }}>
            <Typography style={{ color: colors.plum, fontWeight: 'bold', fontSize: 18 }}>Current Job Title</Typography>
            <TextField
              onChange={(event) => setUpdatedPreferredTitle(event.target.value)}
              value={updatedPreferredTitle}
              defaultValue={preferredTitle}
              style={{ width: '80%', border: 1, height: 45 }}
            />
          </Stack>
          <Stack direction='row' style={{ justifyContent: 'space-around', alignItems: 'center', marginTop: 60 }}>
            <Stack>
              <Button variant='contained'
                style={{ backgroundColor: 'silver', height: 45, width: 150 }}
                onClick={() => clearSelections()}
              >
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>CANCEL</Typography>
              </Button>
            </Stack>
            <Stack>
              <Button variant='contained'
                style={{ backgroundColor: 'purple', height: 45, width: 150 }}
                onClick={() => saveProfileDetails()}
              >
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>SAVE</Typography>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>

  );
}

EditProfileDetailsModal.propTypes = {
  isVisible: PropTypes.bool,
  handleClose: PropTypes.func,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  preferredTitle: PropTypes.string
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  padding: 40,
  p: 4,
};