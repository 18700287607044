import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';

import dayjs from 'dayjs';
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import MenuItem from '@mui/material/MenuItem'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import styles from './CreateMockInterviewAssessmentScreen.module.css'
import SidebarNav from '../../components/navigation/SidebarNav'
import { colors } from '../../constants/Colors'

import EnhancedTable from '../../components/tables/EnhancedTable'
import StandardButton from '../../components/buttons/StandardButton'
import { getAxiosInstance } from '../../utils/ApiUtils'
import { useSelector } from 'react-redux'

import config from '../../env.json'
import { useNavigate } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from '../../utils/ToastUtils';

export default function CreateMockInterviewAssessmentScreen() {

  const { BASE_URI } = config
  const navigate = useNavigate()
  const token = Cookies.get('token');

  const authedUserId = useSelector((state) => state.auth.id)

  const [cohorts, setCohorts] = useState([])
  const [behavioralFocusAreas, setBehavioralFocusAreas] = useState([])
  const [technicalFocusAreas, setTechnicalFocusAreas] = useState([])
  const [mockInterviewQuestions, setMockInterviewQuestions] = useState([])
  const [focusAreaFilters, setFocusAreaFilters] = useState([])

  const [interviewTypes, setInterviewTypes] = useState([])

  const [assessmentName, setAssessmentName] = useState('')
  const [earlyAccessValue, setEarlyAccessValue] = React.useState('no');

  const [selectedCohort, setSelectedCohort] = useState('')
  const [selectedInterviewType, setSelectedInterviewType] = useState('')
  const [assessmentStartTime, setAssessmentStartTime] = useState(dayjs())
  const [assessmentEndTime, setAssessmentEndTime] = useState(dayjs().add(1, 'day'))
  const [selectedInterviewQuestions, setSelectedInterviewQuestions] = useState([])


  const organizationId = useSelector((state) => state.org.id)

  useEffect(() => {
    getAxiosInstance(token).get(`${BASE_URI}/org/cohorts/${organizationId}`)
      .then((res) => {
        if (res.status == 200) {
          setCohorts(res.data)
        }
      })
      .catch((error) => { })

    getAxiosInstance(token).get(`${BASE_URI}/interviews/types`)
      .then((res) => {
        if (res.status == 200) {
          setInterviewTypes(res.data)
        }
      })
      .catch((error) => { })



  }, [organizationId, focusAreaFilters])

  const handleChange = (event, type) => {
    const {
      target: { value },
    } = event;

    switch (type) {
      case 'cohort':
        setSelectedCohort(
          typeof value === 'string' ? value.split(',') : value.name,
        );

        if (selectedInterviewType !== '') {
          getAxiosInstance(token).get(`${BASE_URI}/assessments/questions/${ typeof value === 'string' ? value.split(',') : value.name}/${selectedInterviewType[0]}`)
          .then((res) => {
            let dbQuestions = res.data
            dbQuestions.map((question) => {
              question.isVisible = true
            })
            setMockInterviewQuestions(dbQuestions)
          })
          .catch((err) => { })
        }
        break;
      case 'earlyAccess':
        setEarlyAccessValue(event.target.value)
        break;
      case 'interviewType':
        let interviewTypeValue = typeof value === 'string' ? value.split(',') : value
        setSelectedInterviewType(interviewTypeValue)
        if (selectedCohort !== '') {
          getAxiosInstance(token).get(`${BASE_URI}/interviews/focusAreas/${selectedCohort}/${interviewTypeValue}/`)
            .then((res) => {
              if (res.data.behavioral) {
                setBehavioralFocusAreas(res.data.behavioral)
              }
              if (res.data.technical) {
                setTechnicalFocusAreas(res.data.technical)
              }
            })
            .catch((error) => { })
        }

        if (selectedCohort) {
          getAxiosInstance(token).get(`${BASE_URI}/assessments/questions/${selectedCohort}/${interviewTypeValue}`)
            .then((res) => {
              let dbQuestions = res.data
              dbQuestions.map((question) => {
                question.isVisible = true
              })
              setMockInterviewQuestions(dbQuestions)
            })
            .catch((err) => { })
        }


        break;
      default:
        break;
    }
  };

  const renderCohorts = () => {
    if (cohorts) {
      return cohorts.map((cohort, index) => {
        return (
          <MenuItem key={index} value={cohort.id}>{cohort.name}</MenuItem>
        )
      })
    }
  }

  const renderInterviewTypes = () => {
    return ['Behavioral', 'Technical', 'Behavioral, Technical'].map((interviewType, index) => {
      return (
        <MenuItem key={index} value={interviewType}>{interviewType}</MenuItem>
      )
    })
  }


  const renderBehavioralFocusAreas = () => {
    if (behavioralFocusAreas.length > 0) {
      return behavioralFocusAreas.map((focusArea) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                key={focusArea.id}
                label={focusArea.name}
                value={focusArea.id}
                labelPlacement="end"
                checked={focusAreaFilters.findIndex(obj => obj.id === focusArea.id) == -1 ? false : true}
                onChange={() => updateQuestionFiltersList(focusArea.id, focusArea.type)}
                size='medium'
                color='secondary'
              />}
            label={focusArea.name} />

        )
      })
    }
    else {
      return (
      <Typography>No filters to display...</Typography>
      )
      
    }
  }

  const renderTechnicalFocusAreas = () => {
    if (technicalFocusAreas.length > 0) {
      return technicalFocusAreas.map((focusArea) => {
        return (
          <FormControlLabel control={
            <Checkbox
              key={focusArea.id}
              label={focusArea.name}
              value={focusArea.id}
              labelPlacement="end"
              checked={focusAreaFilters.findIndex(obj => obj.id === focusArea.id) == -1 ? false : true}
              onChange={() => updateQuestionFiltersList(focusArea.id, focusArea.type)}
              size='medium'
              color='secondary'
            />}
            label={focusArea.name} />
        )
      })
    }
    else {
      return (
        <Typography>No filters to display...</Typography>
      )
    }

  }

  /* 
    Scenario 1
    1. all interview questions are available on initial render [working]
    2. Filter (a) is checked. Filter is added to the filters array. Only questions matching the filter are visible. All
      other questions go to filtered question bucket. [working]
    3. if Filter (a) unchecked all questions that were previously filtered should return to make the list whole.  [working]


    Scenario 2
    1. Filter (a) is applied [wokring]
    2. User selects filter (b). Filtered questions from scenario one should be in view. Questions that apply to filter (b)
       should be retrieved from filtered question list and returned to the view

  */

  const updateQuestionFiltersList = (focusAreaId, focusAreaType) => {
    let updatedMockInterviewQuestions = mockInterviewQuestions
    let filteredInterviewQuestions = []

    const index = focusAreaFilters.findIndex((obj) => obj.id === focusAreaId);

    // if focus Areas is not present in focus area filters array
    if (index == -1) {
      // add filter to focus area filters array
      setFocusAreaFilters(focusAreaFilters.concat({ id: focusAreaId, type: focusAreaType }))

      updatedMockInterviewQuestions.forEach((interviewQuestion) => {
        switch (interviewQuestion.interviewQuestionType) {
          case 'BEHAVIORAL':
            const behavioralFilterExists = focusAreaFilters.filter((focusArea) => focusArea.id === interviewQuestion.behavioralFocusArea.id)
            if (interviewQuestion.behavioralFocusArea.id === focusAreaId || behavioralFilterExists.length > 0) {
              interviewQuestion.isVisible = true
            }
            else {
              interviewQuestion.isVisible = false

            }
            break;

          case 'TECHNICAL':
            const technicalFilterExists = focusAreaFilters.filter((focusArea) => focusArea.id === interviewQuestion.technicalFocusArea.id)
            if (interviewQuestion.technicalFocusArea.id === focusAreaId || technicalFilterExists.length > 0) {
              interviewQuestion.isVisible = true
            }
            else {
              interviewQuestion.isVisible = false

            }
            break;

        }
      })
      setMockInterviewQuestions(updatedMockInterviewQuestions)

    }
    // if focus area is present in focus area filter array
    else {

      const updatedFocusAreaFilters = focusAreaFilters.filter((focusArea) => focusArea.id !== focusAreaId)
      updatedMockInterviewQuestions.forEach((interviewQuestion) => {
        switch (interviewQuestion.interviewQuestionType) {
          case 'BEHAVIORAL':
            if (updatedFocusAreaFilters.length > 0 && updatedFocusAreaFilters.find((obj) => obj.id === interviewQuestion.behavioralFocusArea.id) != null) {
              interviewQuestion.isVisible = true
            }
            else if (updatedFocusAreaFilters == 0) {
              interviewQuestion.isVisible = true
            }
            else {
              interviewQuestion.isVisible = false

            }
            break;

          case 'TECHNICAL':
            if (updatedFocusAreaFilters.length > 0 && updatedFocusAreaFilters.find((obj) => obj.id === interviewQuestion.technicalFocusArea.id) != null) {
              interviewQuestion.isVisible = true
            }
            else if (updatedFocusAreaFilters == 0) {
              interviewQuestion.isVisible = true
            }
            else {
              interviewQuestion.isVisible = false

            }

            break;
        }
      })

      setFocusAreaFilters(updatedFocusAreaFilters)
      setMockInterviewQuestions(updatedMockInterviewQuestions)
    }
  }

  const createAssessment = () => {
    const assessmentConfig = {
      assessmentName: assessmentName,
      assessmentCreatorId: authedUserId,
      cohortId: selectedCohort[0],
      assessmentStartDate: assessmentStartTime.toDate(),
      assessmentEndDate: assessmentEndTime.toDate(),
      interviewType: selectedInterviewType[0],
      interviewQuestionIds: selectedInterviewQuestions,
      allowEarlyAccess: earlyAccessValue === 'yes' ? true : false
    }

    getAxiosInstance(token).post(`${BASE_URI}/assessments/create/interview`, assessmentConfig)
      .then(res => {
        if (res.status === 200) {
        showSuccessToast('Successfully created assessment!')
         navigate('/assessments/admin')
        }
      })
      .catch(err => {
        showErrorToast('Error creating assessment')
      })

    
  }


  return (
    <div className={styles.pageWrapper}>
      <SidebarNav />
      <Stack className={styles.mainBodyWrapper} direction='row'>
        <Stack className={styles.leftColumn}>
          <Typography
            variant="h6" component="h2"
            style={{ textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5, fontSize: 20 }}>
            Assessment Details
          </Typography>
          <Stack direction='row' className={styles.assessmentDetailsWrapper}>
            <Stack>
              <Typography>Name</Typography>
              <TextField
                onChange={(event) => setAssessmentName(event.target.value)}
                value={assessmentName}
                className={styles.textInputStyle}
              />
              <Typography>Cohort</Typography>
              <Select
                id="simple-select"
                value={selectedCohort ? selectedCohort : ''}
                onChange={(event) => handleChange(event, 'cohort')}
                className={styles.selectStyle}
              >
                {renderCohorts()}
              </Select>
              <Typography>Interview Type</Typography>
              <Select
                labelId="resume-label-type"
                id="simple-select"
                value={selectedInterviewType}
                onChange={(event) => handleChange(event, 'interviewType')}
                className={styles.selectStyle}
              >
                {renderInterviewTypes()}
              </Select>
            </Stack>
            <Stack className={styles.rightColumnAssessmentDetailsWrapper}>
              <Typography>Start Date and Time</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                  <DateTimePicker
                    defaultValue={new Date()}
                    value={assessmentStartTime}
                    onChange={(val) => setAssessmentStartTime(val)}
                    className={styles.selectStyle} />
                </DemoContainer>
              </LocalizationProvider>
              <Typography>End Date and Time</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                  <DateTimePicker
                    className={styles.selectStyle}
                    // defaultValue={new Date()}
                    value={assessmentEndTime}
                    onChange={(val) => setAssessmentEndTime(val)}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <Typography>Allow Early Access to Assessment</Typography>
              <Box className={styles.radioButtonWrapper}>
                <Typography>No</Typography>
                <Radio
                  color='secondary'
                  checked={earlyAccessValue === 'no'}
                  onChange={(event) => handleChange(event, 'earlyAccess')}
                  value="no"
                  name="radio-buttons"
                  slotProps={{ input: { 'aria-label': 'A' } }}
                />
                <Typography>Yes</Typography>
                <Radio
                  color='secondary'
                  checked={earlyAccessValue === 'yes'}
                  onChange={(event) => handleChange(event, 'earlyAccess')}
                  value="yes"
                  name="radio-buttons"
                  slotProps={{ input: { 'aria-label': 'B' } }}
                />
              </Box>
            </Stack>
          </Stack>
          <Stack className={styles.interviewQuestionsWrapper}>
            {/* <Typography
            variant="h6" component="h2"
            style={{ textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5, fontSize: 20 }}>
            Mock Interview Questions
          </Typography> */}
            <Stack className={styles.tableWrapper}>
              <EnhancedTable
                mockInterviewQuestions={mockInterviewQuestions.length > 0 ? mockInterviewQuestions.filter((question) => question.isVisible == true) : []}
                setSelectedInterviewQuestions={setSelectedInterviewQuestions}
                createAssessment={createAssessment}
              />
            </Stack>

            {/* <Stack className={styles.submitButtonWrapper}>
              <StandardButton 
                buttonWidth={300}
                label='Create Assessment'
                backgroundColor={colors.oceanBlue}
                onPress={() => console.log('Hello')}
              />
            </Stack> */}
          </Stack>
        </Stack>
        <Stack className={styles.rightColumn}>
          <Typography
            variant="h6" component="h2"
            style={{ textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5, fontSize: 20 }}>
            Filters
          </Typography>
          <Stack className={styles.focusAreaFilterWrapper}>
            <Typography className={styles.focusAreaFilterText}>Behavioral</Typography>
            {renderBehavioralFocusAreas()}
          </Stack>
          <Stack className={styles.focusAreaFilterWrapper}>
            <Typography className={styles.focusAreaFilterText}>Technical</Typography>
            {renderTechnicalFocusAreas()}
          </Stack>


        </Stack>

      </Stack>
    </div>
  )
}