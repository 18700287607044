import React from 'react'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Backdrop from "@mui/material/Backdrop";

import PropTypes from 'prop-types'

export default function DeleteConfirmationModal(props) {

  const { 
    isVisible, 
    handleClose, 
    triggerAction, 
    modalBody,
    triggerText,
  } = props

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    padding: 40,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={isVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalBody}
          </Typography>
          <Stack direction='row' style={{justifyContent: 'space-around', alignItems: 'center', marginTop: 20}}>
            <Stack>
              <Button variant='contained'
                style={{ backgroundColor: 'silver', height: 45, width: 150 }}
                onClick={() => handleClose()}
              >
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>CANCEL</Typography>
              </Button>
            </Stack>
            <Stack>
              <Button variant='contained'
                style={{ backgroundColor: 'purple', height: 45, width: 150 }}
                onClick={() => triggerAction()}
              >
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>{triggerText ? triggerText : 'CONFIRM'}</Typography>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>

  );
}

DeleteConfirmationModal.propTypes = {
  isVisible: PropTypes.bool,
  handleClose: PropTypes.func,
  triggerAction: PropTypes.func,
  modalBody: PropTypes.string,
  triggerText: PropTypes.string,
}