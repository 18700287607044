import { getAxiosInstance } from '../../utils/ApiUtils'
import { 
  SET_ACCOUNT_DETAILS,
  SET_STRIPE_CUSTOMER_ID,
  RETRIEVING_CREDITS_FOR_ACCOUNT,
  RETRIEVED_CREDITS_FOR_ACCOUNT,
  ERROR_RETRIEVING_CREDITS_FOR_ACCOUNT
} from '../constants/accountActionTypes'

import config from '../../env.json'
const { BASE_URI } = config

export const setAccountDetails = (accountDetails) => {
  return {
    type: SET_ACCOUNT_DETAILS,
    payload: accountDetails
  }
}

export const setStripeCustomerId = (stripeCustomerId) => {
  return {
    type: SET_STRIPE_CUSTOMER_ID,
    payload: stripeCustomerId
  }
}

// Retrieve Credits for Account
export const retrievingCreditsForAccount = () => {
  return {
    type: RETRIEVING_CREDITS_FOR_ACCOUNT
  }
}

export const retrievedCreditsForAccount = (credits) => {
  return {
    type: RETRIEVED_CREDITS_FOR_ACCOUNT,
    payload: credits
  }
}

export const errorRetrievingCreditsForAccount = (error) => {
  return {
    type: ERROR_RETRIEVING_CREDITS_FOR_ACCOUNT,
    payload: error
  }
}

export const retrieveCreditsForAccount = (userId, token) => {
  return function (dispatch, getState) {
    dispatch(retrievingCreditsForAccount())
    return getAxiosInstance(token).get(`${BASE_URI}/accounts/credits/${userId}`)
      .then(res => {
        if (res.status === 200) {
          dispatch(retrievedCreditsForAccount(res.data))
        } 
      })
      .catch(err => {
        dispatch(errorRetrievingCreditsForAccount())
        
      })
  }
}