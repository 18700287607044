import { loadStripe } from '@stripe/stripe-js';

let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe('pk_test_51NkytyFmIdBJHCdMVWjPgg3fcuct771ywc5r9Vtktc9NT3SOJutDjBCRKFKB9xNsqqAMLmyeEj7Vhj4Nv1LWULNY00KT7sIjYg');
    // stripePromise = loadStripe(process.env.PUBLIC_STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
};

export default getStripe;