import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";

import Cookies from 'js-cookie';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack'
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from '@mui/material/CircularProgress';
import SidebarNav from '../../components/navigation/SidebarNav';


import { Button, Typography, Grid, Box, Tabs, Tab } from '@mui/material';

import NewInterviewModal from '../../components/modals/NewInterviewModal';

import { colors } from '../../constants/Colors';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveActiveInterviews, retrieveCompletedInterviews } from '../../redux/actions/interviewActions';

import { getFormattedDate } from '../../utils/DateUtils';

import RoleSelectorModal from '../../components/modals/RoleSelectorModal';

import CompletedInterviewsTable from '../../components/interviews/CompletedInterviewsTable';

import { ConstructionOutlined } from '@mui/icons-material';

import DeleteConfirmationModal from '../../components/modals/billing/DeleteConfirmationModal';
import { showErrorToast } from '../../utils/ToastUtils';

import styles from './InterviewsHomeScreen.module.css'
import { retrieveCreditsForAccount } from '../../redux/actions/accountActions';
import { retrieveUserResumes } from '../../redux/actions/userActions';
import { getAxiosInstance } from '../../utils/ApiUtils';

import config from '../../env.json'

export default function AdminInterviewsHomeScreen({ navigation }) {

  const { BASE_URI } = config

  const navigate = useNavigate()
  const dispatch = useDispatch()


  const token = Cookies.get('token')
  const role = sessionStorage.getItem('role')
  const planType = sessionStorage.getItem('planType')

  const authedUserId = useSelector((state) => state.auth.id)
  // const activeInterviews = useSelector((state) => state.interviews.activeCandidateInterview)
  // const completedInterviews = useSelector((state) => state.interviews.completedInterviews)
  const preferredRole = useSelector((state) => state.user.preferredRole)
  const accountStatus = useSelector((state) => state.account.status)
  const isCohortMember = useSelector((state) => state.user.isCohortMember)
  const completedOnboardingV1 = useSelector((state) => state.user.completedOnboardingV1)

  const planStatus = useSelector((state) => state.org.planStatus)
  const creditsRemaining = useSelector((state) => state.account.creditsRemaining)

  const [interviewSettingModalOpen, setInterviewSettingsModalOpen] = useState(false)
  const [roleSelectorModalVisible, setRoleSelectorModalVisible] = useState(false)
  const [subscriptionRequiredModalOpen, setSubscriptionRequiredModalOpen] = useState(false)
  const [purchaseAddOnsModalOpen, setPurchaseAddOnModalOpen] = useState(false)
  const [activeAssessments, setActiveAssessments] = useState([])


  const [adminsAndMentors, setAdminsAndMentors] = useState([])
  const [students, setStudents] = useState([])
  const [isAdmin, setIsAdmin] = useState(false)
  const [impersonatorId, setImpersonatorId] = useState(null)
  const [activeInterviews, setActiveInterviews] = useState(null)
  const [completedInterviews, setCompletedInterviews] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const COLLAB_LAB_COHORT_ID = 'e1766e5a-d36a-427c-9ae9-ca7b8d45a908'
  const COLLAB_LAB_ORG_ID = 'b74bb112-f9f9-4418-bddc-99126437aedd'



  useEffect(() => {
    // get all users in the org
    setIsLoading(true)
    getAxiosInstance(token).get(`${BASE_URI}/org/users/lite/${COLLAB_LAB_ORG_ID}`)
      .then(res => {
        if (res.status === 200) {
          let users = res.data
          let adminsAndMentors = []
          let students = []

          if (users) {
            users.map((user) => {
              if (user.role == 'STUDENT') {
                students.push(user)
              }
              else {
                adminsAndMentors.push(user)
              }
            })

            setAdminsAndMentors(adminsAndMentors);
            setStudents(students);
          }
          setIsLoading(false)
        }
      })
      .catch(err => { }, [impersonatorId])


    // get all active interviews
    if (impersonatorId) {
      getAxiosInstance(token).get(`${BASE_URI}/interviews/active/${impersonatorId}`)
        .then((res) => {
          if (res.status == 200) {
            setActiveInterviews(res.data)
          }
        })
        .catch(err => {
          // dispatch(errorRetrievingActiveInterviews())
        })

      getAxiosInstance(token).get(`${BASE_URI}/interviews/completed/${impersonatorId}`)
        .then((res) => {
          if (res.status == 200) {
            setCompletedInterviews(res.data)
          }
        })
        .catch(err => {

        })

      getAxiosInstance(token).get(`${BASE_URI}/assessments/interviews/active/${authedUserId}`)
        .then((res) => {
          if (res.status == 200) {
            setActiveAssessments(res.data)
          }
        })
        .catch((error) => { })
    }

    // dispatch(retrieveUsersForOrg(res.data.organizationId, res.data.token))
    // dispatch(retrieveActiveInterviews(authedUserId, token))
    // dispatch(retrieveCompletedInterviews(authedUserId, token))
    // dispatch(retrieveCreditsForAccount(authedUserId, token))
    // dispatch(retrieveUserResumes(authedUserId, token))
    // setRoleSelectorModalVisible(completedOnboardingV1 == false || preferredRole === '' || preferredRole == null)

  }, [impersonatorId])




  const navigateToInterview = (interviewId) => {
    navigate(`/impersonate/interview/${interviewId}`)
  }

  function AssessmentsTable() {
    return <Grid style={{ alignItems: 'flex-start' }}>
      <TableContainer component={Paper}>
        <Table style={{ minWidth: 650, }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Type</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Due Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activeInterviews.map((activeAssessment) => {
              return (
                <TableRow
                  key={activeInterviews.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      className={styles.interviewTypeTableText}
                      onClick={() => {
                        navigateToInterview(activeAssessment.candidateInterviewId)
                      }}
                    >
                      {activeAssessment.assessmentName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography className={styles.interviewDateTableText}>{renderByTitleCasing(activeAssessment.interviewType)}</Typography>
                  </TableCell>
                  <TableCell align="left">{getFormattedDate(activeAssessment.interviewDate)}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  };

  const renderActiveInterviews = () => {
    if (activeInterviews) {
      return activeInterviews.map((activeInterview) => {
        return (
          <TableRow
            key={activeInterview.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <Typography
                className={styles.interviewTypeTableText}
                onClick={() => {
                  navigateToInterview(activeInterview.id)
                }}
              >
                {activeInterview.type}
              </Typography>
            </TableCell>
            {/* <TableCell align="left">
              <Typography className={styles.interviewDateTableText}>{activeInterview.level || 'Not availabe'}</Typography>
            </TableCell> */}
            <TableCell align="left">{getFormattedDate(activeInterview.interviewDate)}</TableCell>
            <TableCell align="left">{activeInterview.isAssessment ? 'Yes' : 'No'}</TableCell>
          </TableRow>
        )
      })
    }
  }

  function InterviewsTable() {
    return <Grid style={{ alignItems: 'flex-start' }}>
      <TableContainer component={Paper}>
        <Table style={{ minWidth: 650, }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'white' }}>Interview Type</TableCell>
              {/* <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'white' }}>Level</TableCell> */}
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'white' }} align="left">Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'white' }} align="left">Assessment</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {activeInterviews ? renderActiveInterviews() : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  };

  const renderByTitleCasing = (interviewType) => {
    console.log('Interview type: ', interviewType)
    switch (interviewType) {
      case 'BEHAVIORAL':
        return 'Behavioral'
      case 'BEHAVIORAL_TECHNICAL':
        return 'Behavioral & Technical'
      case 'PROFESSIONAL':
        return 'Professional'
      case 'BEHAVIORAL_PROFESSIONAL':
        return 'Behavioral/Professional'
      case 'TECHNICAL':
        return 'Technical'
      default:
      // Handle edge case
    }
  }

  function renderInterviewSummary() {
    if (isLoading) {
      return (
        <Box className={styles.loaderWrapper}>
          <CircularProgress color="secondary" />
        </Box>
      )
    }
    else if (completedInterviews) {
      return completedInterviews.map((completedInterview) => {
        if (completedInterview) {
          return (
            <TableRow
              key={completedInterview.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell variant="body" scope="row">
                <Typography
                  className={styles.interviewTypeTableText}
                  onClick={() => navigate(`/interview/summary/${completedInterview.id}`)}
                >
                  {renderByTitleCasing(completedInterview.interviewType)}
                </Typography>
              </TableCell>
              <TableCell align='left'>
                <Typography className={styles.interviewDateTableText}>{completedInterview.level || 'Not availabe'}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={styles.interviewDateTableText}>{getFormattedDate(completedInterview.interviewDate)}</Typography></TableCell>
            </TableRow>
          )
        }
      })
    }
    else {
      return <Typography>Interviews you've finished will show up here.</Typography>
    }
  }

  const checkAccountStatusBeforeInterviewCreation = () => {
    if (accountStatus === 'CANCELED' && role === 'STUDENT') {
      showErrorToast('Your account requires attention. Please contact your account admin.')
    }
    else if (accountStatus === 'CANCELED' && role === 'ADMIN') {
      setSubscriptionRequiredModalOpen(true)
    }
    else if (accountStatus === 'ACTIVE' && (role === 'ADMIN' || role === 'SUPER_ADMIN') && creditsRemaining == 0) {
      setPurchaseAddOnModalOpen(true)
    }

    else {
      setInterviewSettingsModalOpen(true)
    }
  }

  const renderCreditsCounter = () => {
    if (planType && planType !== 'TEAM') {
      return (
        <Stack className={styles.creditsWrapper}>
          <Typography style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'center' }}>
            Credits Remaining
          </Typography>
          <Typography style={{ fontSize: 35, fontWeight: 'bold', textAlign: 'center', color: colors.plum, textAlign: 'center' }}>
            {creditsRemaining ? creditsRemaining : 0}
          </Typography>

          {creditsRemaining == 0 ? <Button variant='text' onClick={() => navigate('/billing')}><Typography className={styles.buyMoreButtonText}>BUY MORE</Typography></Button> : null}
        </Stack>
      )
    }
  }

  const renderAssessments = () => {
    if (planType && planType === 'TEAM') {
      return (
        <Stack className={styles.activeAssessmentWrapper}>
          <Grid container spacing={1} style={{ marginLeft: 20, alignItems: 'center' }} >
            <h2 style={{ textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5 }}>Active Assessments</h2>
          </Grid>

          <AssessmentsTable />
        </Stack>

      )
    }
  }

  const renderStudentsForCohort = () => {
    if (students) {
      return students.map((student) => {
        return <option value={student.id}>{student.email}: {student.firstName ? student.firstName : null} {student.lastName ? student.lastName : null}</option>
      })
    }
  }

  return (
    <Grid container spacing={2}>
      <SidebarNav />
      <Grid style={{ marginTop: 50, marginLeft: 50 }} xs={8}>
        {isLoading ?  <Stack>
          <Typography>Loading students from cohort...</Typography>
          <LinearProgress color="secondary" size="lg" value={12} /> 
        </Stack>: null}
        <Stack style={{ width: '35dvw' }}>
          <Typography style={{fontSize: 18}}>Select a student: </Typography>

          <select
            name="students" id="students"
            onChange={() => {
              let selectElement = document.querySelector('#students');
              let output = selectElement.value;

              setImpersonatorId(null)
              setImpersonatorId(output)
            }}
          >
            {renderStudentsForCohort()}
          </select>

        </Stack>
        <hr style={{ width: '45dvw', marginLeft: 10, marginBottom: 30 }} />
        <Stack direction='row' className={styles.activeInterviewWrapper}>
          <Stack>
            <Grid container spacing={1} style={{ marginLeft: 20, alignItems: 'center' }} >
              <h2 style={{ textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5 }}>Active Interviews</h2>
            </Grid>

            {activeInterviews ? <InterviewsTable /> : <Stack style={{ marginTop: 20 }}>
              <Button variant='contained'
                style={{ marginLeft: 30, backgroundColor: colors.blazeRed, height: 35, width: 220 }}
                onClick={() => checkAccountStatusBeforeInterviewCreation()}
              >
                <Typography>Start An Interview</Typography>
              </Button></Stack>}
          </Stack>

          {/* {renderCreditsCounter()} */}

        </Stack>
        {/* <Stack>
          {renderAssessments()}
        </Stack> */}

        <Grid style={{ marginTop: 50, marginLeft: 20 }}>
          <h2 style={{ textDecoration: 'underline', textDecorationColor: colors.coolBlue, textUnderlineOffset: 10, textDecorationThickness: 5 }}>Completed Interviews</h2>
        </Grid>
        <Stack>
          <CompletedInterviewsTable
            completedInterviews={completedInterviews}
            renderInterviewSummary={renderInterviewSummary}
          />
        </Stack>
      </Grid>
      <NewInterviewModal
        open={interviewSettingModalOpen}
        handleClose={() => setInterviewSettingsModalOpen(false)}
      />
      <RoleSelectorModal
        isVisible={roleSelectorModalVisible}
        handleClose={setRoleSelectorModalVisible}
        isCohortMember={isCohortMember}
        userId={authedUserId}
      />
      <DeleteConfirmationModal
        isVisible={subscriptionRequiredModalOpen}
        handleClose={() => setSubscriptionRequiredModalOpen()}
        triggerAction={() => navigate(`/plans/solo`)}
        triggerText='Subscribe'
        modalBody={'Subscribe to a plan to start your next interview.'}
      />


      <DeleteConfirmationModal
        isVisible={purchaseAddOnsModalOpen}
        handleClose={() => setPurchaseAddOnModalOpen(false)}
        triggerAction={() => navigate(`/billing`)}
        triggerText='Buy More'
        modalBody="You're putting in some effort! It looks like you've used all of your credits. "
      />
    </Grid>
  )
}