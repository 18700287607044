import React from 'react'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography';

import { useSelector } from 'react-redux';
import StandardButton from '../../components/buttons/StandardButton';
import getStripe from '../../stripe/getStripe';


export default function CheckOutScreen() {

  const authedUserEmail = useSelector((state) => state.auth.email)

  async function handleCheckout() {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          // price: 'price_1Nl0MdFmIdBJHCdMAA7lcXPB', prod
          price: 'price_1NmpKiFmIdBJHCdMbFHMEl8B',
          quantity: 1,
        },
      ],
      mode: 'subscription',
      successUrl: `http://interviewable.us-east-1.elasticbeanstalk.com/login`,
      cancelUrl: `http://interviewable.us-east-1.elasticbeanstalk.com/cancel`,
      customerEmail: authedUserEmail,
    });
    console.warn(error.message);
  }

  return (
    <Box
      style={{
        marginTop: '10%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <Stack style={{
        height: 350,
        padding: 30,
        width: '70%',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: '1px 2px 8px black',
        alignItems: 'center',
        p: 4,
      }}>
        <Typography>
          Welcome to Interviewiable! Select the <em>CHECK OUT</em> button below to complete the registration process.
          We use Stripe to ensure your security while providing payment. We never store any of your payment information. 
        </Typography>
        <StandardButton
          label='Check Out'
          onPress={() => handleCheckout()}
        />
      </Stack>



    </Box>
  )
}