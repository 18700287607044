import { getAxiosInstance } from '../../utils/ApiUtils'
import {
  CREATING_ORG_ACCOUNT,
  CREATED_ORG_ACCOUNT,
  ERROR_CREATING_ORG_ACCOUNT,
  SET_ORGANIZATION_ID,
  CREATING_COHORT,
  CREATED_COHORT,
  ERROR_CREATING_COHORT,
  CREATING_GROUP,
  CREATED_GROUP,
  ERROR_CREATING_GROUP,
  RETRIEVING_COHORT,
  RETRIEVED_COHORT,
  RETRIEVING_ALL_USERS_FOR_ORG,
  RETRIEVED_ALL_USERS_FOR_ORG,
  ERROR_RETRIEVING_ALL_USERS_FOR_ORG,
  ADDING_SEAT_TO_SUBSCRIPTION,
  ADDED_SEAT_TO_SUBSCRIPTION,
  ERROR_ADDING_SEAT_TO_SUBSCRIPTION,
  CANCELLING_SUBSCRIPTION,
  CHECKING_SUBSCRIPTION_ON_ACCOUNT,
  CHECKED_SUBSCRIPTION_ON_ACCOUNT,
  ERROR_CHECKING_SUBSCRIPTION_ON_ACCOUNT,
  UPDATING_COHORT_DETAILS,
  UPDATED_COHORT_DETAILS,
  ERROR_UPDATING_COHORT_DETAILS,
  REMOVING_MEMBER_FROM_TEAM_ACCOUNT,
  REMOVED_MEMBER_FROM_TEAM_ACCOUNT,
  ERROR_REMOVING_MEMBER_FROM_TEAM_ACCOUNT,
  RETRIEVED_GROUPS,
  RETRIEVING_GROUPS,
  ERROR_RETRIEVING_GROUPS
} from '../constants/organizationActionTypes'

import { loggedInUser } from './authenticationActions'

import axios from 'axios'

// const BASE_URI = 'http://localhost:5000'
// const PROD_SERVER_URI = process.env.REACT_APP_PROD_SERVER_URI

import config from '../../env.json'
import { showErrorToast, showSuccessToast } from '../../utils/ToastUtils'

const { BASE_URI } = config

export const setOrganizationId = (organizationId) => {
  return {
    type: SET_ORGANIZATION_ID,
    payload: organizationId
  }
}

// Create a new user account
export const creatingOrgAccount = () => {
  return {
    type: CREATING_ORG_ACCOUNT
  }
}

export const createdOrgAccount = (orgData) => {
  return {
    type: CREATED_ORG_ACCOUNT,
    payload: orgData
  }
}

export const errorCreatingOrgAccount = (error) => {
  return {
    type: ERROR_CREATING_ORG_ACCOUNT,
    payload: error
  }
}

export const createOrgAccount = (organizationConfig, handleCheckout) => {
  return function (dispatch, getState) {
    dispatch(creatingOrgAccount())
    return axios.post(`${BASE_URI}/accounts/organization/create`, organizationConfig)
      .then(res => {
        if (res.status === 200) {
          dispatch(createdOrgAccount(res.data))
          dispatch(loggedInUser(res.data.account.admin))

          // return stripe customer id on create of admin account pass it 
          // as param to handle checkout
          handleCheckout(res.data.account.admin.token, res.data.account.admin.stripeCustomerId)
        }
        else if (res.status === 404) {
          showErrorToast('Error creating teams account.')
        }
      })
      .catch(err => {
        dispatch(errorCreatingOrgAccount())

      })
  }
}



// Create a new cohort
export const creatingCohort = () => {
  return {
    type: CREATING_COHORT
  }
}

export const createdCohort = (orgData) => {
  return {
    type: CREATED_COHORT,
    payload: orgData
  }
}

export const errorCreatingCohort = (error) => {
  return {
    type: ERROR_CREATING_COHORT,
    payload: error
  }
}

export const createNewCohort = (cohortConfig, token, navigate) => {
  return function (dispatch, getState) {
    dispatch(creatingCohort())
    return getAxiosInstance(token).post(`${BASE_URI}/org/cohort/create`, cohortConfig)
      .then(res => {
        if (res.status === 200) {
          dispatch(createdCohort(res.data))
          navigate()
          showSuccessToast('Cohort successfully created!')
        }
      })
      .catch(err => {
        dispatch(errorCreatingCohort())
        showErrorToast('Error creating cohort. Please try again later.')

      })
  }
}

// Create a new group
export const creatingGroup = () => {
  return {
    type: CREATING_GROUP
  }
}

export const createdGroup = (orgData) => {
  return {
    type: CREATED_GROUP,
    payload: orgData
  }
}

export const errorCreatingGroup = (error) => {
  return {
    type: ERROR_CREATING_GROUP,
    payload: error
  }
}

export const createNewGroup = (groupConfig, token, navigate) => {
  return function (dispatch, getState) {
    dispatch(creatingGroup())
    return getAxiosInstance(token).post(`${BASE_URI}/org/cohorts/groups/create`, groupConfig)
      .then(res => {
        if (res.status === 200) {
          dispatch(createdGroup(res.data))
          navigate()
          showSuccessToast('Group successfully created!')
        }
      })
      .catch(err => {
        dispatch(errorCreatingGroup())
        showErrorToast('Error creating Group. Please try again later.')
      })
  }
}

// Retrieve Groups by Cohort
export const retrievingGroups = () => {
  return {
    type: RETRIEVING_GROUPS
  }
}

export const retrievedGroups = (groups) => {
  return {
    type: RETRIEVED_GROUPS,
    payload: groups
  }
}

export const errorRetrievingGroups = (error) => {
  return {
    type: ERROR_RETRIEVING_GROUPS,
    payload: error
  }
}

export const retrieveGroupsForCohort = (cohortId, token) => {
  return function (dispatch, getState) {
    dispatch(retrievingGroups())
    return getAxiosInstance(token).get(`${BASE_URI}/org/cohort/${cohortId}/teams`)
      .then(res => {
        if (res.status === 200) {
          dispatch(retrievedGroups(res.data))
        }
      })
      .catch(err => {
        dispatch(errorRetrievingGroups())

      })
  }
}


// Retrieve All Cohorts
export const retrievingCohorts = () => {
  return {
    type: CREATING_COHORT
  }
}

export const retrievedCohorts = (orgData) => {
  return {
    type: CREATED_COHORT,
    payload: orgData
  }
}

export const errorRetrievingCohorts = (error) => {
  return {
    type: ERROR_CREATING_COHORT,
    payload: error
  }
}

export const retrieveCohortsForOrg = (organizationId, token) => {
  return function (dispatch, getState) {
    dispatch(retrievingCohorts())
    return getAxiosInstance(token).get(`${BASE_URI}/org/cohorts/${organizationId}`)
      .then(res => {
        if (res.status === 200) {
          dispatch(retrievedCohorts(res.data))
        }
      })
      .catch(err => {
        dispatch(errorRetrievingCohorts())

      })
  }
}


// Retrieve Single Cohort
export const retrievingCohort = () => {
  return {
    type: RETRIEVING_COHORT
  }
}

export const retrievedCohort = (cohort) => {
  return {
    type: RETRIEVED_COHORT,
    payload: cohort
  }
}

export const errorRetriveingCohort = (error) => {
  return {
    type: ERROR_CREATING_COHORT,
    payload: error
  }
}

export const retrieveCohortForOrg = (organizationId, cohortId, token) => {
  return function (dispatch, getState) {
    dispatch(retrievingCohort())
    return getAxiosInstance(token).get(`${BASE_URI}/org/cohort/${organizationId}/${cohortId}`)
      .then(res => {
        if (res.status === 200) {
          dispatch(retrievedCohort(res.data))
        }
      })
      .catch(err => {
        dispatch(errorRetriveingCohort())

      })
  }
}


// Retrieving Org Users
export const retrievingOrgUsers = () => {
  return {
    type: RETRIEVING_ALL_USERS_FOR_ORG
  }
}

export const retreievedOrgUsers = (users) => {
  return {
    type: RETRIEVED_ALL_USERS_FOR_ORG,
    payload: users
  }
}

export const errorRetrievingOrgUsers = (error) => {
  return {
    type: ERROR_RETRIEVING_ALL_USERS_FOR_ORG,
    payload: error
  }
}

export const retrieveUsersForOrg = (organizationId, token) => {
  return function (dispatch, getState) {
    dispatch(retrievingOrgUsers())
    return getAxiosInstance(token).get(`${BASE_URI}/org/users/lite/${organizationId}`)
      .then(res => {
        if (res.status === 200) {
          dispatch(retreievedOrgUsers(res.data))
        }
      })
      .catch(err => {
        dispatch(errorRetrievingOrgUsers())

      })
  }
}


// Add Seat to Subscription
export const addingSeatToSubscription = () => {
  return {
    type: ADDING_SEAT_TO_SUBSCRIPTION
  }
}

export const addedSeatToSubscription = (newUser) => {
  return {
    type: ADDED_SEAT_TO_SUBSCRIPTION,
    payload: newUser
  }
}

export const errorAddingSeatToSubscription = (error) => {
  return {
    type: ERROR_ADDING_SEAT_TO_SUBSCRIPTION,
    payload: error
  }
}

export const addSeatToSubscription = (userConfig, token, closeModal) => {
  return function (dispatch, getState) {
    dispatch(addingSeatToSubscription())
    return getAxiosInstance(token).post(`${BASE_URI}/accounts/subscription/addSeat`, userConfig)
      .then(res => {
        if (res.status === 200) {
          dispatch(addedSeatToSubscription(res.data))
          closeModal(false)
          showSuccessToast('Successfully added team member!')
        }
      })
      .catch(err => {
        dispatch(errorAddingSeatToSubscription())
        showErrorToast('Uh oh. Error adding team member!')

      })
  }
}


// Add Seat to Subscription
export const cancellingSubscription = () => {
  return {
    type: CANCELLING_SUBSCRIPTION
  }
}

export const cancelledSubscription = () => {
  return {
    type: CANCELLING_SUBSCRIPTION,
  }
}

export const errorCancellingSUbscription = (error) => {
  return {
    type: ERROR_ADDING_SEAT_TO_SUBSCRIPTION,
    payload: error
  }
}

export const cancelSubscription = (adminId, token, closeModal) => {
  return function (dispatch, getState) {
    dispatch(cancellingSubscription())
    return getAxiosInstance(token).put(`${BASE_URI}/accounts/subscription/cancel/${adminId}`)
      .then(res => {
        if (res.status === 200) {
          dispatch(cancelledSubscription(res.data))
          closeModal()
        }
      })
      .catch(err => {
        dispatch(errorCancellingSUbscription())
      })
  }
}

// Check for Subscription DB Object
export const checkingSubscriptionOnAccount = () => {
  return {
    type: CHECKING_SUBSCRIPTION_ON_ACCOUNT
  }
}

export const checkedSubscriptionOnAccount = (subscriptionConfig) => {
  return {
    type: CHECKED_SUBSCRIPTION_ON_ACCOUNT,
    payload: subscriptionConfig
  }
}

export const errorCheckingSubscriptionOnAccount = (error) => {
  return {
    type: ERROR_CHECKING_SUBSCRIPTION_ON_ACCOUNT,
    payload: error
  }
}

export const checkSubscriptionOnAccount = (adminId, token) => {
  return function (dispatch, getState) {
    dispatch(checkingSubscriptionOnAccount())
    return getAxiosInstance(token).get(`${BASE_URI}/accounts/subscription/checkstatus/${adminId}`)
      .then(res => {
        if (res.status === 200) {
          dispatch(checkedSubscriptionOnAccount(res.data))
        }
      })
      .catch(err => {
        dispatch(errorCheckingSubscriptionOnAccount())

      })
  }
}


// Update Cohort Details
export const updatingCohortDetails = () => {
  return {
    type: UPDATING_COHORT_DETAILS
  }
}

export const updatedCohortDetails = (cohorts) => {
  return {
    type: UPDATED_COHORT_DETAILS,
    payload: cohorts
  }
}

export const errorUpdatingCohortDetails = (error) => {
  return {
    type: ERROR_UPDATING_COHORT_DETAILS,
    payload: error
  }
}

export const updateCohortDetails = (cohortConfig, token, currentCohorts, closeModal, navigate) => {
  return function (dispatch, getState) {
    dispatch(updatingCohortDetails())
    return getAxiosInstance(token).put(`${BASE_URI}/org/cohorts/update`, cohortConfig)
      .then(res => {
        if (res.status === 200) {
          let existingCohorts = currentCohorts
          existingCohorts = existingCohorts.
            map((cohort) => cohort.id === res.data.id ? res.data : cohort);

          dispatch(updatedCohortDetails(existingCohorts))
          closeModal()
          navigate('/org')
          showSuccessToast('Successfully updated cohort details!')

        }
      })
      .catch(err => {
        dispatch(errorUpdatingCohortDetails())
        showErrorToast('Error updating cohort details.')

      })
  }
}




// Removing member from team account
export const removingMemberFromTeamAccount = () => {
  return {
    type: REMOVING_MEMBER_FROM_TEAM_ACCOUNT
  }
}

export const removedMemberFromTeamAccount = (remainingTeamMembers) => {
  return {
    type: REMOVED_MEMBER_FROM_TEAM_ACCOUNT,
    payload: remainingTeamMembers
  }
}

export const errorRemovingMemberFromTeamAccount = (error) => {
  return {
    type: ERROR_REMOVING_MEMBER_FROM_TEAM_ACCOUNT,
    payload: error
  }
}

export const removeMemberFromTeamAccount = (userConfig, token, currentUsers, setModalOpen, setSelectedUser, navToLocation) => {
  return function (dispatch, getState) {
    dispatch(removingMemberFromTeamAccount())
    return getAxiosInstance(token).put(`${BASE_URI}/accounts/subscription/removeSeat`, userConfig)
      .then(res => {
        if (res.status === 200) {

          let updatedUserList = currentUsers
          updatedUserList = updatedUserList.filter((user) => user.email !== userConfig.email)
          dispatch(removedMemberFromTeamAccount(updatedUserList))
          setModalOpen(false)
          if (setSelectedUser) {
            setSelectedUser(null)
          }
          showSuccessToast('Successfully removed team member!')
          if (navToLocation) {
            navToLocation()
          }

          if (userConfig.organizationId) {
            dispatch(retrieveCohortsForOrg(userConfig.organizationId, token))
          }

        }
      })
      .catch(err => {
        dispatch(errorRemovingMemberFromTeamAccount())
        showErrorToast('Error removing team member.')

      })
  }
}
