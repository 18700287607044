import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'

import PropTypes from 'prop-types'

import VIEW_ICON from '../../assets/view-icon.png'
import TRASH_ICON from '../../assets/trash-icon.png'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import styles from './ResumeTile.module.css'
import { ButtonBase } from '@mui/material'
import classnames from 'classnames';

export default function ResumeTile(props) {

  const { setSelectedResume, openModal, resume, setDeleteModalVisible, setResumeAiModalVisible } = props

  const hasResumeAIEnabled = Boolean(useSelector((state) => state.auth.featureFlags.RESUME_AI_ENABLED));
  const userDoc = resume.uploadType === "user";
  const showIcon = (hasResumeAIEnabled && userDoc);

  return (
    <Stack direction='row' className={styles.tileWrapper}>
      <Stack className={styles.fileNameWrapper}>
        <Link onClick={() => {
          setSelectedResume(resume)
          openModal(true)
        }}>{resume.title.length > 32 ? `${resume.title.substring(0, 32)}...` : resume.title}</Link>
      </Stack>
      <Stack direction='row'>
        {showIcon &&
          <ButtonBase variant='text' className={styles.actionButton}
            onClick={() => {
              setSelectedResume(resume)
              setResumeAiModalVisible(true)
            }} >
            <AutoFixHighIcon />
          </ButtonBase>
        }
        <Button variant='text' className={styles.actionButton}>
          <img onClick={() => {
            setSelectedResume(resume)
            setDeleteModalVisible(true)
          }} src={TRASH_ICON} className={styles.iconStyle} />
        </Button>
      </Stack>

    </Stack >
  )
}

ResumeTile.propTypes = {
  fileName: PropTypes.string,
  setSelectedResume: PropTypes.func,
  setResumeAiModalVisible: PropTypes.func,
  openModal: PropTypes.func,
  resume: PropTypes.object,
  setDeleteModalVisible: PropTypes.func
}