import { configureStore } from '@reduxjs/toolkit'
import interviewReducer from '../reducers/interviewReducer'
import authenticationReducer from '../reducers/authenticationReducer'
import checkInReducer from '../reducers/checkInReducer'
import organizationReducer from '../reducers/organizationReducer'
import userReducer from '../reducers/userReducer'
import accountReducer from '../reducers/accountReducer'

export default configureStore({
  reducer: {
    interviews: interviewReducer,
    auth: authenticationReducer,
    checkIns: checkInReducer,
    org: organizationReducer,
    user: userReducer,
    account: accountReducer
  },
})