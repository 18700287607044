import React, { useEffect, useState } from 'react'

import Cookies from 'js-cookie';

import { useDispatch, useSelector } from 'react-redux'

import Stack from '@mui/material/Stack'

import Typography from '@mui/material/Typography'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button'
import CreateIcon from '@mui/icons-material/Create';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ButtonBase from '@mui/material/ButtonBase';

import SidebarNav from '../../components/navigation/SidebarNav'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { retrieveCohortForOrg } from '../../redux/actions/organizationActions'
import CohortStudentsTable from '../../components/tables/CohortStudentsTable';
import GroupStudentsTable from '../../components/tables/GroupStudentsTable';
import CreateGroupModal from './CreateGroupModal';
import BarChart from '../../components/charts/BarChart';
import { retrieveGroupsForCohort } from '../../redux/actions/organizationActions';
import { retrieveCohortStats, retrieveWeeklyCheckInSummaries } from '../../redux/actions/checkInActions';
import { getAbbreviateMonthAndDay, getDateAWeekFromNow, getFormattedDate } from '../../utils/DateUtils';
import { colors } from '../../constants/Colors';
import EditCohortDetailsModal from '../../components/modals/teams/EditCohortDetailsModal';
import { getAxiosInstance } from '../../utils/ApiUtils';

import config from '../../env.json'

import styles from './ViewCohortScreen.module.css'
import AddCohortMemberModal from '../../components/modals/teams/AddCohortMemberModal';
import { showErrorToast } from '../../utils/ToastUtils';

export default function ViewCohortScreen() {

  const { BASE_URI } = config

  const dispatch = useDispatch();
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const organizationId = useSelector((state) => state.org.id)

  const token = Cookies.get('token')
  
  const cohortStats = useSelector((state) => state.checkIns.cohortStats)
  const checkInSummaries = useSelector((state) => state.checkIns.checkInSummaries)
  const currentCohorts = useSelector((state) => state.org.cohorts)
  const authedUserId = useSelector((state) => state.auth.id)
  // const currentGroups = useSelector((state) => state.org.currentGroups)

  const [createCheckInSelected, setCreateCheckInsSelected] = useState(false)
  const [noCheckInsSelected, setNoCheckInsSelected] = useState(false)
  const [isAddNewCohortMemberModalVisible, setIsAddNewCohortMemberModalVisible] = useState(false)

  const [weeklyCheckInFilterStartDate, setWeeklyCheckInFilterStartDate] = useState(new Date())
  const [weeklyCheckInFilterEndDate, setWeeklyCheckInFilterEndDate] = useState(getDateAWeekFromNow(new Date()))

  const [checkInSummaryLabels, setCheckInSummaryLabels] = useState([])
  const [checkInSummaryAverages, setCheckInSummaryAverages] = useState([])

  const [unreviewedCheckIns, setUnreviewedCheckIns] = useState(null)
  const [completedInterviews, setCompletedInterviews] = useState(null)


  const [updatedCheckInStartDate, setUpdatedCheckInStartDate] = useState(null)
  const [updatedCheckInEndDate, setUpdatedCheckInEndDate] = useState(null)
  const [createGroupModal, setCreateGroupModalOpen] = useState(false)
  const [editCohortDetailsModalVisible, setEditCohortDetailsModalVisible] = useState(false)

  const cohortId = params.id

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [cohortName, setCohortName] = useState(null)
  const [focusArea, setFocusArea] = useState(null)
  const [cohortMembers, setCohortMembers] = useState([])
  const [ weeklyCheckInEnabled, setWeeklyCheckInEnabled] = useState(null)
  const [checkInDayOfWeek, setCheckInDayOfWeek] = useState(null)
  const [checkInStartDate, setCheckInStartDate] = useState(null)
  const [checkInEndDate, setCheckInEndDate] = useState(null)
  const [currentGroups, setCurrentGroups] = useState([])

  const daysOfWeek = [
    { value: 'MONDAY', label: 'Monday' },
    { value: 'TUESDAY', label: 'Tuesday' },
    { value: 'WEDNESDAY', label: 'Wednesday' },
    { value: 'THURSDAY', label: 'Thursday' },
    { value: 'FRIDAY', label: 'Friday' },
    { value: 'SATURDAY', label: 'Saturday' },
    { value: 'SUNDAY', label: 'Sunday' },
  ]



  useEffect(() => {

    dispatch(retrieveCohortForOrg(organizationId, cohortId, token))
    getAxiosInstance(token).get(`${BASE_URI}/org/cohort/${organizationId}/${cohortId}`)
      .then(res => {
        if (res.status === 200) {
          
          const {startDate, endDate, name, focusArea, cohortMembers, weeklyCheckInEnabled, checkInDayOfWeek, checkInStartDate,  checkInEndDate} = res.data
          
          setStartDate(startDate)
          setEndDate(endDate)
          setCohortName(name)
          setFocusArea(focusArea)

          setCohortMembers(cohortMembers)
          setWeeklyCheckInEnabled(weeklyCheckInEnabled)
          setCheckInDayOfWeek(checkInDayOfWeek)
          setCheckInStartDate(checkInStartDate)
          setCheckInEndDate(checkInEndDate)
        }
      })
      .catch(err => {        
        showErrorToast('Error retrieving cohort details.')
      })


    dispatch(retrieveCohortStats(cohortId, weeklyCheckInFilterStartDate, weeklyCheckInFilterEndDate, token))
    dispatch(retrieveWeeklyCheckInSummaries(cohortId, token))
    

    getAxiosInstance(token).get(`${BASE_URI}/org/cohort/${cohortId}/teams`)
      .then(res => {
        if (res.status === 200) {
          setCurrentGroups(res.data)
        }
      })
      .catch(err => {
        showErrorToast('Error retriving cohort groups.')

      })

    if (weeklyCheckInEnabled) {
      setCreateCheckInsSelected(true)
      setNoCheckInsSelected(false)
      parseCheckInSummaries()
    }

    getAxiosInstance(token).get(`${BASE_URI}/checkIns/${cohortId}/unreviewed/${authedUserId}`)
      .then((res) => {
        if (res.status == 200) {
          setUnreviewedCheckIns(res.data)
        }
      })
      .catch((error) => { })

    getAxiosInstance(token).get(`${BASE_URI}/interviews/completd/cohort/${cohortId}`)
      .then((res) => {
        if (res.status == 200) {
          setCompletedInterviews(res.data)
        }
      })
      .catch((error) => {

      })

  }, [cohortId, cohortName, startDate, endDate])

  const handleDayOfWeekChange = (dayOfWeekOption) => {
    setUpdatedCheckInEndDate(dayOfWeekOption)
  }

  const handleWeeklyCheckInStartDateChange = (date) => {
    setUpdatedCheckInStartDate(date.$d)
  }

  const handleWeeklyCheckInEndDatChange = (date) => {
    setUpdatedCheckInEndDate(date.$d)
  }

  const handleWeeklyCheckInFilterStartChange = (date) => {
    setWeeklyCheckInFilterStartDate(date.$d)
  }

  const handleWeeklyCheckInFilterEndChange = (date) => {
    setWeeklyCheckInFilterEndDate(date.$d)
  }


  const parseCheckInSummaries = () => {
    let labels = []
    let averages = []

    if (checkInSummaries.length > 0) {
      checkInSummaries.map((summary) => {
        labels.push(getAbbreviateMonthAndDay(summary.dueDate))
        averages.push(summary.averageSentiment ? summary.averageSentiment : 0)
      })

      setCheckInSummaryLabels(labels)
      setCheckInSummaryAverages(averages)
    }
  }

  const handleCheckInStateChange = (type, value) => {
    switch (type) {
      case 'no':
        setNoCheckInsSelected(true)
        setCreateCheckInsSelected(false)
        break;
      case 'yes':
        setNoCheckInsSelected(false)
        setCreateCheckInsSelected(true)
        break;

      default:
        break;
    }
  }

  const renderUnReviewedCheckIns = () => {
    if (unreviewedCheckIns.length > 0) {
      return unreviewedCheckIns.map((checkIn) => {
        return (
          <TableRow
            key={checkIn.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell variant="body" scope="row">
              <Typography
                className={styles.interviewTypeTableText}
                onClick={() => navigate(`/checkIns/${checkIn.id}`)}
              >
                {checkIn.user.firstName ? checkIn.user.firstName : checkIn.user.email}
              </Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography className={styles.interviewDateTableText}>{getFormattedDate(checkIn.dateUpdated)}</Typography>
            </TableCell>
          </TableRow>
        )
      })
    }
  }

  const renderCompletedInterviews = () => {
    if (completedInterviews) {
      return completedInterviews.map((interview) => {
        return (
          <TableRow
            key={interview.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell variant="body" scope="row">
              <Typography
                className={styles.interviewTypeTableText}
                onClick={() => navigate(`/interview/summary/${interview.id}`)}
              >
                {interview.user.firstName ? interview.user.firstName : interview.user.email}
              </Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography className={styles.interviewDateTableText}>{interview.interviewType.substring(0, 1) + interview.interviewType.substring(1, interview.interviewType.length).toLowerCase()}</Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography className={styles.interviewDateTableText}>{getFormattedDate(interview.interviewDate)}</Typography>
            </TableCell>
          </TableRow>
        )
      })
    }
  }


  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <SidebarNav />
      <Stack direction='row' style={{ width: '100%', marginLeft: 100, marginRight: 100 }}>
        <Stack style={{ marginTop: 50, width: '60%' }}>
          <Stack direction='row'>
            <Typography
              style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4 }}>
              COHORT DETAILS
            </Typography>
            <Button
              variant='text'
              onClick={() => {
                setEditCohortDetailsModalVisible(true)
              }}
            >
              <CreateIcon
                style={{ color: colors.plum }}
              />
            </Button>
          </Stack>
          <Stack direction='row' style={{ width: '50%', paddingLeft: 10 }}>
            <Typography style={{ color: colors.plum, fontWeight: 'bold' }}>Name:</Typography>
            <Typography style={{ paddingLeft: 45 }}>{cohortName}</Typography>

          </Stack>
          <Stack direction='row' style={{ width: '50%', paddingLeft: 10, marginTop: 2 }}>
            <Typography style={{ color: colors.plum, fontWeight: 'bold' }}>Start Date:</Typography>
            <Typography style={{ paddingLeft: 10 }}>{getFormattedDate(startDate)}</Typography>

          </Stack>
          <Stack direction='row' style={{ width: '50%', paddingLeft: 10, marginTop: 2 }}>

            <Typography style={{ color: colors.plum, fontWeight: 'bold' }}>End Date:</Typography>
            <Typography style={{ paddingLeft: 16 }}>{getFormattedDate(endDate)}</Typography>

          </Stack>
          <Stack direction='row' style={{ width: '50%', paddingLeft: 10, marginTop: 2 }}>
            <Typography style={{ color: colors.plum, fontWeight: 'bold' }}>Focus Area:</Typography>
            <Typography style={{ paddingLeft: 16 }}>{focusArea ? focusArea : null}</Typography>

          </Stack>
          <Stack style={{ marginTop: 20 }}>
            <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4 }}>CHECK INS</Typography>
            <Stack direction='row' style={{ alignContent: 'center', alignItems: 'center' }}>
              <Typography style={{ color: colors.plum, fontWeight: 'bold' }}>Enabled for Cohort:</Typography>
              <RadioGroup
                style={{ marginLeft: 10 }}
                row
                aria-labelledby="check-ins-row-radio-buttons-group-label"
                name="check-ins-row-radio-buttons-group"
              >
                <FormControlLabel value="no" control={<Radio checked={noCheckInsSelected} onChange={(value) => handleCheckInStateChange('no', value)} disabled />} label="No" />
                <FormControlLabel value="yes" control={<Radio checked={createCheckInSelected} onChange={(value) => handleCheckInStateChange('yes', value)} disabled />} label="Yes" />
              </RadioGroup>
            </Stack>
          </Stack>

          <Stack style={{ height: '100%', marginTop: 40 }}>
            <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4, paddingBottom: 20 }}>CHECK-IN SENTIMENT BY WEEK (AVG)</Typography>
            <BarChart
              chartData={checkInSummaryAverages ? checkInSummaryAverages : null}
              chartLabels={checkInSummaryLabels ? checkInSummaryLabels : null}
              colors={[colors.plum]}
            />

            {true ? (
              <Stack style={{ marginTop: 50, width: '35%', paddingBottom: 80 }}>
                <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4, paddingBottom: 20 }}>UNREVIEWED CHECK-INS</Typography>
                <Grid style={{ alignItems: 'flex-start', marginTop: 10 }}>
                  <TableContainer sx={{ maxHeight: 440, minWidth: 650, maxHeight: 300, }} component={Paper}>
                    <Table stickyHeader style={{ minWidth: 650, width: '40dvw' }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold' }}>User</TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }} align="left">Date Completed</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ width: '100%' }}>
                        {unreviewedCheckIns && unreviewedCheckIns.length > 0 ? renderUnReviewedCheckIns() :
                          <Stack className={styles.emptyStateWrapper}>
                            {/* <img src={MAGNIFYING_GLASS} style={{ height: 45, width: 45 }} /> */}
                            <Typography className={styles.emptyStateText}>No check-ins to review.</Typography>
                          </Stack>
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Stack>
            ) : null}
          </Stack>
          <Stack>
            {true ? (
              <Stack style={{ marginTop: 10, width: '35%', paddingBottom: 80 }}>
                <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4, paddingBottom: 20 }}>COMPLETED INTERVIEWS</Typography>
                <Grid style={{ alignItems: 'flex-start', marginTop: 10 }}>
                  <TableContainer sx={{ maxHeight: 440, minWidth: 650, maxHeight: 300 }} component={Paper}>
                    <Table stickyHeader style={{ minWidth: 650, width: '40dvw', }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold' }}>User</TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>Interview Type</TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }} align="left">Date Completed</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ width: '100%' }}>
                        {completedInterviews && completedInterviews.length > 0 ? renderCompletedInterviews() :
                          <Stack className={styles.emptyStateWrapper}>
                            <Typography className={styles.emptyStateText}>No check-ins to review.</Typography>
                          </Stack>
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Stack>
            ) : null}
          </Stack>
        </Stack>
        <Stack style={{ marginTop: 50, width: '35%' }}>
        <Stack style={{ marginTop: 0, paddingBottom: 0, display: 'flex', flexDirection: 'row' }}>
          <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4, paddingBottom: 10 }}>COHORT MEMBERS</Typography>
          <ButtonBase
              variant="text"
              style={{ width: '10%' }}
              onClick={() => setIsAddNewCohortMemberModalVisible(true)}
            >
              <AddCircleIcon style={{ color: 'purple' }} />
            </ButtonBase>
          </Stack>
          <Stack style={{padding: 10, maxHeight: 300, overflow: 'scroll'}}>
            <CohortStudentsTable
              students={cohortMembers}
            />
          </Stack>
          
         
          <Stack style={{ marginTop: 50, paddingBottom: 20, display: 'flex', flexDirection: 'row' }}>
            <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4 }}>GROUPS</Typography>
            <ButtonBase
              variant="text"
              style={{ width: '10%' }}
              onClick={() => setCreateGroupModalOpen(true)}
            >
              <AddCircleIcon style={{ color: 'purple' }} />
            </ButtonBase>
          </Stack>
          <GroupStudentsTable
            groups={currentGroups ? currentGroups : null}
          />
        </Stack>

      </Stack>

      <EditCohortDetailsModal
        isVisible={editCohortDetailsModalVisible}
        closeModal={setEditCohortDetailsModalVisible}
        cohortId={cohortId}
        cohortName={cohortName}
        startDate={startDate}
        endDate={endDate}
        checkInDayOfWeek={checkInDayOfWeek}
        checkInStartDate={checkInStartDate}
        checkInEndDate={checkInEndDate}
        currentCohorts={currentCohorts}
        token={token}
        focusArea={focusArea}
      />

      <AddCohortMemberModal 
        isVisible={isAddNewCohortMemberModalVisible}
        closeModal={() => setIsAddNewCohortMemberModalVisible(false)}
        cohortId={cohortId}
        navigate={navigate}
        token={token}
      />
      <CreateGroupModal 
        students={cohortMembers} 
        isVisible={createGroupModal} 
        handleClose={() => setCreateGroupModalOpen(false)} />
    </div>
  )
}