import { useRef, useState, useEffect, Fragment } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Cookies from 'js-cookie';

import { useDispatch } from "react-redux";

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import LinearProgress from "@mui/material/LinearProgress";
import SidebarNav from '../../components/navigation/SidebarNav';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';

import { colors } from "../../constants/Colors";

import CandidateQuestion from "./CandidateQuestion";
import {
  retrieveInterview,
  submitIntervewResponseForEvaulation,
  completeActiveInterview
} from '../../redux/actions/interviewActions'

import styles from './ActiveInterviewScreen.module.css'

function ImpersonateActiveInterviewScreen() {

  const params = useParams()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { transcript, resetTranscript } = useSpeechRecognition();

  const token = Cookies.get('token')
  const authedUserId = useSelector((state) => state.auth.id)
  const isLoading = useSelector((state) => state.interviews.isLoading)

  // const currentInterview = useSelector((state) => state.interviews.activeCandidateInterview)

  const [candidateResponseId, setCandidateResponseId] = useState('')
  const [currentInterviewQuestionIndex, setCurrentInterviewQuestionIndex] = useState(0)
  const [isTranscribingResponse, setIsTranscribingResponse] = useState(false)
  const [awaitingEvaluation, setAwaitingEvaluation] = useState(false)
  const [submittedResponse, setSubmittedResponse] = useState(false)
  const [submissionSuccessful, setSubmissionSuccessful] = useState(false)
  const [currentInterview, setCurrentInterview] = useState({})
  
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([])
  const [completed, setCompleted] = useState({});
  const [loadingStepsComplete, setLoadingStepsComplete] = useState(null)

  useEffect(() => {
    dispatch(retrieveInterview(params.id, token, setCurrentInterview, preloadSteps))
  }, [params.id])

  const preloadSteps = (data) => {

    let steps = []
    if (data && data.interviewResponses) {
      if (data.interviewResponses.length > 0) {
        data.interviewResponses.map((question, index) => {
          steps.push({ id: question.id, submissionSuccessful: false, index: index })
          if (question.evaluation !== null) {
            const newCompleted = completed;
            newCompleted[index] = true
            setCompleted(newCompleted);
          }
        })
        setSteps(steps)
      }
    }
  }


  const renderActiveInterviewQuestion = () => {
    if (Object.getOwnPropertyNames(currentInterview).length !== 0) {
      if (currentInterview.interviewResponses != null) {
        const currentInterviewQuestion = currentInterview.interviewResponses[currentInterviewQuestionIndex].candidateInterviewQuestion
        return (
          <CandidateQuestion
            id={currentInterviewQuestion ? currentInterviewQuestion.id : null}
            question={currentInterviewQuestion ? currentInterviewQuestion.question : null}
            // questionURI={currentInterviewQuestion ? currentInterviewQuestion.questionURI : null}
            transcript={transcript}
            questionURI={currentInterviewQuestion ? currentInterviewQuestion.questionURI : null}
            candidateResponseId={currentInterviewQuestion ? currentInterviewQuestion.candidateResponseId : null}
            questionType={currentInterviewQuestion ? currentInterviewQuestion.type : null}
            submitResponseForFeedback={submitResponseForFeedback}
            setCandidateResponseId={setCandidateResponseId}
            token={token}
            setIsTranscribingResponse={setIsTranscribingResponse}
            submittedResponse={completed[currentInterviewQuestionIndex]}
            submissionSuccessful={submissionSuccessful}
            awaitingEvaluation={awaitingEvaluation}

          />
        )
      }
    }

  }

  const navToInterviewsScreen = () => {
    return navigate(`/interviews`)
  }

  const renderProgressButtons = () => {
    if (Object.getOwnPropertyNames(currentInterview).length !== 0) {
      if (currentInterview.interviewResponses != null) {
        if (currentInterviewQuestionIndex == currentInterview.interviewResponses.length - 1) {
          return (
            <Stack
              variant='contained'
              disabled={!allStepsCompleted}
              onClick={() => {
                dispatch(completeActiveInterview(currentInterview.id, token, navToInterviewsScreen))
              }}
              className={ allStepsCompleted() ? styles.activeFinishButton : styles.inactiveFinishButton }
            >
              <Typography 
                className={allStepsCompleted() ? styles.activeButtonText: styles.inactiveButtonText}
              >
                FINISH
              </Typography>
            </Stack>
          )
        }
        else {
          return (
            <Button
              disabled={isTranscribingResponse}
              variant='contained'
              onClick={() => {
                resetTranscript()
                setCurrentInterviewQuestionIndex(currentInterviewQuestionIndex + 1)
                setSubmittedResponse(false)
                setSubmissionSuccessful(false)
              }}
              style={{ height: 50, width: 150, backgroundColor: isTranscribingResponse ? colors.lightGray : colors.oceanBlue, }}
            >
              <Typography>NEXT</Typography>
            </Button>
          )
        }
      }

    }
  }

  const submitResponseForFeedback = () => {

    const interviewConfig = {
      id: candidateResponseId,
      interviewId: currentInterview.id,
      response: transcript
    }

    dispatch(submitIntervewResponseForEvaulation(interviewConfig, token, handleComplete))

  };

  //**********   BEGINNING OF STEPPER LOGIC ***********************/
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return currentInterviewQuestionIndex === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    if (totalSteps() == 0) {
      return false;
    }
    
      return completedSteps() === totalSteps();
    
  };

  const handleNext = () => {
    let newActiveStep; 

    if (isLastStep() && !allStepsCompleted()) {
      newActiveStep =  steps.findIndex((step, i) => !(i in completed))
      setCurrentInterviewQuestionIndex(newActiveStep);
      return; 
    }
    else if (isLastStep() && allStepsCompleted()) {
      return;
    }
    else {
      newActiveStep = currentInterviewQuestionIndex + 1
      setCurrentInterviewQuestionIndex(newActiveStep)
      return;
    }
  };

  const handleBack = () => {
    setCurrentInterviewQuestionIndex((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setCurrentInterviewQuestionIndex(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    
    newCompleted[currentInterviewQuestionIndex] = true;
    setCompleted(newCompleted);
    resetTranscript();
    handleNext();
  };


  const renderStepsForInterview = () => {
    return steps.map((label, index) => {
      return ( <Step key={label} completed={completed[index]}>
        <StepButton
          disabled={isTranscribingResponse}
          onClick={handleStep(index)}
        />
        </Step>
    )})
  }


  //********** END OF STEPPER LOGIC ***********************/

  return (
    <div className={styles.mainWrapper}>
      <SidebarNav />
      <Stack className={styles.bodyWrapper} >
        <Stack className={styles.loaderWrapper}>
          {isLoading ? 
            <Stack>
              <Typography className={styles.evaluatingResponseText}>Evaluating your response...</Typography>
              <LinearProgress color="secondary" size="lg" value={12} />
            </Stack> : <Box style={{ height: 1, width: '100%', paddingTop: 2 }} />}
        </Stack>
        <Stack className={styles.stepperWrapper}>
          <Box sx={{ width: '100%', overflowX: 'auto' }}>
            <Stepper nonLinear activeStep={currentInterviewQuestionIndex}>
              {renderStepsForInterview()}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    You have completed your interview. Please press FINISH below.
                  </Typography>
                </Fragment>
              ) : (
                <Fragment>

                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    {currentInterviewQuestionIndex !== steps.length &&
                      (completed[currentInterviewQuestionIndex] ? (
                        <Typography variant="caption" sx={{ display: 'inline-block', fontSize: 16 }}>
                          Interview question has already been answered.
                        </Typography>
                      ) : null)}
                  </Box>
                </Fragment>
              )}
            </div>
          </Box>
        </Stack>
        {renderActiveInterviewQuestion()}
        <Stack direction='row'
          className={styles.actionButtonWrapper}
        >
          <Button
            disabled={isTranscribingResponse}
            variant='contained'
            onClick={() => {
              setCurrentInterviewQuestionIndex(currentInterviewQuestionIndex == 0 ? currentInterviewQuestionIndex : currentInterviewQuestionIndex - 1)
              setSubmittedResponse(false)
              setSubmissionSuccessful(false)
            }}
            className={isTranscribingResponse ? styles.previousButtonInactive : styles.previousButton}
            style={{ height: 50, width: 150, backgroundColor: isTranscribingResponse ? '#f7f7f7' : '#E8E8E8', color: isTranscribingResponse ? '#aaa3a3' : 'black' }}
          >
            <Typography>PREVIOUS</Typography>
          </Button>
          {renderProgressButtons()}
        </Stack>
      </Stack>
     
    </div>
  );
}

export default ImpersonateActiveInterviewScreen;



