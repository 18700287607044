import React, {useState} from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import LOGO from '../../assets/interviewable-logo.png'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import StandardButton from '../../components/buttons/StandardButton'
import { showErrorToast, showSuccessToast } from '../../utils/ToastUtils'
import { isIdentifcal, isValidPassword } from '../../utils/PasswordUtils';

import { colors } from '../../constants/Colors'

import config from '../../env.json'

export default function CreateNewPasswordScreen() {
  const { BASE_URI } = config
  const navigate = useNavigate()

  const [registrationToken, setRegistrationToken] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const createNewPassword = () => {
    if (registrationToken === '' || newPassword === '' || passwordConfirmation === '') {
      return
    }
    // if (!isValidPassword(newPassword)) {
    //   showErrorToast('Password must be at leat 8 characters, contain at least 1 number, and at least 1 symbol.')
    //   return;
    // }
    if (!isIdentifcal(newPassword, passwordConfirmation)) {
      showErrorToast('Passwords do not match!')
      return;
    }

    const passwordConfig = {
      newPassword: newPassword,
      confirmedPassword: passwordConfirmation,
      registrationToken: registrationToken
    }

    axios.put(`${BASE_URI}/auth/new/password`, passwordConfig)
    .then((res) => {
      if (res.status == 200) {
        showSuccessToast('Successfully updated your password.')
        navigate('/login')
      }
    })
    .catch((error) => {
      showErrorToast('Error updating your password. Please try again.')
    })
  
  }

  return (
    <Box >
      <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'center', marginTop: '5dvh'  }}>
        <Grid item xs={6}>
          <Stack style={{ justifyContent: 'center', alignItems: 'center' }}>
            <img src={LOGO} style={{ width: 310, height: 60 }} />
          </Stack>
          <Stack style={{ alignItems: 'center', justifyContent: 'center' }}>
            <form>
              <Stack style={{ width: 210 }}>
                <Typography>Enter the registration token received in the password reset email.</Typography>
              </Stack>
              <TextField
                type="text"
                value={registrationToken}
                onChange={(event) => setRegistrationToken(event.target.value)}
                fullWidth
                style={{ width: 310 }}
                margin="normal"
                variant="outlined"
              />
              <Stack style={{ width: '20dvw' }}>
                <Typography>New Password</Typography>
              </Stack>
              <TextField
                type="password"
                value={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}
                fullWidth
                style={{ width: 310 }}
                margin="normal"
                variant="outlined"
              />
              <Stack style={{ width: '20dvw' }}>
                <Typography>Confirm Password</Typography>
              </Stack>
              <TextField
                type="password"
                value={passwordConfirmation}
                onChange={(event) => setPasswordConfirmation(event.target.value)}
                fullWidth
                style={{ width: 310 }}
                margin="normal"
                variant="outlined"
              />
              <Stack style={{ marginTop: 30, alignItems: 'flex-start' }}>
                <StandardButton
                  label='RESET PASSWORD'
                  labelColor='white'
                  backgroundColor={colors.oceanBlue}
                  buttonWidth={310}
                  onPress={() => createNewPassword()}
                />

              </Stack>
            </form>
            <Stack>
              <Button variant='text' onClick={() => navigate('/login')}>
                <Typography style={{ color: 'black', paddingTop: 30, fontWeight: 200 }}>BACK</Typography>
              </Button>
            </Stack>
          </Stack>

        </Grid>
      </Grid>

    </Box>
  )
}