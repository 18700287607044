import React, { useState } from 'react'

import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import LOGO from '../../assets/interviewable-logo.png'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { showErrorToast, showSuccessToast } from '../../utils/ToastUtils'


import config from '../../env.json'
import StandardButton from '../../components/buttons/StandardButton'
import { colors } from '../../constants/Colors'


export default function PasswordResetNotificationScreen() {
  const { BASE_URI } = config
  const navigate = useNavigate()

  const [email, setEmail] = useState('')

  const submitResetPasswordRequest = () => {
    if (email === '') {
      return
    }
    else {
      axios.post(`${BASE_URI}/auth/reset/password/${email}`)
        .then((res) => {
          showSuccessToast('Submitted password change request. If an email exists with the information you provided instructios to reset your password will be sent there.')
          navigate('/login')
        })
        .catch((err) => {
          showErrorToast('Error submitting request to reset password. Please try again later.')
        })
    }
  }

  return (
    <div>
      <Stack style={{ marginTop: '20dvh' }}>
        <Stack style={{ justifyContent: 'center', alignItems: 'center' }}>
          <img src={LOGO} style={{ width: '15dvw', height: 60 }} />
        </Stack>
        <Stack style={{ alignItems: 'center' }}>
          <form>
            <Stack style={{ width: '20dvw' }}>
              <Typography>Enter your email address. If an accounts exists instructions to reset your password will be sent to your inbox.</Typography>
            </Stack>
            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              fullWidth
              style={{ width: 310 }}
              margin="normal"
              variant="outlined"
            />
            <Stack style={{ marginTop: 30, alignItems: 'flex-start' }}>
              <StandardButton
                label='RESET PASSWORD'
                labelColor='white'
                backgroundColor={colors.oceanBlue}
                buttonWidth={310}
                onPress={() => submitResetPasswordRequest()}
              />

            </Stack>
          </form>
          <Stack>
            <Button variant='text' onClick={() => navigate(-1)}>
              <Typography style={{ color: 'black', paddingTop: 30, fontWeight: 200 }}>BACK</Typography>
            </Button>
          </Stack>
        </Stack>


      </Stack>

    </div>
  )
}