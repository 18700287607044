import React, { useState } from 'react'
import Cookies from 'js-cookie';

import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import PricingGuide from '../../components/signUp/PricingGuide';
import StandardButton from '../../components/buttons/StandardButton';
import { Modal } from '@mui/material';

import INTERVIEWABLE_LOGO from '../../assets/interviewable-logo.png'

import States from '../../constants/States';

import { colors } from '../../constants/Colors';

import { createOrgAccount } from '../../redux/actions/organizationActions';

import getStripe from '../../stripe/getStripe';
import { getAxiosInstance } from '../../utils/ApiUtils';

import config from '../../env.json'
import { showErrorToast } from '../../utils/ToastUtils';

import styles from './OrganizationSignUpScreen.module.css'

import { isIdentifcal, isValidEmailFormat, isValidPassword } from '../../utils/PasswordUtils';
import { checkUniqueness, renderEmailUniqueness } from '../../utils/AuthUtils';
import TermsAndPrivacy from './TermsAndPrivacy.pdf';

export default function OrganizationSignUpScreen() {
  const { BASE_URI } = config

  const stripeCustomerId = useSelector((state) => state.org.stripeCustomerId)
  const STRIPE_FREE_FOREVER_PLAN_ID = 'price_1OKL7TFmIdBJHCdMsvYHsAuc'
  const STRIPE_PROD_TEAM_PLAN_ID = 'price_1OKLF5FmIdBJHCdMWDS5gZkb'

  const DB_FREE_FOREVER_PLAN_ID = 'f5eb8eee-79ab-4004-92af-ae074d6113e8'
  const DB_TEAM_PLAN_ID = '70ff92fa-4f94-48ef-99fa-d2930515178c'

  const dispatch = useDispatch()


  const [accountType, setAccountType] = useState(null)
  const [username, setUsername] = useState(null)
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const [companyName, setCompanyName] = useState('')
  const [addressOne, setAddressOne] = useState('')
  const [addressTwo, setAddressTwo] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState('United States')
  const [zipCode, setZipCode] = useState('')
  const [isCheckingUniqueness, setIsCheckingUniqueness] = useState(false)
  const [isUniqueEmail, setIsUniqueEmail] = useState(null)
  const [termsModalVisible, setTermsModalVisible] = useState(false)
  const [step, setStep] = useState(0)
  const [agree, setAgree] = useState(false);

  const handleStateChange = (selectedOption) => {
    setState(selectedOption)
  };

  const advanceStep = () => {
    let currentStep = step

    if (companyName === '') {
      showErrorToast('Company name is required.')
      return;
    }
    if (addressOne === '') {
      showErrorToast('Company address is required.')
      return;
    }
    if (city === '') {
      showErrorToast('City is required.')
      return;
    }
    if (state === '') {
      showErrorToast('State is required.')
      return;
    }
    if (zipCode === '') {
      showErrorToast('Zip code is required.')
      return;
    }
    setStep(currentStep + 1)
  }

  const revertStep = () => {
    let currentStep = step
    if (currentStep > 0) {
      setStep(currentStep - 1)
    }
  }

  async function handleCheckout(sessionToken, stripeCustId) {
    let planType = 'TEAM'
    getAxiosInstance(sessionToken).post(`${BASE_URI}/payments/createCheckoutSession/${STRIPE_PROD_TEAM_PLAN_ID}/${email}/${stripeCustId}/${planType}`)
      .then((res) => {
        if (res.status == 200) {
          // navigate(res.data)
          window.location.href = res.data;
        }
      })

  }

  const createAccount = () => {

    if (firstName === '') {
      showErrorToast('Admin first name is required.')
      return;
    }
    if (lastName === '') {
      showErrorToast('Admin last name is required.')
      return;
    }
    if (email === '') {
      showErrorToast('Admin email address is required.')
      return;
    }
    if (!isValidEmailFormat(email)) {
      showErrorToast('Please provide a valid email.')
      return;
    }
    if (!agree) {
      showErrorToast('Please accept Terms of Service and Privacy Policy')
      return;
    }
    if (password === '') {
      showErrorToast('Password is required.')
      return;
    }
    if (!isValidPassword(password)) {
      showErrorToast('Password must be at leat 8 characters, contain at least 1 number, and at least 1 symbol.')
      return;
    }
    if (passwordConfirmation === '') {
      showErrorToast('Password confirmation is required.')
    }

    if (!isIdentifcal(password, passwordConfirmation)) {
      showErrorToast('Passwords do not match!')
      return;
    }

    const organziationConfig = {
      name: companyName,
      addressOne: addressOne,
      addressTwo: addressTwo,
      city: city,
      state: state.value,
      country: country,
      zipCode: zipCode,
      account: {
        planId: DB_TEAM_PLAN_ID,
        planType: 'PAID',
        admin: {
          email: email,
          password: password,
          firstName: firstName,
          lastName: lastName,
          role: 'ADMIN'
        },
      }
    }

    dispatch(createOrgAccount(organziationConfig, handleCheckout))
  }

  

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree);
    // Don't miss the exclamation mark
  }

  const renderTermsAndConditions = () => {
    const style =
    {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: '70vh',
      width: 850,
      bgcolor: "background.paper",
      border: "1px solid #000",
      boxShadow: 24,
      borderRadius: 5,
      p: 4,
    }


    return (
      <div>
        <input type="checkbox" id="agree" onChange={checkboxHandler} />
        <label style={{ paddingLeft: '10px' }} htmlFor="agree"> I agree to the
          <Button onClick={() => setTermsModalVisible(true)}>Terms of Service & Privacy Policy</Button>
          <Modal
            open={termsModalVisible}
            onClose={() => setTermsModalVisible(false)}
          >
            <Box sx={style}>
              <iframe src={TermsAndPrivacy} width='100%' height='100%' />
            </Box>
          </Modal>
        </label>
      </div>
    );
  }

  const renderSignUpFormOne = () => {
    return (
      <Stack className="flex-xl-nowrap">
        <form>
          <Stack>
            <Typography
              style={{ fontSize: 24, }}
            >Tell us a little about your organization: </Typography>
          </Stack>
          <FormGroup id='321'>
            <Typography>Company Name <span className={styles.requiredText}>*</span></Typography>
            <TextField
              className={styles.inputStyle}
              key='11a'
              value={companyName}
              onChange={(event) => setCompanyName(event.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Typography>Address One <span className={styles.requiredText}>*</span></Typography>
            <TextField
              className={styles.inputStyle}
              key='11b'
              type="text"
              value={addressOne}
              onChange={(event) => setAddressOne(event.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Typography>Address Two</Typography>
            <TextField
              className={styles.inputStyle}
              key='11c'
              type="text"
              value={addressTwo}
              onChange={(event) => setAddressTwo(event.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Typography>City <span className={styles.requiredText}>*</span></Typography>
            <TextField
              className={styles.inputStyle}
              key='11d'
              type="text"
              value={city}
              onChange={(event) => setCity(event.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </FormGroup>
          <Stack className={styles.stateWrapper}>
            <Typography>State <span className={styles.requiredText}>*</span></Typography>
            <Select
              value={state}
              onChange={handleStateChange}
              options={States}
            />
          </Stack>
          <FormGroup className="mb-3">
            <Typography>Zip Code <span className={styles.requiredText}>*</span></Typography>
            <TextField
              className={styles.inputStyle}
              key='11e'
              type="text"
              value={zipCode}
              onChange={(event) => setZipCode(event.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Typography>Country</Typography>
            <TextField
              className={styles.inputStyle}
              key='11f'
              disabled
              type="text"
              value={country}
              onChange={(event) => setZipCode(event.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </FormGroup>
        </form>
        {renderTermsAndConditions()}
        <Stack style={{ marginTop: 20, justifyContent: 'center', alignItems: 'center' }}>
          <StandardButton
            label={'NEXT'}
            onPress={() => advanceStep()}
            buttonWidth={400}
            backgroundColor={colors.oceanBlue}
          />

        </Stack>
      </Stack>
    )
  }

  const renderSignUpFormTwo = () => {
    return (
      <Stack className="flex-xl-nowrap">
        <form>
          <Stack style={{ marginBottom: 10 }}>
            <Typography style={{ fontSize: 24, }}>Admin Details </Typography>
            <Typography>Please provide login details for the user that will be maintaining your company's account. </Typography>
          </Stack>
          <FormGroup>
            <Typography>First Name</Typography>
            <TextField
              className={styles.inputStyle}
              key='12a'
              type="text"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </FormGroup>
          <FormGroup id='12b'>
            <Typography>Last Name</Typography>
            <TextField
              className={styles.inputStyle}
              key='12b'
              type="text"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </FormGroup>
          <Stack direction='row' className={styles.emailWrapper}>
            <FormGroup>
              <Typography>Email</Typography>
              <TextField
                className={styles.emailInputStyle}
                id='12c'
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                onBlur={() => checkUniqueness(email, setIsCheckingUniqueness, setIsUniqueEmail)}
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </FormGroup>
            <Stack className={styles.uniquenessWrapper}>
              {renderEmailUniqueness(isCheckingUniqueness, isUniqueEmail, email)}
            </Stack>

          </Stack>
          <FormGroup>
            <Typography>Password</Typography>
            <TextField
              className={styles.inputStyle}
              key='12d'
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </FormGroup>
          <FormGroup>
            <Typography>Confirm Password</Typography>
            <TextField
              className={styles.inputStyle}
              key='12e'
              // label="Confirm password"
              type="password"
              value={passwordConfirmation}
              onChange={(event) => setPasswordConfirmation(event.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </FormGroup>
        </form>
        {renderTermsAndConditions()}
        <Stack
          direction='row'
          spacing={10}
          style={{ width: '100%', marginTop: 50, justifyContent: 'center', alignItems: 'space-between' }}
        >
          <StandardButton
            label={'BACK'}
            onPress={() => revertStep()}
            buttonWidth={150}
            // labelColor={'black'}
            backgroundColor={'lightgray'}
          />
          <StandardButton
            label={'CREATE ACCOUNT'}
            onPress={() => createAccount()}
            buttonWidth={300}
            backgroundColor={colors.oceanBlue}
          />
        </Stack>
      </Stack>
    )
  }

  const renderStep = () => {
    switch (step) {
      case 0:
        return renderSignUpFormOne()
      case 1:
        return renderSignUpFormTwo()
      default:
        break;
    }

  }

  return (
    <div className={styles.mainWrapper}>
      <Stack className={styles.logoWrapper}>
        <img src={INTERVIEWABLE_LOGO} className={styles.logoStyle} />
      </Stack>
      <Stack className={styles.stepWrapper}>
        {renderStep()}
      </Stack>


    </div>
  )
}