import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { colors } from '../../constants/Colors';

import PropTypes from 'prop-types'

const BarChart = (props) => {

  
  const chartRef = useRef(null);

  const {chartData, chartLabels } = props

  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: 'Check-In Sentiment',
        // data: [1, 3, 3, 5, 2, 3],
        data: chartData,
        fill: true,
        // backgroundColor: 'rgba(75,192,192,0.2)',
        backgroundColor: colors.plum,
        borderColor: 'rgba(75,192,192,1)',
        
      },
    ],
    options: {
      scales: {
          y: {
              min: 1,
              max: 5
          }
      }
  }
  };



  return (
    <div style={{width: '80%'}}>
      
      <Bar 
        ref={chartRef} 
        data={data} />
        
    </div>
  )
};

BarChart.propTypes = {
  chartData: PropTypes.array,
  chartLabels: PropTypes.array
}

export default BarChart;
