import React, { useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import styles from './GenericTable.module.css'

export default function GenericTable(props) {


  const navigate = useNavigate()

  const { students } = props

  console.log('Students: ', students)

  const renderRows = () => {
    if (students && students.length > 0) {
    
       return students.map((student, index) => {
        const { user} = student
        return (
          <tr 
            key={index} className={styles.trItem}
            onClick={() => navigate(`/org/student/${user.id}`, { state: { id: user.id, firstName: user.firstName, lastName: user.lastName, email: user.email } })}
          >
            <td><span>{user.email ? user.email.substring(0, 20) + '...': null}</span></td>
            <td><span style={{marginLeft: 5}}>{user.firstName ? user.firstName: null}</span></td>
            <td><span>{user.lastName ? user.lastName: null}</span></td>  
            <td><span>{user.status ? user.status: null}</span></td>   
          </tr>
        )
       })  
    }
  }

  return (
    <div>
       <table>
        <thead>
          <tr>
            <td><span>Email</span></td>
            <td><span>First Name</span></td>
            <td><span>Last Name</span></td>
            <td><span>Status</span></td>
          </tr>
        </thead>
      {renderRows()}
      </table>
    </div>
   
  )

}

