import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import Accordion from '@mui/material/Accordion';
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import EditIcon from '@mui/icons-material/Edit';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import FilterableTable from 'react-filterable-table';
import { retrieveGroupsForCohort } from '../../redux/actions/organizationActions';
import { Typography } from '@mui/material';


export default function GroupStudentsTable(props) {
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch();
  const token = Cookies.get('token')
  const cohortId = params.id

  const { groups } = props

  useEffect(() => {
    dispatch(retrieveGroupsForCohort(cohortId, token))
  }, [cohortId])


  const fields = [
    {
      name: 'groupName',
      displayName: "Group Name",
      inputFilterable: true,
      sortable: true,
      render: renderAccordianGroup
    },
  ];

  function renderGroupMembers(groupMembers) {
    if (groupMembers) {
      return groupMembers.map(({ id, email, }) => {
        return (
          <Box>
            <Button
              variant='text'
              onClick={() => navigate(`/org/student/${id}`, { state: { id: id } })}
            >
              <Typography>{email}</Typography>
            </Button>
          </Box>
        )
      })
    }

  }

  function renderAccordianGroup(props) {

    const { groupName, groupMembers } = props.record
    if (props.record) {
      return (

        <Stack style={{ width: '100%' }} direction='row'>
          <Stack style={{ width: '100%' }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>{groupName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderGroupMembers(groupMembers)}

              </AccordionDetails>
            </Accordion>
          </Stack>
          {/* Hidden until edit endpoint connected with functionality
          <Stack style={{ width: '10%', justifyContent: 'center', alignItems: 'center' }}>
            <Button

              variant='text'>
              <EditIcon style={{ height: 20, width: 20 }} />
            </Button>
          </Stack> */}
        </Stack>



      )
    }
  }

  return (
    <div style={{ height: 400, width: '100%', overflow: 'scroll' }}>
      {groups ? <FilterableTable
        namespace="Groups"
        initialSort="group_name"
        data={groups ? groups : null}
        fields={fields}
        topPagerVisible={false}
        recordCountName='groups'
        recordCountNamePlural='groups'
        noRecordsMessage="There are no groups to display."
        noFilteredRecordsMessage="No group members match your filters."
      /> : null}

    </div>
  );
}