import React, { useState, useEffect } from 'react'

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import FormGroup from '@mui/material/FormGroup';
import Select from 'react-select';

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField";
import CCDatePicker from '../../pickers/CCDatePicker';


import PropTypes from 'prop-types'
import { Button } from '@mui/material';
import { updateCohortDetails } from '../../../redux/actions/organizationActions';
import StandardButton from '../../buttons/StandardButton';

import { colors } from '../../../constants/Colors'
import { getAxiosInstance } from '../../../utils/ApiUtils';

import config from '../../../env.json'

export default function EditCohortDetailsModal(props) {

  const { BASE_URI } = config

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    isVisible,
    closeModal,
    token,
    cohortId,
    cohortName,
    startDate,
    endDate,
    focusArea,
    currentCohorts,
    checkInsEnabled,
    checkInDayOfWeek,
    checkInStartDate,
    checkInEndDate,
  } = props

  const [updatedCohortName, setUpdatedCohortName] = useState('')
  const [updatedStartDate, setUpdatedStartDate] = useState(null)
  const [updatedEndDate, setUpdatedEndDate] = useState(null)
  const [updatedCheckInDate, setUpdatedCheckInDate] = useState(null)
  const [dbFocusAreas, setDbFocusAreas] = useState(null)
  const [selectedFocusArea, setSelectedFocusArea] = useState(null)

  const [checkInFalseSelected, setCheckInFalseSelected] = useState(false)
  const [checkInTrueSelected, setCheckInTrueSelected] = useState(false)

  useEffect(() => {
    getAxiosInstance(token).get(`${BASE_URI}/org/cohorts/focusAreaValues`)
    .then((res) => {
      if (res.status == 200) {

        let focusAreaList = []
        res.data.map((focusArea) => {
          focusAreaList.push({ value: focusArea, label: focusArea })
        })
        setDbFocusAreas(focusAreaList)
      }
    })
  }, [token])

  const handleCheckInStateChange = (type, value) => {
    switch (type) {
      case 'no':
        setCheckInFalseSelected(true)
        setCheckInTrueSelected(false)
        break;
      case 'yes':
        setCheckInFalseSelected(false)
        setCheckInTrueSelected(true)
        break;

      default:
        break;
    }
  }

  const renderRoles = () => {
    return ['ADMIN', 'FACULTY', 'STAFF'].map((type) => {
      return (
        <MenuItem value={type}>{type}</MenuItem>
      )
    })
  }

  const updateCohortPressed = () => {
    const cohortConfig = {
      id: cohortId,
      name: updatedCohortName,
      startDate: updatedStartDate,
      endDate: updatedEndDate,
      focusArea: selectedFocusArea ? selectedFocusArea.value : null
    }

    dispatch(updateCohortDetails(cohortConfig, token, currentCohorts, closeModal, navigate))
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 250,
    }),
  };

  const handleFocusAreaChange = (selectedOption) => {
    setSelectedFocusArea(selectedOption)
  };



  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isVisible}
      onClose={() => {
        closeModal()
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isVisible}>
        <Box sx={style}>
          <Stack>
            <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4 }}>COHORT DETAILS</Typography>
            <FormControl>
              <Typography style={{ paddingTop: 10 }}>Cohort Name </Typography>
              <TextField
                // label="Email"
                type="text"
                defaultValue={updatedCohortName !== '' ? updatedCohortName : cohortName}
                onChange={(event) => setUpdatedCohortName(event.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
              />

              <Stack direction='row'>
                <Stack>
                  <Typography>Cohort Start Date </Typography>
                  <CCDatePicker
                    defaultDate={startDate}
                    updatedDate={updatedStartDate}
                    setDate={(date) => setUpdatedStartDate(date.$d)}
                  />
                </Stack>
                <Stack style={{ marginLeft: 150 }}>
                  <Typography>Cohort End Date </Typography>
                  <CCDatePicker
                    defaultDate={endDate}
                    updatedDate={updatedEndDate}
                    setDate={(date) => setUpdatedEndDate(date.$d)}
                  />
                </Stack>
              </Stack>
              {/* <Stack style={{ marginTop: 20 }}>
                <Typography style={{ fontSize: 20, textDecoration: 'underline', textDecorationColor: colors.oceanBlue, textDecorationThickness: 4, textUnderlineOffset: 4 }}>CHECK INS</Typography>
                <Stack direction='row' style={{ alignContent: 'center', alignItems: 'center' }}>
                  <Typography style={{ color: colors.plum, fontWeight: 'bold' }}>Enabled for Cohort:</Typography>
                  <RadioGroup
                    style={{ marginLeft: 10 }}
                    row
                    aria-labelledby="check-ins-row-radio-buttons-group-label"
                    name="check-ins-row-radio-buttons-group"
                  >
                    <FormControlLabel value="no" control={<Radio checked={checkInFalseSelected} onChange={(value) => handleCheckInStateChange('no', value)} />} label="No" />
                    <FormControlLabel value="yes" control={<Radio checked={checkInTrueSelected} onChange={(value) => handleCheckInStateChange('yes', value)} />} label="Yes" />
                  </RadioGroup>
                </Stack>
              </Stack> */}
            </FormControl>
            <FormGroup className="mb-3" controlId="formBasicEmail">
              <Typography style={{ marginBottom: 15 }}>Cohort Focus Area<span style={{color: colors.blazeRed, paddingTop: 10}}>*</span></Typography>
              <Select
                value={selectedFocusArea ? selectedFocusArea : { value: focusArea, label: focusArea }}
                onChange={handleFocusAreaChange}
                options={dbFocusAreas}
                styles={customStyles}
              />
            </FormGroup>
          </Stack>
          <Stack direction='row' spacing={10} style={{ marginTop: 50, justifyContent: 'center', alignItems: 'center' }}>
            <Button
              variant='text'
              onClick={() => {
                setUpdatedCohortName('')
                setUpdatedStartDate(null)
                setUpdatedEndDate(null)
                closeModal()
              }}
            >
              CANCEL
            </Button>
            <StandardButton
              label='UPDATE'
              backgroundColor={colors.oceanBlue}
              onPress={() => updateCohortPressed()}
            />
          </Stack>
        </Box>
      </Fade>
    </Modal>
  )
}

EditCohortDetailsModal.propTypes = {
  isVisible: PropTypes.bool,
  closeModal: PropTypes.func,
  token: PropTypes.string,
  cohortId: PropTypes.string,
  cohortName: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  checkInsEnabled: PropTypes.bool,
  checkInDayOfWeek: PropTypes.string,
  checkInStartDate: PropTypes.string,
  checkInEndDate: PropTypes.string,
  currentCohorts: PropTypes.array,

}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height: '80vh',
  width: 850,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
  overflowY: 'auto',
};