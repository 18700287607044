import React, { useState } from 'react'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import MAGNIFYING_GLASS from '../../assets/sad-magnifying-glass.png'

import PropTypes from 'prop-types'

import styles from './AssessmentsTable.module.css'

import { getFormattedDate } from '../../utils/DateUtils'
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

export default function AssessmentTable(props) {

  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const {
    assessments,
    showCompletionPercentage,
    setReultsModalVisible,
    setSelectedAssessmentId
  } = props

  const renderByTitleCasing = (interviewType) => {
    switch (interviewType) {
      case 'BEHAVIORAL':
        return 'Behavioral'
      case 'BEHAVIORAL_TECHNICAL':
        return 'Behavioral & Technical'
      case 'PROFESSIONAL':
        return 'Professional'
      case 'BEHAVIORAL_PROFESSIONAL':
        return 'Behavioral/Professional'
      case 'TECHNICAL':
        return 'Technical'
      default:
      // Handle edge case
    }
  }

  const renderAssessments = () => {
    if (isLoading) {
      return (
        <Box className={styles.loaderWrapper}>
          <CircularProgress color="secondary" />
        </Box>
      )
    }
    else if (assessments) {
      return assessments.map((assessment) => {
        if (assessment) {
          return (
            <TableRow
              key={assessment.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell variant="body" scope="row">
                <Typography
                  className={styles.interviewTypeTableText}
                  onClick={() => navigate(`/interview/summary/${assessment.id}`)}
                >
                  {assessment.assessmentName}
                </Typography>
              </TableCell>
              <TableCell align='left'>
                <Typography className={styles.interviewDateTableText}>{renderByTitleCasing(assessment.interviewType) || 'Not availabe'}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={styles.interviewDateTableText}>{getFormattedDate(assessment.assessmentEndDate)}</Typography>
              </TableCell>
              {showCompletionPercentage ?  <TableCell align="left">
                <Button 
                  variant='contained'
                  style={{backgroundColor: 'silver', color: 'black'}}
                  onClick={() => {
                    setSelectedAssessmentId(assessment.id)
                    setReultsModalVisible(true)
                  }}
                >
                  View
                  </Button>
              </TableCell> : null}
             
            </TableRow>
          )
        }
      })
    }
    else {
      return <Typography>Interviews you've finished will show up here.</Typography>
    }
  }




  return <Grid style={{ alignItems: 'flex-start', marginTop: 10 }}>
    <TableContainer sx={{ maxHeight: 440 }} component={Paper}>
      <Table stickyHeader style={{ minWidth: 650, }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Interview Type</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="left">Due Date</TableCell>
            {showCompletionPercentage ? <TableCell sx={{ fontWeight: 'bold' }} align="left">Results</TableCell> : null}

          </TableRow>
        </TableHead>
        <TableBody style={{ width: '100%' }}>
          {assessments && assessments.length > 0 ? renderAssessments() :
            <Stack className={styles.emptyStateWrapper}>
              <Typography className={styles.emptyStateText}>No assessments found.</Typography>
            </Stack>
          }
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>

}


AssessmentTable.propTypes = {
  assessments: PropTypes.array.isRequired,
  showCompletionPercentage: PropTypes.bool.isRequired,
  setReultsModalVisible: PropTypes.func.isRequired,
  setSelectedAssessmentId: PropTypes.func
}